import React from 'react';
import Button from '@material-ui/core/Button';
import { useSetLocale, useLocale } from 'react-admin';
import Cookies from '../../helpers/Cookies';

const changeLocale = (language, setLocale) => {
    setLocale(language);
    Cookies.setCookie('locale', language, 30);
    document.location.reload()
}

const LocaleSwitcher = () => {
    const setLocale = useSetLocale();
    const locale = useLocale();
    return (
        <div>
            {locale === 'ar' && <Button
                onClick={() => changeLocale('en', setLocale)}
                style={{ color: 'inherit' }}
            >English</Button>}
            {locale === 'en' &&
                <Button
                    onClick={() => changeLocale('ar', setLocale)}
                    style={{ color: 'inherit' }}
                >عربي</Button>
            }
            {}
        </div>
    );

}

export default LocaleSwitcher;
