import React from 'react';
import { TextField, DateField, Datagrid, Pagination } from 'react-admin';
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import List from './theme/components/List'

export const LogEventList = (props) => (
    <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={false} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}{...props}>
        <Datagrid>
              
            <TextField source="name" />
            <DateField source="created_at" locales="en-GB" showTime />
        </Datagrid>
    </List>
);
