import React, { useState, useEffect } from 'react';
import {
    Create, Edit, SimpleForm, TextInput, Pagination, RichTextField,
    Datagrid, TextField, Filter, SelectInput, FormDataConsumer, useLocale,
    NumberInput, Tab, Show, TabbedShowLayout, DateField, ShowButton, ChipField,
    ReferenceManyField, ReferenceField, ReferenceInput, required, SimpleShowLayout,FunctionField
} from 'react-admin';
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import List from './theme/components/List'
import httpClient from './httpClient'
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { EntityLocation, LaunchIconLink } from './entityLocation'
import { UnitField, MinutesField, IrrigationReasonField } from './helpers/irrigationComponents';

const useStyles = makeStyles({
    chipBackground: {
        backgroundColor: '#00000022'
    }
});

const genderObject = {
    Male: 'ذكر',
    Female: 'أنثى'
}

const GenderField = props => {
    const locale = useLocale();
    return <span>{locale === 'en' ? props.record.gender : genderObject[props.record.gender]}</span>;
}

export const PalmList = (props) => {
    const locale = useLocale();
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} filters={<PalmFilter />} {...props}>
            <Datagrid>
                  
                <LaunchIconLink source={locale === 'en' ? "Location" : "الموقع"} tab={2} />
                <TextField source="qr_value" />
                <ReferenceField source="palm_type" reference="palmtypes" allowEmpty={true} >
                    <TextField source={locale === 'en' ? "type" : "type_ar"} />
                </ReferenceField>
                <TextField source="palm_number" />
                <GenderField source="gender" />
                <TextField source="age" />
                <TextField source={locale === 'en' ? "health_status" : "health_status_ar"} />
                <TextField source="current_sprouts" />
                <TextField source="splitted_sprouts" />
                <TextField source="current_appendages" />
                <TextField source="splitted_appendages" />
                <ReferenceField reference="farms" source="farm_id" allowEmpty={true} >
                    <TextField source="farm_code" />
                </ReferenceField>
                <ReferenceField reference="blocks" source="block_id" allowEmpty={true} >
                    <TextField source="qr_value" />
                </ReferenceField>
                <ShowButton />
            </Datagrid>
        </List>
    )
};

const CustomSelectInput = props => {
    const choices = props.choices.filter(block => block.farm_id === props.farm_id);
    return (<SelectInput optionText="qr_value" {...props} choices={choices} />);
}

export const PalmEdit = props => (
    <Edit undoable={false} {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar />} className={clsx('tab-rtl-layout')}>
            <TextInput disabled source="id" />
            <TextInput disabled source="qr_value" />
            <ReferenceInput perPage={-1} source="palm_type" reference="palmtypes" validate={required()} >
                <SelectInput optionText="type" />
            </ReferenceInput>
            <SelectInput source="gender" choices={[
                { id: 'Male', name: 'Male' },
                { id: 'Female', name: 'Female' }
            ]} />
            <NumberInput source="age" />
            <TextInput source="health_status" />
            <NumberInput source="row" />
            <NumberInput source="column" />
            <NumberInput source="palm_number" />
            <NumberInput source="average_production_per_branch" />
            <NumberInput source="date_branches" />
            <NumberInput source="total_drips" />
            <NumberInput source="broken_drips" />
            <NumberInput source="blocked_drips" />
            <ReferenceInput perPage={-1} source="farm_id" reference="farms" validate={required()} >
                <SelectInput optionText="farm_code" />
            </ReferenceInput>
            <FormDataConsumer>
                {({ formData, ...rest }) => (formData.farm_id &&
                    <ReferenceInput perPage={-1} source="block_id" reference="blocks" {...rest} validate={required()} >
                        <CustomSelectInput farm_id={formData.farm_id} />
                    </ReferenceInput>
                )}
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
);

export const PalmCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list" className={clsx('tab-rtl-layout')}>
            <TextInput source="qr_value" />
            <ReferenceInput perPage={-1} source="palm_type" reference="palmtypes" validate={required()} >
                <SelectInput optionText="type" />
            </ReferenceInput>
            <SelectInput source="gender" choices={[
                { id: 'Male', name: 'Male' },
                { id: 'Female', name: 'Female' }
            ]} />
            <NumberInput source="age" />
            <TextInput source="health_status" />
            <NumberInput source="row" />
            <NumberInput source="column" />
            <NumberInput source="palm_number" />
            <NumberInput source="average_production_per_branch" />
            <NumberInput source="date_branches" />
            <NumberInput source="total_drips" />
            <NumberInput source="broken_drips" />
            <NumberInput source="blocked_drips" />
            <ReferenceInput perPage={-1} source="farm_id" reference="farms" validate={required()} >
                <SelectInput optionText="farm_code" />
            </ReferenceInput>
            <FormDataConsumer>
                {({ formData, ...rest }) => (formData.farm_id &&
                    <ReferenceInput perPage={-1} source="block_id" reference="blocks" {...rest} validate={required()} >
                        <CustomSelectInput farm_id={formData.farm_id} />
                    </ReferenceInput>
                )}
            </FormDataConsumer>
        </SimpleForm>
    </Create>
);

const MyChipList = props => {
    const locale = useLocale();
    const classes = useStyles();
    try {
        const activity = JSON.parse(props.record.activity_actions);
        if (activity.length > 0)
            return activity.map(chip => <ChipField className={classes.chipBackground} source="id" record={{ id: chip }} />)
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

const MyTextField = props => {
    const locale = useLocale();
    return <span>{locale === 'en' ? props.record[props.source] : props.arabic[props.record[props.source]]}</span>
}

const BunchCoverField = props => {
    const locale = useLocale();
    return <span>{locale === 'en' ? (props.record[props.source] ? "Yes" : "No") : (props.record[props.source] ? "نعم" : "لا")}</span>
}

const PalmActivity = (props) => {
    const locale = useLocale();
    const [activity, setActivity] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/palmactivitytypes?id=${props.record.activity_id}`);
            if (response.json.length > 0)
                setActivity(response.json[0].activity);
        };
        if (props.record.activity_id)
            fetchData();
    }, [props.record.activity_id]);
    return (
        <Show {...props} title=" ">
            <SimpleShowLayout className={clsx('tab-rtl-layout')}>
                {props.record.chemical_amount &&
                    <Tab label="Chemicals">
                        <ReferenceField reference="chemicaltypes" source="chemicaltype_id" allowEmpty={true} >
                            <TextField source="type" />
                        </ReferenceField>
                        <TextField source="chemical_amount" />
                        <TextField source="chemical_unit" />
                    </Tab>
                }
                {props.record.yield_amount &&
                    <Tab label="Yield">
                        <TextField source="yield_amount" />
                        <TextField source="yield_unit" />
                    </Tab>
                }
                {props.record.pesttype_id &&
                    <Tab label="Pest">
                        <ReferenceField reference="pests" source="pesttype_id"  label={locale =="ar"?"نوع الافة":"pest type"}>
                            <TextField source={locale ==="ar"? "name_ar":"name"} />
                        </ReferenceField>
                        <ReferenceField reference="infestationtechniques" source="technique_id"  label={locale =="ar"?"طريقة الكشف":"Infestation technique"}>
                            <TextField source={locale ==="ar"? "technique_ar" : "technique"} />
                        </ReferenceField>
                        <RichTextField source="notes" label={locale ==="ar"? "ملاحظات":"Notes"} />
                    </Tab>
                }
                {props.record.current_sprouts &&
                    <Tab label="Sprouts">
                        <TextField source="current_sprouts" />
                        <TextField source="splitted_sprouts" />
                    </Tab>
                }
                {props.record.current_appendages &&
                    <Tab label="Appendages">
                        <TextField source="current_appendages" />
                        <TextField source="splitted_appendages" />
                    </Tab>
                }
                {props.record.fertilizer_amount &&
                    <Tab label="Fertilization">
                        <ReferenceField source="fertilizertype_id" reference="palmfertilizationtypes"
                            label={locale === 'en' ? "Fertilizer Type" : "نوع التسميد"} allowEmpty={true}>
                            <TextField source={locale === 'en' ? "name" : "name_ar"} />
                        </ReferenceField>
                        <ReferenceField source="fertilizer_subcategory_id" reference="inventorysubcategories"
                            label={locale === 'en' ? "Fertilizer Subcategory" : "نوع التسميد الفرعي"} allowEmpty={true}>
                            <TextField source={locale === 'en' ? "name" : "name_ar"} />
                        </ReferenceField>
                        <TextField source="fertilizer_amount" label={locale === 'en' ? "Fertilizer Amount" : "كمية التسميد"} />
                    </Tab>
                }
                {activity === 'Irrigation' &&
                    <Tab label="Irrigation">
                        <MinutesField source="actual_irrigation_time" addLabel />
                        <MinutesField source="projected_irrigation_time" addLabel />
                        <UnitField source="actual_irrigated_water" unit="Liter" unit_ar="لتر" addLabel />
                        <UnitField source="projected_irrigation_water" unit="Liter" unit_ar="لتر" addLabel />
                        <IrrigationReasonField source="irrigation_reason" addLabel />
                    </Tab>
                }
                {props.record.yield_sorting &&
                    <Tab label="yield_sorting">
                        <UnitField source="yield_sorting" unit="KG" unit_ar="كيلو جرام" label={locale === 'en' ? "Quantity" : "الكمية"} addLabel />
                    </Tab>
                }
                {activity === 'Land Preparation' &&
                    <Tab label="Land Preparation">
                        <MyTextField source="activity_mechanism" arabic={{ 'Land Grading': 'Land Grading', 'Land Plowing': 'Land Plowing' }}
                            label={locale === 'en' ? 'Land Preparation Type' : "طريقة تجهيز الارض"} addLabel />
                    </Tab>
                }
                {activity === 'Yeild' &&
                    <Tab label="Yeild">
                        <UnitField source="actual_production" unit="KG" unit_ar="كيلو جرام" addLabel />
                        <MyChipList source="activity_actions" label={locale === 'en' ? 'Yield Mechanism' : "طريقة الحصاد"} addLabel />
                    </Tab>
                }
                {activity === 'Pruning' &&
                    <Tab label="Pruning">
                        <MyTextField source="activity_mechanism" arabic={{ 'Automatic': 'تلقائي', 'Manual': 'عادي' }}
                            label={locale === 'en' ? 'Pruning Mechanism' : "طريقة التقليم"} addLabel />
                        <MyChipList source="activity_actions" label={locale === 'en' ? 'Pruning Type' : "نوع التقليم"} addLabel />
                    </Tab>
                }
                {activity === 'Thinning' &&
                    <Tab label="Thinning">
                        <MyTextField source="activity_mechanism" arabic={{ 'Automatic': 'تلقائي', 'Manual': 'عادي' }}
                            label={locale === 'en' ? 'Thinning Mechanism' : "طريقة الخف"} addLabel />
                        <MyChipList source="activity_actions" label={locale === 'en' ? 'Thinning Type' : "نوع الخف"} addLabel />
                    </Tab>
                }
                {activity === 'Pollination' &&
                    <Tab label="Pollination">
                        <TextField source="pollinated_branches" />
                        <MyTextField source="activity_mechanism" arabic={{ 'Automatic': 'تلقائي', 'Manual': 'عادي' }}
                            label={locale === 'en' ? 'Pollination Mechanism' : "طريقة التلقيح"} addLabel />
                        <BunchCoverField source="bunch_cover" label={"Bunch Cover"} addLabel />
                    </Tab>
                }
                {activity === 'Weed Control' &&
                    <Tab label="Weed Control">
                        <MyTextField source="activity_mechanism" arabic={{ 'Automatic': 'تلقائي', 'Manual': 'عادي' }}
                            label={locale === 'en' ? 'Weed Control Type' : "نوع الإزالة"} addLabel />
                    </Tab>
                }
                {activity === 'Dethroning' &&
                    <Tab label="Dethroning">
                        <MyTextField source="dethroning" arabic={{ 'Automatic': 'تلقائي', 'Manual': 'عادي' }}
                            label={locale === 'en' ? 'Dethroning Type' : "نوع الإزالة"} addLabel />
                    </Tab>
                }
                {activity === 'Faulty Drips' &&
                    <Tab label="Faulty Drips">
                        <TextField source="properly_working_drips" />
                        <TextField source="current_broken_drips" />
                        <TextField source="current_blocked_drips" />
                    </Tab>
                }
                {activity === 'Drips Maintenance' &&
                    <Tab label="Drips Maintenance">
                        <TextField source="properly_working_drips" />
                        <TextField source="current_broken_drips" />
                        <TextField source="current_blocked_drips" />
                        <TextField source="broken_drips_maintained" />
                        <TextField source="blocked_drips_maintained" />
                    </Tab>
                }
                {props.record.total_palms_number &&
                    <TextField source="total_palms_number" />
                }
                <ReferenceManyField perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
                    reference="workerlogs" target="activity_id" label={locale === 'en' ? "Workers" : "العمال"}>
                    <Datagrid>
                          
                        <ReferenceField source="worker_id" reference="workers" allowEmpty={true}>
                            <TextField source="name" />
                        </ReferenceField>
                        <TextField source="hours" />
                        <TextField source="rating" />
                        <TextField source="palms_number" />
                        <TextField source="amount" />
                        <TextField source="notes" />
                    </Datagrid>
                </ReferenceManyField>
            </SimpleShowLayout>
        </Show>
    )
};
const PalmName = ({ record }) => {
    const locale = useLocale();
    return <span>{record ? (locale === 'en' ? record.qr_value : record.qr_value) : ''}</span>;
}
export const PalmShow = (props) => {
    const locale = useLocale();
    return (
        <Show {...props} title={<PalmName />}>
            <TabbedShowLayout>
                <Tab label={locale === 'en' ? "summary" : "الملخص"} contentClassName={clsx('tab-rtl-layout')}>
                      
                    <TextField source="qr_value" />
                    <ReferenceField source="palm_type" reference="palmtypes" allowEmpty={true} >
                        <TextField source={locale === 'en' ? "type" : "type_ar"} />
                    </ReferenceField>
                    <GenderField source="gender" />
                    <TextField source="age" />
                    <TextField source={locale === 'en' ? "health_status" : "health_status_ar"} />
                    <TextField source="row" />
                    <TextField source="column" />
                    <TextField source="palm_number" />
                    <TextField source="current_sprouts" />
                    <TextField source="splitted_sprouts" />
                    <TextField source="current_appendages" />
                    <TextField source="splitted_appendages" />
                    <TextField source="average_production_per_branch" />
                    <TextField source="date_branches" />
                    <TextField source="projected_production" />
                    <TextField source="actual_production" />
                    <TextField source="total_drips" />
                    <TextField source="broken_drips" />
                    <TextField source="blocked_drips" />
                    <DateField source="created_at" locales="en-GB" />
                    <ReferenceField reference="farms" source="farm_id" allowEmpty={true} >
                        <TextField source="farm_code" />
                    </ReferenceField>
                    <ReferenceField reference="blocks" source="block_id" allowEmpty={true} >
                        <TextField source="qr_value" />
                    </ReferenceField>
                </Tab>
                <Tab label={locale === 'en' ? "Activities" : "السجلات"}>
                    <ReferenceManyField perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} reference="palmlogs" target="palm_id" addLabel={false}>
                        <Datagrid expand={<PalmActivity />}>
                              
                            <ReferenceField source="activity_id" reference="palmactivitytypes" allowEmpty={true} >
                                <TextField source={locale === 'en' ? "activity" : "activity_ar"} />
                            </ReferenceField>
                            <ReferenceField source="created_by" reference="users" allowEmpty={true} >
                                <TextField source="username" />
                            </ReferenceField>
                            <DateField source="created_at" locales="en-GB" showTime />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                <Tab label={locale === 'en' ? "Location" : "الموقع"}>
                    <EntityLocation lat="lat" lng="long" />
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
};

const PalmFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput perPage={-1} source="palm_type" reference="palmtypes" >
            <SelectInput optionText="type" />
        </ReferenceInput>
        <TextInput source="row" />
        <TextInput source="column" />
        <TextInput source="palm_number" />
        <ReferenceInput perPage={-1} source="block_id" reference="blocks" >
            <SelectInput optionText="qr_value" />
        </ReferenceInput>
        <ReferenceInput perPage={-1} source="farm_id" reference="farms" >
            <SelectInput optionText="farm_code" />
        </ReferenceInput>
    </Filter>
);
