import React from 'react';
import {
    Create, Edit, SimpleForm, TextInput, Show, Tab,
    EditButton, Datagrid, TextField, useLocale, Pagination,
    TabbedShowLayout, ReferenceInput, SelectInput, ReferenceField,
    ShowButton, ReferenceManyField
} from 'react-admin';
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import List from './theme/components/List'
import { create } from 'jss';
import rtl from 'jss-rtl';
import JssProvider from 'react-jss/lib/JssProvider';
import { jssPreset } from '@material-ui/core/styles';
import clsx from 'clsx';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

export const InventoryList = (props) => {
    const locale = useLocale();
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}{...props}>
            <Datagrid>
                  
                <TextField source="qr_value" />
                <TextField source={locale === 'en' ? "name" : "name_ar"} />
                <ReferenceField source="inventorytype_id" reference="inventorytypes" allowEmpty={true} >
                    <TextField source={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceField>
                <ReferenceField source="farm_id" reference="farms" allowEmpty={true} >
                    <TextField source="farm_code" />
                </ReferenceField>
                <EditButton />
                <ShowButton />
            </Datagrid>
        </List>
    )
};

export const InventoryEdit = props => {
    const locale = useLocale();
    return (
        <Edit undoable={false} {...props}>
            <SimpleForm redirect="list" toolbar={<CustomToolbar />} className={clsx('tab-rtl-layout')}>
                <TextInput disabled source="id" />
                <TextInput source="qr_value" />
                <TextInput source="name" />
                <TextInput source="name_ar" />
                <ReferenceInput perPage={-1} source="inventorytype_id" reference="inventorytypes" >
                    <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceInput>
                <ReferenceInput perPage={-1} source="farm_id" reference="farms" >
                    <SelectInput optionText="farm_code" />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    )
};

export const InventoryCreate = props => {
    const locale = useLocale();
    return (
        <Create {...props}>
            <SimpleForm redirect="list" className={clsx('tab-rtl-layout')}>
                <TextInput source="qr_value" />
                <TextInput source="name" />
                <TextInput source="name_ar" />
                <ReferenceInput perPage={-1} source="inventorytype_id" reference="inventorytypes" >
                    <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceInput>
                <ReferenceInput perPage={-1} source="farm_id" reference="farms" >
                    <SelectInput optionText="farm_code" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    )
};

const CustomField = ({ record }) => {
    const locale = useLocale();
    return <span className="MuiTypography-root-167 MuiTypography-body1-176">
        {(record.movement) ?
            (locale === 'en' ? "In" : "وارد") : (locale === 'en' ? "Out" : "صادر")}
    </span>
};

const CustomLabel = props => {
    const locale = useLocale();
    return (
        <ReferenceField {...props} source="subcategory_id" reference="inventorysubcategories"  >
            <ReferenceField source="unit_id" reference="units" allowEmpty={true}>
                <TextField source={locale === 'en' ? "name" : "name_ar"} />
            </ReferenceField>
        </ReferenceField>
    );
}

const EntityField = props => {
    return <ReferenceField reference={props.record.entity_type === 'block' ? 'blocks' : 'pivots'} {...props} >
        <TextField source="qr_value" />
    </ReferenceField>
}
const InventoryName = ({ record }) => {
    const locale = useLocale();
    return <span>{record ? (locale === 'en' ? record.name : record.name_ar) : ''}</span>;
}
export const InventoryShow = (props) => {
    const locale = useLocale();
    return (
        <JssProvider jss={jss}>
            <Show {...props} title={<InventoryName />}>
                <TabbedShowLayout >
                    <Tab label={locale === 'en' ? "summary" : "الملخص"} contentClassName={clsx('tab-rtl-layout')}>
                          
                        <TextField source="qr_value" />
                        <TextField source={locale === 'en' ? "name" : "name_ar"} />
                        <ReferenceField source="inventorytype_id" reference="inventorytypes" allowEmpty={true} >
                            <TextField source={locale === 'en' ? "name" : "name_ar"} />
                        </ReferenceField>
                        <ReferenceField source="farm_id" reference="farms" allowEmpty={true} >
                            <TextField source="farm_code" />
                        </ReferenceField>
                    </Tab>
                    <Tab label={locale === 'en' ? "Balance" : "الرصيد"}>
                        <ReferenceManyField perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
                            reference="inventoryevents/balance" target="inventory_id" addLabel={false}>
                            <Datagrid>
                                <ReferenceField source="category_id" reference="inventorycategories" >
                                    <TextField source={locale === 'en' ? "name" : "name_ar"} />
                                </ReferenceField>
                                <ReferenceField source="subcategory_id" reference="inventorysubcategories" >
                                    <TextField source={locale === 'en' ? "name" : "name_ar"} />
                                </ReferenceField>
                                <CustomLabel source={locale === 'en' ? "Unit" : "الوحدة"} />
                                <TextField source="balance" />
                            </Datagrid>
                        </ReferenceManyField>
                    </Tab>
                </TabbedShowLayout>
            </Show>
        </JssProvider>
    )
};
