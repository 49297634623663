import React from 'react';
import {
    Create, Edit, SimpleForm, TextInput,
    EditButton, Datagrid, TextField, useLocale, Pagination
} from 'react-admin';
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import List from './theme/components/List'
import clsx from 'clsx';

export const TrapTypeList = (props) => {
    const locale = useLocale();
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}{...props}>
            <Datagrid>
                  
                <TextField source={locale === 'en' ? "type" : "type_ar"} />
                <EditButton />
            </Datagrid>
        </List>
    )
};

export const TrapTypeEdit = props => (
    <Edit undoable={false} {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar />} className={clsx('tab-rtl-layout')}>
            <TextInput disabled source="id" />
            <TextInput source="type" />
            <TextInput source="type_ar" />
        </SimpleForm>
    </Edit>
);

export const TrapTypeCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list" className={clsx('tab-rtl-layout')}>
            <TextInput source="type" />
            <TextInput source="type_ar" />
        </SimpleForm>
    </Create>
);
