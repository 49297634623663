import React, { Fragment, useState, useEffect  } from 'react';
import {
    Create, SimpleForm, TextInput, ReferenceInput,
    DateInput, SelectInput, BooleanInput,
    required, NumberInput, FormDataConsumer,
    minValue, maxValue, SelectArrayInput 
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { parse } from 'query-string';
import { useForm } from 'react-final-form';
import httpClient from './httpClient';
import { useLocale } from 'ra-core';
import clsx from 'clsx';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginTop: '1em',
        width:'16rem',
        display:"flex"
    }
}));

const InventoryInput = ({ formData, ...rest }) => {
    const locale = useLocale();
    const [data, setData] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/units`);
            const units = {};
            response.json.forEach(element => {
                units[element.id] = element;
            });
            setData(units);
        };
        fetchData();
    }, []);

    const [inventoryArray, setInventoryArray] = useState([['category_id0', 'subcategory_id0', 'amount0']]);

    function addNewItem() {
        const id = inventoryArray.length;
        const newItems = ['category_id', 'subcategory_id', 'amount'].map(item => item + id);
        setInventoryArray([...inventoryArray, newItems]);
    }

    function checkAddButton(formData) {
        return (formData[inventoryArray[inventoryArray.length - 1][2]]);
    }

    function deleteItem() {
        const lastRecord = inventoryArray.pop();
        for (let attribute of lastRecord) {
            delete formData[attribute];
        }
        setInventoryArray([...inventoryArray]);
    }

    function checkDeleteButton() {
        return inventoryArray.length > 1;
    }
    return <>

        {inventoryArray.map((record, key) => {
            return <ReferenceInput
                key={key}
                perPage={-1}
                source={record[0]}
                reference="inventorycategories"
                label={locale === 'en' ? 'Category' : 'الفئة'}
                validate={required()}
                style={locale === 'en' ? { marginRight: '1rem' } : { marginLeft: '1rem' }}
                {...rest}
            >
                <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
            </ReferenceInput>;
        })}

        {checkDeleteButton() ?
            <img src="https://nakheel-cdn.s3.eu-west-2.amazonaws.com/455-4552435_-remove-button-delete-denied-red-round-cancel.png"
                style={{ maxHeight: '2rem', margin: '1rem', cursor: 'pointer' }} onClick={deleteItem} />
            : null
        }

        {checkAddButton(formData) ?
            <img src="https://nakheel-cdn.s3.eu-west-2.amazonaws.com/add-icon-png-2459.png"
                style={{ maxHeight: '2rem', margin: '1rem', cursor: 'pointer' }} onClick={addNewItem} />
            : null}

        {inventoryArray.map((record, key) => {
            return (formData[record[0]] &&
                <Fragment key={key}>
                    {key === 0 && <br />}
                    <ReferenceInput
                        perPage={-1}
                        source={record[1]}
                        reference="inventorysubcategories"
                        label={locale === 'en' ? 'Sub Category' : 'الفئة الفرعية'}
                        filter={{ category_id: formData[record[0]] }}
                        validate={required()}
                        style={locale === 'en' ? { marginRight: '1rem' } : { marginLeft: '1rem' }}
                        {...rest}
                    >
                        <SelectInput optionText={(record) => {
                            if (data && data[record.unit_id])
                                return locale === 'en' ?
                                    record.name + ' - ' + data[record.unit_id].name :
                                    record.name_ar + ' - ' + data[record.unit_id].name_ar;
                            else
                                return locale === 'en' ?
                                    record.name :
                                    record.name_ar;
                        }} />
                    </ReferenceInput>
                </Fragment >
            )
        })}
        {inventoryArray.map((record, key) => {
            return (formData[record[1]] &&
                <Fragment key={key}>
                    {key === 0 && <br />}
                    <NumberInput
                        source={record[2]}
                        label={rest.amount_label}
                        validate={required()}
                        style={locale === 'en' ? { marginRight: '1rem' } : { marginLeft: '1rem' }}
                        {...rest}
                    />
                </Fragment >
            )
        })}
    </>;
}

const permissions = {
    'is_palm_tree': ['block', 'palm', 'trap'],
    'is_pivot': ['pivot', 'section']
};

const subActivities = {
    block: ["Fertilization", "Irrigation", 'Land Preparation'],
    palm: ["Fertilization", 'Pruning', 'Thinning', 'Pollination', 'Weed Control'],
    pivot: ["Fertilization", "Spray"],
    section: ["Fertilization", "Spray", "Machinery"]
}

const checkMechanism = sub_activity => {
    for (let activity of ['pruning', 'thinning', 'pollination', 'land preparation', 'weed control']) {
        if (sub_activity === activity) {
            return true;
        }
    }
    return false;
}

const checkActions = sub_activity => {
    for (let activity of ['pruning', 'thinning']) {
        if (sub_activity === activity) {
            return true;
        }
    }
    return false;
}

const pruningActions = ['Dry Fronds removal', 'Palm Crown Cleaning', 'Dethorning'];
const thinningActions = ['Partial Bunch removal', 'Bunch Trimming', 'Fruit Thinning'];

const SubActivity = props => {
    const { entity, activity_id,form, ...rest } = props;
    const [sub_activity, setSub_activity] = useState(null);
    const [automaticIrrigation , setAutomaticIrrigation] = useState("true");
    const handleIrrigationMethodChange = event => {
        event.target.value ==="true"?setAutomaticIrrigation("true"):setAutomaticIrrigation("false")
        form.change('required_water_per_palm_in_liters' , null)
      };
    
    const locale = useLocale();
    const classes = useStyles();
    useEffect(() => {
        const fetchData = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/${entity}activitytypes?id=${activity_id}`);
            if (response.json.length > 0)
                if (entity === 'block' && response.json[0].activity === 'Irrigation') {
                    setSub_activity(response.json[0].activity.toLowerCase());
                    return;
                }
                else
                    for (let activity of subActivities[entity]) {
                        if (response.json[0].activity.includes(activity)) {
                            setSub_activity(activity.toLowerCase());
                            return;
                        }
                    }
            setSub_activity(null);
        };
        if (activity_id)
            fetchData();
    }, [activity_id]);
    if (sub_activity) {
        if (sub_activity === 'irrigation') {
            return <Fragment>
                <br />
                <SelectInput source="irrigation_reason" choices={[
                    { id: 'Ordinary Irrigation', name: locale === 'en' ? 'Ordinary Irrigation' : 'ري اعتيادي' },
                    { id: 'Frost Irrigation', name: locale === 'en' ? 'Frost Irrigation' : 'ري الصقيع' }
                ]} {...rest} />
                <br />
         <FormControl variant="filled" classes={classes.formControl}>
            <InputLabel htmlFor="grouped-select">Irrigation Type</InputLabel>
            <Select   defaultValue="true" onChange={handleIrrigationMethodChange} style={{width:"16rem" , display:"flex"}} >
              <MenuItem value={"true"}>{locale ==="ar"?"رى تلقائى":"Automatic Irrigation"}</MenuItem>
              <MenuItem value={"false"}>{locale ==="ar"?"رى يدوى ":"Manual Irrigation"} </MenuItem>
            </Select>
          </FormControl>
                { automaticIrrigation==="false" ? <NumberInput 
                source="required_water_per_palm_in_liters"
                validate={[required()]}
                 label={locale ==="ar" ?"كمية المياه المطلوبة للنخلة باللتر":"required water per palm in litres"} style={{width:"16rem" , display:"flex"}}{...rest} /> : null}
                <br />
                {/* <NumberInput source="irrigation_periodicity" validate={[minValue(1), maxValue(7)]} {...rest} /> */}
            </Fragment>
        }
        else if (checkMechanism(sub_activity)) {
            const Mechanism = <Fragment key="Mechanism">
                <br />
                <SelectInput source="activity_mechanism" label={locale === 'en' ? "Mechanism" : "التقنية المستخدمة"} choices={
                    sub_activity === 'land preparation' ?
                        [
                            { id: 'Land Grading', name: locale === 'en' ? 'Land Grading' : 'Land Grading' },
                            { id: 'Land Plowing', name: locale === 'en' ? 'Land Plowing' : 'Land Plowing' }
                        ]
                        :
                        [
                            { id: 'Manual', name: locale === 'en' ? 'Manual' : 'عادي' },
                            { id: 'Automatic', name: locale === 'en' ? 'Automatic' : 'تلقائي' }
                        ]} {...rest} />
            </Fragment>

            let Actions = null;
            if (checkActions(sub_activity)) {
                Actions = <Fragment key="Actions">
                    <br />
                    <SelectArrayInput source="activity_actions" label={locale === 'en' ? "Actions" : "الإجراءات الفرعية"}
                        choices={
                            (sub_activity === 'pruning' ? pruningActions : thinningActions).map(action => ({ id: action, name: action }))
                        }
                        parse={v => {
                            return JSON.stringify(v);
                        }}
                        format={v => {
                            return typeof v === 'string' ? JSON.parse(v) : v;
                        }} {...rest} />
                </Fragment>
            }

            let Bunch_cover = null;
            if (sub_activity === 'pollination') {
                Bunch_cover = <Fragment key="Bunch">
                    <br />
                    <BooleanInput source="bunch_cover" label={locale === 'en' ? "Bunch Cover" : "Bunch Cover"} initialValue={false}
                        {...rest} />
                </Fragment>
            }
            return [Mechanism, Actions, Bunch_cover]
        }
        else {
            return <Fragment>
                <br />
                <ReferenceInput perPage={-1} source="sub_activity"
                    reference={`${entity === 'section' ? "pivot" : entity === 'block' ? "palm" : entity}${sub_activity}types`}
                    validate={required()} {...rest}>
                    <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceInput>
            </Fragment>
        }
    }
    return null;
}

const FormHook = ({ formData, data, ...rest }) => {
    const locale = useLocale();
    const form = useForm();
    const [codes, setCodes] = useState([]);
    const taskParams = ['sub_activity', 'bunch_cover', 'activity_actions', 'activity_mechanism', 'irrigation_periodicity', 'required_water_per_palm_in_liters', 'irrigation_reason'];
    const taskInventoryParams = ['category_id', 'subcategory_id', 'amount'];

    useEffect(() => {
        const fetchData = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/programs/${formData.program_id}`);
            const responseCodes = permissions[response.json.agriculture_type];
            setCodes(responseCodes);
        };
        if (formData.program_id)
            fetchData();
    }, []);
    return <Fragment>
        {codes.length > 0 && <ReferenceInput perPage={-1} source="targetlevel_id" reference="targetlevels"
            filter={{ code: codes }}
            onChange={() => {
                form.change('activitytype_id', null);
                for (let param of taskParams) {
                    form.change(param, null);
                }
                for (let attribute of Object.keys(formData)) {
                    for (let param of taskInventoryParams) {
                        if (attribute.includes(param)) {
                            form.change(attribute, null);
                        }
                    }
                }
            }} validate={required()} {...rest}>
            <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
        </ReferenceInput>}
        {formData.targetlevel_id && <br />}
        {(formData.targetlevel_id && data &&
            <ReferenceInput perPage={-1} source="activitytype_id" reference={`${data[formData.targetlevel_id]}activitytypes`}
                filter={(data[formData.targetlevel_id] === 'palm' || data[formData.targetlevel_id] === 'block') ? { viewable: true } : {}}
                onChange={() => {
                    for (let param of taskParams) {
                        form.change(param, null);
                    }
                    for (let attribute of Object.keys(formData)) {
                        for (let param of taskInventoryParams) {
                            if (attribute.includes(param)) {
                                form.change(attribute, null);
                            }
                        }
                    }
                }} {...rest} validate={required()} >
                <SelectInput optionText={locale === 'en' ? "activity" : "activity_ar"} />
            </ReferenceInput>
        )}
        {formData.activitytype_id ?
            <SubActivity entity={data[formData.targetlevel_id]} activity_id={formData.activitytype_id} form={form} {...rest} /> :
            null}
        <BooleanInput source="select_inventory" label={locale === 'en' ? "select_inventory" : "استخدام المخزن"} initialValue={false}
            onChange={() => {
                for (let attribute of Object.keys(formData)) {
                    for (let param of taskInventoryParams) {
                        if (attribute.includes(param)) {
                            form.change(attribute, null);
                        }
                    }
                }
            }} {...rest} />
    </Fragment>;
};

function checkSection(targetlevel_id, data) {
    if (data && data[targetlevel_id] === 'section') {
        return true;
    }
    return false
}

const targetlevelMap = {
    1: 'Trap',
    2: 'Block',
    3: 'Palm',
    4: 'Feddan',
    5: 'Feddan',
}

const DateField = ({ formData, data, ...rest }) => {
    const check = checkSection(formData.targetlevel_id, data);
    const form = useForm();
    const locale = useLocale();

    const [activityName, setActivityName] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/${data[formData.targetlevel_id]}activitytypes/${formData.activitytype_id}`);
            setActivityName(response.json && response.json.activity ? response.json.activity : null);
        };
        if (formData.activitytype_id)
            fetchData();
    }, [formData.activitytype_id]);

    return <Fragment>
        {check && <BooleanInput source="select_date" label={locale === 'en' ? "Use Plant Age" : "استخدام عمر النبات"} initialValue={false}
            onChange={() => {
                const dateParams = ["start_time", "end_time", "section_age", "irrigation_periodicity"];
                for (let param of dateParams) {
                    form.change(param, null);
                }
            }} {...rest} />}
        {(check && formData.select_date) ?
            <NumberInput source="section_age" label={locale === 'en' ? 'Plant Age' : 'عمر النبات'} validate={required()} {...rest} />
            :
            <Fragment>
                {activityName === 'Irrigation' && <Fragment>
                    <NumberInput source="irrigation_periodicity" validate={[minValue(1), maxValue(7)]} {...rest} />
                    <br />
                </Fragment>}
                <DateInput source="start_time" validate={required()} {...rest} />
                <br />
                <DateInput source="end_time" validate={required()} {...rest} />
            </Fragment>}
    </Fragment>
}

export const TaskCreate = props => {
    const { program_id: program_id_string } = parse(props.location.search);
    const program_id1 = program_id_string ? parseInt(program_id_string, 10) : '';
    const redirect = program_id1 ? `/programs/${program_id1}/show/1` : false;
    const [data, setData] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/targetlevels/codes`);
            setData(response.json);
        };
        fetchData();
    }, []);
    return (
        <Create {...props}>
            <SimpleForm redirect={redirect} initialValues={{ program_id: program_id1 }} className={clsx('tab-rtl-layout')}>
                <TextInput disabled source="program_id" />
                <TextInput source="name" validate={required()} />
                <TextInput source="name_ar" validate={required()} />
                <FormDataConsumer>
                    {formDataProps => (
                        <FormHook {...formDataProps} data={data} />
                    )}
                </FormDataConsumer>

                {/* Amount based on target level and activity */}
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        return formData.select_inventory &&
                            <InventoryInput formData={formData} amount_label={`Amount per ${targetlevelMap[formData.targetlevel_id]}`} {...rest} />;
                    }}
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        return formData.targetlevel_id &&
                            <NumberInput source="man_hour" label={`Man Hour required per ${targetlevelMap[formData.targetlevel_id]}`}
                                step={0.01} validate={required()} {...rest} />
                    }}
                </FormDataConsumer>
                <FormDataConsumer>
                    {(formDataProps) =>
                        <DateField {...formDataProps} data={data} />
                    }
                </FormDataConsumer>
                <TextInput multiline source="notes" />
            </SimpleForm>
        </Create>
    );
};
