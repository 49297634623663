import React from 'react'
import httpClient from './httpClient';
import {useLocale} from 'react-admin';
import Chart from 'react-apexcharts';
import {Grid, Card, CardContent} from '@material-ui/core';

const Locale = props => {
    const locale = useLocale();
    return locale === 'en' ? props.variable : props.variable_ar;
}

class DataChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [] };
    }

    async componentDidMount() {
        const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/devices/fetchdata?id=${this.props.record.id}`);
        this.setState({ data: response });
    }


    render() {
        const data = this.state.data.json;
        const groups = {}

        let Charts = null
        if(data){
        data.forEach(series => {

            if(series.length > 0){
                series.forEach(item => {
                    let groupName = item.variable

                    let groupTitle =  <Locale
                    variable={item.variable}
                    variable_ar={item.variable_ar}
                    />;

                 

                    let hasSubGroups = false

                    if(item.variable.indexOf("SM") !== -1){
                        groupName = "SM"
                        groupTitle =  <Locale
                                variable={"Volumetric Water Content"}
                                variable_ar={"Volumetric Water Content"}
                        />;

                        hasSubGroups = true
                 

                    if(!groups[groupName]){
                        groups[groupName] = []
                        groups[groupName]['title'] = groupTitle
                        groups[groupName]['unit'] = item.unit ? item.unit : null
                        groups[groupName]['data'] = hasSubGroups ?  {} : {'all':{'data':[]}}
                        groups[groupName].hasSubGroups = hasSubGroups
                        
                    }

                    if(hasSubGroups){
                        if(!groups[groupName]['data'][item.variable]){
                            groups[groupName]['data'][item.variable] = {
                                title : <Locale
                                variable={item.variable}
                                variable_ar={item.variable_ar}
                             />,
                                data: []
                            }
                        }

                        groups[groupName]['data'][item.variable]['data'].push(item)

                    }else{
                        groups[groupName]['data']['all']['data'].push(item)
                    }

       
                }
                    
                });
            }

            
        });



        const types = Object.keys(groups)

         Charts = types.map((type)=>{

            const collection = groups[type]

            const chartOptions = {
                title: {
                    text: "Volumetric Water Content",
                    align: 'center'
                },
                stroke: {
                    curve: 'smooth'
                },
                markers: {
                    size: 0
                },
                xaxis: {
                    type: 'datetime',
                },
                yaxis: {
                    title: {
                        text: collection.unit,
                        rotate: 0
                    },
                },
                chart: {
                    animations: {
                        enabled: false
                    }
                }
            };

               const subTypes = Object.keys(collection.data)



              const chartData =  subTypes.map((subType)=>{

                    const dataSeries = collection.data[subType]['data'].map(element => {
                        const date = new Date(Date.parse(element.time));
                        element.x = date.toLocaleString();
                        element.y = element.value;
                        return element;
                    })

                    dataSeries.sort(function(a,b){
                        return new Date(b.x) - new Date(a.x);
                    });

                    return   {
                        name: collection.hasSubGroups ? subType : type,
                        data: dataSeries
                    }

               })

            
        
             return <Grid item xs={12} md={12}>
               <Card className="card-box mb-4-spacing overflow-visible">
                   <div className="card-header">
                       <div className="card-header--title font-size-md font-weight-bold py-2">
                           {collection.title}
                       </div>
                   </div>
                   <CardContent className="p-3">
                       <Chart
                           height={300}
                           options={chartOptions}
                           series={chartData}
                           type="line"
                           key={type}
                       />
                   </CardContent>
               </Card>
           </Grid>


         })


    }
    


    console.log({Charts})

    return (data ?
        <Grid container spacing={6}>
           {Charts}
        </Grid>
        :
        <div></div>
);


        // return (data ?
        //         <Grid container spacing={6}>
        //             {data.map(
        //                 (variable, key) => {
        //                     if (variable.length > 0) {
        //                         const title = <Locale
        //                             variable={variable[0].variable}
        //                             variable_ar={variable[0].variable_ar}
        //                         />;
        //                         const unit = variable ? variable[0].unit : null
        //                         const options = {
        //                             title: {
        //                                 text: variable[0].variable,
        //                                 align: 'center'
        //                             },
        //                             stroke: {
        //                                 curve: 'smooth'
        //                             },
        //                             markers: {
        //                                 size: 0
        //                             },
        //                             xaxis: {
        //                                 type: 'datetime',
        //                             },
        //                             yaxis: {
        //                                 title: {
        //                                     text: unit,
        //                                     rotate: 0
        //                                 },
        //                             },
        //                             chart: {
        //                                 animations: {
        //                                     enabled: false
        //                                 }
        //                             }
        //                         };
        //                         const dataSeries = variable.map(element => {
        //                             const date = new Date(Date.parse(element.time));
        //                             element.x = date.toLocaleString();
        //                             element.y = element.value;
        //                             element.y = element.value;
        //                             return element;
        //                         })
        //                         const series = [
        //                             {
        //                                 name: variable[0].variable,
        //                                 data: dataSeries
        //                             }
        //                         ];
        //                         return (
        //                             <Grid item xs={12} md={6}>
        //                                 <Card className="card-box mb-4-spacing overflow-visible">
        //                                     <div className="card-header">
        //                                         <div className="card-header--title font-size-md font-weight-bold py-2">
        //                                             {title}
        //                                         </div>
        //                                     </div>
        //                                     <CardContent className="p-3">
        //                                         <Chart
        //                                             height={300}
        //                                             options={options}
        //                                             series={series}
        //                                             type="line"
        //                                             key={key}
        //                                         />
        //                                     </CardContent>
        //                                 </Card>
        //                             </Grid>
        //                         );
        //                     }
        //                     return null;
        //                 }
        //             )}
        //         </Grid>
        //         :
        //         <div></div>
        // );
    }
}


export default DataChart;
