import React from "react";
import { SvgIcon } from "@material-ui/core";

export const Scouting = () => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Capa_1"
      viewBox="0 0 480 480"
      style={{ enableBackground: "new 0 0 480 480" }}
      width="24px"
      height="24px"
      class=""
    >
      <g>
        <path
          style={{ fill: "#B28B12" }}
          d="M228.012,8C169.808,8.831,115.695,38.088,83.125,86.332c58.331-31.518,131.169-9.783,162.687,48.549  s9.783,131.168-48.548,162.687c-47.894,25.879-107.207,16.291-144.508-23.359C63.94,313.998,86.887,349.48,118.589,376h53.423  c8.823-0.014,15.986,7.127,16,15.949c0.012,7.382-5.036,13.811-12.21,15.55c16.841,5.589,34.466,8.458,52.21,8.5  c101.621,0,184-91.334,184-204S329.632,8,228.012,8z"
          data-original="#FED966"
          class="active-path"
          data-old_color="#FED966"
        />
        <circle
          style={{ fill: "#9DF992" }}
          cx="140.012"
          cy="192"
          r="120"
          data-original="#92B1F9"
          class=""
          data-old_color="#92B1F9"
        />
        <path
          style={{ fill: "#DFDEED" }}
          d="M138.266,136c-43.772,0-65.656,21.58-86.255,56c20.599,34.42,45.973,56,89.745,56  s65.656-21.58,86.255-56C207.413,157.58,182.038,136,138.266,136z"
          data-original="#DFDEED"
          class=""
        />
        <circle
          style={{ fill: "#7F7943" }}
          cx="140.012"
          cy="192"
          r="56"
          data-original="#7F7943"
        />
        <path
          style={{ fill: "#DFDEED" }}
          d="M331.06,168c-18.853-1.024-36.809,8.136-47.048,24c11.058,15.94,29.579,25.02,48.952,24  c18.853,1.024,36.809-8.136,47.048-24C368.954,176.06,350.433,166.98,331.06,168z"
          data-original="#DFDEED"
          class=""
        />
        <path
          style={{ fill: "#B28B12" }}
          d="M188.012,424c0-8.837-7.163-16-16-16c8.837,0,16-7.163,16-16s-7.163-16-16-16h-80v96h80  c8.837,0,16-7.163,16-16s-7.163-16-16-16C180.848,440,188.012,432.837,188.012,424z"
          data-original="#FED966"
          class="active-path"
          data-old_color="#FED966"
        />
        <circle
          style={{ fill: "#7F7943" }}
          cx="332.012"
          cy="192"
          r="16"
          data-original="#7F7943"
        />
        <path
          style={{ fill: "#CAC2A8" }}
          d="M140.012,312c-8.06,0.001-16.101-0.806-24-2.41V376h48v-66.41  C156.112,311.194,148.072,312.001,140.012,312z"
          data-original="#CAC2A8"
        />
        <path
          d="M100.012,192h16c0.014-13.249,10.751-23.986,24-24v-16C117.931,152.026,100.038,169.919,100.012,192z"
          data-original="#000000"
          class=""
        />
        <path
          d="M137.841,255.89c0.727,0.024,1.438,0.11,2.17,0.11c0.21,0,0.413-0.03,0.623-0.032c0.379,0.003,0.742,0.032,1.124,0.032  c47.539,0,71.606-23.945,93.117-59.891c1.516-2.53,1.516-5.689,0-8.219c-11.188-20.787-28.121-37.916-48.779-49.342l16.314-21.752  l-12.797-9.594l-18.94,25.253c-7.385-2.168-14.984-3.529-22.662-4.062V104h-16v24.201c-6.083,0.245-12.135,0.993-18.094,2.236  l-15.25-22.875l-13.312,8.875l12.699,19.049c-22.941,9.73-38.515,28.357-52.906,52.404c-1.516,2.53-1.516,5.689,0,8.219  C69.149,236.221,97.886,254.755,137.841,255.89L137.841,255.89z M92.012,192c0.036-26.136,20.964-47.446,47.095-47.954  c2.396,0.017,4.66,0.222,6.958,0.378c26.278,3.32,44.889,27.313,41.569,53.59c-2.987,23.646-22.887,41.511-46.717,41.939  c-2.396-0.017-4.66-0.222-6.958-0.378C110.007,236.513,92.05,216.147,92.012,192L92.012,192z M218.633,192.016  c-5.73,9.598-12.585,18.477-20.42,26.45c7.37-16.034,7.73-34.414,0.992-50.724C206.624,175.031,213.146,183.18,218.633,192.016z   M81.81,165.535c-7.37,16.034-7.73,34.414-0.992,50.724c-7.419-7.289-13.941-15.438-19.428-24.274  C67.121,182.387,73.976,173.507,81.81,165.535L81.81,165.535z"
          data-original="#000000"
          class=""
        />
        <path
          d="M340.012,160.439V144h-16v16.41c-4.258,0.306-8.48,1.003-12.611,2.081l-13.288-15.663l-12.203,10.344l10.262,12.096  c-7.184,4.82-13.456,10.876-18.523,17.888c-2.184,2.86-2.184,6.827,0,9.688c12.569,17.931,33.443,28.178,55.316,27.156  c21.336,0.95,41.608-9.357,53.41-27.156c2.184-2.86,2.184-6.827,0-9.688c-5.26-7.121-11.809-13.193-19.306-17.9l10.845-11.857  l-11.805-10.797L351.477,162.6C347.721,161.563,343.887,160.841,340.012,160.439L340.012,160.439z M332.012,200  c-4.418,0-8-3.582-8-8s3.582-8,8-8s8,3.582,8,8C340.007,196.416,336.428,199.996,332.012,200z M294.344,191.945  c4.645-5.668,10.652-10.064,17.459-12.779c-4.955,7.63-5.061,17.434-0.271,25.168C304.923,201.587,299.04,197.347,294.344,191.945  L294.344,191.945z M352.221,204.834c4.955-7.63,5.061-17.434,0.272-25.168c6.609,2.747,12.492,6.988,17.188,12.389  C365.034,197.722,359.027,202.119,352.221,204.834L352.221,204.834z"
          data-original="#000000"
          class=""
        />
        <path
          d="M228.012,0C167.786,0.677,111.667,30.657,77.621,80.341C16.031,114.566-6.152,192.24,28.073,253.83  c4.876,8.775,10.771,16.944,17.562,24.337C55.483,311.981,73.53,342.839,98.174,368h-6.163c-4.417-0.001-7.999,3.579-8,7.996  c0,0.001,0,0.003,0,0.004v96c-0.001,4.417,3.579,7.999,7.996,8c0.001,0,0.003,0,0.004,0h80c13.249,0.006,23.994-10.729,24-23.978  c0.003-5.913-2.179-11.618-6.125-16.022c4.83-5.149,6.983-12.254,5.823-19.218c10.642,2.09,21.457,3.167,32.302,3.218  c105.871,0,192-95.102,192-212S333.883,0,228.012,0z M140.012,80c61.856,0,112,50.144,112,112s-50.144,112-112,112  s-112-50.144-112-112C28.081,130.173,78.184,80.069,140.012,80z M124.012,368v-49.111c5.305,0.704,10.649,1.075,16,1.111  c5.351-0.036,10.695-0.407,16-1.111V368H124.012z M108.012,355.096c-15.573-16.321-28.252-35.176-37.493-55.756  c11.528,7.47,24.189,13.024,37.493,16.447V355.096z M174.368,431.523L147.867,432l0.289,16l21.903-0.394  c0.631,0.215,1.287,0.348,1.953,0.394c4.418,0,8,3.582,8,8s-3.582,8-8,8h-72v-80h72c4.416,0.004,7.996,3.584,8,8  c-0.026,3.475-2.315,6.526-5.644,7.523L147.867,400l0.289,16l21.903-0.394c0.631,0.215,1.287,0.348,1.953,0.394  c4.416,0.004,7.996,3.584,8,8C179.986,427.475,177.697,430.526,174.368,431.523L174.368,431.523z M228.012,408  c-11.856-0.04-23.67-1.425-35.214-4.128c2.094-3.607,3.202-7.701,3.214-11.872c-0.014-13.249-10.751-23.986-24-24v-52.212  c68.447-17.359,109.862-86.918,92.503-155.365S177.597,50.561,109.15,67.92c-0.153,0.039-0.307,0.078-0.46,0.118  C139.681,35.113,182.797,16.31,228.012,16c97.047,0,176,87.922,176,196S325.059,408,228.012,408z"
          data-original="#000000"
          class=""
        />
        <path
          d="M201.008,329.75l-9.992,12.5c26.256,21.187,63.736,21.187,89.992,0l-9.992-12.5  C250.596,346.25,221.427,346.25,201.008,329.75L201.008,329.75z"
          data-original="#000000"
          class=""
        />
        <path
          d="M436.012,464h32v16h-32V464z"
          data-original="#000000"
          class=""
        />
        <path
          d="M388.012,464h32v16h-32V464z"
          data-original="#000000"
          class=""
        />
        <path
          d="M340.012,464h32v16h-32V464z"
          data-original="#000000"
          class=""
        />
      </g>
    </SvgIcon>
  );
};
