import React from 'react';
import {
    useLocale, ReferenceField, ReferenceArrayField, SingleFieldList,
    EditButton, Datagrid, TextField, Pagination, ChipField, DateField
} from 'react-admin';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import List from './theme/components/List'

const SupervisorsField = (props) => {
    if (props.record.supervisors) {
        const record = { ...props.record };
        record.supervisors = JSON.parse(record.supervisors);
        return <ReferenceArrayField reference="users" source="supervisors" {...props} record={record}>
            <SingleFieldList>
                <ChipField source="username" />
            </SingleFieldList>
        </ReferenceArrayField>
    }
    else
        return null;
}

export const ScoutingTaskList = props => {
    const locale = useLocale();
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}{...props}>
            <Datagrid>
                  
                <TextField source="status" />
                <DateField source="task_date" />
                <DateField source="action_date" />
                <ReferenceField source="section_id" reference="sections" allowEmpty >
                    <TextField source="qr_value" />
                </ReferenceField>
                <SupervisorsField source="supervisors" />
            </Datagrid>
        </List>
    )
};

