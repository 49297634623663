import React from 'react';
import {
    Create, Edit, SimpleForm, TextInput, DateField,
    EditButton, Datagrid, TextField, Filter, Pagination,
    Show, ReferenceField, Tab, TabbedShowLayout, ReferenceManyField,
    ShowButton, ReferenceInput, SelectInput, useLocale
} from 'react-admin';
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import List from './theme/components/List'
import DataChart from './dataChart'
import { FormDataConsumer } from 'ra-core';
import { EntityLocation, LaunchIconLink } from './entityLocation'
import clsx from 'clsx';

export const DeviceList = (props) => {
    const locale = useLocale();
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} filters={<DeviceFilter />} {...props}>
            <Datagrid>
                  
                <LaunchIconLink source={locale === 'en' ? "Location" : "الموقع"} tab={3} />
                <TextField source="qr_value" />
                <TextField source="deviceuid" />
                <ReferenceField source="devicetype_id" reference="devicetypes" allowEmpty={true}>
                    <TextField source="type" />
                </ReferenceField>
                <ReferenceField source="farm_id" reference="farms" allowEmpty={true}>
                    <TextField source="farm_code" />
                </ReferenceField>
                <EditButton />
                <ShowButton />
            </Datagrid>
        </List>
    )
};

const redirect = (basePath, id, data) => `/devicetypes/${data.devicetype_id}/show/`;

export const DeviceEdit = props => {
    const locale = useLocale();
    return (
        <Edit undoable={false} {...props}>
            <SimpleForm redirect={redirect} toolbar={<CustomToolbar />} className={clsx('tab-rtl-layout')}>
                <TextInput disabled source="id" />
                <TextInput source="qr_value" />
                <TextInput source="deviceuid" />
                <ReferenceInput perPage={-1} source="devicetype_id" reference="devicetypes" >
                    <SelectInput optionText={locale === 'en' ? "type" : "type_ar"} />
                </ReferenceInput>
                <ReferenceInput perPage={-1} source="farm_id" reference="farms" >
                    <SelectInput optionText="farm_code" />
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        return <ReferenceInput perPage={-1} source="block_id" reference="blocks" filter={{ farm_id: formData.farm_id }} {...rest} >
                            <SelectInput optionText="qr_value" />
                        </ReferenceInput>
                    }}
                </FormDataConsumer>
            </SimpleForm>
        </Edit>
    )
};

export const DeviceCreate = props => {
    const locale = useLocale();
    return (
        <Create {...props}>
            <SimpleForm redirect="list" className={clsx('tab-rtl-layout')}>
                <TextInput source="qr_value" />
                <TextInput source="deviceuid" />
                <ReferenceInput perPage={-1} source="devicetype_id" reference="devicetypes" >
                    <SelectInput optionText={locale === 'en' ? "type" : "type_ar"} />
                </ReferenceInput>
                <ReferenceInput perPage={-1} source="farm_id" reference="farms" >
                    <SelectInput optionText="farm_code" />
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        return <ReferenceInput perPage={-1} source="block_id" reference="blocks" filter={{ farm_id: formData.farm_id }} {...rest} >
                            <SelectInput optionText="qr_value" />
                        </ReferenceInput>
                    }}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    )
};

const DeviceName = ({ record }) => {
    const locale = useLocale();
    return <span>{record ? (locale === 'en' ? record.qr_value : record.qr_value) : ''}</span>;
}
export const DeviceShow = (props) => {
    const locale = useLocale();
    return (
        <Show {...props} title={<DeviceName />}>
            <TabbedShowLayout>
                <Tab label={locale === 'en' ? "Chart" : "الرسم البياني"} >
                    <DataChart />
                </Tab>
                <Tab label={locale === 'en' ? "summary" : "الملخص"} contentClassName={clsx('tab-rtl-layout')}>
                      
                    <TextField source="qr_value" />
                    <TextField source="deviceuid" />
                    <ReferenceField source="farm_id" reference="farms" allowEmpty={true}>
                        <TextField source="farm_code" />
                    </ReferenceField>
                </Tab>
                <Tab label={locale === 'en' ? "Data" : "القراءات"}>
                    <ReferenceManyField perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} target="device_id" reference="devicedata" addLabel={false}>
                        <Datagrid>
                              
                            <TextField source={locale === 'en' ? "variable" : "variable_ar"} />
                            <TextField source="value" />
                            <TextField source="unit" />
                            <TextField source="rssi" />
                            <TextField source="snr" />
                            <DateField source="time" locales="en-GB" showTime />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                <Tab label={locale === 'en' ? "Location" : "الموقع"}>
                    <EntityLocation lat="latitude" lng="longitude" />
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
};

const DeviceFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput perPage={-1} source="devicetype_id" reference="devicetypes" >
            <SelectInput optionText="type" />
        </ReferenceInput>
        <ReferenceInput perPage={-1} source="farm_id" reference="farms" >
            <SelectInput optionText="farm_code" />
        </ReferenceInput>
    </Filter>
);
