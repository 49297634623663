import React from 'react';
import {List} from 'react-admin';
import ListActions from "./ListActions"
import CardContent from "./CardContent"
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    overflow: {
        overflowX: 'auto',
    },
});
const MyList = (props) => {
    const classes = useStyles();

    return (
        <CardContent >
            <List actions={<ListActions />}  classes={{ overflowX: "scroll" }} {...props} />
        </CardContent>
    );
};
export default MyList
