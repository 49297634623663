import React, { useState, useEffect, Fragment } from 'react';
import {
    Create, SimpleForm, useLocale,
    Datagrid, TextField, Filter, required, FormDataConsumer,
    NumberInput, ReferenceField, ReferenceInput, SelectInput,
    TopToolbar, Pagination, RadioButtonGroupInput
} from 'react-admin';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import List from './theme/components/List'
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { parse } from 'query-string';
import httpClient from './httpClient';
import { useForm } from 'react-final-form';

const PostActions = ({
    bulkActionButtons,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => {
    const locale = useLocale();
    return (
        <TopToolbar>
            {bulkActionButtons && React.cloneElement(bulkActionButtons, {
                basePath,
                filterValues,
                resource,
                selectedIds,
                onUnselectItems,
            })}
            {filters && React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <Button
                variant="contained"
                component={Link}
                to={`/inventoryevents/create?movement_param=1`}
                style={{ margin: '0.5em', marginTop: '0', backgroundColor: '#1976d2', color: 'white' }}>
                {locale === 'en' ? "In" : "وارد"}
            </Button>
            <Button
                variant="contained"
                component={Link}
                to={`/inventoryevents/create?movement_param=0`}
                style={{ margin: '0.5em', marginTop: '0', backgroundColor: '#dc004e', color: 'white' }}>
                {locale === 'en' ? "Out" : "صادر"}
            </Button>
        </TopToolbar>
    )
};

const CustomField = ({ record }) => {
    const locale = useLocale();
    return <span className="MuiTypography-root-167 MuiTypography-body1-176">
        {(record.movement) ?
            (locale === 'en' ? "In" : "وارد") : (locale === 'en' ? "Out" : "صادر")}
    </span>
};

const CustomLabel = props => {
    const locale = useLocale();
    return (
        <ReferenceField {...props} source="subcategory_id" reference="inventorysubcategories" link={false} >
            <ReferenceField source="unit_id" reference="units" allowEmpty={true} link={false}>
                <TextField source={locale === 'en' ? "name" : "name_ar"} />
            </ReferenceField>
        </ReferenceField>
    );
}

const EntityField = props => {
    return <ReferenceField reference={props.record.entity_type === 'block' ? 'blocks' : 'pivots'} {...props} >
        <TextField source="qr_value" />
    </ReferenceField>
}

export const InventoryEventList = (props) => {
    const locale = useLocale();
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}{...props} filters={<InventoryEventFilter />} actions={<PostActions />} >
            <Datagrid optimized={true}>
                  
                <ReferenceField source="inventory_id" reference="inventories" >
                    <TextField source={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceField>
                <ReferenceField source="category_id" reference="inventorycategories" >
                    <TextField source={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceField>
                <ReferenceField source="subcategory_id" reference="inventorysubcategories" >
                    <TextField source={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceField>
                <TextField source="amount" />
                <CustomLabel source={locale === 'en' ? "Unit" : "الوحدة"} />
                <CustomField source="movement" />
                <EntityField source="entity_id" />
                <ReferenceField source="activeprogram_id" reference="activeprograms" >
                    <TextField source={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceField>
                <TextField source="balance" />
                <ReferenceField source="user_id" reference="users" >
                    <TextField source="username" />
                </ReferenceField>
            </Datagrid>
        </List>
    )
};

const FormHook = props => {
    const { formData, ...rest } = props.form;
    const movementValue = props.movement;
    const form = useForm();
    const locale = useLocale();
    const [data, setData] = useState(null);
    const [type, setType] = useState({
        choices: [],
    });
    useEffect(() => {
        const fetchData = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/inventories/${formData.inventory_id}`);
            setData(response.json.farm_id);
        };
        if (formData.inventory_id)
            fetchData();
    }, [formData.inventory_id]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/organizations/check`);
            const choices = [];
            let defaultValue = null;
            if (response.json.is_palm_tree) {
                choices.push({ id: 'block', name: locale === 'en' ? 'Block' : 'حوشة' });
                defaultValue = 'block';
            }
            if (response.json.is_pivot) {
                choices.push({ id: 'pivot', name: locale === 'en' ? 'Pivot' : 'قطاع ري محوري' });
                defaultValue = 'pivot';
            }
            setType({
                choices: choices,
            });
            form.change('entity_type', defaultValue);
        };
        fetchData();
    }, []);

    return <Fragment>
        <SelectInput source="movement" defaultValue={movementValue} choices={[
            { id: 0, name: locale === 'en' ? "Out" : "صادر" },
            { id: 1, name: locale === 'en' ? "In" : "وارد" },
        ]} onChange={() => {
            form.change('block_id', null);
            form.change('activeprogram_id', null);
        }} {...rest} />
        {formData.movement === 0 &&
            <Fragment>
                <br />
                <RadioButtonGroupInput source="entity_type" choices={type.choices} {...rest} />
                <br />
                <ReferenceInput perPage={-1} source="entity_id" reference={formData.entity_type === 'block' ? 'blocks' : 'pivots'} filter={{ farm_id: data }} {...rest} >
                    <SelectInput optionText="qr_value" />
                </ReferenceInput>
                <br />
                <ReferenceInput perPage={-1} source="activeprogram_id" reference="activeprograms" filter={{ farm_id: data }} {...rest} >
                    <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceInput>
            </Fragment>
        }
    </Fragment>

}

export const InventoryEventCreate = props => {
    const { movement_param: movement } = parse(props.location.search);
    const movementValue = movement ? parseInt(movement, 10) : '';
    const locale = useLocale();
    const [data, setData] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/units`);
            const units = {};
            response.json.forEach(element => {
                units[element.id] = element;
            });
            setData(units);
        };
        fetchData();
    }, []);
    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <ReferenceInput perPage={-1} source="inventory_id" reference="inventories" validate={required()} >
                    <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceInput>
                <ReferenceInput perPage={-1} source="category_id" reference="inventorycategories" validate={required()} >
                    <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) => (formData.category_id &&
                        <ReferenceInput perPage={-1} source="subcategory_id" reference="inventorysubcategories"
                            filter={{ category_id: formData.category_id }} {...rest} validate={required()} >
                            <SelectInput optionText={(record) => {
                                if (data && data[record.unit_id])
                                    return locale === 'en' ?
                                        record.name + ' - ' + data[record.unit_id].name :
                                        record.name_ar + ' - ' + data[record.unit_id].name_ar;
                                else
                                    return locale === 'en' ?
                                        record.name :
                                        record.name_ar;
                            }} />
                        </ReferenceInput>
                    )}
                </FormDataConsumer>
                <NumberInput source="amount" validate={required()} />
                <FormDataConsumer>
                    {(formData) => (
                        <FormHook form={formData} movement={movementValue} />
                    )}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    )
};

const InventoryEventFilter = (props) => {
    const locale = useLocale();
    return (
        <Filter {...props}>
            <ReferenceInput perPage={-1} source="category_id" reference="inventorycategories" >
                <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
            </ReferenceInput>
            <ReferenceInput perPage={-1} source="subcategory_id" reference="inventorysubcategories" >
                <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
            </ReferenceInput>
            <ReferenceInput perPage={-1} source="inventory_id" reference="inventories" >
                <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
            </ReferenceInput>
        </Filter>
    )
};
