import Cookies from './helpers/Cookies';
import {fetchJson} from './helpers/fetch';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json', User: `${Cookies.getCookie('user')}` });
    }
    const token = Cookies.getCookie('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchJson(url, options);
}
export default httpClient;