import React, { Fragment } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { IconButton, Box } from '@material-ui/core';

import projectLogo from '../assets/images/logo.svg';

const HeaderLogo = props => {
    const { sidebarToggle } = props;
    return (
        <Fragment>
            <div
                className={clsx('app-sidebar-header', {
                    'app-sidebar-header-close': sidebarToggle,
                })}>
                <Box
                    className="header-logo-wrapper"
                    title="The Platform">
                    <Link to="/" className="header-logo-wrapper-link">
                        <IconButton
                            color="primary"
                            size="medium"
                            className="header-logo-wrapper-btn">
                            <img
                                className="app-header-logo-img"
                                alt="The Platform"
                                src={projectLogo}
                            />
                        </IconButton>
                    </Link>
                    <Box className="header-logo-text">The Platform</Box>
                </Box>
            </div>
        </Fragment>
    );
};

export default HeaderLogo;
