import React from 'react';
import {
    Create, Edit, SimpleForm, TextInput,
    EditButton, Datagrid, TextField, Filter,
    useLocale, ReferenceField,
    DateField, ImageField, ReferenceInput, SelectInput, Pagination
} from 'react-admin';
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import List from './theme/components/List'
import clsx from 'clsx';
import { MyImageField, MyImageInput } from './helpers/iconComponent';
import { withStyles } from "@material-ui/core/styles";

const styles = {
    image: { maxHeight: '1.4rem' }
}

export const ChemicalTypeList = withStyles(styles)(({ classes, ...props }) => {
    const locale = useLocale();
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} filters={<ChemicalTypeFilter />} {...props}>
            <Datagrid>
                  
                <MyImageField source="icon_id" />
                <TextField source={locale === 'en' ? "type" : "type_ar"} />
                <ReferenceField source="chemicalunittype_id" reference="units" allowEmpty={true} >
                    <TextField source={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceField>
                <DateField source="created_at" locales="en-GB" />
                <EditButton />
            </Datagrid>
        </List>
    )
});

export const ChemicalTypeEdit = props => {
    const locale = useLocale();
    return (
        <Edit undoable={false} {...props}>
            <SimpleForm redirect="list" toolbar={<CustomToolbar />} className={clsx('tab-rtl-layout')}>
                <TextInput disabled source="id" />
                <TextInput source="type" />
                <TextInput source="type_ar" />
                <MyImageInput />
                <ReferenceInput perPage={-1} source="chemicalunittype_id" reference="units">
                    <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    )
};

export const ChemicalTypeCreate = props => {
    const locale = useLocale();
    return (
        <Create {...props}>
            <SimpleForm redirect="list" className={clsx('tab-rtl-layout')}>
                <TextInput source="type" />
                <TextInput source="type_ar" />
                <MyImageInput />
                <ReferenceInput perPage={-1} source="chemicalunittype_id" reference="units" >
                    <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    )
};

const ChemicalTypeFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput perPage={-1} source="chemicalunittype_id" reference="units" label="Unit" >
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);
