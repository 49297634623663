import React, { useState } from "react";
import {
  Create,
  Show,
  ShowButton,
  SimpleShowLayout,
  Edit,
  SimpleForm,
  TextInput,
  EditButton,
  Datagrid,
  useLocale,
  Pagination,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  FunctionField,
  NumberInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import MuiTextField from "@material-ui/core/TextField";
import CustomToolbar from "./helpers/customToolbar";
import PostBulkActionButtons from "./helpers/PostBulkActionButtons";
import List from "./theme/components/List";
import clsx from "clsx";
import { Fragment } from "react";
import httpClient from "./httpClient";
import Chart from "react-apexcharts";
import { Grid, Card, CardContent } from "@material-ui/core";
import {
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TablePagination,
  Paper,
  TableContainer,
} from "@material-ui/core";
const useStyles = makeStyles({
  table: {
    maxWidth: 350,
  },
});
let pairs = [],
  isFao;
const saveKCProfile = async (values) => {
  let accumelatedPeriod = values["Initial Period"];
  let points = [];
  points.push({ x: 1, y: values["KC Initial"] });
  points.push({ x: values["Initial Period"], y: values["KC Initial"] });
  accumelatedPeriod += values["Crop Development Period"];
  points.push({ x: accumelatedPeriod, y: values["KC Mid"] });
  accumelatedPeriod += values["Mid Season Period"];
  points.push({ x: accumelatedPeriod, y: values["KC Mid"] });
  accumelatedPeriod += values["Late Season Period"];
  points.push({ x: accumelatedPeriod, y: values["KC End"] });
  let payload = {
    name: values.name,
    name_ar: values.name_ar,
    crop_id: values.crop_id,
    isFAO: 1,
    points: points,
  };
  console.log(JSON.stringify(payload));
  let response = await httpClient(
    `${process.env.REACT_APP_DATA_PROVIDER_URL}/kcprofiles/create_new`,
    {
      method: "POST",
      body: JSON.stringify(payload),
    }
  );
  if (response.status == 200) window.history.back();
  console.log(response);
};

const KcChart = (props) => {
  const locale = useLocale();
  {
    console.log(props.values);
  }
  let currentSeries = {};
  currentSeries.name = props.name;
  let data = [];
  props.values.map((val, index) => {
    data.push([val.start, val.value]);
    data.push([val.end, val.value]);
  });

  currentSeries.data = data;
  const options = {
    stroke: {
      curve: "smooth",
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: "numeric",
    },
    tooltip: {
      x: {
        formatter: function (val) {
          return val.toFixed(1);
        },
      },
    },
    chart: {
      animations: {
        enabled: true,
      },
    },
  };
  const finalSeries = [];
  finalSeries.push(currentSeries);
  return (
    <Card className="card-box mb-4-spacing overflow-visible">
      <CardContent className="p-3">
        <Chart
          height={250}
          padding={40}
          margin={{ top: 50, right: 5, left: 5, bottom: 5 }}
          options={options}
          series={finalSeries}
          type="line"
        />
      </CardContent>
    </Card>
  );
};
const PalmKcChart = (props) => {
  const locale = useLocale();
  let currentSeries = {};
  currentSeries.name = props.name;
  let data = [];
  props.values.map((val, index) => {
    data.push(val.coefficient);
  });

  currentSeries.data = data;
  const options = {
    stroke: {
      curve: "smooth",
    },
    markers: {
      size: 0,
    },
    xaxis: {
      categories: months,
    },
    chart: {
      animations: {
        enabled: true,
      },
    },
  };
  const finalSeries = [];
  finalSeries.push(currentSeries);
  return (
    <Card className="card-box mb-4-spacing overflow-visible">
      <CardContent className="p-3">
        <Chart
          height={250}
          padding={40}
          margin={{ top: 50, right: 5, left: 5, bottom: 5 }}
          options={options}
          series={finalSeries}
          type="line"
        />
      </CardContent>
    </Card>
  );
};
export const KCEdit = (props) => (
  <Edit undoable={false} {...props}>
    <SimpleForm
      redirect="list"
      toolbar={<CustomToolbar />}
      className={clsx("tab-rtl-layout")}
    >
      {/* <TextInput disabled source="id" /> */}
      <TextInput source="name" />
      <TextInput source="name_ar" />
    </SimpleForm>
  </Edit>
);
export const KCProfileShow = ({ id, record, resource }) => {
  const locale = useLocale();
  const classes = useStyles();
  return (
    <SimpleShowLayout className={clsx("tab-rtl-layout")}>
      <Grid
        container
        direction="row"
        alignContent="center"
        alignItems="flex-start"
        wrap="wrap"
        spacing={2}
      >
        <Grid item xs={3}>
          <TableContainer className={classes.table} component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {locale === "en" ? "Start" : "بداية الفترة"}
                  </TableCell>
                  <TableCell>
                    {locale === "en" ? "End" : "نهاية الفترة"}
                  </TableCell>
                  <TableCell>{locale === "en" ? "Value" : "القيمة"}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {record.data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.start}
                    </TableCell>
                    <TableCell>{row.end}</TableCell>
                    <TableCell>{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={9}>
          <KcChart
            values={record.data}
            name={locale === "ar" ? record.name_ar : record.name}
          />
        </Grid>
      </Grid>
    </SimpleShowLayout>
  );
};

export const PalmKCProfileShow = ({ id, record, resource }) => {
  const locale = useLocale();
  const classes = useStyles();
  return (
    <SimpleShowLayout className={clsx("tab-rtl-layout")}>
      <Grid
        container
        direction="row"
        alignContent="center"
        alignItems="flex-start"
        wrap="wrap"
        spacing={2}
      >
        <Grid item xs={3}>
          <TableContainer className={classes.table} component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{locale === "en" ? "Month" : "الشهر"}</TableCell>
                  <TableCell>{locale === "en" ? "Value" : "القيمة"}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {record.data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {months[row.month - 1]}
                    </TableCell>
                    <TableCell>{row.coefficient}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={9}>
          <PalmKcChart
            values={record.data}
            name={locale === "ar" ? record.name_ar : record.name}
          />
        </Grid>
      </Grid>
    </SimpleShowLayout>
  );
};
export const FAOKCProfilesList = (props) => {
  const locale = useLocale();
  isFao = props.location.search.includes("true");
  return (
    <List
      
      filter={{ isFAO: props.location.search.includes("true") ? 1 : 0 }}
      bulkActionButtons={<PostBulkActionButtons />}
      perPage={-1}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
      {...props}
    >
      <Datagrid expand={<KCProfileShow {...props} />}>
        {/* <TextField source="id" label={locale === "ar" ? "الرقم" : "id"} /> */}
        <TextField
          source={locale === "en" ? "name" : "name_ar"}
          label={locale === "ar" ? "الاسم" : "Name"}
        />
        <ReferenceField
          label={locale === "ar" ? "نوع المحصول" : "Crop Type"}
          source="crop_id"
          reference="pivotcroptypes"
        >
          <TextField source={locale === "en" ? "name" : "name_ar"} />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export const FAOKCProfileCreate = (props) => {
  const locale = useLocale();
  const [values, setValues] = useState([{ from: 0, to: 0, value: 0 }]);
  const [errorMessage, setError] = useState("");
  function addNewItem(record) {
    setValues((prevItems) => [...prevItems, { from: 0, to: 0, value: 0 }]);
    console.log(values);
  }
  function handleChangeToValue(event, index) {
    var valCopy = values.slice(); // Make a copy of the emails first.

    if (
      values[index - 1] &&
      event.target.value < parseInt(values[index - 1].to)
    ) {
      setError((prevItems) => "Period must be less than previous period");
      console.log("error");
    } else setError("");
    values[index].from = values[index - 1]
      ? parseInt(values[index - 1].to) + 1
      : 1;
    values[index].to = event.target.value; // Update it with the modified email.
    setValues((prevItems) => values); // Update the state.
  }
  function handleChangeKCValue(event, index) {
    var valCopy = values.slice(); // Make a copy of the emails first.
    values[index].value = event.target.value; // Update it with the modified email.
    setValues((prevItems) => values); // Update the state.
    console.log(values);
  }
  function deleteItem(record) {
    values.splice(values.length - 1, 1);
    setValues((prevItesm) => [...values]);
  }
  const saveCustomKCProfile = async (formValues) => {
    let points = [];
    let payload = {
      name: formValues.name,
      name_ar: formValues.name_ar,
      crop_id: formValues.crop_id,
      isFAO: 0,
    };
    values.forEach((record, index) => {
      points.push({ x: record.from, y: record.value });
      points.push({ x: record.to, y: record.value });
    });
    payload.points = points;
    let response = await httpClient(
      `${process.env.REACT_APP_DATA_PROVIDER_URL}/kcprofiles/create_new`,
      {
        method: "POST",
        body: JSON.stringify(payload),
      }
    );
    if (response.status == 200) window.history.back();
    console.log("vals", payload);
  };
  return (
    <Fragment {...props}>
      {console.log(props)}
      {isFao ? (
        <SimpleForm
          redirect="list"
          className={clsx("tab-rtl-layout")}
          save={saveKCProfile}
        >
          <TextInput source="name" size="small" />
          <TextInput
            source="name_ar"
            label={locale === "ar" ? "الاسم" : "Name"}
          />
          <ReferenceInput
            perPage={-1}
            reference="pivotcroptypes"
            source="crop_id"
            allowEmpty
          >
            <SelectInput optionText={locale === "en" ? "name" : "name_ar"} />
          </ReferenceInput>
          <NumberInput label="KC Initial" source="KC Initial" />
          <NumberInput source="Initial Period" label="Initial Period in Days" />
          <NumberInput
            source="Crop Development Period"
            label="Crop Development Period in Days"
          />
          <NumberInput source="KC Mid" label="KC Mid" />
          <NumberInput
            source="Mid Season Period"
            label="Mid Season Period in Days"
          />
          <NumberInput source="KC End" label="KC End" />

          <NumberInput
            source="Late Season Period"
            label="Late Season Period in Days"
          />
        </SimpleForm>
      ) : (
        <SimpleForm
          redirect="list"
          className={clsx("tab-rtl-layout")}
          save={saveCustomKCProfile}
        >
          <TextInput
            source="name"
            style={{
              marginRight: "1rem",
              marginLeft: "1rem",
              marginBottom: "1rem",
            }}
          />
          <TextInput
            label={locale === "ar" ? "الاسم" : "Name"}
            source="name_ar"
            style={{
              marginRight: "1rem",
              marginLeft: "1rem",
              marginBottom: "1rem",
            }}
          />
          <ReferenceInput
            perPage={-1}
            reference="pivotcroptypes"
            source="crop_id"
            allowEmpty
            label={locale === "ar" ? "نوع المحصول" : "Name"}
            style={{
              marginRight: "1rem",
              marginLeft: "1rem",
              marginBottom: "1rem",
            }}
          >
            <SelectInput optionText={locale === "en" ? "name" : "name_ar"} />
          </ReferenceInput>
          {console.log(values)}
          {values.map((record, key) => {
            return (
              <Fragment key={key}>
                <MuiTextField
                  size="medium"
                  disabled
                  label={
                    locale === "en"
                      ? "Period Start in Days"
                      : "بداية الفترة-العمر بالايام"
                  }
                  variant="filled"
                  value={values[key - 1] ? parseInt(values[key - 1].to) + 1 : 1}
                  source="Period Start"
                  type="number"
                  // onChange={(e) => handleChangeFromValue(e, key)}
                  style={{
                    marginRight: "1rem",
                    marginLeft: "1rem",
                    marginBottom: "1rem",
                  }}
                />
                <MuiTextField
                  size="medium"
                  source="Period End"
                  label={
                    locale === "en"
                      ? "Period End in Days"
                      : "نهاية الفترة-العمر بالايام"
                  }
                  variant="filled"
                  type="number"
                  errorMessage={errorMessage}
                  error={errorMessage.length > 0 ? 1 : 0}
                  onChange={(e) => handleChangeToValue(e, key)}
                  style={{
                    marginRight: "1rem",
                    marginLeft: "1rem",
                    marginBottom: "1rem",
                  }}
                />
                <MuiTextField
                  size="medium"
                  source="KC Value"
                  label={locale === "en" ? "KC Value" : "قيمة KC"}
                  variant="filled"
                  type="number"
                  onChange={(e) => handleChangeKCValue(e, key)}
                  style={{
                    marginRight: "1rem",
                    marginLeft: "1rem",
                    marginBottom: "1rem",
                  }}
                />
                {(key === values.length - 1 && values.length > 1) === true ? (
                  <img
                    src="https://nakheel-cdn.s3.eu-west-2.amazonaws.com/455-4552435_-remove-button-delete-denied-red-round-cancel.png"
                    style={{
                      maxHeight: "2rem",
                      margin: "1rem",
                      cursor: "pointer",
                    }}
                    onClick={() => deleteItem(record)}
                  />
                ) : null}

                {key === values.length - 1 ? (
                  <img
                    src="https://nakheel-cdn.s3.eu-west-2.amazonaws.com/add-icon-png-2459.png"
                    style={{
                      maxHeight: "2rem",
                      margin: "1rem",
                      cursor: "pointer",
                    }}
                    onClick={() => addNewItem(record)}
                  />
                ) : null}
              </Fragment>
            );
          })}
        </SimpleForm>
      )}
    </Fragment>
  );
};

export const PalmKCProfileList = (props) => {
  const locale = useLocale();
  return (
    <List
     
      bulkActionButtons={<PostBulkActionButtons />}
      perPage={-1}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
      {...props}
    >
      <Datagrid expand={<PalmKCProfileShow {...props} />}>
        {/* <TextField source="id" label={locale === "ar" ? "الرقم" : "id"} /> */}
        <TextField
          source={locale === "en" ? "name" : "name_ar"}
          label={locale === "ar" ? "الاسم" : "Name"}
        />
        <ReferenceField
          label={locale === "ar" ? "نوع النخلة" : "Palm Type"}
          source="palmtype_id"
          reference="palmtypes"
        >
          <TextField source={locale === "en" ? "type" : "type_ar"} />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const savePalmKCProfile = async (values) => {
  let points = [];
  for (let i = 1; i <= 12; i++) {
    points.push({ x: i, y: values[months[i - 1]] });
  }
  let payload = {
    name: values.name,
    name_ar: values.name_ar,
    ageStart: values.StartAge,
    ageEnd: values.EndAge,
    palmtype_id: values.palmtype_id,
    isFAO: true,
    points: points,
  };
  console.log(JSON.stringify(payload));
  let response = await httpClient(
    `${process.env.REACT_APP_DATA_PROVIDER_URL}/palmkcprofiles/create_new`,
    {
      method: "POST",
      body: JSON.stringify(payload),
    }
  );
  if (response.status == 200) window.history.back();
  console.log(response);
};
export const PalmKCProfileCreate = (props) => {
  const locale = useLocale();
  // const [values, setValues] = useState([{ month: 0, value: 0 }]);
  // function handleChangeToValue(event, index) {
  //   var valCopy = values.slice(); // Make a copy of the emails first.
  //   setValues((prevItems) => values); // Update the state.
  // }
  // function handleChangeKCValue(event, index) {
  //   var valCopy = values.slice(); // Make a copy of the emails first.
  //   values[index].value = event.target.value; // Update it with the modified email.
  //   setValues((prevItems) => values); // Update the state.
  //   console.log(values);
  // }
  return (
    <Fragment {...props}>
      <SimpleForm
        redirect="list"
        className={clsx("tab-rtl-layout")}
        save={savePalmKCProfile}
      >
        <TextInput source="name" size="small" />
        <TextInput
          source="name_ar"
          label={locale === "ar" ? "الاسم" : "Name"}
        />
        <ReferenceInput
          perPage={-1}
          label={locale === "en" ? "Palm Type" : "نوع النخلة"}
          reference="palmtypes"
          source="palmtype_id"
          allowEmpty
        >
          <SelectInput optionText={locale === "en" ? "type" : "type_ar"} />
        </ReferenceInput>
        <NumberInput
          label={locale === "en" ? "Start Age in Years" : "بداية العمر بالسنة"}
          source="StartAge"
        />
        <NumberInput
          label={locale === "en" ? "End Age in Years" : "نهاية العمر بالسنة"}
          source="EndAge"
        />
        <NumberInput label="January" source="January" />
        <NumberInput label="February" source="February" />
        <NumberInput label="March" source="March" />
        <NumberInput label="April" source="April" />
        <NumberInput label="May" source="May" />
        <NumberInput label="June" source="June" />
        <NumberInput label="July" source="July" />
        <NumberInput label="August" source="August" />
        <NumberInput label="September" source="September" />
        <NumberInput label="October" source="October" />
        <NumberInput label="November" source="November" />
        <NumberInput label="December" source="December" />
      </SimpleForm>
    </Fragment>
  );
};
