import { processCSV, processKML } from './processFiles'

function loadHandler(event, fileType, setProgress, basePath, data) {
    let fileBody = event.target.result;
    if (fileType === "csv")
        processCSV(fileBody, setProgress);
    else if (fileType === "kml") {
        processKML(fileBody, basePath, data);
    }
}

function errorHandler(evt) {
    alert("The file is corrupted!");
}

const myHandleChange = (event, fileType, setProgress, basePath, data) => {
    let reader = new FileReader();
    reader.readAsText(event.target.files[0]);
    reader.onload = (event) => loadHandler(event, fileType, setProgress, basePath, data);
    reader.onerror = errorHandler;
}

export default myHandleChange;
