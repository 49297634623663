import React from 'react'
import { GoogleMap, LoadScript, Polygon, Marker, GroundOverlay } from '@react-google-maps/api'
import httpClient from './httpClient';
import LaunchIcon from '@material-ui/icons/Launch';
//import MapGL, {Marker, Source, Layer} from 'react-map-gl';
const MAPBOX_TOKEN = process.env.REACT_APP_MAP_GL_KEY;

const palmHealth = {
    Healthy: 'https://i.imgur.com/q4UyDuy.png',
    'Recently healed': 'https://i.imgur.com/g8Y7jWr.png',
    Infected: 'https://i.imgur.com/rxiWqrE.png'
};

const entityIcon = {
    traps: 'https://i.imgur.com/Kaf8AGA.png',
    assets: 'https://i.imgur.com/CJTBIyG.png',
    devices: 'https://i.imgur.com/AuQM9MU.png'
};



export const LaunchIconLink = (props) => {
    return <a href={`#/${props.resource}/${props.record.id}/show/${props.tab}`} target="_blank">
        <LaunchIcon style={{ color: 'teal' }} />
    </a>;
}

export const EntityLocation = props => {

    let { record } = props;
    let boundaries=[];
    
    console.log(props)


    const [mapImg, setMapImage] = React.useState(false)
    const [pathArray, setPathArray] = React.useState([]);
    const [mounted, setMounted] = React.useState(false);
    const [viewport, setViewport] = React.useState({
        latitude: 37.8,
        longitude: -122.4,
        zoom: 13,
        bearing: 0,
        pitch: 0
      });

      const fetchMapImg = async () => {
        const bounds = {
            north: -90,
            south: 90,
            east: -180,
            west: 180
        };

        const images = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/farms/${record.farm_id}`);
       
        let url ="";
        let avgLat = 0, avgLng = 0
            let initialCenter = {};
        if (images.json.borders) {
            if(images.json.file){
                url = process.env.REACT_APP_DATA_PROVIDER_URL + images.json.file.url;
            }
            boundaries = images.json.borders;
            boundaries = JSON.parse(boundaries);
            for (var i = 0; i < boundaries.length; i++) {
                avgLat += boundaries[i][1];
                avgLng += boundaries[i][0];
                bounds.north = Math.max(bounds.north, boundaries[i][1]);
                bounds.south = Math.min(bounds.south, boundaries[i][1]);
                bounds.east = Math.max(bounds.east, boundaries[i][0]);
                bounds.west = Math.min(bounds.west, boundaries[i][0]);
            }
            console.log(bounds)

            
            if (boundaries.length > 0) {
                avgLat /= boundaries.length;
                avgLng /= boundaries.length;
            }
            initialCenter = { lat: avgLat, lng: avgLng };

            setViewport({
                latitude: avgLat,
                longitude: avgLng,
                zoom: 12,
                bearing: 0,
                pitch: 0
              })
        }

        setMapImage({
            url: url,
            bounds: bounds
        })
    }

    React.useEffect(() => {
            fetchMapImg();
        const fetchBlock = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/blocks?id=${record.block_id}`);
            if (response.json.length > 0) {
                let boundaries = response.json.borders;
                let polygonArr = [];
                if (boundaries) {
                    boundaries = JSON.parse(boundaries);
                    for (let i = 0; i < boundaries.length; i++) {
                        polygonArr.push({ lat: boundaries[i][1], lng: boundaries[i][0] });
                    }
                }
                setPathArray(polygonArr);
            }
        }
        if (record.farm_id){
            fetchMapImg();
            console.log(mapImg.bounds)
        }
        if (record.block_id)
            fetchBlock();
    }, [record.block_id]);

    return (<div>
        {/* <MapGL
            {...viewport}
            width="100vw"
            height="75vh"
            mapStyle="mapbox://styles/mapbox/satellite-v9"
            onViewportChange={nextViewport => setViewport(nextViewport)}
            mapboxApiAccessToken={MAPBOX_TOKEN}
        >
            {record[props.lat] && <Marker latitude={parseFloat(record[props.lat])} longitude={parseFloat(record[props.lng])} offsetLeft={0} offsetTop={0}  title={record.qr_value}  >
                            <div>
                            
                            <img src={props.resource === 'palms' ? palmHealth[record.health_status] : entityIcon[props.resource]} /></div>
                        </Marker>}

            { mapImg && 
            <Source
                type="image"
                url={mapImg.url}
                coordinates={
                    [
                        [mapImg.bounds.west, mapImg.bounds.north],
                        [mapImg.bounds.east, mapImg.bounds.north],
                        [mapImg.bounds.east, mapImg.bounds.south],
                        [mapImg.bounds.west, mapImg.bounds.south]
                    ]
                }>
                <Layer
                    type="raster"
                />


            </Source>}

        </MapGL> */}
        

        
            <LoadScript
            id="script-loader"
            googleMapsApiKey={process.env.REACT_APP_MAPS_KEY}>
            <GoogleMap
                id='drawing-example'
                mapTypeId="satellite"
                onMouseOver={() => {
                    if (!mounted)
                        setMounted(true);
                }}
                zoom={14}
                center={mounted ? null : { lat: parseFloat(record[props.lat]), lng: parseFloat(record[props.lng]) }}
                style={{ flexBasis: 'auto' }}
                mapContainerStyle={{
                    position: 'relative', height: '600px', display: 'flex'
                }}>
                 
                    { mapImg && <GroundOverlay
                    key={mapImg.url}
                    url={mapImg.url}
                    bounds={mapImg.bounds}
                    />}

                <Polygon options={{
                    fillColor: "#0000FF",
                    fillOpacity: '0.15',
                    strokeColor: "#0000FF",
                    strokeOpacity: '0.8',
                    strokeWeight: '2'
                }}
                    path={pathArray}
                />
                <Marker
                    position={{ lat: parseFloat(record[props.lat]), lng: parseFloat(record[props.lng]) }}
                    title={record.qr_value}
                    icon={props.resource === 'palms' ? palmHealth[record.health_status] : entityIcon[props.resource]}
                />

            </GoogleMap>
        </LoadScript>




    </div>
    )
}

