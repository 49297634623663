import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { shallowEqual, useSelector } from "react-redux";
import inflection from "inflection";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import classnames from "classnames";
import DefaultIcon from "@material-ui/icons/ViewList";
import { getResources, useTranslate } from "ra-core";
import { SettingsIcon } from "./icons/Settings";
import { DashboardMenuItem, MenuItemLink, useLocale } from "react-admin";
import Collapsible from "react-collapsible";
import httpClient from "../../httpClient";
import { CentralReportingIcon } from "./icons/CnetralReportingUnit";
import { CentralUnitRedirectModal } from "./../../redirectCentralUnit";
const TriggerButton = () => {
  const locale = useLocale();
  return (
    <MenuItemLink
      to={`#`}
      primaryText={locale === "en" ? "Farm Configurations" : "إعدادات المزرعة"}
      //icon={SettingsIcon}
      leftIcon={<SettingsIcon />}
    />
  );
};
const KCTriggerButton = () => {
  const locale = useLocale();
  return (
    <MenuItemLink
      to={`#`}
      primaryText={locale === "en" ? "KC Profiles" : "معاملات النبات"}
      //icon={SettingsIcon}
      style={{ paddingLeft: "2em" }}
      leftIcon={<SettingsIcon />}
    />
  );
};
const useStyles = makeStyles({
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  collapsibleMargin: {
    marginBottom: "2em",
  },
});

const translatedResourceName = (resource, translate) =>
  translate(`resources.${resource.name}.name`, {
    smart_count: 2,
    _:
      resource.options && resource.options.label
        ? translate(resource.options.label, {
            smart_count: 2,
            _: resource.options.label,
          })
        : inflection.humanize(inflection.pluralize(resource.name)),
  });

const farmTypes = ["is_palm_tree", "is_pivot", "is_orchard", "is_greenhouse"];

const adminMenu = {
  is_palm_tree: ["blocks", "palms", "traps"],
  is_pivot: ["pivots", "sections", "scoutingtasks"],
};

const lookupMenu = {
  is_palm_tree: [
    "blockactivitytypes",
    "palmactivitytypes",
    "palmtypes",
    "traptypes",
    "trapactivitytypes",
    "palmkcprofiles",
    "palmcanopyareaprofiles"
  ],
  is_pivot: [
    "pivotactivitytypes",
    "sectionactivitytypes",
    "pivotplantationtypes",
    "pivotcroptypes",
    "scoutingconfigs",
    "growthstages",
    "growthstageattributes",
    "kcprofiles",
  ],
};

const Menu = ({
  classes: classesOverride,
  className,
  dense,
  hasDashboard,
  onMenuClick,
  logout,
  ...rest
}) => {
  const locale = useLocale();
  const lookupList = [
    "inventorytypes",
    "icons",
    // 'farmtypes',
    // 'devicevariables',
    "assettypes",
    "assetactivitytypes",
    "assetsubactivitytypes",
    "pests",
    "chemicaltypes",
    "units",
    "inventorycategories",
    "inventorysubcategories",
    "qrcodes",
    "weather-alert-configurations",
  ];
  const adminList = [
    "farms",
    "programs",
    "actions",
    "users",
    "workers",
    "teams",
    "assets",
    "devicetypes",
    "activeprograms",
    "inventories",
    "inventoryevents",
    "logevents",
  ];

  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const response = await httpClient(
        `${process.env.REACT_APP_DATA_PROVIDER_URL}/organizations/check`
      );
      setData(response.json);
    };
    fetchData();
  }, []);
  const options = ["pivot", "palm"];
  const [modalOpen, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(options[0]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };
  const translate = useTranslate();
  const classes = useStyles({ classes: classesOverride });
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources, shallowEqual);
  useSelector((state) => state.router.location.pathname); // used to force redraw on navigation

  if (data)
    for (let permission of farmTypes) {
      if (data[permission]) {
        Array.prototype.push.apply(adminList, adminMenu[permission]);
        Array.prototype.push.apply(lookupList, lookupMenu[permission]);
      }
    }

  return (
    <div className={classnames(classes.main, className)} {...rest}>
      {hasDashboard && (
        <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      )}
      {resources
        .filter((r) => adminList.includes(r.name))
        .map((resource) => (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={translatedResourceName(resource, translate)}
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            onClick={onMenuClick}
            dense={dense}
            sidebarIsOpen={open}
          />
        ))}
      {
        <MenuItemLink
          to={
            data == null || (data.is_palm_tree && data.is_pivot)
              ? "/#"
              : data.is_palm_tree
              ? "/redirect-palm"
              : "/redirect_pivot"
          }
          //to={'/#'}
          primaryText={
            locale === "ar" ? "وحدة التقارير" : "Central Reporting Unit"
          }
          leftIcon={<CentralReportingIcon />}
          onClick={
            data == null || (data.is_palm_tree && data.is_pivot)
              ? handleClickOpen
              : onMenuClick
          }
          dense={dense}
          sidebarIsOpen={open}
        />
      }
      <CentralUnitRedirectModal
        selectedValue={selectedValue}
        open={modalOpen}
        onClose={handleClose}
      />
      <Collapsible
        trigger={TriggerButton()}
        className={classes.collapsibleMargin}
        openedClassName={classes.collapsibleMargin}
      >
        {resources
          .filter((r) => lookupList.includes(r.name))
          .map(
            (resource) => {
              switch (resource.name) {
                case "kcprofiles": {
                 return  <Collapsible
                    trigger={KCTriggerButton()}
                    label={locale ==="en"?"KC Profiles" : "معاملات النبات"}
                    style={
                      locale === "en"
                        ? { paddingLeft: "2em" }
                        : { paddingRight: "2em" }
                    }
                  >
                    <MenuItemLink
                      key={"FAO KC Profiles"}
                      to={`/kcprofiles?isFao=true`}
                      primaryText={
                        locale === "en" ? "FAO KC Profiles" : "معامل النبات-FAO KC"
                      }
                      leftIcon={
                        resource.icon ? <resource.icon /> : <SettingsIcon />
                      }
                      onClick={onMenuClick}
                      dense={dense}
                      sidebarIsOpen={open}
                      style={
                        locale === "en"
                          ? { paddingLeft: "4em" }
                          : { paddingRight: "4em" }
                      }
                    />
                    <MenuItemLink
                      key={"Custom KC Profiles"}
                      to={`/kcprofiles?isFao=false`}
                      primaryText={
                        locale === "en"
                          ? "Custom KC Profiles"
                          : "معاملات النبات الخاصة-KC"
                      }
                      leftIcon={
                        resource.icon ? <resource.icon /> : <SettingsIcon />
                      }
                      onClick={onMenuClick}
                      dense={dense}
                      sidebarIsOpen={open}
                      style={
                        locale === "en"
                          ? { paddingLeft: "4em" }
                          : { paddingRight: "4em" }
                      }
                    />
                  </Collapsible>;
                  break;
                }
                default: {
                   return <MenuItemLink
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={translatedResourceName(resource, translate)}
                leftIcon={resource.icon ? <resource.icon /> : <SettingsIcon />}
                onClick={onMenuClick}
                dense={dense}
                sidebarIsOpen={open}
                style={{ paddingLeft: "2em" }}
              />
                }
              }
            }
            // resource.name === "kcprofiles" ? (
            //   <Collapsible
            //     trigger={KCTriggerButton()}
            //     style={locale==="en"?{ paddingLeft: "2em" }:{paddingRight: "2em"}}
            //   >
            //     <MenuItemLink
            //       key={"FAO KC Profiles"}
            //       to={`/kcprofiles?isFao=true`}
            //       primaryText={locale==="en"?"FAO KC Profiles":"اعدادات FAO KC"}
            //       leftIcon={
            //         resource.icon ? <resource.icon /> : <SettingsIcon />
            //       }
            //       onClick={onMenuClick}
            //       dense={dense}
            //       sidebarIsOpen={open}
            //       style={locale==="en"?{ paddingLeft: "4em" }:{paddingRight: "4em"}}
            //     />
            //     <MenuItemLink
            //       key={"Custom KC Profiles"}
            //       to={`/kcprofiles?isFao=false`}
            //       primaryText={locale==="en"?"Custom KC Profiles" : "اعدادات KC خاص"}
            //       leftIcon={
            //         resource.icon ? <resource.icon /> : <SettingsIcon />
            //       }
            //       onClick={onMenuClick}
            //       dense={dense}
            //       sidebarIsOpen={open}
            //       style={locale==="en"?{ paddingLeft: "4em" }:{paddingRight: "4em"}}
            //     />
            //   </Collapsible>
            // ) : (
            //   <MenuItemLink
            //     key={resource.name}
            //     to={`/${resource.name}`}
            //     primaryText={translatedResourceName(resource, translate)}
            //     leftIcon={resource.icon ? <resource.icon /> : <SettingsIcon />}
            //     onClick={onMenuClick}
            //     dense={dense}
            //     sidebarIsOpen={open}
            //     style={{ paddingLeft: "2em" }}
            //   />
            // )}
          )}
      </Collapsible>
      {isXSmall && logout}
    </div>
  );
};

Menu.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  dense: PropTypes.bool,
  hasDashboard: PropTypes.bool,
  logout: PropTypes.element,
  onMenuClick: PropTypes.func,
};

Menu.defaultProps = {
  onMenuClick: () => null,
};

export default Menu;
