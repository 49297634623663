import React, { Fragment } from 'react'
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from 'recharts';
import httpClient from './httpClient';
import { useLocale } from 'react-admin';
import { Button } from '@material-ui/core';
import Chart from 'react-apexcharts';
import { Grid, Card, CardContent } from '@material-ui/core';
const arabicObj = {
    Temperature: 'الحرارة',
    Humidity: 'الرطوبة',
    'Wind Speed': 'سرعة الرياح',
    'Weekly Temperature': 'الحرارة اسبوعيا',
    'Weekly Humidity': 'الرطوبة اسبوعيا',
    'Weekly Wind Speed': 'سرعة الرياح اسبوعيا'
}

const Weekly = () => {
    const locale = useLocale();
    return locale === 'en' ? "Weekly" : "أسبوعيا";
}

const CustomizedLabel = (props) => {
    const {
        x, y, stroke, value,
    } = props;

    return <text x={x} y={y} dy={-6} fill={stroke} fontSize={14} textAnchor="middle">{value}</text>;

}


const Locale = ({ variable, name, key }) => {
    const locale = useLocale();

    let currentSeries = {}
    let currentData = []
    currentSeries.name = locale === 'en' ? name : arabicObj[name];
    variable.forEach(element => {
        currentData.push({ x: element.time, y: element.value })

    })

    currentSeries.data = currentData
    const options = {
        stroke: {
            curve: 'smooth'
        },
        markers: {
            size: 0
        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeFormatter: {
                    year: 'yyyy',
                    month: "MMM 'yy",
                    day: 'dd MMM'
                }
            }

        },
        yaxis: {
            title: {
                text: variable ? variable[0].unit : null,
                rotate: 0,
            },
            labels: {
                show: true,
                align: 'right',
            }
        },
        chart: {
            animations: {
                enabled: true
            }
        }
    }
    const finalSeries = []
    finalSeries.push(currentSeries)
    // return <LineChart width={1200} height={250} data={variable}
    //     margin={{ top: 50, right: 5, left: 5, bottom: 5 }}>
    //     <CartesianGrid strokeDasharray="3 3" />
    //     <XAxis dataKey="time" interval={7} />
    //     <YAxis label={variable ? variable[0].unit : null} width={100} />
    //     <Tooltip />
    //     <Legend />
    //     <Line name={locale === 'en' ? name : arabicObj[name]} type="monotone" dataKey="value" stroke="#009688" />
    // </LineChart>;

    return <Card className="card-box mb-4-spacing overflow-visible" >
        <div className="card-header" direction="rtl" >
            <div className="card-header--title font-size-md font-weight-bold py-2">
                {currentSeries.name}
            </div>
        </div>
        <CardContent className="p-3"><Chart
            height={250}
            width={400}
            padding={40}
            margin={{ top: 50, right: 5, left: 5, bottom: 5 }}
            options={options}
            series={finalSeries}
            type="line"
            key={key} />
        </CardContent>
    </Card>

}

class WeatherChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: null };
    }

    getData(data, value, unit) {
        return data.map(hour => {
            return {
                value: hour[value],
                time: hour.time,
                unit: unit
            }
        });
    }

    async componentDidMount() {
        const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/weatherforecasts/getforecast?type=${this.props.type}&id=${this.props.record.id}`);
        if (response.json.error) {
            return;
        }
        const { day, week } = response.json;
        this.setState({
            data: {
                Temperature: this.getData(day, 'temp', 'C'),
                Humidity: this.getData(day, 'humidity', '%'),
                'Wind Speed': this.getData(day, 'wind', 'm/s'),
                'Weekly Temperature': this.getData(week, 'temp', 'C'),
                'Weekly Humidity': this.getData(week, 'humidity', '%'),
                'Weekly Wind Speed': this.getData(week, 'wind', 'm/s')
            }
        });
    }

    render() {
        const data = this.state.data;
        return (data ?
            <Grid container
                direction="row"
                alignContent="center"
                alignItems="center"
                wrap="wrap"
                spacing={1} >{
                    Object.entries(data).map(
                        (entry, key) => {
                            let variable = entry[1];
                            if (variable.length > 0) {
                                // if (variable) {
                                //     variable = variable.map(element => {
                                //         const date = new Date(element.time);
                                //         date.setHours(date.getHours() - date.getTimezoneOffset() / 60);
                                //         element.time = date.toLocaleString();
                                //         return element;
                                //     })
                                // }
                                return (<Fragment>
                                    {entry[0] === 'Weekly Temperature' ?
                                        <Grid item xs={1} md={12}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Button style={{ marginTop: '3em', padding: '1em 4em' }}
                                                    color='primary' variant='contained' >

                                                    <Weekly />

                                                </Button>
                                            </div>
                                            {/* //<Locale variable={variable} name={entry[0]} key={key} /> */}
                                        </Grid> : null}
                                    <Grid item container md={6} lg={4} sm={6} key={key}>
                                        <Locale variable={variable} name={entry[0]} key={key} />
                                    </Grid>

                                </Fragment>

                                );
                            }
                            return null;
                        }
                    )}</Grid >
            :
            <div></div>
        );
    }
}


export default WeatherChart;