import React from "react";
import { SvgIcon } from "@material-ui/core";

export const CentralReportingIcon = () => {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.002 512.002" version="1.1" id="Capa_1" x="0px" y="0px" width="30px" height="30px" class="hovered-paths">
            <g><circle style={{ fill: "#69E781" }} cx="256" cy="267" r="245" data-original="#445EA0" class="hovered-path active-path" data-old_color="#445EA0" /><path style={{ fill: "#FFAF10" }} d="M100.718,421.644c-2.75,0-5-2.25-5-5V71.247H62.635c-2.75,0-5,2.25-5,5v334.546  c13.366,18.407,29.218,34.891,47.084,48.935h276.398c2.75,0,5-2.25,5-5v-33.083L100.718,421.644L100.718,421.644z" data-original="#FFAF10" /><path style={{ fill: "#EF9614" }} d="M76.635,76.247c0-2.75,2.25-5,5-5h-19c-2.75,0-5,2.25-5,5v334.546  c5.867,8.08,12.215,15.789,19,23.085V76.247z" data-original="#EF9614" /><path style={{ fill: "#FF9518" }} d="M133.718,385.646c-2.75,0-5-2.25-5-5V35.998H97.883c-2.75,0-5,2.25-5,5v378.48c0,2.751,2.25,5,5,5  h318.484c2.75,0,5-2.249,5-5v-33.832L133.718,385.646L133.718,385.646z" data-original="#FF9518" /><path style={{ fill: "#EF8318" }} d="M110.883,419.478V40.998c0-2.75,2.25-5,5-5h-18c-2.75,0-5,2.25-5,5v378.48c0,2.751,2.25,5,5,5h18  C113.133,424.478,110.883,422.229,110.883,419.478z" data-original="#EF8318" /><path style={{ fill: "#D9EAFC" }} d="M454.365,383.48c0,2.75-2.25,5-5,5H130.883c-2.75,0-5-2.25-5-5V5c0-2.75,2.25-5,5-5h318.482  c2.75,0,5,2.25,5,5V383.48z" data-original="#D9EAFC" class="" /><path style={{ fill: "#C3DDF4" }} d="M145.727,383.48V5c0-2.75,2.25-5,5-5h-19.845c-2.75,0-5,2.25-5,5v378.48c0,2.75,2.25,5,5,5h19.845  C147.977,388.48,145.727,386.23,145.727,383.48z" data-original="#C3DDF4" /><g>
                <path style={{ fill: "#DB722C" }} d="M415.09,50.794h-69.561c-4.143,0-7.5-3.357-7.5-7.5c0-4.142,3.357-7.5,7.5-7.5h69.561   c4.143,0,7.5,3.358,7.5,7.5C422.59,47.437,419.232,50.794,415.09,50.794z" data-original="#DB722C" />
                <path style={{ fill: "#DB722C" }} d="M415.09,76.293h-69.561c-4.143,0-7.5-3.358-7.5-7.5c0-4.143,3.357-7.5,7.5-7.5h69.561   c4.143,0,7.5,3.357,7.5,7.5C422.59,72.935,419.232,76.293,415.09,76.293z" data-original="#DB722C" />
                <path style={{ fill: "#DB722C" }} d="M415.09,101.791h-69.561c-4.143,0-7.5-3.358-7.5-7.5s3.357-7.5,7.5-7.5h69.561   c4.143,0,7.5,3.358,7.5,7.5C422.59,98.434,419.232,101.791,415.09,101.791z" data-original="#DB722C" />
                <path style={{ fill: "#DB722C" }} d="M415.09,127.29h-69.561c-4.143,0-7.5-3.358-7.5-7.5c0-4.143,3.357-7.5,7.5-7.5h69.561   c4.143,0,7.5,3.357,7.5,7.5C422.59,123.932,419.232,127.29,415.09,127.29z" data-original="#DB722C" />
            </g><g>
                    <path style={{ fill: "#52BBEF" }} d="M285.397,305.281H168.238c-4.143,0-7.5-3.358-7.5-7.5s3.357-7.5,7.5-7.5h117.158   c4.143,0,7.5,3.358,7.5,7.5C292.897,301.923,289.539,305.281,285.397,305.281z" data-original="#52BBEF" />
                    <path style={{ fill: "#52BBEF" }} d="M285.397,330.779H168.238c-4.143,0-7.5-3.357-7.5-7.5c0-4.142,3.357-7.5,7.5-7.5h117.158   c4.143,0,7.5,3.358,7.5,7.5C292.897,327.422,289.539,330.779,285.397,330.779z" data-original="#52BBEF" />
                    <path style={{ fill: "#52BBEF" }} d="M285.397,356.278H168.238c-4.143,0-7.5-3.357-7.5-7.5c0-4.142,3.357-7.5,7.5-7.5h117.158   c4.143,0,7.5,3.358,7.5,7.5C292.897,352.92,289.539,356.278,285.397,356.278z" data-original="#52BBEF" />
                </g><g>
                    <path style={{ fill: "#FE3745" }} d="M415.09,171.788H308.358c-4.143,0-7.5-3.358-7.5-7.5s3.357-7.5,7.5-7.5H415.09   c4.143,0,7.5,3.358,7.5,7.5S419.232,171.788,415.09,171.788z" data-original="#FE3745" class="" />
                    <path style={{ fill: "#FE3745" }} d="M415.09,197.287H308.358c-4.143,0-7.5-3.358-7.5-7.5s3.357-7.5,7.5-7.5H415.09   c4.143,0,7.5,3.358,7.5,7.5S419.232,197.287,415.09,197.287z" data-original="#FE3745" class="" />
                    <path style={{ fill: "#FE3745" }} d="M415.09,222.785H308.358c-4.143,0-7.5-3.359-7.5-7.5c0-4.143,3.357-7.5,7.5-7.5H415.09   c4.143,0,7.5,3.357,7.5,7.5C422.59,219.426,419.232,222.785,415.09,222.785z" data-original="#FE3745" class="" />
                    <path style={{ fill: "#FE3745" }} d="M415.09,248.283H308.358c-4.143,0-7.5-3.358-7.5-7.5s3.357-7.5,7.5-7.5H415.09   c4.143,0,7.5,3.358,7.5,7.5S419.232,248.283,415.09,248.283z" data-original="#FE3745" class="" />
                    <path style={{ fill: "#FE3745" }} d="M154.77,215.755h29.242c0-16.15,13.091-29.243,29.241-29.243v-29.24   C180.956,157.272,154.77,183.455,154.77,215.755z" data-original="#FE3745" class="" />
                </g><path style={{ fill: "#91E0E8" }} d="M228.084,142.442v29.241c16.15,0,29.241,13.091,29.241,29.241h29.243  C286.568,168.624,260.383,142.442,228.084,142.442z" data-original="#91E0E8" class="" /><g>
                    <path style={{ fill: "#91E0E8" }} d="M242.494,215.755c0,16.148-13.09,29.241-29.24,29.241s-29.241-13.093-29.241-29.241H154.77   c0,32.298,26.186,58.483,58.483,58.483c32.299,0,58.484-26.185,58.484-58.483H242.494z" data-original="#FFAF10" />
                    <path style={{ fill: "#91E0E8" }} d="M188.359,115.124V45.29c0-1.65-1.35-3-3-3h-18.182c-1.65,0-3,1.35-3,3v69.834H188.359z" data-original="#FFAF10" />
                </g><path style={{ fill: "#FE3745" }} d="M227.476,115.124V64.29c0-1.65-1.35-3-3-3h-18.181c-1.65,0-3,1.35-3,3v50.834H227.476z" data-original="#FE3745" class="" /><path style={{ fill: "#37C47A" }} d="M266.592,115.124V87.29c0-1.65-1.35-3-3-3h-18.181c-1.65,0-3,1.35-3,3v27.834H266.592z" data-original="#37C47A" class="" /><path style={{ fill: "#91E0E8" }} d="M305.709,115.124V45.29c0-1.65-1.35-3-3-3h-18.182c-1.65,0-3,1.35-3,3v69.834H305.709z" data-original="#91E0E8" class="" /><path style={{ fill: "#FE3745" }} d="M379.592,319.708c0-1.654,1.346-3,3-3h13.561v-33.56c0-2.75-2.25-5-5-5h-45.121c-2.75,0-5,2.25-5,5  v45.121c0,2.75,2.25,5,5,5h33.56V319.708z" data-original="#FE3745" class="" /><path style={{ fill: "#37C47A" }} d="M415.09,347.206c0,2.75-2.25,5-5,5h-27.498c-2.75,0-5-2.25-5-5v-27.498c0-2.75,2.25-5,5-5h27.498  c2.75,0,5,2.25,5,5V347.206z" data-original="#37C47A" class="" /><path style={{ fill: "#52BBEF" }} d="M310.879,127.29H159.008c-4.143,0-7.5-3.358-7.5-7.5c0-4.143,3.357-7.5,7.5-7.5h151.871  c4.143,0,7.5,3.357,7.5,7.5C318.379,123.932,315.022,127.29,310.879,127.29z" data-original="#52BBEF" /></g>
        </SvgIcon>
    );
};
