import React from 'react';
import {
    Create, Edit, SimpleForm, TextInput,
    EditButton, Datagrid, TextField, Filter,
    useLocale, ReferenceField,
    required, ReferenceInput, SelectInput, ImageField, Pagination
} from 'react-admin';
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import List from './theme/components/List'

import { MyImageField, MyImageInput } from './helpers/iconComponent';
import { withStyles } from "@material-ui/core/styles";
import clsx from 'clsx';

const styles = {
    image: { maxHeight: '1.4rem' }
}

export const PestList = withStyles(styles)(({ classes, ...props }) => {
    const locale = useLocale();
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} filters={<PestFilter />} {...props}>
            <Datagrid>
                  
                <MyImageField source="icon_id" />
                <TextField source={locale === 'en' ? "name" : "name_ar"} />
                <TextField source="code" />
                <ReferenceField source="pestinfectionlevel_id" reference="pestinfectionlevels" allowEmpty={true} >
                    <TextField source={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceField>
                <ReferenceField source="pestcategory_id" reference="pestcategories" allowEmpty={true} >
                    <TextField source={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceField>
                <EditButton />
                {/* <ShowButton /> */}
            </Datagrid>
        </List>
    )
});

export const PestEdit = props => {
    const locale = useLocale();
    return (
        <Edit undoable={false} {...props} className={clsx('tab-rtl-layout')}>
            <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
                <TextInput disabled source="id" />
                <TextInput source="name" validate={required()} />
                <TextInput source="name_ar" />
                <TextInput source="code" validate={required()} />
                <MyImageInput />
                <ReferenceInput perPage={-1} source="pestinfectionlevel_id" reference="pestinfectionlevels" validate={required()} >
                    <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceInput>
                <ReferenceInput perPage={-1} source="pestcategory_id" reference="pestcategories" validate={required()} >
                    <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    )
};

export const PestCreate = props => {
    const locale = useLocale();
    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <TextInput source="name" validate={required()} />
                <TextInput source="name_ar" />
                <TextInput source="code" validate={required()} />
                <MyImageInput />
                <ReferenceInput perPage={-1} source="pestinfectionlevel_id" reference="pestinfectionlevels" validate={required()} >
                    <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceInput>
                <ReferenceInput perPage={-1} source="pestcategory_id" reference="pestcategories" validate={required()} >
                    <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    )
};


// export const PestShow = (props) => (
//     <Show {...props}>
//         <TabbedShowLayout>
//             <Tab label="summary">
//                   
//                 <TextField source="qr_value" />
//                 <ReferenceField source="farm_id" reference="farms" >
//                     <TextField source="farm_code" />
//                 </ReferenceField>
//                 <ReferenceField source="Pesttype_id" reference="Pesttypes" >
//                     <TextField source="type" />
//                 </ReferenceField>
//                 <ReferenceField source="created_by" reference="users" >
//                     <TextField source="username" />
//                 </ReferenceField>
//                 <TextField source="lat" />
//                 <TextField source="lng" />
//             </Tab>
//         </TabbedShowLayout>
//     </Show>
// );

const PestFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput perPage={-1} source="pestinfectionlevel_id" reference="pestinfectionlevels" >
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);
