import React from 'react';
import {
    Create, Edit, SimpleForm, TextInput, NumberInput, SelectInput,
    EditButton, Datagrid, TextField, useLocale, Pagination, ReferenceField,
    ReferenceInput
} from 'react-admin';
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import List from './theme/components/List'
import clsx from 'clsx';

const AttributeField = props => {
    const locale = useLocale();
    const arabicAttributes = {
        'Temp': 'حرارة',
        'Wind': 'رياح',
        'Humidity': 'رطوبة',
    };

    if (props.record[props.source]) {
        return <span>{locale === 'en' ? props.record[props.source] : arabicAttributes[props.record[props.source]]}</span>
    }
    else
        return null;
}

export const WeatherAlertConfigList = (props) => {
    const locale = useLocale();
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}{...props}>
            <Datagrid>
                  
                <TextField source={locale === 'en' ? "name" : "name_ar"} />
                <TextField source="from" />
                <TextField source="to" />
                <AttributeField source="attribute" />
                <ReferenceField source="farm_id" reference="farms" allowEmpty>
                    <TextField source="farm_code" />
                </ReferenceField>;
                <EditButton />
            </Datagrid>
        </List>
    )
};

export const WeatherAlertConfigEdit = props => {
    const locale = useLocale();
    return (
        <Edit undoable={false} {...props}>
            <SimpleForm redirect="list" toolbar={<CustomToolbar />} className={clsx('tab-rtl-layout')}>
                <TextInput disabled source="id" />
                <TextInput source="name" />
                <TextInput source="name_ar" />
                <NumberInput source="from" step={0.01} />
                <NumberInput source="to" step={0.01} />
                <SelectInput source="attribute" choices={[
                    { id: 'Temp', name: locale === 'en' ? 'Temp' : 'حرارة' },
                    { id: 'Wind', name: locale === 'en' ? 'Wind' : 'رياح' },
                    { id: 'Humidity', name: locale === 'en' ? 'Humidity' : 'رطوبة' }
                ]} />
                <ReferenceInput perPage={-1} reference="farms" source="farm_id" allowEmpty>
                    <SelectInput optionText="farm_code" />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    )
};

export const WeatherAlertConfigCreate = props => {
    const locale = useLocale();
    return (
        <Create {...props}>
            <SimpleForm redirect="list" className={clsx('tab-rtl-layout')}>
                <TextInput source="name" />
                <TextInput source="name_ar" />
                <NumberInput source="from" step={0.01} />
                <NumberInput source="to" step={0.01} />
                <SelectInput source="attribute" choices={[
                    { id: 'Temp', name: locale === 'en' ? 'Temp' : 'حرارة' },
                    { id: 'Wind', name: locale === 'en' ? 'Wind' : 'رياح' },
                    { id: 'Humidity', name: locale === 'en' ? 'Humidity' : 'رطوبة' }
                ]} />
                <ReferenceInput perPage={-1} reference="farms" source="farm_id" allowEmpty>
                    <SelectInput optionText="farm_code" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    )
};
