import React, { useState, useEffect } from 'react';
import {
    Create, Edit, SimpleForm, TextInput,
    EditButton, Datagrid, TextField, Filter,
    Pagination, Show, SimpleShowLayout, ReferenceField,
    Tab, TabbedShowLayout, ReferenceManyField, useLocale,
    ShowButton, EmailField, SelectInput, required,
    minLength, email, ReferenceInput
} from 'react-admin';
import List from './theme/components/List'
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';

import httpClient from './httpClient';
import clsx from 'clsx';
const validateTeamName = [required(), minLength(4)];
const validateEmail = [required(), email()];
const validatePassword = [required(), minLength(6)];
const validateRole = required();

export const TeamList = (props) => {
    const locale = useLocale();
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}  {...props}>
            <Datagrid>
                  
                <TextField source={locale === 'en' ? "name" : "name_ar"} />
                <ReferenceField source="farm_id" reference="farms">
                    <TextField source="farm_code" />
                </ReferenceField>
                <EditButton />
                <ShowButton />
            </Datagrid>
        </List>
    )
};

export const TeamEdit = props => (
    <Edit undoable={false} {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar />} className={clsx('tab-rtl-layout')}>
            <TextInput disabled source="id" />
            <TextInput source="name" />
            <TextInput source="name_ar" />
            <ReferenceInput perPage={-1} source="farm_id" reference="farms" allowEmpty >
                <SelectInput optionText="farm_code" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);
const farmTypes = {
    is_palm_tree: 'Palm Tree Farm',
    is_pivot: 'Pivot Farm',
    is_orchard: 'Orchard Farm',
    is_greenhouse: 'GreenHouse Farm'
}

export const TeamCreate = props => {
    const [data, setData] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/organizations/check`);
            setData(response.json);
        };
        fetchData();
    }, []);
    const choices = Object.entries(farmTypes).filter(item => data && data[item[0]]).map(type => ({ id: type[0], name: type[1] }));

    return (
        <Create {...props}>
            <SimpleForm redirect="list" className={clsx('tab-rtl-layout')}>
                <TextInput source="name" />
                <TextInput source="name_ar" />
                <ReferenceInput perPage={-1} source="farm_id" reference="farms" allowEmpty >
                    <SelectInput optionText="farm_code" />
                </ReferenceInput>
                <SelectInput source="agriculture_type" choices={choices} />
            </SimpleForm>
        </Create>
    )
};

const TeamName = ({ record }) => {
    const locale = useLocale();
    return <span>{record ? (locale === 'en' ? record.name : record.name_ar) : ''}</span>;
}
export const TeamShow = (props) => {
    const locale = useLocale()
    return (
        <Show {...props} title={<TeamName />}>
            <TabbedShowLayout>
                <Tab label={locale === 'en' ? "Summary" : "الملخص"} contentClassName={clsx('tab-rtl-layout')}>
                    <TextField source={locale === 'en' ? "name" : "name_ar"} />
                    <ReferenceField source="farm_id" reference="farms">
                        <TextField source="farm_code" />
                    </ReferenceField>
                </Tab>
                <Tab label={locale === 'en' ? "Members" : "الأعضاء"}>
                    <ReferenceManyField perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} reference="users" target="team_id" addLabel={false}>
                        <Datagrid>
                              
                            <TextField source="username" />
                            <EmailField source="email" />
                            <ReferenceField source="role" reference="customroles" link={false}>
                                <TextField source={locale === 'en' ? "name" : "name_ar"} />
                            </ReferenceField>
                            <ShowButton />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
};

const TeamFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);
