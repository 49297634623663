import React from 'react';
import { useLocale } from 'react-admin';

export const UnitField = props => {
    const locale = useLocale();
    return <span>{Number.parseFloat(props.record[props.source]).toFixed(2)} {locale === 'en' ? props.unit : props.unit_ar}</span>
}


const formatSingleDigit = (num) => {
    if (num < 10) return '0' + num;
    else return num;
}

export const MinutesField = props => {
    const hours = Math.floor(Math.round(props.record[props.source]) / 60);
    const minutes = Math.floor(Math.round(props.record[props.source]) % 60);
    return <span>{formatSingleDigit(hours)}:{formatSingleDigit(minutes)}:{formatSingleDigit(0)}</span>
}

export const IrrigationReasonField = props => {
    const locale = useLocale();
    const arabicReasons = {
        'Ordinary Irrigation': 'ري اعتيادي',
        'Frost Irrigation': 'ري الصقيع'
    };
    return <span>{locale === 'en' ? props.record[props.source] : arabicReasons[props.record[props.source]]}</span>
}

export const IrrigationTypeField = props => {
    const locale = useLocale();
    const arabicTypes = {
        'Drip Irrigation': 'ري بالتنقيط',
        'Flood Irrigation': 'ري بالغمر'
    };
    return <span>{locale === 'en' ? props.record[props.source] : arabicTypes[props.record[props.source]]}</span>
}

export const IrrigationPeriodicity = props => {
    const locale = useLocale();
    const value = props.record[props.source];
    return <span>{value} {locale === 'en' ? (value > 1 ? 'days' : 'day') : (value > 1 ? 'أيام' : 'يوم')}</span>
}