import React from 'react'
import { GoogleMap, LoadScript, Polygon, Marker, InfoWindow, GroundOverlay } from '@react-google-maps/api'
import httpClient from './httpClient';
import { Button } from '@material-ui/core';
import { useLocale } from 'react-admin';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { VegetationChartComponent } from './vegetationChartComponent';
//import Markers from './markers';
//import MapGL, { Marker, Source, Layer, Popup } from 'react-map-gl';
// import { Editor, DrawPolygonMode, } from 'react-map-gl-draw';
import { roundToNearestMinutes } from 'date-fns';
const MAPBOX_TOKEN = process.env.REACT_APP_MAP_GL_KEY;

const arabicTypes = {
    Blocks: "الحوش",
    Traps: "المصائد",
    Assets: "الأصول الثابتة",
    Sensors: "المستشعرات",
    Palms: "النخيل",
    Pivots: 'قطاعات الري المحوري',
    Sections: 'القطاعات الفرعية',
    Index: 'معدل النمو'
};

const CapitalizeWord = (word) => {
    return word[0].toUpperCase() + word.substr(1);
}

const randomColor = () => {
    return '#' + '0123456789abcdef'.split('').map(function (v, i, a) {
        return i > 5 ? null : a[Math.floor(Math.random() * 16)]
    }).join('');
}

const colors = {};

const host = ''
const palmHealth = {
    Healthy: host + '/Healthy.png',
    'Recently healed': host + '/Recently healed.png',
    Infected: host + '/Infected.png'
};

const ButtonName = props => {
    const locale = useLocale();
    return <span>
        {locale === 'en' ?
            ((props.show ? "Hide " : "Show ") + props.type)
            :
            ((props.show ? "إخفاء " : "إظهار ") + arabicTypes[props.type])
        }

    </span>
}

const MyRadio = ({ value, thisObj, options }) => {
    console.log(thisObj)
    return <FormControl component="fieldset">
        <RadioGroup row aria-label="index" name="index" value={value}
            onChange={(event) =>
                thisObj.setState({ indexOption: event.target.value })}>
            {options.map((option, key) =>
                <FormControlLabel style={{ margin: '1em 3em' }} key={key} value={option} control={<Radio />} label={option} />
            )}
        </RadioGroup>
    </FormControl>
}

const displayPalms = (palms, thisObj) => {
    const markers = [];
    const keys = {};
    //console.log(this)
    for (let i = 0; i < palms.length; i++) {
        if (i === 0) {
            // assign column headers to index;
            palms[i].forEach((key, index) => keys[key] = index);
        }
        else if (palms[i][keys.lat] != null && palms[i][keys.health_status] != null) {
            markers.push(
                // GOOGLE MARKERS

                <Marker
                    position={{ lat: parseFloat(palms[i][keys.lat]), lng: parseFloat(palms[i][keys.long]) }}
                    title={palms[i][keys.qr_value]}
                    icon={palmHealth[palms[i][keys.health_status]]}
                    onClick={() => window.location.href = `#/palms/${palms[i][keys.id]}/show`}
                    key={"P" + i}
                />

                //MAPBOX MARKERS
                // <div>
                //    <a href={`#/palms/${palms[i][keys.id]}/show`}>
                //         <Marker latitude={parseFloat(palms[i][keys.lat])} longitude={parseFloat(palms[i][keys.long])} offsetLeft={0} offsetTop={0} key={"P" + i} title={palms[i][keys.qr_value]} >
                //         <div>
                //             <img 
                //             src={palmHealth[palms[i][keys.health_status]]} 
                //             // onClick={() => {thisObj.setState({selectedMarker: palms[i][keys.qr_value]})} }
                //                 />
                //             </div>
                //     </Marker>
                // {/* <Source
                //             type="geojson"
                //             data={{
                //                 "type": "Feature",
                //                 "geometry": {
                //                     "type": "Point",
                //                     "coordinates": [parseFloat(palms[i][keys.long]),parseFloat(palms[i][keys.lat])]
                //                 },
                //                 "properties": {
                //                   "name": palms[i][keys.qr_value]
                //                 }
                //             }}

                //         >
                //             <Layer
                //                 type="circle"
                //                 paint={{
                //                     "circle-color": '#00FF00',

                //                 }}

                //             />
                //         </Source> */}
                // </a>
                // {/* {thisObj.state.selectedMarker == palms[i][keys.qr_value] ? (
                //     <Popup
                //         latitude={parseFloat(palms[i][keys.lat])}
                //         longitude={parseFloat(palms[i][keys.long])}
                //         onClose={thisObj.setState({selectedMarker: null})}
                //     >
                //         <div>
                //             <a href={`#/palms/${palms[i][keys.id]}/show`}>
                //                 <p>
                //                     <b>{palms[i][keys.qr_value]}</b>
                //                 </p>
                //             </a>
                //         </div>
                //     </Popup>
                // ) : null} */}
                // </div>
            )
        }
    }
    return markers;
}



class ShowBorders extends React.Component {
    constructor(props) {
        super(props);
        console.log("probs" + this.props.toString())
        this.state = {
            serverData: null,
            isOpen: false,
            name: '',
            position: {},
            mounted: false,
            indexOption: 'NDVI',
            prevIndex: 'NDVI',
            show: {
                Blocks: 0,
                Traps: 0,
                Assets: 0,
                Sensors: 0,
                Palms: 0,
                Pivots: 0,
                Sections: 0,
                Index: 0
            },
            rgbImage: null,
            bounds: null,
            active: false,
            mapActive: false,
            viewport: {
                latitude: 37.8,
                longitude: -122.4,
                zoom: 13,
                bearing: 0,
                pitch: 0
            },
            selectedMarker: null

        };
        this.setSelectedMarker = this.setSelectedMarker.bind(this);


        console.log(this.props)

        this.types = [];
        if (this.props.resource === 'farms') {
            this.types.push(this.props.type);
        }
        if (this.props.type === 'Blocks') {
            this.types.push('Palms', 'Traps');
        }
        this.types.push('Sensors');

        if (this.props.resource !== 'sections') {
            this.types.push('Assets');
        }

        if (this.props.type === 'Pivots') {
            this.types.push('Sections');
        }

        if (this.props.resource === 'pivots') {
            this.types.push('Index');
        }
    }
    async componentDidMount() {

        if (this.props.resource === 'farms') {
            console.log(this.props)
            const images = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/farms/${this.props.record.id}`);
            if (images.json.file) {
                this.setState({ rgbImage: images.json.file.url })
            }
            this.setState({ bounds: images.json.borders })
            this.setState({ mapActive: true })
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/farms/fetchpalms?id=${this.props.record.id}&type=${this.props.type}`);
            this.setState({ serverData: response.json });
            
            
        }
        else if (this.props.resource) {
            try {
                const images = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/farms/${this.props.record.farm_id}`);
                console.log("iamges" , images)
                if (images.json.file) {
                    this.setState({ rgbImage: images.json.file.url })
                }
                this.setState({ bounds: images.json.borders })
                this.setState({ mapActive: true })
            } catch (e) { }
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/${this.props.resource}/fetchdata?id=${this.props.record.id}`);
            if (this.props.resource === 'pivots' || this.props.resource === 'sections') {
                const entityString = this.props.resource.slice(0, this.props.resource.length - 1);
                const indexValues = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/farms/vegetation?id=${this.props.record.farm_id}`);
                const images = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/pivots/getimage?id=${this.props.record.id}&type=${entityString}`);
                this.setState({
                    indexImages: images.json,
                    indexValues: indexValues.json
                });
            }
            this.setState({ serverData: response.json });
        }
        this.setState({ active: true })
    }

    handleToggleOpen = (qr, boundaries) => {
        let avgLat = 0, avgLng = 0, Center;
        for (let i = 0; i < boundaries.length; i++) {
            avgLat += boundaries[i][1];
            avgLng += boundaries[i][0];
        }
        if (boundaries.length > 0) {
            avgLat /= boundaries.length;
            avgLng /= boundaries.length;
        }
        Center = { lat: avgLat, lng: avgLng };
        this.setState({
            isOpen: true,
            name: qr,
            position: Center,
        });
    };

    handleToggleClose = () => {
        this.setState({
            isOpen: false,
        });
    };

    handelButtonClick = (type) => {
        let showObj = { ...this.state.show };
        showObj[type] = 1 - this.state.show[type];
        this.setState({ show: showObj });
    };

    setSelectedMarker = (object) => {

        console.log(object);
        this.setState({
            selectedMarker: object
        });
    };

    closePopup = () => {
        this.setState({
            selectedMarker: null
        });
    };

    render() {


        console.log(this.props)
        const data = this.state.serverData;
        let { record, source } = this.props
        let boundaries = this.state.bounds;
        let boundariesPivots = record[source];
        let imgurl = process.env.REACT_APP_DATA_PROVIDER_URL + this.state.rgbImage;
        console.log(this.props)
        console.log(this.state)
        let polygonArr = [];
        let initialCenter = {};
        let avgLat = 0, avgLng = 0;
        const bounds = {
            north: -90,
            south: 90,
            east: -180,
            west: 180
        };
        const boundsPivots = {
            north: -90,
            south: 90,
            east: -180,
            west: 180
        };
        if (boundaries) {
            boundaries = JSON.parse(boundaries);
            for (var i = 0; i < boundaries.length; i++) {
                polygonArr.push({ lat: boundaries[i][1], lng: boundaries[i][0] });
                avgLat += boundaries[i][1];
                avgLng += boundaries[i][0];
                bounds.north = Math.max(bounds.north, boundaries[i][1]);
                bounds.south = Math.min(bounds.south, boundaries[i][1]);
                bounds.east = Math.max(bounds.east, boundaries[i][0]);
                bounds.west = Math.min(bounds.west, boundaries[i][0]);
            }
            if (boundaries.length > 0) {
                avgLat /= boundaries.length;
                avgLng /= boundaries.length;
            }
            initialCenter = { lat: avgLat, lng: avgLng };
        }
        if (boundariesPivots) {
            boundariesPivots = JSON.parse(boundariesPivots);
            polygonArr=[];
            for (var i = 0; i < boundariesPivots.length; i++) {
                polygonArr.push({ lat:boundariesPivots[i][1], lng: boundariesPivots[i][0] })
                boundsPivots.north = Math.max(boundsPivots.north, boundariesPivots[i][1]);
                boundsPivots.south = Math.min(boundsPivots.south, boundariesPivots[i][1]);
                boundsPivots.east = Math.max(boundsPivots.east, boundariesPivots[i][0]);
                boundsPivots.west = Math.min(boundsPivots.west, boundariesPivots[i][0]);
            }
        }
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {data && this.types.map((type, i) =>
                        <Button key={i} onClick={() => { this.handelButtonClick(type) }} variant="contained"
                            color={this.state.show[type] ? "primary" : "default"} style={{ margin: '2em' }}>
                            <ButtonName show={this.state.show[type]} type={type} />
                        </Button>
                    )}
                </div>
                {(this.state.indexImages && this.state.show.Index) ? <div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <MyRadio value={this.state.indexOption} thisObj={this} options={Object.keys(this.state.indexImages)} />
                    </div>
                    <VegetationChartComponent indexes={this.state.indexValues[CapitalizeWord(this.props.resource)]} indexOption={this.state.indexOption} qr_option={record.qr_value} />
                </div> : null}



                {/* {this.state.mapActive && <MapGL
                    {...this.state.viewport}
                    latitude={this.state.active ? this.state.viewport.latitude : avgLat}
                    longitude={this.state.active ? this.state.viewport.longitude : avgLng}
                    width="100vw"
                    height="75vh"
                    mapStyle="mapbox://styles/mapbox/satellite-v9"
                    onViewportChange={viewport => this.setState({ viewport })}
                    mapboxApiAccessToken={MAPBOX_TOKEN}
                >

                    {this.state.mapActive && <Source
                        type="image"
                        url={imgurl}
                        coordinates={
                            [
                                [bounds.west, bounds.north],
                                [bounds.east, bounds.north],
                                [bounds.east, bounds.south],
                                [bounds.west, bounds.south]
                            ]
                        }>
                        <Layer
                            type="raster"
                        />


                    </Source>}


                    {this.state.show.Sensors && data && data.sensors.map((sensor, i) => {
                        if (sensor.latitude != null) {
                            return (<div>

                                <Marker latitude={parseFloat(sensor.latitude)} longitude={parseFloat(sensor.longitude)} offsetLeft={0} offsetTop={0} key={"S" + i} title={sensor.qr_value} onClick={() => window.location.href = `#/devices/${sensor.id}/show`} >
                                    <div>

                                        <img src={host + '/sensor.png'} onClick={() => {
                                            this.setSelectedMarker(sensor.qr_value)
                                        }} /></div>
                                </Marker>

                                {this.state.selectedMarker == sensor.qr_value ? (
                                    <Popup
                                        latitude={parseFloat(sensor.latitude)}
                                        longitude={parseFloat(sensor.longitude)}
                                        onClose={this.closePopup}
                                    >
                                        <div>
                                            <a href={`#/devices/${sensor.id}/show`}>
                                                <p>
                                                    <b>{sensor.qr_value}</b>
                                                </p>

                                            </a>
                                        </div>
                                    </Popup>
                                ) : null}
                            </div>)
                        }
                    }
                    )
                    }


                    {this.state.show.Assets && data && data.assets.map((asset, i) => {
                        if (asset.lat != null) {
                            return (<div>

                                <Marker latitude={parseFloat(asset.lat)} longitude={parseFloat(asset.lng)} offsetLeft={0} offsetTop={0} key={"S" + i} title={asset.qr_value} onClick={() => window.location.href = `#/assets/${asset.id}/show`} key={"A" + i}>
                                    <div><img src={host + '/asset.png'} onClick={() => {
                                        this.setSelectedMarker(asset.qr_value)
                                    }} /></div>
                                </Marker>
                                {this.state.selectedMarker == asset.qr_value ? (
                                    <Popup
                                        latitude={parseFloat(asset.lat)}
                                        longitude={parseFloat(asset.lng)}
                                        onClose={this.closePopup}
                                    >
                                        <div>
                                            <a href={`#/assets/${asset.id}/show`}>
                                                <p>
                                                    <b>{asset.qr_value}</b>
                                                </p>

                                            </a>
                                        </div>
                                    </Popup>
                                ) : null}


                            </div>
                            )

                        }

                    }
                    )
                    }

                    {this.state.show.Traps && data && data.traps.map((trap, i) => {
                        if (trap.lat != null) {
                            console.log(trap);
                            console.log(this.state.selectedMarker)
                            return (<div>
                                <Marker
                                    latitude={parseFloat(trap.lat)}
                                    longitude={parseFloat(trap.lng)}
                                    title={trap.qr_value}
                                    key={"T" + i}
                                >
                                    <div><img src={host + '/trap.png'} onClick={() => {
                                        this.setSelectedMarker(trap.qr_value)
                                    }} /></div>
                                </Marker>
                                {this.state.selectedMarker == trap.qr_value ? (
                                    <Popup
                                        latitude={parseFloat(trap.lat)}
                                        longitude={parseFloat(trap.lng)}
                                        onClose={this.closePopup}
                                    >
                                        <div>
                                            <a href={`#/traps/${trap.id}/show`}>
                                                <p>
                                                    <b>{trap.qr_value}</b>
                                                </p>

                                            </a>
                                        </div>
                                    </Popup>
                                ) : null}

                            </div>
                            )
                        }
                    }
                    )
                    }
                    {this.state.show.Palms && data && displayPalms(data.palms, this)}

                    {this.state.show.Index && this.state.indexImages && this.state.indexImages[this.state.indexOption].file ?
                        (this.state.prevIndex === this.state.indexOption ?
                            <Source
                                type="image"
                                url={`${process.env.REACT_APP_DATA_PROVIDER_URL}${this.state.indexImages[this.state.indexOption].file.url}`}
                                coordinates={
                                    [
                                        [boundsPivots.west, boundsPivots.north],
                                        [boundsPivots.east, boundsPivots.north],
                                        [boundsPivots.east, boundsPivots.south],
                                        [boundsPivots.west, boundsPivots.south]
                                    ]
                                }>
                                <Layer
                                    type="raster"
                                />


                            </Source>
                            : this.setState({ prevIndex: this.state.indexOption }))
                        : null
                    }

                    {this.props.resource === 'farms' && this.state.show.Blocks && data && data.blocks.map((block, i) => {
                        const blockBoundaries = [JSON.parse(block.borders)];
                        if (blockBoundaries) {
                            return (<div onClick={() => window.location.href = `#/blocks/${block.id}/show`}>
                                <Source
                                    type="geojson"
                                    data={{
                                        "type": "Feature",
                                        "geometry": {
                                            "type": "Polygon",
                                            "coordinates": blockBoundaries
                                        }
                                    }}

                                >
                                    <Layer
                                        type="fill"
                                        paint={{
                                            "fill-color": '#0000FF',
                                            "fill-opacity": 0.5,
                                            "fill-outline-color": "#009688",

                                        }}

                                    />
                                </Source>
                            </div>

                            );
                        }
                        else return null;
                    })
                    }




                    {this.props.resource === 'farms' && this.state.show.Pivots && data && data.pivots.map((pivot, i) => {
                        const pivotBoundaries = [JSON.parse(pivot.borders)];
                        if (pivotBoundaries) {
                            return (<Source
                                type="geojson"
                                data={{
                                    "type": "Feature",
                                    "geometry": {
                                        "type": "Polygon",
                                        "coordinates": pivotBoundaries
                                    }
                                }}
                            >
                                <Layer
                                    type="fill"
                                    paint={{
                                        "fill-color": '#009688',
                                        "fill-opacity": 0.35,
                                        "fill-outline-color": "#009688"

                                    }}
                                />
                            </Source>);
                        }
                        else return null;
                    })
                    }



                    {this.props.resource !== 'sections' && this.state.show.Sections && data && data.sections.map((section, i) => {
                        const sectionBoundaries = [JSON.parse(section.borders)];
                        if (sectionBoundaries) {

                            if (!colors[section.plantationtype_id]) {
                                colors[section.plantationtype_id] = randomColor();
                            }
                            return (<Source
                                type="geojson"
                                data={{
                                    "type": "Feature",
                                    "geometry": {
                                        "type": "Polygon",
                                        "coordinates": sectionBoundaries
                                    }
                                }}
                            >
                                <Layer
                                    type="fill"
                                    paint={{
                                        "fill-color": colors[section.plantationtype_id],
                                        "fill-opacity": 0.5,
                                        "fill-outline-color": colors[section.plantationtype_id]

                                    }}
                                />
                            </Source>);
                        }
                        else return null;
                    })
                    }






                </MapGL>} */}

                <LoadScript
                    id="script-loader"
                    googleMapsApiKey={process.env.REACT_APP_MAPS_KEY}>
                    <GoogleMap
                        id='drawing-example'
                        mapTypeId="satellite"
                        onMouseOver={() => {
                            if (!this.state.mounted)
                                this.setState({ mounted: true });
                        }}
                        zoom={14}
                        center={this.state.mounted ? null : initialCenter}
                        style={{ flexBasis: 'auto' }}
                        mapContainerStyle={{
                            position: 'relative', height: '600px', display: 'flex'
                        }}>
                        {this.state.mapActive && <GroundOverlay
                            url={imgurl}
                            bounds={bounds}
                        />}

                        <Polygon options={{
                            fillColor: "#0000FF",
                            fillOpacity: '0.1',
                            strokeColor: "#000000",
                            strokeOpacity: '0.8',
                            strokeWeight: '2'
                        }}
                            path={polygonArr}
                        />
                        {this.state.show.Index && this.state.indexImages && this.state.indexImages[this.state.indexOption].pngURI ?
                            (this.state.prevIndex === this.state.indexOption ?
                                <GroundOverlay
                                    url={`${process.env.REACT_APP_FTP_IMAGES_SERVER}${this.state.indexImages[this.state.indexOption].pngURI}`}
                                    bounds={boundsPivots}
                                /> : this.setState({ prevIndex: this.state.indexOption }))
                            : null
                        }
                        {this.state.show.Sensors && data && data.sensors.map((sensor, i) =>
                            <Marker
                                position={{ lat: parseFloat(sensor.latitude), lng: parseFloat(sensor.longitude) }}
                                title={sensor.qr_value}
                                icon="https://i.imgur.com/AuQM9MU.png"
                                onClick={() => window.location.href = `#/devices/${sensor.id}/show`}
                                key={"S" + i}
                            />)
                        }
                        {this.state.show.Assets && data && data.assets.map((asset, i) =>
                            <Marker
                                position={{ lat: parseFloat(asset.lat), lng: parseFloat(asset.lng) }}
                                title={asset.qr_value}
                                icon="https://i.imgur.com/CJTBIyG.png"
                                onClick={() => window.location.href = `#/assets/${asset.id}/show`}
                                key={"A" + i}
                            />)
                        }
                        {this.state.show.Traps && data && data.traps.map((trap, i) =>
                            <Marker
                                position={{ lat: parseFloat(trap.lat), lng: parseFloat(trap.lng) }}
                                title={trap.qr_value}
                                icon="https://i.imgur.com/Kaf8AGA.png"
                                onClick={() => window.location.href = `#/traps/${trap.id}/show`}
                                key={"T" + i}
                            />)
                        }
                        {this.state.show.Palms && data && displayPalms(data.palms)}
                        {this.props.resource === 'farms' && this.state.show.Blocks && data && data.blocks.map((block, i) => {
                            const blockBoundaries = JSON.parse(block.borders);
                            if (blockBoundaries) {
                                const pathArray = blockBoundaries.map(boundary => { return { lat: boundary[1], lng: boundary[0] } })
                                return (<Polygon options={{
                                    fillColor: "#009688",
                                    fillOpacity: '0.35',
                                    strokeColor: "#009688",
                                    strokeOpacity: '0.8',
                                    strokeWeight: '2'
                                }}
                                    path={pathArray}
                                    key={"B" + i}
                                    onMouseOver={() => this.handleToggleOpen(block.qr_value, blockBoundaries)}
                                    onMouseOut={() => this.handleToggleClose()}
                                    onClick={() => window.location.href = `#/blocks/${block.id}/show`}
                                />);
                            }
                            else return null;
                        })
                        }
                        {this.props.resource === 'farms' && this.state.show.Pivots && data && data.pivots.map((pivot, i) => {
                            const pivotBoundaries = JSON.parse(pivot.borders);
                            if (pivotBoundaries) {
                                const pathArray = pivotBoundaries.map(boundary => { return { lat: boundary[1], lng: boundary[0] } })
                                return (<Polygon options={{
                                    fillColor: "#009688",
                                    fillOpacity: '0.35',
                                    strokeColor: "#009688",
                                    strokeOpacity: '0.8',
                                    strokeWeight: '2'
                                }}
                                    path={pathArray}
                                    key={"B" + i}
                                    onMouseOver={() => this.handleToggleOpen(pivot.qr_value, pivotBoundaries)}
                                    onMouseOut={() => this.handleToggleClose()}
                                    onClick={() => window.location.href = `#/pivots/${pivot.id}/show`}
                                />);
                            }
                            else return null;
                        })
                        }
                        {this.props.resource !== 'sections' && this.state.show.Sections && data && data.sections.map((section, i) => {
                            const sectionBoundaries = JSON.parse(section.borders);
                            if (sectionBoundaries) {
                                const pathArray = sectionBoundaries.map(boundary => { return { lat: boundary[1], lng: boundary[0] } });
                                if (!colors[section.plantationtype_id]) {
                                    colors[section.plantationtype_id] = randomColor();
                                }
                                return (<Polygon options={{
                                    fillColor: colors[section.plantationtype_id],
                                    fillOpacity: '0.5',
                                    strokeColor: colors[section.plantationtype_id],
                                    strokeOpacity: '0.9',
                                    strokeWeight: '2'
                                }}
                                    path={pathArray}
                                    key={"B" + i}
                                    onMouseOver={() => this.handleToggleOpen(section.qr_value, sectionBoundaries)}
                                    onMouseOut={() => this.handleToggleClose()}
                                    onClick={() => window.location.href = `#/sections/${section.id}/show`}
                                />);
                            }
                            else return null;
                        })
                        }
                        {this.state.isOpen && (
                            <InfoWindow onCloseClick={() => this.handleToggleClose()} position={this.state.position}>
                                <span>{this.state.name}</span>
                            </InfoWindow>
                        )}
                    </GoogleMap>
                </LoadScript>
            </div >
        )
    }
}

export default ShowBorders