import React from "react";
import { SvgIcon } from "@material-ui/core";

export const SettingsIcon = () => {
	return (
		<SvgIcon viewBox="0 0 512.002 512.002">
			<g>
				<path style={{ fill: "#78B9EB" }} d="M224.512,299.46c0.495,0.631,0.973,1.28,1.451,1.937C225.485,300.74,225.007,300.092,224.512,299.46
		z"/>
				<path style={{ fill: "#78B9EB" }} d="M219.255,293.393c0.35,0.358,0.657,0.742,0.998,1.109
		C219.913,294.135,219.605,293.751,219.255,293.393z"/>
				<path style={{ fill: "#78B9EB" }} d="M229.402,306.654c0.307,0.521,0.649,1.024,0.947,1.553
		C230.05,307.678,229.709,307.2,229.402,306.654z"/>
				<path style={{ fill: "#78B9EB" }} d="M233.813,315.418c0.23,0.572,0.418,1.169,0.64,1.707
		C234.231,316.587,234.044,315.989,233.813,315.418z"/>
				<path style={{ fill: "#78B9EB" }} d="M211.738,286.882c0.538,0.41,1.101,0.802,1.63,1.22
		C212.838,287.676,212.275,287.292,211.738,286.882z"/>
				<path style={{ fill: "#78B9EB" }} d="M204.655,282.18c0.691,0.393,1.365,0.853,2.048,1.237
		C206.02,282.999,205.346,282.581,204.655,282.18z"/>
				<path style={{ fill: "#78B9EB" }} d="M209.195,397.653c5.043-3.174,9.771-6.81,14.131-10.871c1.203-1.135,2.261-2.432,3.132-3.84
		c4.676-7.543,8.132-15.778,10.24-24.397C232.542,374.468,222.78,388.361,209.195,397.653z"/>
				<path style={{ fill: "#78B9EB" }} d="M179.123,273.647c0.811,0.094,1.604,0.239,2.398,0.367
		C180.727,273.92,179.934,273.741,179.123,273.647z"/>
				<path style={{ fill: "#78B9EB" }} d="M197.453,278.528c0.299,0.128,0.572,0.29,0.853,0.418
		C198.025,278.818,197.751,278.656,197.453,278.528z"/>
				<path style={{ fill: "#78B9EB" }} d="M236.433,323.234c0.222,0.802,0.427,1.604,0.623,2.415
		C236.86,324.838,236.655,324.036,236.433,323.234z"/>
				<path style={{ fill: "#78B9EB" }} d="M170.351,409.6c-1.937,0-3.823-0.196-5.717-0.367C166.528,409.387,168.414,409.6,170.351,409.6z" />
				<path style={{ fill: "#78B9EB" }} d="M187.938,407.313l-1.024,0.265L187.938,407.313z" />
				<path style={{ fill: "#78B9EB" }} d="M238.285,332.399c0.068,0.503,0.171,0.99,0.23,1.493
		C238.455,333.389,238.353,332.902,238.285,332.399z"/>
				<path style={{ fill: "#78B9EB" }} d="M161.818,409.02c-2.765-0.367-5.513-0.879-8.218-1.553
		C156.305,408.141,159.053,408.653,161.818,409.02z"/>
				<path style={{ fill: "#78B9EB" }} d="M179.49,408.969c-0.393,0.051-0.759,0.128-1.152,0.171
		C178.722,409.097,179.106,409.02,179.49,408.969z"/>
				<path style={{ fill: "#78B9EB" }} d="M208.085,398.404c-1.212,0.794-2.458,1.536-3.721,2.261
		C205.653,399.983,206.848,399.172,208.085,398.404z"/>
				<path style={{ fill: "#78B9EB" }} d="M238.882,342.349c0,0.742-0.077,1.468-0.111,2.202C238.805,343.817,238.874,343.04,238.882,342.349z
		"/>
				<path style={{ fill: "#78B9EB" }} d="M238.165,351.053c-0.145,1.007-0.273,2.022-0.461,3.012
		C237.884,353.058,238.029,352.06,238.165,351.053z"/>
				<path style={{ fill: "#78B9EB" }} d="M187.631,275.277c0.734,0.188,1.476,0.35,2.202,0.563
		C189.107,275.627,188.365,275.465,187.631,275.277z"/>
			</g>
			<path
				style={{ fill: "#FFAF46" }} d="M25.6,375.467h9.387c7.074,0,13.397,4.386,15.889,11.008c0.973,2.56,2.022,5.077,3.149,7.552
	c2.953,6.451,1.596,14.054-3.413,19.081l-6.665,6.733c-6.647,6.665-6.647,17.451,0,24.115l24.115,24.115
	c6.665,6.647,17.451,6.647,24.115,0l6.699-6.673c5.026-5.009,12.629-6.366,19.081-3.413c2.475,1.135,4.992,2.185,7.552,3.149
	c6.622,2.483,11.017,8.806,11.025,15.881v9.387c0,9.429,7.637,17.067,17.067,17.067h34.133c9.429,0,17.067-7.637,17.067-17.067
	v-9.387c0-7.074,4.386-13.397,11.008-15.889c2.56-0.973,5.077-2.022,7.552-3.149c6.451-2.953,14.054-1.596,19.081,3.413l6.699,6.673
	c6.665,6.647,17.451,6.647,24.115,0l24.115-24.115c6.647-6.665,6.647-17.451,0-24.115l-6.673-6.699
	c-5.009-5.026-6.366-12.629-3.413-19.081c1.135-2.475,2.185-4.992,3.149-7.552c2.483-6.639,8.824-11.034,15.915-11.034h9.387
	c9.429,0,17.067-7.637,17.067-17.067v-34.133c0-9.429-7.637-17.067-17.067-17.067h-9.387c-7.074,0-13.397-4.386-15.889-11.008
	c-0.973-2.56-2.022-5.077-3.149-7.552c-2.961-6.451-1.596-14.054,3.413-19.081l6.673-6.699c6.647-6.665,6.647-17.451,0-24.115
	L273.28,214.63c-6.665-6.647-17.451-6.647-24.115,0l-6.699,6.673c-5.026,5.009-12.629,6.366-19.081,3.413
	c-2.475-1.135-4.992-2.185-7.552-3.149c-6.639-2.483-11.034-8.823-11.034-15.915v-9.387c0-9.429-7.637-17.067-17.067-17.067H153.6
	c-9.429,0-17.067,7.637-17.067,17.067v9.387c0,7.074-4.386,13.397-11.008,15.889c-2.56,0.973-5.077,2.022-7.552,3.149
	c-6.451,2.953-14.054,1.596-19.081-3.413l-6.733-6.665c-6.665-6.647-17.451-6.647-24.115,0l-24.081,24.132
	c-6.647,6.665-6.647,17.451,0,24.115l6.673,6.699c5.009,5.026,6.374,12.629,3.413,19.081c-1.135,2.475-2.185,4.992-3.149,7.552
	c-2.492,6.63-8.832,11.017-15.915,11.008H25.6c-9.429,0-17.067,7.637-17.067,17.067V358.4
	C8.533,367.829,16.171,375.467,25.6,375.467z M153.6,275.2c5.581-1.408,11.315-2.125,17.067-2.133
	c2.825,0.017,5.649,0.213,8.457,0.58c0.811,0.094,1.604,0.239,2.398,0.367c2.074,0.324,4.105,0.751,6.11,1.263
	c0.734,0.188,1.476,0.35,2.202,0.563c2.594,0.742,5.137,1.638,7.62,2.688c0.299,0.128,0.572,0.29,0.853,0.418
	c2.167,0.964,4.284,2.048,6.34,3.234c0.691,0.401,1.365,0.853,2.048,1.237c1.707,1.084,3.413,2.244,5.035,3.465
	c0.538,0.41,1.101,0.794,1.63,1.22c2.057,1.655,4.028,3.422,5.888,5.291c0.35,0.358,0.657,0.742,0.998,1.109
	c1.502,1.579,2.927,3.234,4.267,4.958c0.495,0.631,0.973,1.28,1.451,1.937c1.22,1.707,2.372,3.413,3.413,5.257
	c0.307,0.521,0.649,1.024,0.947,1.553c1.297,2.33,2.449,4.745,3.465,7.211c0.23,0.572,0.418,1.161,0.64,1.707
	c0.751,1.988,1.417,4.011,1.98,6.076c0.222,0.802,0.427,1.604,0.623,2.415c0.521,2.227,0.93,4.48,1.229,6.75
	c0.068,0.503,0.171,0.99,0.23,1.493c0.282,2.483,0.435,4.975,0.444,7.475c0,0.341,0,0.674-0.051,1.015
	c0,0.725-0.077,1.468-0.111,2.202c-0.102,2.193-0.299,4.361-0.606,6.502c-0.137,1.007-0.282,2.005-0.461,3.012
	c-0.29,1.527-0.666,3.012-1.05,4.506c-2.108,8.619-5.564,16.853-10.24,24.397c-0.87,1.408-1.929,2.705-3.132,3.84
	c-4.343,4.045-9.062,7.68-14.089,10.846l-1.109,0.725c-1.237,0.768-2.475,1.579-3.721,2.261c-5.163,2.927-10.684,5.154-16.427,6.647
	l-1.024,0.265c-2.449,0.597-4.924,1.067-7.424,1.391c-0.384,0.051-0.768,0.128-1.152,0.171c-2.543,0.307-5.111,0.469-7.671,0.486
	h-0.316c-1.937,0-3.823-0.196-5.717-0.367c-0.93-0.085-1.894-0.077-2.816-0.196c-2.765-0.367-5.513-0.896-8.218-1.57
	c-36.523-9.446-58.479-46.711-49.033-83.234C110.788,300.186,129.562,281.421,153.6,275.2z"/>
			<g>
				<path style={{ fill: "#78B9EB" }} d="M230.349,308.207c1.297,2.33,2.449,4.745,3.465,7.211
		C232.798,312.951,231.646,310.537,230.349,308.207z"/>
				<path style={{ fill: "#78B9EB" }} d="M234.453,317.158c0.751,1.988,1.417,4.011,1.98,6.076
		C235.87,321.169,235.204,319.147,234.453,317.158z"/>
				<path style={{ fill: "#78B9EB" }} d="M238.933,341.333c-0.009-2.483-0.145-4.966-0.418-7.441c0.299,2.807,0.418,5.632,0.367,8.457
		C238.933,342.007,238.933,341.675,238.933,341.333z"/>
				<path style={{ fill: "#78B9EB" }} d="M237.056,325.649c0.521,2.227,0.93,4.48,1.229,6.75
		C237.986,330.129,237.577,327.876,237.056,325.649z"/>
				<path style={{ fill: "#78B9EB" }} d="M181.521,274.014c2.074,0.324,4.105,0.751,6.11,1.263
		C185.626,274.773,183.595,274.338,181.521,274.014z"/>
				<path style={{ fill: "#78B9EB" }} d="M170.667,273.067c2.825,0.017,5.649,0.213,8.457,0.58
		C176.316,273.28,173.491,273.084,170.667,273.067z"/>
				<path style={{ fill: "#78B9EB" }} d="M198.315,278.946c2.167,0.964,4.284,2.039,6.34,3.234
		C202.598,280.994,200.482,279.91,198.315,278.946z"/>
				<path style={{ fill: "#78B9EB" }} d="M189.833,275.84c2.594,0.742,5.137,1.638,7.62,2.688C194.97,277.478,192.427,276.582,189.833,275.84
		z"/>
				<path style={{ fill: "#78B9EB" }} d="M225.963,301.397c1.22,1.707,2.372,3.413,3.413,5.257
		C228.335,304.845,227.183,303.087,225.963,301.397z"/>
				<path style={{ fill: "#78B9EB" }} d="M220.254,294.502c1.493,1.579,2.918,3.234,4.267,4.958
		C223.181,297.737,221.756,296.081,220.254,294.502z"/>
				<path style={{ fill: "#78B9EB" }} d="M206.703,283.418c1.707,1.084,3.413,2.244,5.035,3.465
		C210.108,285.662,208.435,284.501,206.703,283.418z"/>
				<path style={{ fill: "#78B9EB" }} d="M213.333,288.102c2.065,1.647,4.028,3.413,5.888,5.291
		C217.361,291.524,215.39,289.758,213.333,288.102z"/>
				<path style={{ fill: "#78B9EB" }} d="M204.365,400.666c-5.188,2.876-10.701,5.103-16.427,6.647
		C193.681,405.82,199.202,403.593,204.365,400.666z"/>
				<path style={{ fill: "#78B9EB" }} d="M186.88,407.578c-2.449,0.597-4.924,1.067-7.424,1.391
		C181.956,408.644,184.431,408.175,186.88,407.578z"/>
				<path style={{ fill: "#78B9EB" }} d="M170.667,409.6c2.569-0.009,5.129-0.162,7.68-0.461c-2.654,0.307-5.316,0.452-7.987,0.444
		L170.667,409.6z"/>
				<path style={{ fill: "#78B9EB" }} d="M164.634,409.216c-0.93-0.085-1.894-0.077-2.816-0.196
		C162.739,409.139,163.703,409.131,164.634,409.216z"/>
				<path style={{ fill: "#78B9EB" }} d="M238.771,344.55c-0.094,2.15-0.307,4.318-0.606,6.502
		C238.473,348.911,238.669,346.743,238.771,344.55z"/>
				<path style={{ fill: "#78B9EB" }} d="M209.195,397.653c-0.367,0.239-0.734,0.495-1.109,0.725L209.195,397.653z" />
				<path style={{ fill: "#78B9EB" }} d="M237.705,354.065c-0.273,1.493-0.683,3.004-1.05,4.506
		C237.039,357.077,237.414,355.593,237.705,354.065z"/>
			</g>
			<g>
				<path style={{ fill: "#231F20" }} d="M315.733,298.667h-9.387c-3.533,0.009-6.69-2.193-7.902-5.513c-1.041-2.731-2.159-5.402-3.362-8.047
		c-1.493-3.217-0.811-7.023,1.707-9.523l6.69-6.699c9.933-10.018,9.916-26.18-0.043-36.173l-24.115-24.115
		c-10.01-9.95-26.172-9.95-36.181,0l-6.69,6.682c-2.5,2.509-6.306,3.191-9.523,1.707c-2.645-1.203-5.316-2.321-8.055-3.413
		c-3.337-1.203-5.547-4.378-5.538-7.919v-9.387c0-14.14-11.46-25.6-25.6-25.6H153.6c-14.14,0-25.6,11.46-25.6,25.6v9.387
		c0.009,3.533-2.193,6.69-5.513,7.902c-2.731,1.041-5.402,2.159-8.047,3.362c-3.217,1.485-7.023,0.802-9.523-1.707l-6.699-6.69
		c-10.01-9.941-26.163-9.941-36.173,0l-24.115,24.192c-9.95,10.01-9.95,26.172,0,36.181l6.682,6.69c2.517,2.5,3.2,6.306,1.707,9.523
		c-1.203,2.645-2.321,5.316-3.371,8.055c-1.22,3.337-4.412,5.538-7.962,5.504H25.6c-14.14,0-25.6,11.46-25.6,25.6V358.4
		C0,372.54,11.46,384,25.6,384h9.387c3.533-0.009,6.69,2.193,7.902,5.513c1.041,2.731,2.159,5.402,3.362,8.047
		c1.493,3.217,0.811,7.023-1.707,9.523l-6.69,6.699c-9.941,10.01-9.941,26.163,0,36.173l24.115,24.115
		c10.01,9.95,26.172,9.95,36.181,0l6.69-6.682c2.5-2.509,6.306-3.191,9.523-1.707c2.645,1.203,5.316,2.321,8.055,3.413
		c3.354,1.186,5.589,4.361,5.581,7.919v9.387c0,14.14,11.46,25.6,25.6,25.6h34.133c14.14,0,25.6-11.46,25.6-25.6v-9.387
		c-0.009-3.533,2.193-6.69,5.513-7.902c2.731-1.041,5.402-2.159,8.047-3.362c3.217-1.485,7.023-0.802,9.523,1.707l6.699,6.69
		c10.018,9.924,26.172,9.907,36.173-0.043l24.115-24.115c9.95-10.01,9.95-26.172,0-36.181l-6.682-6.69
		c-2.517-2.5-3.2-6.306-1.707-9.523c1.203-2.645,2.321-5.316,3.413-8.055c1.203-3.337,4.378-5.547,7.919-5.538h9.387
		c14.14,0,25.6-11.46,25.6-25.6v-34.133C341.333,310.127,329.873,298.667,315.733,298.667z M324.267,358.4
		c0,4.71-3.823,8.533-8.533,8.533h-9.387c-10.607,0.043-20.105,6.596-23.893,16.503c-0.905,2.389-1.886,4.745-2.935,7.057
		c-4.386,9.685-2.355,21.069,5.12,28.638l6.673,6.682c3.337,3.328,3.337,8.73,0.009,12.066l-0.009,0.009l-24.115,24.115
		c-3.328,3.328-8.73,3.328-12.066,0l-6.69-6.682c-7.561-7.475-18.953-9.515-28.638-5.12c-2.313,1.05-4.668,2.031-7.04,2.935
		c-9.899,3.789-16.452,13.278-16.495,23.876v9.387c0,4.71-3.823,8.533-8.533,8.533H153.6c-4.71,0-8.533-3.823-8.533-8.533v-9.387
		c-0.043-10.607-6.596-20.105-16.503-23.893c-2.389-0.905-4.745-1.886-7.057-2.935c-9.685-4.386-21.069-2.347-28.638,5.12
		l-6.682,6.673c-3.328,3.337-8.73,3.337-12.066,0.009l-0.009-0.009l-24.115-24.09c-3.328-3.328-3.328-8.738,0-12.066l6.682-6.69
		c7.475-7.569,9.506-18.953,5.12-28.638c-1.05-2.313-2.031-4.668-2.935-7.04c-3.78-9.907-13.269-16.478-23.876-16.521H25.6
		c-4.71,0-8.533-3.823-8.533-8.533v-34.133c0-4.71,3.823-8.533,8.533-8.533h9.387c10.607-0.043,20.105-6.596,23.893-16.503
		c0.905-2.389,1.886-4.745,2.935-7.057c4.386-9.685,2.355-21.069-5.12-28.638l-6.707-6.682c-3.337-3.328-3.337-8.73-0.009-12.066
		l0.009-0.009l24.115-24.115c3.337-3.328,8.73-3.328,12.066,0l6.69,6.682c7.561,7.475,18.953,9.515,28.638,5.12
		c2.313-1.05,4.668-2.031,7.04-2.935c9.916-3.78,16.478-13.269,16.529-23.876v-9.387c0-4.71,3.823-8.533,8.533-8.533h34.133
		c4.71,0,8.533,3.823,8.533,8.533v9.387c0.043,10.607,6.596,20.105,16.503,23.893c2.389,0.905,4.745,1.886,7.057,2.935
		c9.685,4.395,21.077,2.355,28.638-5.12l6.682-6.673c3.345-3.311,8.73-3.311,12.075,0l24.115,24.115
		c3.328,3.328,3.328,8.738,0,12.066l-6.682,6.69c-7.475,7.569-9.506,18.953-5.12,28.638c1.05,2.313,2.031,4.668,2.935,7.04
		c3.789,9.899,13.278,16.452,23.876,16.495h9.387c4.71,0,8.533,3.823,8.533,8.533V358.4z"/>
				<path style={{ fill: "#231F20" }} d="M247.296,337.92c-0.085-2.048-0.23-4.087-0.478-6.118c-0.273-2.185-0.597-4.352-1.05-6.477
		c-0.299-1.391-0.691-2.765-1.067-4.147c-0.478-1.758-1.015-3.49-1.613-5.197c-0.418-1.186-0.853-2.364-1.297-3.533
		c-1.101-2.731-2.355-5.402-3.763-7.987c-0.111-0.196-0.179-0.41-0.29-0.606c-2.219-3.951-4.787-7.697-7.68-11.187
		c-0.247-0.307-0.529-0.597-0.785-0.896c-1.178-1.391-2.389-2.739-3.661-4.028c-0.666-0.691-1.365-1.34-2.065-2.005
		c-0.853-0.794-1.707-1.579-2.56-2.33c-1.033-0.896-2.091-1.766-3.166-2.611l-1.399-1.041c-13.201-9.89-29.261-15.232-45.756-15.223
		c-0.853,0-1.707,0.128-2.56,0.162c-1.818,0.051-3.644,0.154-5.478,0.341c-0.964,0.102-1.937,0.213-2.901,0.35
		c-2.782,0.367-5.538,0.879-8.269,1.545c-41.088,10.615-65.792,52.531-55.177,93.628c6.997,27.076,28.151,48.213,55.236,55.185
		c0.137,0,0.282,0.043,0.427,0.077c2.944,0.734,5.931,1.306,8.943,1.707c1.988,0.247,3.977,0.299,5.973,0.393
		c1.331,0.068,2.56,0.265,3.951,0.265c2.432,0,4.958-0.162,7.535-0.418c0.273,0,0.546-0.077,0.853-0.111
		c2.074-0.23,4.173-0.538,6.315-0.964c0.853-0.162,1.647-0.375,2.466-0.563c1.502-0.35,2.995-0.717,4.531-1.169
		c1.195-0.358,2.355-0.777,3.524-1.186c1.169-0.41,2.381-0.853,3.593-1.314s2.227-0.99,3.328-1.502
		c1.434-0.657,2.859-1.306,4.267-2.074h0.051c7.962-4.267,15.317-9.574,21.871-15.795c1.766-1.655,3.311-3.533,4.574-5.598
		c6.665-10.675,11.034-22.622,12.851-35.072c0.239-1.647,0.452-3.294,0.58-4.975c0.119-1.545,0.205-3.081,0.239-4.617
		c0-0.486,0.068-0.947,0.068-1.434C247.467,340.19,247.347,339.081,247.296,337.92z M155.674,283.477
		c4.898-1.237,9.941-1.869,14.993-1.877c2.441,0.017,4.873,0.179,7.296,0.495c0.649,0.077,1.289,0.196,1.929,0.29
		c1.843,0.29,3.669,0.657,5.47,1.118c0.589,0.154,1.178,0.29,1.758,0.452c4.54,1.306,8.909,3.149,13.013,5.478
		c0.657,0.384,1.297,0.785,1.946,1.186c1.348,0.853,2.654,1.707,3.942,2.679c0.691,0.503,1.391,0.998,2.057,1.536
		c1.673,1.34,3.277,2.765,4.804,4.267c0.606,0.614,1.161,1.289,1.749,1.929c0.981,1.067,1.937,2.159,2.85,3.311
		c0.631,0.794,1.237,1.613,1.835,2.441c0.853,1.229,1.707,2.509,2.475,3.806c0.427,0.708,0.853,1.399,1.289,2.125
		c1.101,2.022,2.082,4.113,2.935,6.255c0.341,0.853,0.631,1.707,0.939,2.56c0.555,1.57,1.033,3.157,1.451,4.762
		c0.213,0.853,0.452,1.613,0.631,2.441c0.495,2.304,0.862,4.642,1.084,6.989c0.051,0.623,0.051,1.254,0.094,1.877
		c0.119,1.963,0.154,3.934,0.077,5.914c0,0.708-0.06,1.417-0.102,2.116c-0.154,2.065-0.418,4.13-0.768,6.204
		c-3.191,17.741-14.208,33.101-29.986,41.813c-1.058,0.555-2.099,1.075-3.14,1.553l-0.708,0.341
		c-4.958,2.253-10.189,3.831-15.565,4.71c-0.375,0.06-0.759,0.128-1.143,0.179c-1.067,0.154-2.116,0.282-3.157,0.375
		c-1.374,0.119-2.756,0.188-4.164,0.213c-0.597,0-1.186,0-1.783,0c-2.022-0.026-4.036-0.145-6.042-0.375l-1.024-0.119
		c-2.347-0.307-4.676-0.742-6.972-1.314c-31.957-8.183-51.234-40.721-43.059-72.678c5.402-21.12,21.888-37.615,42.999-43.042
		L155.674,283.477z"/>
				<path style={{ fill: "#231F20" }} d="M486.4,179.2h-26.837c-3.575,0.034-6.784-2.219-7.953-5.598c-2.645-7.927-5.845-15.659-9.583-23.134
		c-1.587-3.226-0.922-7.108,1.638-9.634l19.029-19.055c9.984-9.993,9.984-26.197,0-36.19l-36.284-36.224
		c-9.993-9.984-26.197-9.984-36.19,0L371.2,68.378c-2.517,2.56-6.4,3.226-9.626,1.647c-7.475-3.755-15.206-6.972-23.142-9.634
		c-3.396-1.161-5.666-4.369-5.632-7.953V25.6c0-14.14-11.46-25.6-25.6-25.6H256c-14.14,0-25.6,11.46-25.6,25.6v26.837
		c0.034,3.575-2.219,6.784-5.598,7.953c-7.927,2.662-15.659,5.879-23.134,9.626c-3.226,1.587-7.117,0.93-9.634-1.638l-19.055-19.029
		c-10.01-9.958-26.18-9.958-36.19,0l-36.215,36.215c-9.993,9.993-9.993,26.206,0,36.198l27.981,28.032
		c3.388,3.277,8.789,3.191,12.066-0.188c3.2-3.302,3.209-8.542,0.017-11.861l-27.998-28.023c-3.328-3.337-3.328-8.73,0-12.066
		l36.215-36.215c3.328-3.328,8.738-3.328,12.066,0l19.063,19.029c7.714,7.757,19.533,9.702,29.321,4.813
		c6.758-3.388,13.747-6.289,20.915-8.695c10.342-3.499,17.289-13.227,17.246-24.149V25.6c0-4.71,3.823-8.533,8.533-8.533h51.2
		c4.71,0,8.533,3.823,8.533,8.533v26.837c-0.043,10.923,6.903,20.642,17.246,24.141c7.168,2.406,14.165,5.316,20.924,8.704
		c9.788,4.864,21.589,2.918,29.312-4.821l19.063-19.021c3.328-3.328,8.738-3.328,12.066,0l36.215,36.224
		c3.328,3.328,3.328,8.73,0,12.058l-19.029,19.063c-7.748,7.714-9.694,19.533-4.813,29.321c3.388,6.758,6.289,13.747,8.695,20.915
		c3.499,10.342,13.227,17.297,24.149,17.246H486.4c4.71,0,8.533,3.823,8.533,8.533V256c0,4.71-3.823,8.533-8.533,8.533h-26.837
		c-10.923-0.043-20.642,6.903-24.141,17.246c-2.406,7.168-5.316,14.165-8.704,20.924c-4.873,9.788-2.927,21.598,4.821,29.312
		l19.029,19.063c3.328,3.328,3.328,8.738,0,12.066l-36.233,36.215c-3.328,3.328-8.73,3.328-12.058,0l-28.041-27.981
		c-3.277-3.388-8.678-3.473-12.066-0.196c-3.388,3.277-3.473,8.678-0.196,12.066c0.068,0.068,0.137,0.137,0.213,0.205l28.032,27.981
		c9.993,9.984,26.197,9.984,36.19,0l36.215-36.207c9.993-10.001,9.993-26.197,0-36.198L443.622,320
		c-2.56-2.517-3.226-6.4-1.647-9.626c3.755-7.475,6.972-15.206,9.634-23.142c1.161-3.396,4.369-5.666,7.953-5.632H486.4
		c14.14,0,25.6-11.46,25.6-25.6v-51.2C512,190.66,500.54,179.2,486.4,179.2z"/>
				<path style={{ fill: "#231F20" }} d="M360.038,151.962c-43.349-43.255-113.527-43.255-156.877,0c-3.277,3.388-3.183,8.789,0.213,12.066
		c3.311,3.191,8.55,3.191,11.853,0c36.412-36.898,95.846-37.299,132.745-0.887s37.299,95.846,0.887,132.745
		c-0.299,0.299-0.589,0.597-0.887,0.887c-3.388,3.277-3.482,8.678-0.205,12.066c3.277,3.388,8.678,3.482,12.066,0.205
		c0.068-0.068,0.137-0.137,0.205-0.205C403.294,265.489,403.294,195.311,360.038,151.962z"/>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
		</SvgIcon>
	);
};
