import arabicMessages from 'ra-language-arabic';

export const translation = {
    en: {
        resources: {
            farms: {
                fields: {
                    supervisors: 'Supervisors'
                }
            },
            blocks: {
                fields: {
                    supervisors: 'Supervisors'
                }
            },
            pivots: {
                fields: {
                    supervisors: 'Supervisors'
                }
            },
            users: {
                fields: {
                    role: {
                        name: 'Role'
                    }
                }
            },
            assets: {
                fields: {
                    assettype_id: 'Asset Type'
                }
            },
            assetlogs: {
                fields: {
                    activitytype_id: 'Activity'
                }
            },
            chemicaltypes: {
                fields: {
                    chemicalunittype_id: 'Unit',
                },
            },
            pests: {
                fields: {
                    pestinfectionlevel_id: 'Pest Infection Level',
                },
            },
            growthstageattributes: {
                fields: {
                    growthstage_id: 'Growth Stage'
                }
            }
        }
    },
    ar: {
        "Username already taken.": 'اسم المستخدم موجود بالفعل',
        "Duplicate QR value entered": 'الرمز موجود بالفعل',
        'You can\'t edit static Activity': 'لا يمكنك تعديل إجراء ثابت',
        'Duplicate Activity': 'الإجراء موجود بالفعل',
        'You can\'t delete static Activity': 'لا يمكنك حذف إجراء ثابت',
        ra: {
            ...arabicMessages.ra,
            action: {
                ...arabicMessages.ra.action,
                export: 'حفظ'
            }
        },
        resources: {
            farms: {
                name: 'المزارع |||| المزرعة',
                fields: {
                    id: 'رقم',
                    farm_code: 'رمز المزرعة',
                    address: 'العنوان',
                    name_ar: 'اسم المزرعة',
                    total_palms: 'مجموع النخيل',
                    actual_palm_count: 'عدد النخيل الحقيقي',
                    supervisors: 'المشرفون',
                    borders: 'الحدود',
                    total_pivots_area: 'إجمالي مساحات قطاعات الري المحوري',
                    number_of_pivots: 'عدد قطاعات الري المحوري',
                    farm_area: 'مساحة المزرعة'
                }
            },
            blocks: {
                name: 'الحوش |||| الحوشة',
                fields: {
                    id: 'رقم',
                    qr_value: 'رمز الحوشة',
                    name_ar: 'اسم الحوشة',
                    rows: 'الصفوف',
                    columns: 'الأعمدة',
                    total_palms: 'مجموع النخيل',
                    actual_palm_count: 'عدد النخيل الحقيقي',
                    supervisors: 'المشرفون',
                    farm_id: 'المزرعة',
                    borders: 'الحدود',
                    splitted_sprouts: "الفسائل المفصولة حتى الآن",
                    current_sprouts: "عدد الفسائل الحالي",
                    splitted_appendages: "الرواكيب المفصولة حتى الآن",
                    current_appendages: "عدد الرواكيب الحالي",
                    projected_production: "الإنتاج المتوقع",
                    actual_production: "الإنتاج الحقيقي",
                    available_spots: "الأماكن المتاحة",
                    pump_flow_rate: "معدل تصريف المضخة",
                    palm_drip_flow_rate: "معدل تصريف النقاط",
                    irrigation_system_efficiency: "كفاءة شبكة الري",
                    irrigation_type: "طريقة الري",
                    drips_per_palm: "عدد النقاط لكل نخلة"
                },
            },
            pivots: {
                name: 'قطاعات الري المحوري |||| قطاع الري المحوري',
                fields: {
                    id: 'رقم',
                    qr_value: 'رمز قطاع الري المحوري',
                    total_sections: 'عدد القطاعات الفرعية',
                    farm_id: 'المزرعة',
                    borders: 'الحدود',
                    area: 'المساحة',
                    water_pump_flow_rate: 'معدل تصريف البئر',
                    accumulated_water_consumption: 'كمية الماء المستهلك',
                    accumulated_water_consumption_feddan: 'كمية الماء المستهلك (فدان)',
                    supervisors: 'المشرفون'
                },
            },
            sections: {
                name: 'القطاعات الفرعية |||| قطاع فرعي',
                fields: {
                    id: 'رقم',
                    qr_value: 'رمز القطاع الفرعي',
                    pivot_id: 'قطاع الري المحوري',
                    farm_id: 'المزرعة',
                    borders: 'الحدود',
                    plantationtype_id: 'نوع الصنف',
                    season_start_date: 'بداية موسم الزراعة',
                    season_end_date: 'انتهاء موسم الزراعة',
                    area: 'المساحة',
                    accumulated_water_consumption: 'كمية الماء المستهلك',
                    accumulated_water_consumption_feddan: 'كمية الماء المستهلك (فدان)',
                    projected_production: "الإنتاج المتوقع",
                    actual_production: "الإنتاج الحقيقي",
                    scouting: 'الاستكشاف',
                    configuration_id: 'الإعداد',
                    growthstage_id: 'المرحلة العمرية',
                    age: 'العمر',
                    irrigation_scaler: 'مقياس الري'
                },
            },
            palms: {
                name: 'النخيل |||| النخلة',
                fields: {
                    id: 'رقم',
                    qr_value: 'رمز النخلة',
                    palm_type: 'نوع النخلة',
                    gender: 'الجنس',
                    age: 'العمر',
                    health_status_ar: 'الحالة',
                    farm_id: 'المزرعة',
                    block_id: 'الحوشة',
                    row: "الصف",
                    column: "العمود",
                    palm_number: "رقم النخلة",
                    splitted_sprouts: "الفسائل المفصولة حتى الآن",
                    current_sprouts: "عدد الفسائل الحالي",
                    splitted_appendages: "الرواكيب المفصولة حتى الآن",
                    current_appendages: "عدد الرواكيب الحالي",
                    average_production_per_branch: "متوسط الإنتاج للعرجون",
                    date_branches: "عدد العراجين",
                    projected_production: "الإنتاج المتوقع",
                    actual_production: "الإنتاج الحقيقي",
                    total_drips: 'إجمالي نقاط الري',
                    broken_drips: 'نقاط الري المكسورة',
                    blocked_drips: 'نقاط الري المسدودة',
                },
            },
            programs: {
                name: 'البرامج |||| البرنامج',
                fields: {
                    id: 'رقم',
                    name_ar: 'اسم البرنامج',
                    agriculture_type: 'نوع الزراعة',
                    plantationtype_id: 'نوع المحصول'
                },
            },
            actions: {
                name: 'المهمات الجارية |||| المهمة الجارية',
                fields: {
                    id: 'رقم',
                    Description_ar: 'الإجراء',
                    Type_ar: 'النوع',
                    RelatedAssetType_ar: 'مستوى الإجراء',
                    RelatedAsset: 'الرمز',
                    Status_ar: 'الحالة',
                    Status: 'الحالة',
                    start_date: 'تاريخ البداية',
                    end_date: 'تاريخ الانتهاء',
                    action_date: 'تاريخ التنفيذ',
                    supervisors: 'المشرفون',
                    required_water_amount: 'كمية المياه المطلوبة',
                    required_irrigation_time: 'زمن الري المطلوب',
                    irrigation_reason: 'سبب الري',
                    irrigation_speed_timer: 'سرعة جهاز الري المحوري'
                },
            },
            traps: {
                name: 'المصائد |||| المصيدة',
                fields: {
                    id: 'رقم',
                    qr_value: 'رمز المصيدة',
                    Location: 'الموقع',
                    lng: 'خط الطول',
                    lat: 'خط العرض',
                    created_by: 'مُنشئ المصيدة',
                    farm_id: 'المزرعة',
                    block_id: 'الحوشة',
                    traptype_id: 'نوع المصيدة',
                },
            },
            users: {
                name: 'المشرفين |||| المشرف',
                fields: {
                    id: 'رقم',
                    username: 'اسم المستخدم',
                    password: 'كلمة المرور',
                    email: 'البريد الالكتروني',
                    role: 'دور المشرف',
                    team_id: 'الفريق',
                    confirmed: 'طلب التأكيد',
                    blocked: 'حظر',
                    first_name: 'الاسم',
                    last_name: 'اسم العائلة'
                },
            },
            workers: {
                name: 'العمال |||| العامل',
                fields: {
                    id: 'رقم',
                    name: 'اسم العامل',
                    qr_value: 'رمز العامل',
                    rating: 'التقييم الكلي',
                    total_work_hours: 'مجموع ساعات العمل',
                    team_id: 'الفريق'
                },
            },
            workerlogs: {
                name: 'العمال |||| العامل',
                fields: {
                    id: 'رقم',
                    targetlevel_id: 'نوع العملية',
                    hours: 'ساعات العمل',
                    rating: 'التقييم',
                    evaluator_id: 'محرر التقييم',
                    created_at: 'توقيت الحدث',
                    notes: 'الملاحظات',
                    worker_id: 'العامل',
                    palms_number: 'عدد النخيل'
                },
            },
            teams: {
                name: 'الفرق |||| الفريق',
                fields: {
                    id: 'رقم',
                    name_ar: 'اسم الفريق',
                    farm_id: 'المزرعة'
                },
            },
            assets: {
                name: 'الأصول الثابتة |||| الأصل الثابت',
                fields: {
                    id: 'رقم',
                    code: 'الكود',
                    assettype_id: 'النوع',
                    qr_value: 'رمز الأصل الثابت',
                    lat: 'خط الطول',
                    lng: 'خط العرض',
                    notes: 'ملاحظات',
                    farm_id: 'المزرعة',
                    serial_number: 'الرقم التسلسلي'
                },
            },
            assetlogs: {
                name: 'سجلات الأصل الثابت |||| سجل الأصل الثابت',
                fields: {
                    id: 'رقم',
                    lat: 'خط الطول',
                    lng: 'خط العرض',
                    notes: 'ملاحظات',
                    activitytype_id: 'الإجراء',
                    created_by: 'محرر السجل',
                    created_at: "توقيت الحدث"
                },
            },
            assettypes: {
                name: 'أنواع الأصل الثابت |||| نوع الأصل الثابت',
                fields: {
                    id: 'رقم',
                    icon: 'الأيقونة',
                    icon_id: 'الأيقونة',
                    type_ar: 'النوع',
                },
            },
            assetactivitytypes: {
                name: 'إجراءات الأصل الثابت |||| إجراء الأصل الثابت',
                fields: {
                    id: 'رقم',
                    icon: 'الأيقونة',
                    icon_id: 'الأيقونة',
                    name_ar: 'الإجراء',
                },
            },
            assetsubactivitytypes: {
                name: 'الإجراءات الفرعية للأصل الثابت |||| الإجراء الفرعي للأصل الثابت',
                fields: {
                    id: 'رقم',
                    name_ar: 'الإجراء الفرعي',
                },
            },
            blocklogs: {
                name: 'سجلات الحوشة |||| سجل الحوشة',
                fields: {
                    id: 'رقم',
                    activity_id: 'الإجراء',
                    type: 'النوع',
                    created_by: "محرر السجل",
                    created_at: "توقيت الحدث",
                    actual_irrigation_time: 'زمن الري الفعلي',
                    actual_irrigated_water: 'إجمالي كمية المياه الفعلية',
                    projected_irrigation_water: 'إجمالي كمية المياه المقترحة',
                    projected_irrigation_time: 'زمن الري المقترح',
                    irrigation_reason: 'سبب الري',
                    pollinated_branches: "الفروع الملقحة"
                },
            },
            pivotlogs: {
                name: 'سجلات قطاعات الري المحوري |||| سجل قطاعات الري المحوري',
                fields: {
                    id: 'رقم',
                    activity_id: 'الإجراء',
                    speed: 'السرعة',
                    water_amount: 'كمية المياه',
                    irrigation_period: 'فترة الري',
                    created_by: "محرر السجل",
                    created_at: "توقيت الحدث",
                    type_id: 'النوع',
                    subcategory_id: 'المادة المستخدمة',
                    amount: 'الكمية'
                },
            },
            sectionlogs: {
                name: 'سجلات القطاعات الفرعية |||| سجل القطاعات الفرعية',
                fields: {
                    id: 'رقم',
                    activity_id: 'الإجراء',
                    speed: 'السرعة',
                    water_amount: 'كمية المياه',
                    irrigation_period: 'فترة الري',
                    created_by: "محرر السجل",
                    created_at: "توقيت الحدث",
                    type_id: 'النوع',
                    subcategory_id: 'المادة المستخدمة',
                    amount: 'الكمية',
                    projected_production: "الإنتاج المتوقع",
                    actual_production: "الإنتاج الحقيقي",
                    notes: 'الملاحظات',
                    pest_id: 'الآفة'
                },
            },
            blockactivitytypes: {
                name: 'إجراءات الحوشة |||| إجراء الحوشة',
                fields: {
                    id: 'رقم',
                    icon: 'الأيقونة',
                    icon_id: 'الأيقونة',
                    activity_ar: 'الإجراء',
                },
            },
            palmlogs: {
                name: 'سجلات النخلة |||| سجل النخلة',
                fields: {
                    id: 'رقم',
                    activity_id: 'الإجراء',
                    created_by: "محرر السجل",
                    created_at: "توقيت الحدث",
                    total_palms_number: "إجمالي عدد النخيل",
                    fertilizationtype_id: "نوع التسميد",
                    fertilization_amount: "كمية التسميد",
                    actual_irrigation_time: 'زمن الري الفعلي',
                    actual_irrigated_water: 'كمية المياه الفعلية للنخلة',
                    projected_irrigation_water: 'كمية المياه المقترحة للنخلة',
                    projected_irrigation_time: 'زمن الري المقترح',
                    irrigation_reason: 'سبب الري',
                    pollinated_branches: "الفروع الملقحة",
                    properly_working_drips: 'النقاطات الصالحة',
                    current_blocked_drips: 'نقاطات الري المسدودة',
                    current_broken_drips: 'نقاطات الري المكسورة',
                    blocked_drips_maintained: 'نقاطات الري المسدودة التي تم صيانتها',
                    broken_drips_maintained: 'نقاطات الري المكسورة التي تم صيانتها'
                },
            },
            palmactivitytypes: {
                name: 'إجراءات النخلة |||| إجراء النخلة',
                fields: {
                    id: 'رقم',
                    icon: 'الأيقونة',
                    icon_id: 'الأيقونة',
                    activity_ar: 'الإجراء',
                },
            },
            palmtypes: {
                name: 'أنواع النخلة |||| نوع النخلة',
                fields: {
                    id: 'رقم',
                    type_ar: 'النوع',
                    code: 'الرمز'
                },
            },
            tasks: {
                name: 'المهام |||| المهمة',
                fields: {
                    id: 'رقم',
                    program_id: 'البرنامج',
                    name_ar: 'اسم المهمة',
                    targetlevel_id: 'مستوى التطبيق',
                    activitytype_id: 'الإجراء',
                    start_time: 'وقت البداية',
                    end_time: 'وقت النهاية',
                    category_id: 'الفئة',
                    subcategory_id: 'الفئة الفرعية',
                    amount: 'الكمية',
                    man_hour: 'الساعات المطلوبة',
                    notes: 'الملاحظات',
                    sub_activity: 'نوع الإجراء',
                    irrigation_periodicity: 'معدل تكرار الري بالأيام',
                    required_water_per_palm_in_liters: 'كمية الماء لكل نخلة - لتر',
                    irrigation_reason: 'سبب الري',
                    section_age: 'عمر النبات'
                },
            },
            targetlevels: {
                name: 'مستويات التطبيق |||| مستوى التطبيق',
                fields: {
                    id: 'رقم',
                    name_ar: 'مستوى التطبيق',
                },
            },
            traplogs: {
                name: 'سجلات المصيدة |||| سجل المصيدة',
                fields: {
                    id: 'رقم',
                    activity_id: 'الإجراء',
                    type: 'النوع',
                    weevil_cnt: 'عدد السوس',
                    qr_value: 'رمز المصيدة',
                    created_at: 'وقت الحدث',
                    created_by: 'منشئ السجل'
                },
            },
            traptypes: {
                name: 'أنواع المصيدة |||| نوع المصيدة',
                fields: {
                    id: 'رقم',
                    type_ar: 'النوع',
                },
            },
            trapactivitytypes: {
                name: 'إجراءات المصيدة |||| إجراء المصيدة',
                fields: {
                    id: 'رقم',
                    icon: 'الأيقونة',
                    icon_id: 'الأيقونة',
                    activity_ar: 'الإجراء',
                },
            },
            devices: {
                name: 'الأجهزة |||| الجهاز',
                fields: {
                    id: 'رقم',
                    qr_value: 'رمز المستشعر',
                    deviceuid: 'رقم المستشعر',
                    farm_id: 'المزرعة',
                    devicetype_id: 'نوع المستشعر'
                },
            },
            devicetypes: {
                name: 'المستشعرات |||| المستشعر',
                fields: {
                    id: 'رقم',
                    type_ar: 'النوع',
                    icon: 'الأيقونة',
                    icon_id: 'الأيقونة',
                    template_id: 'النموذج'
                },
            },
            devicedata: {
                name: 'بيانات المستشعر |||| بيانات المستشعر',
                fields: {
                    id: 'رقم',
                    variable_ar: 'العنصر',
                    value: 'القيمة',
                    unit: 'الوحدة',
                    time: 'توقيت الحدث'
                },
            },
            chemicaltypes: {
                name: 'أنواع المبيدات |||| نوع المبيد',
                fields: {
                    id: 'رقم',
                    icon: 'الأيقونة',
                    icon_id: 'الأيقونة',
                    type_ar: 'النوع',
                    chemicalunittype_id: 'الوحدة',
                    created_at: 'تاريخ الإنشاء'
                },
            },
            units: {
                name: 'الوحدات |||| الوحدة',
                fields: {
                    id: 'رقم',
                    name_ar: 'الوحدة',
                },
            },
            pests: {
                name: 'الآفات |||| الآفة',
                fields: {
                    id: 'رقم',
                    icon: 'الأيقونة',
                    icon_id: 'الأيقونة',
                    name_ar: 'الآفة',
                    code: 'الكود',
                    pestinfectionlevel_id: 'مستوى عدوى الآفة',
                    pestcategory_id: 'نوع الآفة'
                },
            },
            pestinfectionlevels: {
                name: 'مستويات عدوى الآفة |||| مستوى عدوى الآفة',
                fields: {
                    id: 'رقم',
                    name_ar: 'المستوى',
                },
            },
            activeprograms: {
                name: 'البرامج النشطة |||| البرنامج النشط',
                fields: {
                    id: 'رقم',
                    name_ar: 'اسم البرنامج النشط',
                    program_id: 'البرنامج',
                    farm_id: 'المزرعة',
                    user_id: 'المشرف',
                    man_hour: 'عدد الساعات'
                },
            },
            inventories: {
                name: 'المخازن |||| المخزن',
                fields: {
                    id: 'رقم',
                    qr_value: 'رمز المخزن',
                    name_ar: 'اسم المخزن',
                    inventorytype_id: 'نوع المخزن',
                    farm_id: 'المزرعة'
                }
            },
            inventorycategories: {
                name: 'فئات المخزن |||| فئة المخزن',
                fields: {
                    id: 'رقم',
                    name_ar: 'الفئة',
                    inventorytype_id: 'نوع المخزن'
                },
            },
            inventorysubcategories: {
                name: 'الفئات الفرعية للمخزن |||| الفئة الفرعية للمخزن',
                fields: {
                    id: 'رقم',
                    name_ar: 'الفئة الفرعية',
                    unit_id: 'الوحدة',
                    category_id: 'الفئة',
                },
            },
            inventoryevents: {
                name: 'سجلات المخزن |||| سجل المخزن',
                fields: {
                    id: 'رقم',
                    category_id: 'الفئة',
                    subcategory_id: 'الفئة الفرعية',
                    amount: 'الكمية',
                    balance: 'الرصيد',
                    movement: 'الحركة',
                    unit: 'الوحدة',
                    inventory_id: 'اسم المخزن',
                    entity_id: 'رمز الوجهة',
                    entity_type: 'نوع الوجهة',
                    activeprogram_id: 'البرنامج النشط',
                    user_id: 'محرر العملية'
                },
            },
            'inventoryevents/balance': {
                fields: {
                    category_id: 'الفئة',
                    subcategory_id: 'الفئة الفرعية',
                    balance: 'الرصيد',
                    unit: 'الوحدة',
                }
            },
            inventorytypes: {
                name: 'أنواع المخازن |||| نوع المخزن',
                fields: {
                    id: 'رقم',
                    name_ar: 'النوع'
                },
            },
            qrcodes: {
                name: 'إنشاء الأكواد |||| سجل النظام',
            },
            logevents: {
                name: 'سجلات النظام |||| سجل النظام',
                fields: {
                    id: 'رقم',
                    name: 'الحدث',
                    created_at: 'توقيت الحدث',
                },
            },
            devicevariables: {
                name: 'متغيرات المستشعرات |||| متغير المستشعر',
                fields: {
                    id: 'رقم',
                    variable_ar: 'المتغير',
                    unit: 'الوحدة',
                    template_id: 'النموذج'
                },
            },
            pivotactivitytypes: {
                name: 'إجراءات قطاعات الري المحوري |||| إجراء قطاعات الري المحوري',
                fields: {
                    id: 'رقم',
                    activity_ar: 'الإجراء',
                },
            },
            sectionactivitytypes: {
                name: 'إجراءات القطاعات الفرعية |||| إجراء القطاعات الفرعية',
                fields: {
                    id: 'رقم',
                    activity_ar: 'الإجراء',
                    icon_id: 'الايقونة'
                },
            },
            pivotplantationtypes: {
                name: 'أنواع الصنف |||| نوع الصنف',
                fields: {
                    id: 'رقم',
                    name_ar: 'الاسم',
                    crop_id: 'نوع المحصول'
                },
            },
            pivotcroptypes: {
                name: 'أنواع المحاصيل |||| نوع المحصول',
                fields: {
                    id: 'رقم',
                    name_ar: 'الاسم',
                },
            },
            farmtypes: {
                name: 'الزراعات المتاحة |||| الزراعة',
                fields: {
                    id: 'رقم',
                    name_ar: 'نوع المزرعة',
                },
            },
            icons: {
                name: 'الأيقونات |||| أيقونة',
                fields: {
                    id: 'رقم',
                    name_ar: 'الاسم',
                    icon: 'الأيقونة'
                },
            },
            scoutingconfigs: {
                name: 'إعدادات الاستكشاف |||| إعداد الاستكشاف',
                fields: {
                    id: 'رقم',
                    points: 'النقاط',
                    days: 'الأيام'
                },
            },
            scoutingtasks: {
                name: 'مهمات الاستكشاف |||| مهمة الاستكشاف',
                fields: {
                    id: 'رقم',
                    status: 'الحالة',
                    section_id: 'القطاع الفرعي',
                    task_date: 'تاريخ المهمة',
                    action_date: 'تاريخ التنفيذ',
                    supervisors: 'المشرفون'
                },
            },
            growthstages: {
                name: 'المراحل العمرية |||| المرحلة العمرية',
                fields: {
                    id: 'رقم',
                    plant_id: 'نوع المحصول',
                    name_ar: 'اسم المرحلة',
                    start_age: 'بداية العمر',
                    end_age: 'نهاية العمر'
                },
            },
            growthstageattributes: {
                name: 'مدخلات المراحل العمرية |||| مدخلات المرحلة العمرية',
                fields: {
                    id: 'رقم',
                    plant_id: 'نوع المحصول',
                    growthstage_id: 'المرحلة العمرية',
                    attributes_ids: 'المدخلات'
                },
            },
            taskinventories: {
                name: 'أصناف المخزن المستخدمة |||| أصناف المخزن المستخدمة',
                fields: {
                    id: 'رقم',
                    category_id: 'الفئة',
                    subcategory_id: 'الفئة الفرعية',
                    amount: 'الكمية'
                },
            },
            sectionloginventories: {
                name: 'أصناف المخزن المستخدمة |||| أصناف المخزن المستخدمة',
                fields: {
                    id: 'رقم',
                    category_id: 'الفئة',
                    subcategory_id: 'الفئة الفرعية',
                    amount: 'الكمية'
                },
            },
            'weather-alert-configurations': {
                name: 'إعدادات تحذيرات الطقس |||| إعداد تحذيرات الطقس',
                fields: {
                    id: 'رقم',
                    name_ar: 'اسم التحذير',
                    from: 'من',
                    to: 'إلى',
                    attribute: 'نوع التحذير',
                    farm_id: 'المزرعة',
                },
            },
            'palmkcprofiles' :{
                name : 'معامل النبات للنخيل - KC',
                fields :{
                    id: 'رقم',
                    name_ar: 'الاسم',
                }
            },
            'kcprofiles':{
                name : 'معامل النبات - KC',
                fields :{
                    id: 'رقم',
                    name_ar: 'الاسم',
                }
            },
            'palmcanopyareaprofiles':{
                name : 'مساحة المجموع الخضرى',
                fields :{
                    id: 'رقم',
                    name_ar: 'الاسم',
                }
            }
        },
    }
}