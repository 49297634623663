import React from 'react';
import {
    Create, Edit, SimpleForm, TextInput,
    useLocale, Datagrid, ReferenceField, ReferenceArrayField,
    TextField, Filter, DateInput, SelectInput, DateField,
    Tab, TabbedShowLayout, Show, Pagination, SingleFieldList,
    ChipField, ReferenceInput, SimpleShowLayout
} from 'react-admin';
import List from './theme/components/List'
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import { UnitField, MinutesField, IrrigationReasonField } from './helpers/irrigationComponents';
import clsx from 'clsx';
const ActivityField = props => {
    return <ReferenceField {...props} source="RelatedAsset" reference={props.record.RelatedAssetType + 's'} allowEmpty>
        <TextField source="qr_value" />
    </ReferenceField>;
}

const SupervisorsField = (props) => {
    if (props.record.supervisors) {
        const record = { ...props.record };
        record.supervisors = JSON.parse(record.supervisors);
        return <ReferenceArrayField reference="users" source="supervisors" {...props} record={record}>
            <SingleFieldList>
                <ChipField source="username" />
            </SingleFieldList>
        </ReferenceArrayField>
    }
    else
        return null;
}

const ActionDetails = props => {
    return (
        <Show {...props} title=" " className={clsx('tab-rtl-layout')}>
            <SimpleShowLayout>
                {props.record.required_water_amount &&
                    <Tab label="Irrigation">
                        <UnitField source="required_water_amount" unit="m³" unit_ar="م۳" addLabel />
                        <MinutesField source="required_irrigation_time" addLabel />
                        <IrrigationReasonField source="irrigation_reason" addLabel />
                    </Tab>
                }
                {props.record.irrigation_speed_timer &&
                    <Tab label="Irrigation">
                        <UnitField source="irrigation_speed_timer" unit="%" unit_ar="%" addLabel />
                        <MinutesField source="required_irrigation_time" addLabel />
                    </Tab>
                }
            </SimpleShowLayout>
        </Show>
    )
}

export const ActionList = (props) => {
    const locale = useLocale();
    return (
        <List title={locale === 'en' ? "Running Tasks" : "المهمات الجارية"} sort={{ field: 'id', order: 'DESC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} filters={<ActionFilter />} {...props}>
            <Datagrid expand={<ActionDetails />}>
                
                <TextField source={locale === 'en' ? "Description" : "Description_ar"} />
                <TextField source={locale === 'en' ? "RelatedAssetType" : "RelatedAssetType_ar"} />
                <TextField source={locale === 'en' ? "Status" : "Status_ar"} />
                <ActivityField source={locale === 'en' ? "qr_value" : "الرمز"} />
                <DateField source="start_date" locales="en-GB" />
                <DateField source="end_date" locales="en-GB" />
                <DateField source="action_date" locales="en-GB" />
                <SupervisorsField source="supervisors" />
            </Datagrid>
        </List>
    )
};

export const ActionEdit = props => (
    <Edit undoable={false} {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar />} className={clsx('tab-rtl-layout')}>
            <TextInput disabled source="id" />
            <TextInput source="action" />
            <SelectInput source="status" resettable choices={[
                { id: 'Pending', name: 'Pending' },
                { id: 'Done', name: 'Done' },
            ]} />
            <DateInput source="created_Date" />
            <DateInput source="due_Date" />
        </SimpleForm>
    </Edit>
);

export const ActionCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="action" />
            <SelectInput source="status" resettable choices={[
                { id: 'Pending', name: 'Pending' },
                { id: 'Done', name: 'Done' },
            ]} />
            <DateInput source="created_Date" />
            <DateInput source="due_Date" />
        </SimpleForm>
    </Create>
);


export const ActionShow = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="summary">
                  
                <TextField source="action" />
                <TextField source="status" />
                <DateField source="created_Date" locales="en-GB" />
                <DateField source="due_Date" locales="en-GB" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

const ActionFilter = (props) => {
    const locale = useLocale();
    return (
        <Filter {...props}>
            <SelectInput source="Status" choices={[
                { id: 'Pending', name: locale === 'en' ? 'Pending' : 'مطلوب' },
                { id: 'Done', name: locale === 'en' ? 'Done' : 'انتهى' },
                { id: 'Planned', name: locale === 'en' ? 'Planned' : 'مخطط' }
            ]} />
            <ReferenceInput perPage={-1} reference="farms" source="farm_code"
                format={(v) => (!v ? null : v)}
                label={locale === 'en' ? "Farm" : "المزرعة"} addLabel>
                <SelectInput optionText="farm_code" optionValue="farm_code" />
            </ReferenceInput>
            <ReferenceInput perPage={-1} reference="users" source="supervisors"
                format={(v) => (!v ? null : v)}
                label={locale === 'en' ? "Supervisor" : "المشرف"} addLabel>
                <SelectInput optionText="username" />
            </ReferenceInput>
        </Filter>
    )
};
