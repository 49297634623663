import React, { cloneElement, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    TopToolbar, CreateButton, ExportButton, Button, sanitizeListRestProps,
    useLocale
} from 'react-admin';
import GetApp from '@material-ui/icons/GetApp';
import * as jsPDF from 'jspdf'
import httpClient from '../httpClient';


const generateFile = async (codes) => {

    var QRCode = require('qrcode');

    let QrCodes = [];
    for (let code of codes) {
        QRCode.toDataURL(code.qr_value, {
            errorCorrectionLevel: 'H',
            width: 200,
            margin: 2
        }, function (err, url) {
            QrCodes.push({
                url: url,
                code: code
            });
        });
    }

    const imagesPromise = QrCodes.map(code => new Promise((resolve, reject) => {
        const img = new Image();
        img.onerror = () => reject(new Error('Couldn\'t load image'));
        img.onload = () => resolve({ img: img, code: code.code });
        img.src = code.url;
    }));


    const canvas = window.document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = 200;
    canvas.height = 250;
    ctx.globalAlpha = 1;

    const images = await Promise.all(imagesPromise);

    QrCodes = images.map(({ img, code }) => {
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        ctx.drawImage(img, 0, 0);

        ctx.font = '20px Arial';
        ctx.fillStyle = 'black';
        const qrText = code.name;
        const textWidth = ctx.measureText(qrText).width;
        const textLeftOffset = Math.max(canvas.width * 0.05, (canvas.width / 2) - (textWidth / 2));
        ctx.fillText(qrText, textLeftOffset, 225, canvas.width * 0.9);
        const canvasURL = canvas.toDataURL('image/png', 0.92);
        return canvasURL;
    });

    const doc = new jsPDF({
        unit: 'in'
    });

    const pageWidth = 8.27;
    const pageHeight = 11.69;
    const rows = 6;
    const cols = 5;
    const imageWidth = (pageWidth / cols) * 0.8;
    const imageHeight = imageWidth * (canvas.height / canvas.width);

    function generatePDF() {
        let code = 0;

        while (code < QrCodes.length) {
            for (let i = 0; i < rows; i++) {
                for (let j = 0; j < cols; j++) {
                    const leftOffset = (pageWidth / cols) * j + (pageWidth / cols) * 0.1;
                    const upOffset = (pageHeight / rows) * i + (pageWidth / cols) * 0.1;
                    doc.addImage(QrCodes[code++], 'PNG', leftOffset, upOffset, imageWidth, imageHeight);
                    if (code === QrCodes.length)
                        return;
                }
            }
            doc.addPage();
        }
    }

    generatePDF();

    doc.save(`codes.pdf`);
}

const generateCurrentCodes = async () => {
    const list = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/workers?_limit=-1&_sort=id:ASC`);
    const codes = list.json.map(entry => ({ qr_value: entry.qr_value, name: entry.name }));
    generateFile(codes);
}


const WorkersActions = ({
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter, // you can hide ExportButton if exporter = (null || false)
    filterValues,
    permanentFilter,
    hasCreate, // you can hide CreateButton if hasCreate = false
    basePath,
    selectedIds,
    onUnselectItems,
    showFilter,
    maxResults,
    total,
    ...rest
}) => {
    const locale = useLocale();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filter={{ ...filterValues, ...permanentFilter }}
                exporter={exporter}
                maxResults={maxResults}
            />
            {/* Add your custom actions */}
            <Button
                onClick={generateCurrentCodes}
                label={locale === 'en' ? 'Export QR' : 'طباعة الرموز'}
            >
                <GetApp />
            </Button>
        </TopToolbar>
    )
};

WorkersActions.defaultProps = {
    selectedIds: [],
    onUnselectItems: () => null,
};

export default WorkersActions;