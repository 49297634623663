import React from 'react';
import { ReferenceInput, SelectInput, ReferenceField, useLocale } from 'react-admin';

const CustomImage = props => {
    if (props.record.file)
        return <img src={`${process.env.REACT_APP_DATA_PROVIDER_URL}${props.record.file.url}`} style={{ maxHeight: '1.4rem' }} />
    else
        return null;
}

export const MyImageField = props => {
    return <ReferenceField source="icon_id" reference="icons" allowEmpty={true} {...props}>
        <CustomImage source="icon" />
    </ReferenceField>
}

export const MyImageInput = props => {
    const locale = useLocale();

    return <ReferenceInput source="icon_id" reference="icons" perPage={-1} {...props}>
        <SelectInput optionText={(record) => {
            let displayText = record.file ? <div>
                <div> {locale === 'en' ? <span>{record.name}</span> : <span>{record.name_ar}</span>} </div>
                <div> <img src={`${process.env.REACT_APP_DATA_PROVIDER_URL}${record.file.url}`} style={{ maxHeight: '1.4rem' }} /> </div>
            </div> : null;
            return displayText;
        }} />
    </ReferenceInput>
}