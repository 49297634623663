import React, { useState, useEffect } from 'react';
import {
    Datagrid, TextField, useLocale, ShowButton, SimpleShowLayout,
    Show, Tab, TabbedShowLayout, TextInput, ReferenceInput, BooleanInput,
    SelectInput, SimpleForm, Edit, Create, ReferenceManyField, NumberInput,
    Pagination, ReferenceField, DateField, DateInput, CheckboxGroupInput, Filter
} from 'react-admin';
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import { UnitField, MinutesField } from './helpers/irrigationComponents';

import List from './theme/components/List'
import DrawBorders from './drawBorders';
import ShowBorders from './showBorders';
import { parse } from 'query-string';
import httpClient from './httpClient';
import { PestLocation } from './pestMap';
import { FormDataConsumer } from 'ra-core';
import clsx from 'clsx';
import * as Mui from '@material-ui/core';
import { useInput, required } from 'react-admin';
import InputAdornment from '@material-ui/core/InputAdornment';
const FixedFloatField = props => props.record[props.source] !== null ?
    <span>{Number.parseFloat(props.record[props.source]).toFixed(2)}</span> :
    null;
const BoundedTextField = props => {
    let {
        input: { name, onChange, ...rest },
        meta: { touched, error },
        isRequired
    } = useInput(props);

    return (
        <Mui.TextField
        name={name}
        source="irrigation_scaler"
        className={props.className}
        label={props.label}
        variant="filled"
        InputProps={{
            inputProps: { 
                max: 200, min: 50 
            },
            endAdornment: <InputAdornment style={{margin:"8px"}} position="start">%</InputAdornment>,
          }}
         onChange={onChange}
        required={isRequired}
        validate={props.validate}
        error={error}
        helperText={touched && error}
        {...rest}
    />
    );
};
const AgeField = props => {
    const today = new Date();
    const start_day = new Date(props.record.season_start_date);
    props.record.age = (today - start_day) > 0 && props.record.season_start_date ? Math.floor((today - start_day) / (1000 * 60 * 60 * 24)) : 0;

    return <TextField {...props} />
}

export const SectionList = props => {
    const locale = useLocale();
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} filters={<SectionFilter />} {...props}>
            <Datagrid>
                  
                <TextField source="qr_value" />
                <ReferenceField source="plantationtype_id" reference="pivotplantationtypes" allowEmpty={true}>
                    <TextField source={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceField>
                <AgeField source="age" />
                <ReferenceField source="growthstage_id" reference="growthstages" allowEmpty={true} link={false}>
                    <TextField source={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceField>
                <DateField source="season_start_date" />
                <DateField source="season_end_date" />
                <FixedFloatField source="area" />
                <FixedFloatField source="accumulated_water_consumption" />
                <FixedFloatField source="accumulated_water_consumption_feddan" />
                <FixedFloatField source="projected_production" />
                <FixedFloatField source="actual_production" />
                <ReferenceField source="pivot_id" reference="pivots" allowEmpty={true}>
                    <TextField source="qr_value" />
                </ReferenceField>
                <ReferenceField source="farm_id" reference="farms" allowEmpty={true}>
                    <TextField source="farm_code" />
                </ReferenceField>
                <ShowButton />
            </Datagrid>
        </List>
    )
}

const CustomForm = (props) => {
    let record = { ...props.record };
    const locale = useLocale();
    const [data, setData] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/sections?pivot_id=${record.pivot_id}`);
            setData(response.json);
        };
        if (record.pivot_id)
            fetchData();
    }, []);

    let choices = null;
    if (data) {
        choices = data.map(item => ({ id: item.id, name: item.qr_value }));
        choices.push({ id: 0, name: 'All' });
    }

    if (props.borders)
        record.borders = props.borders;
        const {source, ...rest} = props;
    return (
        <SimpleForm {...props} record={record} toolbar={<CustomToolbar />} className={clsx('tab-rtl-layout')}>
            <TextInput disabled source="id" />
            <TextInput disabled source="qr_value" />
            <ReferenceInput perPage={-1} reference="pivotplantationtypes" source="plantationtype_id">
                <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
            </ReferenceInput>
            <DateInput source="season_start_date" />
            <DateInput source="season_end_date" />
            <BoundedTextField label="Irrigation Scaler [50 -150] %" source="irrigation_scaler" 
            validate={() =>false}
            format={(v) =>{ 
                return  v===null?100:Math.floor(v * 100);
            }} 
            parse={ v =>{ return v===null || v === ""?0: (parseFloat(v) / 100)}} {...rest} />
            {choices && <CheckboxGroupInput source="Apply_to" choices={choices} />}
            <ReferenceInput perPage={-1} reference="pivots" source="pivot_id">
                <SelectInput optionText="qr_value" />
            </ReferenceInput>
            <ReferenceInput perPage={-1} reference="farms" source="farm_id">
                <SelectInput optionText="farm_code" />
            </ReferenceInput>
            <TextInput disabled source="borders" />
            <BooleanInput source="scouting" />
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    return formData.scouting && <ReferenceInput perPage={-1} source="configuration_id" reference="scoutingconfigs" {...rest} >
                        <SelectInput optionText={(record) => {
                            if (locale === 'en') {
                                return `${record.days} days - ${record.points} points`;
                            }
                            else {
                                return <span dir='rtl'>{record.days} أيام - {record.points} نقاط</span>;

                            }
                        }} />
                    </ReferenceInput>
                }}
            </FormDataConsumer>
            <ReferenceInput perPage={-1} reference="kcprofiles" source="kcprofile_id">
                <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
            </ReferenceInput>
        </SimpleForm>
    )
};

export const SectionEdit = props => {
    const { borders: bordersArray } = parse(props.location.search);
    return (
        <Edit undoable={false} {...props}>
            <CustomForm borders={bordersArray} />
        </Edit>
    )
};

export const SectionCreate = props => {
    const { pivot_id_param: pivot_id_string } = parse(props.location.search);
    const pivot_id = pivot_id_string ? parseInt(pivot_id_string, 10) : null;
    const locale = useLocale();
    return (
        <Create {...props}>
            <SimpleForm redirect="list" className={clsx('tab-rtl-layout')}>
                <TextInput source="qr_value" />
                <ReferenceInput perPage={-1} reference="pivotplantationtypes" source="plantationtype_id">
                    <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceInput>
                <DateInput source="season_start_date" />
                <DateInput source="season_end_date" />
                <NumberInput step={0.01} source="irrigation_scaler" />
                <ReferenceInput perPage={-1} reference="pivots" source="pivot_id" defaultValue={pivot_id}>
                    <SelectInput optionText="qr_value" />
                </ReferenceInput>
                <ReferenceInput perPage={-1} reference="farms" source="farm_id">
                    <SelectInput optionText="farm_code" />
                </ReferenceInput>
                <TextInput disabled source="borders" />
            </SimpleForm>
        </Create>
    )
};

const PestCategory = props => {
    const locale = useLocale(null);
    const [pest, setPest] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/pests/${props.record.pest_id}`);
            setPest(response.json);
        };
        if (props.record.pest_id)
            fetchData();
    }, []);

    const { record, ...rest } = props;
    record.pest_category = pest ? pest.pestcategory_id : null;
    return <ReferenceField reference="pestcategories" allowEmpty={true} record={record} {...rest}>
        <TextField source={locale === 'en' ? "name" : "name_ar"} />
    </ReferenceField>

}

const CustomImage = props => {
    if (props.record.file)
        return <img src={`${process.env.REACT_APP_DATA_PROVIDER_URL}${props.record.file.url}`} />
    else
        return null;
}

const MachineryLevel = props => {
    const locale = useLocale();
    return <span>{locale === 'en' ? (props.record.machinery_area ? 'No' : 'Yes') : (props.record.machinery_area ? 'لا' : 'نعم')}</span>
}

const TotalAmountField = props => {
    if (props.record.amount && props.section_area) {
        return <span>{(props.record.amount * props.section_area).toFixed(2)}</span>
    }
    else
        return null;
}

const SectionActivity = ({ section_area, ...props }) => {
    const locale = useLocale();
    const [data, setData] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/sectionactivitytypes/${props.record.activity_id}`);
            setData(response.json);
        };
        fetchData();
    }, []);
    return (
        <Show {...props} title=" ">
            <SimpleShowLayout className={clsx('tab-rtl-layout')}>
                {props.record.speed &&
                    <Tab label="irrigation">
                        <UnitField source="speed" unit="%" unit_ar="%"
                            label={locale === 'en' ? "Actual Speed Timer" : "سرعة جهاز الري المحوري الحقيقية"} addLabel />
                        {props.record.projected_speed_timer && <UnitField source="projected_speed_timer" unit="%" unit_ar="%"
                            label={locale === 'en' ? "Projected Speed Timer" : "سرعة جهاز الري المحوري المتوقعة"} addLabel />}
                        <MinutesField source="irrigation_period" label={locale === 'en' ? "Actual Irrigation Period" : "زمن الري الحقيقي"} addLabel />
                        {props.record.projected_irrigation_period && <MinutesField source="projected_irrigation_period"
                            label={locale === 'en' ? "Projected Irrigation Period" : "زمن الري المتوقع"} addLabel />
                        }
                        <UnitField source="water_amount" unit="m³" unit_ar="م۳"
                            label={locale === 'en' ? "Actual Water Amount" : "كمية المياه الحقيقية"} addLabel />
                    </Tab>
                }
                {(data && data.activity === 'Report Infection') ?
                    <Tab label='Report Infection'>
                        <PestCategory source="pest_category" label={locale === 'en' ? 'Disease Type' : 'نوع المرض'} addLabel />
                        <ReferenceField source="pest_id" reference="pests" label={locale === 'en' ? 'Disease' : 'المرض'} addLabel allowEmpty={true}>
                            <TextField source={locale === 'en' ? "name" : "name_ar"} />
                        </ReferenceField>
                        <TextField source="notes" />
                        <CustomImage source="image" label={locale === 'en' ? 'Image' : 'الصورة'} addLabel />
                        <PestLocation source={locale === 'en' ? "Location" : "الموقع"} addLabel lat={props.record.lat} lng={props.record.lng} type='sections' id={props.record.section_id} />
                    </Tab>
                    : null
                }
                {(props.record.actual_production || props.record.projected_production) ?
                    <Tab label="production">
                        {props.record.actual_production && <TextField source="actual_production" />}
                        {props.record.projected_production && <TextField source="projected_production" />}
                    </Tab>
                    : null
                }
                {(data && (data.activity === 'Fertilization' || data.activity === 'Spray')) &&
                    <Tab label="Fertilization">
                        <ReferenceField source="type_id"
                            reference={data.activity === 'Fertilization' ? "pivotfertilizationtypes" : "pivotspraytypes"}
                            allowEmpty={true}>
                            <TextField source={locale === 'en' ? "name" : "name_ar"} />
                        </ReferenceField>

                    </Tab>
                }
                {(data && data.activity === 'Machinery') &&
                    <Tab label="Machinery">
                        <ReferenceField source="type_id" reference="pivotmachinerytypes" allowEmpty={true}>
                            <TextField source={locale === 'en' ? "name" : "name_ar"} />
                        </ReferenceField>
                        <MachineryLevel source={locale === 'en' ? "Whole Sector" : "القطاع بالكامل"} addLabel />
                        {props.record.machinery_area && <UnitField source="machinery_area" unit="Feddan" unit_ar="فدان"
                            label={locale === 'en' ? "Machinery Area" : "مساحة الميكنة"} addLabel />}
                    </Tab>
                }
                <ReferenceManyField perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
                    reference="sectionloginventories" target="log_id" label={locale === 'en' ? "Inventory Items" : "أصناف المخزن المستخدمة"}>
                    <Datagrid>
                          
                        <ReferenceField source="category_id" reference="inventorycategories" allowEmpty={true}>
                            <TextField source={locale === 'en' ? "name" : "name_ar"} />
                        </ReferenceField>
                        <ReferenceField source="subcategory_id" reference="inventorysubcategories" allowEmpty={true}>
                            <TextField source={locale === 'en' ? "name" : "name_ar"} />
                        </ReferenceField>
                        <TextField source="amount" label={locale === 'en' ? "Amount Per Feddan" : "الكمية لكل فدان"} />
                        <TotalAmountField section_area={section_area} label={locale === 'en' ? "Total Amount" : "الكمية الكلية"} addLabel />
                        <ReferenceField source="subcategory_id" reference="inventorysubcategories" allowEmpty={true} link={false}
                            label={locale === 'en' ? "Unit" : "الوحدة"}>
                            <ReferenceField source="unit_id" reference="units" allowEmpty={true} link={false}>
                                <TextField source={locale === 'en' ? "name" : "name_ar"} />
                            </ReferenceField>
                        </ReferenceField>
                    </Datagrid>
                </ReferenceManyField>
                <ReferenceManyField perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
                    reference="workerlogs" target="activity_id" label={locale === 'en' ? "Workers" : "العمال"}>
                    <Datagrid>
                          
                        <ReferenceField source="worker_id" reference="workers" allowEmpty={true}>
                            <TextField source="name" />
                        </ReferenceField>
                        <TextField source="hours" />
                        <TextField source="rating" />
                        <TextField source="notes" />
                    </Datagrid>
                </ReferenceManyField>
            </SimpleShowLayout>
        </Show>
    )
};

const SectionName = ({ record }) => {
    const locale = useLocale();
    return <span>{record ? (locale === 'en' ? record.qr_value : record.qr_value) : ''}</span>;
}

export const SectionShow = (props) => {
    const locale = useLocale();
    const [section, setSection] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/sections/${props.id}`);
            setSection(response.json);
        };

        if (props.id)
            fetchData();
    }, [props.id]);

    return <Show {...props} title={<SectionName />}>
        <TabbedShowLayout>
            <Tab label={locale === 'en' ? "summary" : "الملخص"} contentClassName={clsx('tab-rtl-layout')}>
                  
                <TextField source="qr_value" />
                <ReferenceField source="plantationtype_id" reference="pivotplantationtypes" allowEmpty={true}>
                    <TextField source={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceField>
                <DateField source="season_start_date" />
                <DateField source="season_end_date" />
                <TextField source="irrigation_scaler" />
                <FixedFloatField source="area" addLabel={true} />
                <FixedFloatField source="accumulated_water_consumption" addLabel />
                <FixedFloatField source="accumulated_water_consumption_feddan" addLabel />
                <FixedFloatField source="projected_production" addLabel />
                <FixedFloatField source="actual_production" addLabel />
                <ReferenceField source="pivot_id" reference="pivots" allowEmpty={true}>
                    <TextField source="qr_value" />
                </ReferenceField>
                <ReferenceField source="farm_id" reference="farms" allowEmpty={true}>
                    <TextField source="farm_code" />
                </ReferenceField>
            </Tab>
            <Tab label={locale === 'en' ? "Activities" : "السجلات"}>
                <ReferenceManyField perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} reference="sectionlogs" target="section_id" addLabel={false}>
                    <Datagrid expand={<SectionActivity section_area={section.area} />}>
                          
                        <ReferenceField source="activity_id" reference="sectionactivitytypes" allowEmpty={true} >
                            <TextField source={locale === 'en' ? "activity" : "activity_ar"} />
                        </ReferenceField>
                        <ReferenceField source="created_by" reference="users" allowEmpty={true} >
                            <TextField source="username" />
                        </ReferenceField>
                        <DateField source="created_at" locales="en-GB" showTime />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab label={locale === 'en' ? "Map" : "الخريطة"}>
                <ShowBorders source="borders" />
            </Tab>
            <Tab label={locale === 'en' ? "Map Tool" : "أداة تعيين الخريطة"}>
                <DrawBorders table="sections" source="borders" />
            </Tab>

        </TabbedShowLayout>
    </Show>
}
const SectionFilter = (props) => {
    return <Filter {...props}>
        <ReferenceInput perPage={-1} reference="farms" source="farm_id" >
            <SelectInput optionText="farm_code" />
        </ReferenceInput>
        <ReferenceInput perPage={-1} reference="pivots" source="pivot_id" >
            <SelectInput optionText="qr_value" />
        </ReferenceInput>
    </Filter>
};
