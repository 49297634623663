import React, { useState, useEffect, Fragment } from 'react';
import {
    Create, Edit, SimpleForm, TextInput, SingleFieldList,
    EditButton, Datagrid, TextField, Filter, ReferenceArrayField,
    NumberInput, Show, ReferenceField, ShowButton, ChipField, DatagridBody,
    Tab, TabbedShowLayout, ReferenceManyField, ReferenceArrayInput,
    SelectArrayInput, Pagination, BooleanInput, useLocale
} from 'react-admin';
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import MyCreateButton from './helpers/MyCreateButton';

import DrawBorders from './drawBorders';
import ShowBorders from './showBorders';
import { parse } from 'query-string';
import { ScoutingVis, ScoutingEnable } from './scouting';
import httpClient from './httpClient';
import checkPermission from './helpers/checkpermission'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { Tab as MuiTab } from '@material-ui/core';
import WeatherChart from './weatherChart';
import VegetationChart from './vegetationChart';
import LaunchIcon from '@material-ui/icons/Launch';
import List from './theme/components/List'
import clsx from 'clsx';
const MyDatagridRow = ({ record, resource, id, onToggleItem, children, selected, basePath }) => (
    <TableRow key={id} hover={true} >
        {/* first column: selection checkbox */}
        <TableCell padding="checkbox">
            <Checkbox
                checked={selected}
                onClick={() => onToggleItem(id)}
            />
        </TableCell>
        {/* data columns based on children */}
        {React.Children.map(children, field => {
            if (record && typeof record.supervisors === 'string') {
                record.supervisors = JSON.parse(record.supervisors);
            }
            return (
                <TableCell key={`${id}-${field.props.source}`}>
                    {React.cloneElement(field, {
                        record,
                        basePath,
                        resource,
                    })}
                </TableCell>
            );
        })}
    </TableRow>
);

const MyDatagridBody = props => <DatagridBody {...props} row={<MyDatagridRow />} />;
const MyDatagrid = props => <Datagrid {...props} body={<MyDatagridBody />} />;

const FarmBoolType = myProps => {
    const { data, ...props } = myProps;

    return data ?
        <Fragment>
            {data.is_palm_tree && <BooleanInput {...props} source="is_palm_tree" initialValue={false} />}
            {data.is_pivot && <BooleanInput {...props} source="is_pivot" initialValue={false} />}
            {data.is_orchard && <BooleanInput {...props} source="is_orchard" initialValue={false} />}
            {data.is_greenhouse && <BooleanInput {...props} source="is_greenhouse" initialValue={false} />}
        </Fragment> :
        null;
}

const CustomForm = (props) => {
    const [data, setData] = useState({});
    const [eosmap, setEosmap] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/organizations/check`);
            setData(response.json);
            // const eosmap = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/farms/${props.record.id}`);
            // setEosmap(eosmap.eosmap)
        };
        fetchData();
    }, []);

    const locale = useLocale();

    let record = { ...props.record };
    console.log({Original:record.supervisors})
    if (props.borders)
        record.borders = props.borders;
            
      

    return (
        <SimpleForm {...props} record={record} toolbar={<CustomToolbar />} className={clsx('tab-rtl-layout')}  >
            {/* <TextInput disabled source="id" /> */}
            <TextInput disabled source="farm_code" />
            <TextInput source="name" />
            <TextInput source="name_ar" />
            <TextInput source="address" />
            {record.is_palm_tree && <NumberInput source="total_palms" />}
            {record.is_palm_tree && <NumberInput source="actual_palm_count" />}
            <ReferenceArrayInput source="supervisors" reference="users"
                parse={v => {
                    return JSON.stringify(v);
                }}
                format={v => {
                
                    {console.log("inEdit" , v)}
                    let parsedArray = v;
                    console.log({v})
                    try {
                        parsedArray = JSON.parse(v);
                    }
                    catch (e) {
                        if (typeof v === 'string')
                            parsedArray = [];
                    }
                    return parsedArray;
                }} >
                <SelectArrayInput optionText="username" />
            </ReferenceArrayInput>
            <TextInput disabled source="borders" />
            <FarmBoolType data={data} />
            {<BooleanInput {...props} source="eosmap" label={locale === 'en' ? "Recent Satellite Images" : "صور قمر صناعى حديثة"} initialValue={eosmap} />}
        </SimpleForm>
    )
};

export const FarmList = (props) => {
    const locale = useLocale();
    return (
        <List sort={{ field: 'id', order: 'ASC' }}
            bulkActionButtons={<PostBulkActionButtons />}
            perPage={100}
            pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
            filters={<FarmFilter />}
            {...props}
        >
            <MyDatagrid>
                  
                <TextField source="farm_code" />
                <TextField source={locale === 'en' ? "name" : "name_ar"} />
                <TextField source="address" />
                <ReferenceArrayField reference="users" source="supervisors">
                    <SingleFieldList>
                        <ChipField source="username" />
                    </SingleFieldList>
                </ReferenceArrayField>
                {/* <EditButton {...props}/> */}
                <ShowButton />
            </MyDatagrid>
        </List>
    )
};

export const FarmEdit = props => {
    const { borders: bordersArray } = parse(props.location.search);
    return (
        <Edit undoable={false} {...props} style={{marginTop:'0px'}}>
            <CustomForm borders={bordersArray} />
        </Edit>
    )
};

export const FarmCreate = props => {
    const [data, setData] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/organizations/check`);
            setData(response.json);
        };
        fetchData();
    }, []);

    return (
        <Create {...props}>
            <SimpleForm redirect="list" className={clsx('tab-rtl-layout')}>
                <TextInput source="farm_code" />
                <TextInput source="name" />
                <TextInput source="name_ar" />
                <TextInput source="address" />
                {data.is_palm_tree && <NumberInput source="total_palms" />}
                {data.is_palm_tree && <NumberInput source="actual_palm_count" />}
                <ReferenceArrayInput source="supervisors" reference="users"
                    parse={v => {
                        return JSON.stringify(v);
                    }}
                    format={v => {
                        return typeof v === 'string' ? JSON.parse(v) : v;
                    }} >
                    <SelectArrayInput optionText="username" />
                </ReferenceArrayInput>
                <TextInput disabled source="borders" />
                <FarmBoolType data={data} />
            </SimpleForm>
        </Create>
    )
};

const SupervisorsField = (props) => {
    if (props.record.supervisors) {
        const record = { ...props.record };
        record.supervisors = JSON.parse(record.supervisors);
        return <ReferenceArrayField reference="users" source="supervisors" {...props} record={record}>
            <SingleFieldList>
                <ChipField source="username" />
            </SingleFieldList>
        </ReferenceArrayField>
    }
    else
        return null;
}

const FixedFloatField = props => props.record[props.source] !== null ?
    <span>{Number.parseFloat(props.record[props.source]).toFixed(2)}</span> :
    null;
const LaunchIconLink = ({ record = {} }) =>
    <a href={`#/pivots/${record.id}/show/2`} target="_blank">
        <LaunchIcon style={{ color: 'teal' }} />
    </a>;

const MyFarmShow = props => {
    const locale = useLocale();
    return <TabbedShowLayout {...props} >
        <Tab label={locale === 'en' ? "summary" : "الملخص"} contentClassName={clsx('tab-rtl-layout')} >
              
            <TextField source="farm_code" />
            <TextField source={locale === 'en' ? "name" : "name_ar"} />
            <TextField source="address" />
            {props.record.is_palm_tree && <TextField source="total_palms" />}
            {props.record.is_palm_tree && <TextField source="actual_palm_count" />}
            {props.record.is_pivot && <FixedFloatField source="farm_area" addLabel />}
            {props.record.is_pivot && <FixedFloatField source="total_pivots_area" addLabel />}
            {props.record.is_pivot && <TextField source="number_of_pivots" />}
        </Tab>
        {props.record.is_palm_tree &&
            <Tab label={locale === 'en' ? "Blocks" : "الحوش"}>
                <ReferenceManyField perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} reference="blocks" target="farm_id" addLabel={false}>
                    <Datagrid>
                          
                        <TextField source="qr_value" />
                        <TextField source="rows" />
                        <TextField source="columns" />
                        <TextField source="total_palms" />
                        <TextField source="actual_palm_count" />
                        <TextField source="available_spots" />
                        <TextField source="current_sprouts" />
                        <TextField source="splitted_sprouts" />
                        <TextField source="current_appendages" />
                        <TextField source="splitted_appendages" />
                        <SupervisorsField source="supervisors" />
                        <ShowButton />
                    </Datagrid>
                </ReferenceManyField>
                <MyCreateButton entity="Block" table="blocks" param="farm_id_param" />
            </Tab>
        }
        {props.record.is_palm_tree &&
            <Tab label={locale === 'en' ? "Blocks Map" : "خريطة الحوش"}>
                <ShowBorders source="borders" type="Blocks" />
            </Tab>
        }
        {props.record.is_pivot &&
            <Tab label={locale === 'en' ? "Pivots" : "قطاعات الري المحوري"}>
                <ReferenceManyField perPage={100} sort={{ field: 'id', order: 'ASC' }} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} reference="pivots" target="farm_id" addLabel={false}>
                    <Datagrid>
                          
                        <TextField source="qr_value" />
                        <LaunchIconLink source={locale === 'en' ? "Production" : "الإنتاج"} />
                        <TextField source="total_sections" />
                        <FixedFloatField source="area" />
                        <TextField source="water_pump_flow_rate" />
                        <FixedFloatField source="accumulated_water_consumption" />
                        <FixedFloatField source="accumulated_water_consumption_feddan" />
                        <ShowButton />
                    </Datagrid>
                </ReferenceManyField>
                <MyCreateButton entity="Pivot" table="pivots" param="farm_id_param" />
            </Tab>
        }
        {props.record.is_pivot &&
            <Tab label={locale === 'en' ? "Pivots Map" : "خريطة قطاعات الري المحوري"}>
                <ShowBorders source="borders" type="Pivots" />
            </Tab>
        }
        {/* <Tab label={locale === 'en' ? "Traps" : "المصائد"}>
            <ReferenceManyField perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} reference="traps" target="farm_id" addLabel={false}>
                <Datagrid>
                      
                    <TextField source="qr_value" />
                    <ReferenceField source="traptype_id" reference="traptypes" allowEmpty={true} >
                        <TextField source={locale === 'en' ? "type" : "type_ar"} />
                    </ReferenceField>
                    <ReferenceField source="created_by" reference="users" allowEmpty={true} >
                        <TextField source="username" />
                    </ReferenceField>
                    <ShowButton />
                </Datagrid>
            </ReferenceManyField>
        </Tab> */}

        <Tab label={locale === 'en' ? "Map Tool" : "أداة تعيين الخريطة"}>
            <DrawBorders table="farms" source="borders" />
        </Tab>
        {/* <Tab label={locale === 'en' ? "Weather" : "الطقس"}>
            <WeatherChart type="farm" />
        </Tab> */}
        {props.record.is_pivot &&
            <Tab label={locale === 'en' ? "Vegetation Index" : "معدل النمو الخضرى"} >
                <VegetationChart />
            </Tab>
        }
        {props.record.is_pivot &&
            <Tab label={locale === 'en' ? "Scouting Visualization" : "عرض الاستكشاف"}>
                <ScoutingVis />
            </Tab>
        }
        {props.record.is_pivot &&
            <Tab label={locale === 'en' ? "Scouting Enable" : "تفعيل الاستكشاف"}>
                <ScoutingEnable />
            </Tab>
        }
    </TabbedShowLayout>
}

const FarmTitle = ({ record }) => {
    const locale = useLocale();
    return <span>{record ? (locale === 'en' ? record.name : record.name_ar) : ''}</span>;
}

export const FarmShow = (props) => {
    return (
        <Show title={<FarmTitle />}  {...props}>
            <MyFarmShow />
        </Show>
    )
};

const FarmFilter = (props) => (
    <Filter {...props}>

    </Filter>
);
