import httpClient from '../httpClient';
import Cookies from './Cookies';
import axios from 'axios';

export function processKML(KML, basePath, data) {
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(KML, "text/xml");
    const coordinates = xmlDoc.getElementsByTagName('coordinates')[0].childNodes[0].nodeValue.trim();
    const points = coordinates.split(" ");
    const parsedArray = points.map(point => point.split(",").map(number => parseFloat(number)));
    httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/${basePath}/${data.id}`, {
        method: 'PUT',
        body: JSON.stringify({ borders: JSON.stringify(parsedArray) })
    }).then(res => {
        if (res.status >= 400) {
            alert('Error Parsing File!');
        }
        else {
            alert('File Loaded Successfully');
        }
        window.location.reload(true)
    });
}

export function processCSV(csv, setProgress) {
    const Papa = require('papaparse');
    const result = Papa.parse(csv, {
        header: true,
        dynamicTyping: true
    });
    const removed = result.data.filter(object => {
        for (let value of Object.values(object)) {
            if (value !== null)
                return true;
        }
        return false;
    });
    const token = Cookies.getCookie('token');

    axios({
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: JSON.stringify(removed),
        url: `${process.env.REACT_APP_DATA_PROVIDER_URL}/palms/import`,
        onUploadProgress: (ev) => {
            const progress = ev.loaded / ev.total * 100;
            setProgress(progress);
        },
    }).then(res => {
        if (res.status >= 400) {
            alert('Error Parsing File!');
        }
        else {
            alert('File Loaded Successfully');
        }
        window.location.reload(true)
    }).catch(err => {
        alert('Error Parsing File!');
        window.location.reload(true)
    });
}
