import React from 'react';
import {
    Create, Edit, SimpleForm, TextInput,
    ReferenceInput, Datagrid, ReferenceField,
    TextField, Filter, useLocale, SelectInput,
    EditButton, Pagination
} from 'react-admin';
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import List from './theme/components/List'
import clsx from 'clsx';

export const DeviceVariableList = (props) => {
    const locale = useLocale();
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}filters={<DeviceVariableFilter />} {...props}>
            <Datagrid>
                  
                <TextField source={locale === 'en' ? "variable" : "variable_ar"} />
                <TextField source="unit" />
                <ReferenceField source="template_id" reference="decodingtemplates" allowEmpty={true}>
                    <TextField source={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceField>
                <EditButton />
            </Datagrid>
        </List>
    )
};

export const DeviceVariableEdit = props => {
    const locale = useLocale();
    return (
        <Edit undoable={false} {...props}>
            <SimpleForm redirect="list" toolbar={<CustomToolbar />} className={clsx('tab-rtl-layout')}>
                <TextInput disabled source="id" />
                <TextInput source="variable" />
                <TextInput source="variable_ar" />
                <TextInput source="unit" />
                <ReferenceInput perPage={-1} reference="decodingtemplates" source="template_id" >
                    <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    )
};

export const DeviceVariableCreate = props => {
    const locale = useLocale();
    return (
        <Create {...props}>
            <SimpleForm redirect="list" className={clsx('tab-rtl-layout')}>
                <TextInput source="variable" />
                <TextInput source="variable_ar" />
                <TextInput source="unit" />
                <ReferenceInput perPage={-1} reference="decodingtemplates" source="template_id" >
                    <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    )
};

const DeviceVariableFilter = (props) => {
    const locale = useLocale();
    return (
        <Filter {...props}>
            <ReferenceInput perPage={-1} reference="decodingtemplates" source="template_id" >
                <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
            </ReferenceInput>
        </Filter>
    )
};
