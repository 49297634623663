import React, { Fragment } from 'react';
import {
    Paper,
    Box,
} from '@material-ui/core';

function PageTitle() {
    return (
        <Fragment>
            <Paper
                square
                elevation="2"
                className="app-page-title"
            >
                <div>
                    <Box className="app-page-title--first">
                        <div className="app-page-title--heading">
                            <h1 id="react-admin-title"></h1>
                        </div>
                    </Box>
                </div>
            </Paper>
        </Fragment>
    );
}

export default PageTitle;
