import React from "react";
import { SvgIcon } from "@material-ui/core";

export const BlocksIcon = () => {
  return (
    <SvgIcon
      enable-background="new 0 0 512 512"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="XMLID_25_">
        <g id="XMLID_1168_">
          <path id="XMLID_1164_" d="m10 174h492v56h-492z" fill="#c68a65" />
        </g>
        <g id="XMLID_765_">
          <path id="XMLID_361_" d="m10 334h492v56h-492z" fill="#c68a65" />
        </g>
        <g id="XMLID_1275_">
          <path
            id="XMLID_357_"
            d="m312 475h-112v-382.167l56-55.833 56 55.833z"
            fill="#ffda8f"
          />
        </g>
        <g id="XMLID_340_">
          <path
            id="XMLID_353_"
            d="m122 475h-112v-382.167l56-55.833 56 55.833z"
            fill="#ffda8f"
          />
        </g>
        <g id="XMLID_338_">
          <path
            id="XMLID_344_"
            d="m390 475h112v-382.167l-56-55.833-56 55.833z"
            fill="#ffda8f"
          />
        </g>
        <g id="XMLID_1174_">
          <path
            id="XMLID_1177_"
            d="m509.06 85.752-56-55.833c-3.903-3.892-10.218-3.892-14.121 0l-56 55.833c-1.882 1.876-2.94 4.424-2.94 7.082v71.166h-58v-71.166c0-2.657-1.058-5.206-2.94-7.082l-56-55.833c-3.903-3.892-10.218-3.892-14.121 0l-56 55.833c-1.882 1.876-2.939 4.424-2.939 7.082v71.166h-58v-71.166c0-2.657-1.058-5.206-2.94-7.082l-56-55.833c-3.903-3.892-10.218-3.892-14.121 0l-56 55.833c-1.88 1.876-2.938 4.424-2.938 7.082v382.166c0 5.523 4.477 10 10 10h112c5.523 0 10-4.477 10-10v-75h58v75c0 5.523 4.477 10 10 10h112c5.523 0 10-4.477 10-10v-75h58v75c0 5.523 4.477 10 10 10h112c5.523 0 10-4.477 10-10v-173.999c0-5.523-4.477-10-10-10s-10 4.477-10 10v163.999h-92v-368.016l46-45.863 46 45.863v114.016c0 5.523 4.477 10 10 10s10-4.477 10-10v-118.166c0-2.658-1.058-5.206-2.94-7.082zm-187.06 154.248h58v84h-58zm58-56v36h-58v-36zm-248 56h58v84h-58zm58-56v36h-58v-36zm-78 281h-92v-368.016l46-45.863 46 45.863zm20-85v-36h58v36zm170 85h-92v-368.016l46-45.863 46 45.863zm20-85v-36h58v36z"
          />
          <path
            id="XMLID_1279_"
            d="m66 192.82c-2.63 0-5.21 1.06-7.07 2.92-1.86 1.87-2.93 4.44-2.93 7.08 0 2.63 1.07 5.2 2.93 7.07 1.86 1.86 4.44 2.93 7.07 2.93s5.21-1.07 7.07-2.93c1.86-1.87 2.93-4.44 2.93-7.07 0-2.64-1.07-5.22-2.93-7.08s-4.44-2.92-7.07-2.92z"
          />
          <path
            id="XMLID_1280_"
            d="m256 192.82c-2.63 0-5.21 1.06-7.07 2.92-1.86 1.87-2.93 4.44-2.93 7.07 0 2.64 1.07 5.22 2.93 7.079 1.86 1.86 4.44 2.921 7.07 2.921s5.21-1.06 7.07-2.921c1.86-1.86 2.93-4.44 2.93-7.079 0-2.63-1.07-5.2-2.93-7.07-1.86-1.86-4.44-2.92-7.07-2.92z"
          />
          <path
            id="XMLID_1281_"
            d="m453.07 195.74c-1.86-1.86-4.44-2.92-7.07-2.92s-5.21 1.06-7.07 2.92-2.93 4.44-2.93 7.08c0 2.63 1.07 5.21 2.93 7.07s4.44 2.93 7.07 2.93 5.21-1.07 7.07-2.93c1.86-1.87 2.93-4.44 2.93-7.07 0-2.64-1.07-5.21-2.93-7.08z"
          />
          <path
            id="XMLID_1282_"
            d="m66 352c-2.63 0-5.21 1.07-7.07 2.93s-2.93 4.44-2.93 7.07 1.07 5.21 2.93 7.07 4.44 2.93 7.07 2.93 5.21-1.07 7.07-2.93 2.93-4.44 2.93-7.07-1.07-5.21-2.93-7.07-4.44-2.93-7.07-2.93z"
          />
          <path
            id="XMLID_1465_"
            d="m256 352c-2.63 0-5.21 1.07-7.07 2.93s-2.93 4.44-2.93 7.07 1.07 5.21 2.93 7.07 4.44 2.93 7.07 2.93 5.21-1.07 7.07-2.93 2.93-4.44 2.93-7.07-1.07-5.21-2.93-7.07-4.44-2.93-7.07-2.93z"
          />
          <path
            id="XMLID_1466_"
            d="m446 352c-2.63 0-5.21 1.07-7.07 2.93s-2.93 4.44-2.93 7.07 1.07 5.21 2.93 7.07 4.44 2.93 7.07 2.93 5.21-1.07 7.07-2.93 2.93-4.44 2.93-7.07-1.07-5.21-2.93-7.07-4.44-2.93-7.07-2.93z"
          />
          <path
            id="XMLID_1467_"
            d="m502 246c-2.63 0-5.21 1.07-7.07 2.93s-2.93 4.44-2.93 7.07 1.07 5.21 2.93 7.07 4.44 2.93 7.07 2.93 5.21-1.07 7.07-2.93 2.93-4.44 2.93-7.07-1.07-5.21-2.93-7.07-4.44-2.93-7.07-2.93z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};
