import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Hidden, Drawer, Paper } from '@material-ui/core';
import { setSidebarVisibility } from 'ra-core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SidebarHeader from './SidebarHeader';


const Sidebar = props => {
    const {
        children,
        classes: classesOverride,
        ...rest
    } = props;
    const dispatch = useDispatch();
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    useSelector(state => state.locale); // force redraw on locale change
    const toggleSidebar = () => dispatch(setSidebarVisibility(!open));
    const handleClose = () => dispatch(setSidebarVisibility(false));


    return (
        <Fragment>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    open={open}
                    onClose={toggleSidebar}
                    variant="temporary"
                    elevation={4}
                    className="app-sidebar-wrapper-lg"
                    {...rest}
                >
                    <SidebarHeader />
                    <PerfectScrollbar>
                        {cloneElement(Children.only(children), {
                            onMenuClick: handleClose,
                        })}
                    </PerfectScrollbar>
                </Drawer>
            </Hidden>

            <Hidden mdDown>
                <Paper
                    className={clsx('app-sidebar-wrapper', 'app-sidebar-wrapper-fixed', {
                        'app-sidebar-wrapper-close': !open,
                        'app-sidebar-wrapper-open': open,
                    })}
                    square
                    open={open}
                    elevation="11">
                    <SidebarHeader />
                    <div
                        className={clsx('app-sidebar-menu',{
                            'app-sidebar-collapsed': open
                        })}>
                        <PerfectScrollbar options={{ wheelPropagation: false }}>
                            {cloneElement(Children.only(children), {
                                onMenuClick: handleClose,
                            })}
                        </PerfectScrollbar>
                    </div>
                </Paper>
            </Hidden>
        </Fragment>
    );
};

Sidebar.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Sidebar;
