import React from "react";
import { SvgIcon } from "@material-ui/core";

export const TeamIcon = () => {
  return (
    <SvgIcon
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
    >
      <path
        style={{ fill: "#78D2FA" }}
        d="M217.21,353.387c-7.692-7.987-17.642-13.886-29.026-16.614l-41.143-9.859l-0.017,0.027l0.017-0.027
	v-25.117H96.799v25.198l0,0l-41.036,9.803C28.93,343.208,10,367.191,10,394.779V462h90.962L217.21,353.387z"
      />
      <polygon
        style={{ fill: "#FFB487" }}
        points="148.12,301.797 148.12,327.592 122.046,390.047 96,327.681 96,301.797 "
      />
      <path
        style={{ fill: "#FFCDAC" }}
        d="M177.955,215.261v34.79c0,30.928-25.073,56-56.002,56l0,0l0,0c-30.929,0-56.002-25.072-56.002-56
	v-34.79L177.955,215.261L177.955,215.261z"
      />
      <path
        style={{ fill: "#878791" }}
        d="M177.955,226.266v-13.481c0-30.928-25.073-56-56.002-56l0,0c-30.929,0-56.002,25.072-56.002,56
	v13.481h38.603c24.174,0,47.895-6.563,68.631-18.988l0,0"
      />
      <path
        style={{ fill: "#A5DC69" }}
        d="M294.79,353.387c7.692-7.987,17.642-13.886,29.026-16.614l41.143-9.859l0.017,0.027l-0.017-0.027
	v-25.117h50.242v25.198l0,0l41.036,9.803C483.07,343.208,502,367.191,502,394.779V462h-90.962L294.79,353.387z"
      />
      <path
        style={{ fill: "#FF5A5A" }}
        d="M220.953,352.403l-57.306,13.732c-37.348,8.95-63.685,42.343-63.685,80.747V502h311.87v-55.07
	c0-38.426-26.366-71.832-63.742-80.761l-57.157-13.654L220.953,352.403z"
      />
      <g>
        <polygon
          style={{ fill: "#FFB487" }}
          points="416,301.797 416,327.681 389.954,390.047 363.88,327.592 363.88,301.797 	"
        />
        <path
          style={{ fill: "#FFB487" }}
          d="M256.01,432.738l37.31-84.708c-2.769-4.65-4.347-10.062-4.347-15.792v-8.142h-65.966v8.143
		c0,5.72-1.572,11.124-4.333,15.769L256.01,432.738z"
        />
      </g>
      <path
        style={{ fill: "#FFCDAC" }}
        d="M334.001,191.525v53.819c0,43.078-34.922,78.001-78,78.001l0,0l0,0c-43.078,0-78-34.922-78-78.001
	v-53.819H334.001z"
      />
      <path
        style={{ fill: "#F5B955" }}
        d="M256,115.437L256,115.437c-43.079,0-78.001,34.922-78.001,78v18.777h38.137
	c25.351,0,49.402-11.225,65.684-30.656l0,0l52.179,30.656v-18.777C334.001,150.359,299.079,115.437,256,115.437z"
      />
      <path
        style={{ fill: "#FFCDAC" }}
        d="M334.045,215.261v34.79c0,30.928,25.073,56,56.002,56l0,0l0,0c30.929,0,56.002-25.072,56.002-56
	v-34.79L334.045,215.261L334.045,215.261z"
      />
      <path
        style={{ fill: "#878791" }}
        d="M334.045,226.266v-13.481c0-30.928,25.073-56,56.002-56l0,0c30.929,0,56.002,25.072,56.002,56v13.481
	h-38.603c-24.174,0-47.895-6.563-68.631-18.988l0,0"
      />
      <path
        d="M255.74,492c-2.631,0-5.211,1.07-7.07,2.93c-1.86,1.86-2.93,4.44-2.93,7.07s1.069,5.21,2.93,7.07
	c1.859,1.86,4.439,2.93,7.07,2.93c2.63,0,5.21-1.07,7.069-2.93c1.86-1.86,2.931-4.44,2.931-7.07s-1.07-5.21-2.931-7.07
	C260.95,493.07,258.37,492,255.74,492z"
      />
      <path
        d="M458.56,327.071l-33.358-7.969V305.87c18.516-11.703,30.848-32.342,30.848-55.818v-37.267c0-36.393-29.608-66-66.002-66
	c-19.632,0-37.279,8.626-49.38,22.275c-10.598-36.703-44.495-63.623-84.564-63.623h-0.001c-40.134,0-74.077,27.005-84.616,63.798
	c-12.104-13.75-29.816-22.45-49.532-22.45c-36.394,0-66.002,29.608-66.002,66v37.267c0,23.477,12.332,44.116,30.848,55.818v13.232
	l-33.358,7.969C21.976,334.587,0,362.43,0,394.779V462c0,5.523,4.478,10,10,10h79.963v30c0,5.523,4.478,10,10,10h109.704
	c5.522,0,10-4.477,10-10s-4.478-10-10-10h-44.643v-44.012c0-5.523-4.478-10-10-10s-10,4.477-10,10V492h-35.062v-45.118
	c0-33.914,23.034-63.119,56.015-71.022l49.183-11.786l31.646,71.996c1.596,3.631,5.187,5.976,9.153,5.976h0.001
	c3.966,0,7.558-2.344,9.154-5.975l31.614-71.891l49.04,11.715c33.011,7.885,56.065,37.096,56.065,71.034v11.159
	c-0.511,1.202-0.795,2.523-0.795,3.911s0.284,2.71,0.795,3.911V492h-34.857v-44.012c0-5.523-4.478-10-10-10s-10,4.477-10,10V492
	h-48.975c-5.522,0-10,4.477-10,10s4.478,10,10,10h113.832c5.522,0,10-4.477,10-10v-30H502c5.522,0,10-4.477,10-10v-67.221
	C512,362.43,490.024,334.587,458.56,327.071z M405.224,327.535l-15.268,36.559l-15.062-36.078c0.04-0.363,0.069-0.728,0.069-1.094
	c0-0.093-0.001-0.187-0.004-0.28v-12.35c4.85,1.139,9.896,1.76,15.088,1.76c5.215,0,10.285-0.627,15.154-1.776v12.718
	C405.201,327.176,405.214,327.355,405.224,327.535z M210.953,335.568c-6.278-3.894-13.166-6.777-20.438-8.519l-33.474-8.021v-13.115
	c8.325-5.247,15.396-12.299,20.667-20.607c7.539,14.729,19.11,27.063,33.245,35.547V335.568z M188.1,245.345v-23.13h28.138
	c25.524,0,49.715-10.088,67.587-27.941l40.221,23.629v29.789c-1.244,36.411-31.233,65.654-67.943,65.654
	C218.605,313.345,188.1,282.84,188.1,245.345z M300.934,321.041c14.222-8.455,25.874-20.798,33.471-35.559
	c5.26,8.231,12.289,15.22,20.554,20.431v13.115l-33.474,8.021c-7.316,1.753-14.243,4.662-20.552,8.592v-14.6H300.934z
	 M390.047,296.052c-25.365,0-46.002-20.636-46.002-46v-1.632c0.035-1.021,0.06-26.888,0.06-26.888
	c19.659,9.668,41.332,14.735,63.342,14.735h28.603v13.786C436.049,275.417,415.412,296.052,390.047,296.052z M390.047,166.785
	c25.365,0,46.002,20.636,46.002,46v2.476v1.005h-28.603c-21.606,0-42.837-5.673-61.548-16.426
	C351.504,180.758,369.174,166.785,390.047,166.785z M188.1,193.438c0-37.496,30.506-68,68.003-68c37.496,0,68.002,30.505,68.002,68
	v1.304l-37.115-21.805c-4.211-2.475-9.593-1.544-12.73,2.199c-14.42,17.208-35.568,27.078-58.021,27.078H188.1V193.438z
	 M75.951,212.785c0-25.365,20.637-46,46.002-46c20.873,0,38.543,13.973,44.148,33.056c-18.711,10.752-39.941,16.426-61.548,16.426
	H75.951v-1.005V212.785z M75.951,236.266h28.603c22.032,0,43.727-5.076,63.401-14.763v28.549c0,25.365-20.637,46-46.002,46
	s-46.002-20.636-46.002-46C75.951,250.052,75.951,236.266,75.951,236.266z M121.953,316.052c5.192,0,10.238-0.622,15.088-1.76v12.07
	c-0.011,0.188-0.016,0.376-0.016,0.564c0,0.315,0.03,0.628,0.06,0.941l-15.13,36.227l-15.187-36.379
	c0.017-0.239,0.031-0.478,0.031-0.72v-12.718C111.668,315.425,116.738,316.052,121.953,316.052z M89.963,446.882V452H60v-44.429
	c0-5.523-4.478-10-10-10s-10,4.477-10,10V452H20v-57.221c0-23.055,15.662-42.898,38.087-48.255l31.698-7.573l20.688,49.555
	C97.534,404.58,89.963,424.954,89.963,446.882z M161.317,356.411c-5.905,1.415-11.548,3.384-16.906,5.812l9.74-23.322l31.703,7.597
	c2.459,0.589,4.855,1.368,7.174,2.314L161.317,356.411z M255.962,407.199l-25.009-56.897v-20.624
	c7.972,2.382,16.413,3.667,25.149,3.667c8.62,0,16.953-1.252,24.831-3.573v20.641L255.962,407.199z M318.789,348.888
	c2.376-0.98,4.833-1.785,7.357-2.39l31.626-7.578l9.78,23.426c-5.427-2.472-11.148-4.471-17.138-5.902L318.789,348.888z M492,452
	h-20v-44.429c0-5.523-4.478-10-10-10s-10,4.477-10,10V452h-30.167v-5.07c0-21.899-7.546-42.245-20.448-58.305l20.753-49.692
	l31.775,7.591c22.425,5.357,38.087,25.2,38.087,48.255V452z"
      />
      <path
        d="M222.46,231.25c-2.63,0-5.21,1.07-7.07,2.93c-1.86,1.86-2.93,4.44-2.93,7.07s1.069,5.21,2.93,7.07s4.44,2.93,7.07,2.93
	c2.64,0,5.21-1.07,7.069-2.93c1.87-1.86,2.931-4.44,2.931-7.07s-1.061-5.21-2.931-7.07C227.67,232.32,225.09,231.25,222.46,231.25z"
      />
      <path
        d="M289.33,251.25c2.63,0,5.21-1.07,7.069-2.93c1.87-1.86,2.931-4.44,2.931-7.07s-1.061-5.21-2.931-7.07
	c-1.859-1.86-4.439-2.93-7.069-2.93s-5.21,1.07-7.07,2.93c-1.86,1.86-2.93,4.44-2.93,7.07s1.069,5.21,2.93,7.07
	S286.7,251.25,289.33,251.25z"
      />
      <path
        d="M275.247,267.842c-3.906-3.905-10.236-3.905-14.143,0c-2.957,2.957-7.768,2.957-10.725,0
	c-3.906-3.905-10.236-3.905-14.143,0c-3.905,3.905-3.905,10.237,0,14.143c5.378,5.377,12.441,8.066,19.505,8.066
	c7.064,0,14.127-2.688,19.505-8.066C279.152,278.079,279.152,271.747,275.247,267.842z"
      />
      <path d="M406.62,74h6.859c5.522,0,10-4.477,10-10s-4.478-10-10-10h-6.859c-5.522,0-10,4.477-10,10S401.098,74,406.62,74z" />
      <path d="M455.141,74H462c5.522,0,10-4.477,10-10s-4.478-10-10-10h-6.859c-5.522,0-10,4.477-10,10S449.618,74,455.141,74z" />
      <path
        d="M434.311,101.69c5.522,0,10-4.477,10-10v-6.859c0-5.523-4.478-10-10-10s-10,4.477-10,10v6.859
	C424.311,97.213,428.788,101.69,434.311,101.69z"
      />
      <path
        d="M434.311,53.169c5.522,0,10-4.477,10-10V36.31c0-5.523-4.478-10-10-10s-10,4.477-10,10v6.859
	C424.311,48.692,428.788,53.169,434.311,53.169z"
      />
      <path d="M49.444,74h6.858c5.522,0,10-4.477,10-10s-4.478-10-10-10h-6.858c-5.522,0-10,4.477-10,10S43.922,74,49.444,74z" />
      <path d="M97.965,74h6.858c5.522,0,10-4.477,10-10s-4.478-10-10-10h-6.858c-5.522,0-10,4.477-10,10S92.442,74,97.965,74z" />
      <path
        d="M77.134,101.69c5.522,0,10-4.477,10-10v-6.859c0-5.523-4.478-10-10-10s-10,4.477-10,10v6.859
	C67.134,97.213,71.611,101.69,77.134,101.69z"
      />
      <path
        d="M77.134,53.169c5.522,0,10-4.477,10-10V36.31c0-5.523-4.478-10-10-10s-10,4.477-10,10v6.859
	C67.134,48.692,71.611,53.169,77.134,53.169z"
      />
      <path
        d="M256,84.397c5.522,0,10-4.477,10-10v-26.31c0-5.523-4.478-10-10-10s-10,4.477-10,10v26.31
	C246,79.92,250.478,84.397,256,84.397z"
      />
      <path
        d="M256,20c2.63,0,5.21-1.07,7.069-2.93C264.93,15.21,266,12.63,266,10s-1.07-5.21-2.931-7.07C261.21,1.07,258.63,0,256,0
	s-5.21,1.07-7.07,2.93C247.069,4.79,246,7.37,246,10s1.069,5.21,2.93,7.07S253.37,20,256,20z"
      />
      <path
        d="M206.813,54.262c1.953,1.953,4.512,2.929,7.071,2.929s5.118-0.976,7.071-2.929c3.905-3.905,3.905-10.237,0-14.143
	l-14.634-14.634c-3.906-3.905-10.236-3.905-14.143,0c-3.905,3.905-3.905,10.237,0,14.143L206.813,54.262z"
      />
      <path
        d="M297.626,57.13c2.56,0,5.118-0.976,7.071-2.929l15.123-15.123c3.905-3.905,3.905-10.237,0-14.143
	c-3.906-3.905-10.236-3.905-14.143,0l-15.123,15.123c-3.905,3.905-3.905,10.237,0,14.143
	C292.508,56.154,295.066,57.13,297.626,57.13z"
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </SvgIcon>
  );
};
