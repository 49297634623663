import React from 'react';
import {
    Create, Edit, SimpleForm, TextInput, DateField,
    EditButton, Datagrid, ReferenceInput, useLocale,
    TextField, Filter, ReferenceField, SelectInput, ShowButton,
    Tab, TabbedShowLayout, Show, ReferenceManyField, Pagination
} from 'react-admin';
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import List from './theme/components/List'
import { EntityLocation, LaunchIconLink } from './entityLocation'
import clsx from 'clsx';


export const AssetList = (props) => {
    const locale = useLocale()
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} filters={<AssetFilter />} {...props}>
            <Datagrid>
                  
                <LaunchIconLink source={locale === 'en' ? "Location" : "الموقع"} tab={2} />
                <TextField source="qr_value" />
                <ReferenceField source="assettype_id" reference="assettypes" allowEmpty={true}>
                    <TextField source={locale === 'en' ? "type" : "type_ar"} />
                </ReferenceField>
                <TextField source="serial_number" />
                <TextField source="lat" />
                <TextField source="lng" />
                <ReferenceField reference="farms" source="farm_id" allowEmpty={true} >
                    <TextField source="farm_code" />
                </ReferenceField>
                <TextField source="notes" />
                <EditButton />
                <ShowButton />
            </Datagrid>
        </List>
    )
};

export const AssetEdit = props => {
    const locale = useLocale()
    return (
        <Edit undoable={false} {...props}>
            <SimpleForm redirect="list" toolbar={<CustomToolbar />} className={clsx('tab-rtl-layout')}>
                <TextInput disabled source="id" />
                <TextInput source="qr_value" />
                <ReferenceInput perPage={-1} source="assettype_id" reference="assettypes" >
                    <SelectInput optionText={locale === 'en' ? "type" : "type_ar"} />
                </ReferenceInput>
                <TextInput source="serial_number" />
                <TextInput source="lat" />
                <TextInput source="lng" />
                <ReferenceInput perPage={-1} reference="farms" source="farm_id">
                    <SelectInput optionText="farm_code" />
                </ReferenceInput>
                <TextInput source="notes" />
            </SimpleForm>
        </Edit>
    )
};

export const AssetCreate = props => {
    const locale = useLocale()
    return (
        <Create {...props}>
            <SimpleForm redirect="list" className={clsx('tab-rtl-layout')}>
                <TextInput source="qr_value" />
                <ReferenceInput perPage={-1} source="assettype_id" reference="assettypes" >
                    <SelectInput optionText={locale === 'en' ? "type" : "type_ar"} />
                </ReferenceInput>
                <TextInput source="serial_number" />
                <TextInput source="lat" />
                <TextInput source="lng" />
                <ReferenceInput perPage={-1} reference="farms" source="farm_id">
                    <SelectInput optionText="farm_code" />
                </ReferenceInput>
                <TextInput source="notes" />
            </SimpleForm>
        </Create>
    )
};
const AssetName = ({ record }) => {
    const locale = useLocale();
    return <span>{record ? (locale === 'en' ? record.qr_value : record.qr_value) : ''}</span>;
}
export const AssetShow = (props) => {
    const locale = useLocale()
    return (
        <Show {...props} title={<AssetName />}>
            <TabbedShowLayout>
                <Tab label={locale === 'en' ? "summary" : "الملخص"} contentClassName={clsx('tab-rtl-layout')}>
                      
                    <TextField source="qr_value" />
                    <ReferenceField source="assettype_id" reference="assettypes" label="Asset Type" allowEmpty={true}>
                        <TextField source={locale === 'en' ? "type" : "type_ar"} />
                    </ReferenceField>
                    <TextField source="serial_number" />
                    <TextField source="lat" />
                    <TextField source="lng" />
                    <ReferenceField reference="farms" source="farm_id" allowEmpty={true} >
                        <TextField source="farm_code" />
                    </ReferenceField>
                    <TextField source="notes" />
                </Tab>
                <Tab label={locale === 'en' ? "Activities" : "السجلات"}>
                    <ReferenceManyField perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} reference="assetlogs" target="asset_id" addLabel={false}>
                        <Datagrid>
                              
                            <ReferenceField source="activitytype_id" reference="assetactivitytypes" allowEmpty={true} >
                                <TextField source={locale === 'en' ? "name" : "name_ar"} />
                            </ReferenceField>
                            <TextField source="lat" />
                            <TextField source="lng" />
                            <TextField source="notes" />
                            <ReferenceField source="created_by" reference="users" allowEmpty={true} >
                                <TextField source="username" />
                            </ReferenceField>
                            <DateField source="created_at" locales="en-GB" showTime />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                <Tab label={locale === 'en' ? "Location" : "الموقع"}>
                    <EntityLocation lat="lat" lng="lng" />
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
};


const AssetFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput perPage={-1} source="assettype_id" reference="assettypes" label="Asset Type">
            <SelectInput optionText="type" />
        </ReferenceInput>
    </Filter>
);
