import React from 'react';
import { useLocale, TopToolbar, ShowButton } from 'react-admin';
import myHandleChange from './myHandleChange';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Button } from '@material-ui/core';

const KMLComponent = ({ basePath, data }) => {
    const locale = useLocale();
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <input
                type="file"
                id="kmlInput"
                style={{ display: 'none' }}
                accept='.kml'
                onChange={(event) => myHandleChange(event, "kml", null, basePath, data)}
            />
            <label htmlFor="kmlInput" style={{ display: 'inline-flex', alignItems: 'center' }}>
                <Button
                    variant="contained"
                    component="span"
                    color="primary"
                    style={{ margin: '1em', padding: '1em 4em' }}
                >
                    <span>{locale === 'en' ? "Import KML File" : "رفع ملف الإحداثيات"}</span>
                </Button>
            </label>
        </div>
    )
};

export default KMLComponent;