import React, { Fragment } from 'react';
import {
    Create, Edit, SimpleForm, TextInput, useLocale, ReferenceInput,
    EditButton, Datagrid, TextField, ImageField, Pagination, SelectInput,
    ReferenceField
} from 'react-admin';
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import { MyImageField, MyImageInput } from './helpers/iconComponent';
import { withStyles } from "@material-ui/core/styles";
import List from './theme/components/List'
import clsx from 'clsx';
const styles = {
    image: { maxHeight: '1.4rem' }
}

export const AssetActivityTypeList = withStyles(styles)(({ classes, ...props }) => {
    const locale = useLocale();
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}{...props}>
            <Datagrid>
                  
                <MyImageField source="icon_id" />
                <TextField source={locale === 'en' ? "name" : "name_ar"} />
                <ReferenceField source="assettype_id" reference="assettypes"
                    label={locale === 'en' ? "Asset Type" : "نوع المعدة"} allowEmpty={true}>
                    <TextField source={locale === 'en' ? "type" : "type_ar"} />
                </ReferenceField>
                <EditButton />
            </Datagrid>
        </List>
    )
});

export const AssetActivityTypeEdit = props => {
    const locale = useLocale();
    return (
        <Edit undoable={false} {...props}>
            <SimpleForm redirect="list" toolbar={<CustomToolbar />} className={clsx('tab-rtl-layout')}>
                <TextInput disabled source="id" />
                <TextInput source="name" />
                <TextInput source="name_ar" />
                <ReferenceInput perPage={-1} reference="assettypes" source="assettype_id" allowEmpty>
                    <SelectInput optionText={locale === 'en' ? "type" : "type_ar"} />
                </ReferenceInput>
                <MyImageInput />
            </SimpleForm>
        </Edit>
    )
};

export const AssetActivityTypeCreate = props => {
    const locale = useLocale();
    return (
        <Create {...props}>
            <SimpleForm redirect="list" className={clsx('tab-rtl-layout')}>
                <TextInput source="name" />
                <TextInput source="name_ar" />
                <ReferenceInput perPage={-1} reference="assettypes" source="assettype_id" allowEmpty>
                    <SelectInput optionText={locale === 'en' ? "type" : "type_ar"} />
                </ReferenceInput>
                <MyImageInput />
            </SimpleForm>
        </Create>
    )
};
