import React, { Fragment, useState, useEffect } from 'react';
import {
    Create, Edit, SimpleForm, TextInput, SingleFieldList, ChipField,
    Datagrid, CheckboxGroupInput, ReferenceInput, Tab, TabbedShowLayout,
    TextField, Filter, ReferenceArrayInput, SelectInput, FormDataConsumer,
    ReferenceArrayField, SimpleShowLayout, Show, required, ReferenceField,
    useLocale, Pagination, ShowButton
} from 'react-admin';
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import List from './theme/components/List'
import clsx from 'clsx';

import { parse } from 'query-string';

import httpClient from './httpClient';
import { useForm } from 'react-final-form';
import { ExportButton } from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import GanttChart from './gantt';

const PostActions = ({
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
        <Toolbar>
            {filters && React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filter={filterValues}
                exporter={exporter}
            />
        </Toolbar>
    );


const BlocksExpand = props => {
    const locale = useLocale();

    const ids = ['sections_ids', 'pivots_ids', 'blocks_ids'];
    const referenceObj = {
        'sections_ids': 'sections',
        'pivots_ids': 'pivots',
        'blocks_ids': 'blocks'
    };
    const labelObj = {
        en: {
            'sections_ids': 'Sections',
            'pivots_ids': 'Pivots',
            'blocks_ids': 'Blocks'
        },
        ar: {
            'sections_ids': 'القطاعات الفرعية',
            'pivots_ids': 'قطاعات الري المحوري',
            'blocks_ids': 'الحوش'
        }
    }
    for (let id of ids) {
        if (typeof props.record[id] === "string") {
            props.record[id] = JSON.parse(props.record[id]);
        }
        if (props.record[id]) {
            return <Show {...props} title=" " className={clsx('tab-rtl-layout')}>
                <SimpleShowLayout>
                    <ReferenceArrayField label={locale === 'en' ? labelObj.en[id] : labelObj.ar[id]} reference={referenceObj[id]} source={id}>
                        <SingleFieldList>
                            <ChipField source="qr_value" />
                        </SingleFieldList>
                    </ReferenceArrayField>
                </SimpleShowLayout>
            </Show>
        }
    }
    return null;
}
export const ActiveProgramList = (props) => {
    const locale = useLocale();
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} filters={<ActiveProgramFilter />} actions={<PostActions />} {...props}>
            <Datagrid expand={<BlocksExpand />}>
                  
                <TextField source={locale === 'en' ? "name" : "name_ar"} />
                <ReferenceField source="program_id" reference="programs" allowEmpty={true} >
                    <TextField source={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceField>
                <ReferenceField source="farm_id" reference="farms" allowEmpty={true} >
                    <TextField source="farm_code" />
                </ReferenceField>
                <ReferenceField source="user_id" reference="users" allowEmpty={true} >
                    <TextField source="username" />
                </ReferenceField>
                <TextField source="man_hour" />
                <ShowButton />
            </Datagrid>
        </List>
    )
};

const CustomCheckBox = props => {
    let { filterParam, choices, program, entityID, pivotSpecieTypes, ...rest } = props;

    let SpeciesToCrops = {}
    for (let specie of (pivotSpecieTypes || [])) {
        SpeciesToCrops[specie.id] = specie.crop_id;
    }

    let pivots;
    try {
        pivots = JSON.parse(entityID);
    }
    catch (e) {
        pivots = null;
    }

    choices = choices.filter(entity => {
        if (pivots && pivots.length > 0) {
            return pivots.includes(entity[filterParam]) && program && program.plantationtype_id === SpeciesToCrops[entity.plantationtype_id];
        }
        else return entity[filterParam] === entityID;
    });
    return <CheckboxGroupInput optionText="qr_value" choices={choices} {...rest} />;
}

// export const ActiveProgramEdit = props => {
//     const locale = useLocale();
//     return (
//         <Edit undoable={false} {...props}>
//             <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
//                 <TextInput disabled source="id" />
//                 <ReferenceInput perPage={-1} source="program_id" reference="programs" validate={required()} >
//                     <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
//                 </ReferenceInput>
//                 <TextInput source="name" validate={required()} />
//                 <TextInput source="name_ar" validate={required()} />
//                 <ReferenceInput perPage={-1} source="farm_id" reference="farms" validate={required()} >
//                     <SelectInput optionText="farm_code" />
//                 </ReferenceInput>
//                 <FormDataConsumer>
//                     {({ formData, ...rest }) => (formData.farm_id &&
//                         <ReferenceArrayInput source="blocks_ids" reference="blocks" label={locale === 'en' ? "Blocks" : "الحوش"} {...rest}
//                             parse={v => {
//                                 return JSON.stringify(v);
//                             }}
//                             format={v => {
//                                 return typeof v === 'string' ? JSON.parse(v) : v;
//                             }} >
//                             <CustomCheckBox farm_id={formData.farm_id} />
//                         </ReferenceArrayInput>
//                     )}
//                 </FormDataConsumer>
//                 <ReferenceInput perPage={-1} source="user_id" reference="users" validate={required()} >
//                     <SelectInput optionText="username" />
//                 </ReferenceInput>
//             </SimpleForm>
//         </Edit>
//     )
// };

const FormHook = ({ setFarm_id, setBlocks_ids, setPivots_ids, setSections_ids, pivotSpecieTypes, formData, ...rest }) => {
    const form = useForm();
    const locale = useLocale();
    const [data, setData] = useState(null);
    const [program, setProgram] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/activeprograms/farmtype?program_id=${formData.program_id}`);
            setData(response.json);
        };
        const fetchProgram = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/programs/${formData.program_id}`);
            setProgram(response.json);
        }
        if (formData.program_id) {
            fetchData();
            fetchProgram();
        }
    }, [formData.program_id]);

    return <Fragment>
        <ReferenceInput perPage={-1}
            onChange={(value) => {
                setFarm_id(value.target.value);
                if (formData.blocks_ids) {
                    setBlocks_ids(null);
                    form.change('blocks_ids', null);
                }
                if (formData.pivots_ids) {
                    setPivots_ids(null);
                    form.change('pivots_ids', null);
                }
                if (formData.sections_ids) {
                    setSections_ids(null);
                    form.change('sections_ids', null);
                }
            }}
            source="farm_id" reference="farms" validate={required()} {...rest}>
            <SelectInput optionText="farm_code" />
        </ReferenceInput>
        {formData.farm_id && data ?
            <Fragment>
                <br />

                {data.type === 'is_palm_tree' && <ReferenceArrayInput sort={{ field: 'qr_value', order: 'ASC' }} perPage={-1} onChange={(value) => setBlocks_ids(value)} source="blocks_ids" reference="blocks" label={locale === 'en' ? "Blocks" : "الحوش"} {...rest}
                    parse={v => {
                        return JSON.stringify(v);
                    }}
                    format={v => {
                        return typeof v === 'string' ? JSON.parse(v) : v;
                    }} >
                    <CustomCheckBox filterParam='farm_id' entityID={formData.farm_id} />
                </ReferenceArrayInput>}

                {data.type === 'is_pivot' && <ReferenceArrayInput sort={{ field: 'id', order: 'ASC' }} perPage={-1} source="pivots_ids" reference="pivots"
                    label={locale === 'en' ? "Pivots" : "قطاعات الري المحوري"} {...rest}
                    parse={v => {
                        return JSON.stringify(v);
                    }}
                    format={v => {
                        return typeof v === 'string' ? JSON.parse(v) : v;
                    }}
                    onChange={(value) => {
                        setPivots_ids(value);
                        if (formData.sections_ids) {
                            setSections_ids(null);
                            form.change('sections_ids', null);
                        }
                    }} >
                    <CustomCheckBox filterParam='farm_id' entityID={formData.farm_id} />
                </ReferenceArrayInput>}

                {data.sections && formData.pivots_ids && JSON.parse(formData.pivots_ids).length > 0 ?
                    <Fragment>
                        <br />
                        <ReferenceArrayInput sort={{ field: 'qr_value', order: 'ASC' }} perPage={-1} source="sections_ids" reference="sections"
                            label={locale === 'en' ? "Sections" : "القطاعات الفرعية"} {...rest}
                            parse={v => {
                                return JSON.stringify(v);
                            }}
                            format={v => {
                                return typeof v === 'string' ? JSON.parse(v) : v;
                            }}
                            onChange={(value) => {
                                setSections_ids(value);
                            }} >
                            <CustomCheckBox filterParam='pivot_id' entityID={formData.pivots_ids} program={program} pivotSpecieTypes={pivotSpecieTypes} />
                        </ReferenceArrayInput>
                    </Fragment> : null}
            </Fragment>
            : null
        }
    </Fragment>;
};


export const ActiveProgramCreate = props => {
    const { program_id: program_id_string } = parse(props.location.search);
    const program_id1 = program_id_string ? parseInt(program_id_string, 10) : '';

    const locale = useLocale();
    const [farm_id, setFarm_id] = useState(null);
    const [blocks_ids, setBlocks_ids] = useState(null);
    const [sections_ids, setSections_ids] = useState(null);
    const [pivots_ids, setPivots_ids] = useState(null);
    const [pivotSpecieTypes, setPivotSpecieTypes] = useState([]);

    useEffect(() => {
        const fetchSpecieTypes = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/pivotplantationtypes`);
            setPivotSpecieTypes(response.json);
        };

        fetchSpecieTypes();
    }, []);
    return (
        <Fragment>
            <Create {...props}>
                <SimpleForm initialValues={{ program_id: program_id1 }} redirect="list" >
                    <ReferenceInput perPage={-1} disabled source="program_id" reference="programs" validate={required()} >
                        <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
                    </ReferenceInput>
                    <TextInput source="name" validate={required()} />
                    <TextInput source="name_ar" validate={required()} />
                    <FormDataConsumer>
                        {formDataProps => (
                            <FormHook setFarm_id={setFarm_id} setBlocks_ids={setBlocks_ids}
                                setPivots_ids={setPivots_ids} setSections_ids={setSections_ids}
                                pivotSpecieTypes={pivotSpecieTypes}
                                {...formDataProps} />
                        )}
                    </FormDataConsumer>
                </SimpleForm>
            </Create>
            <GanttChart program_id={program_id1} farm_id={farm_id} blocks_ids={blocks_ids} pivots_ids={pivots_ids}
                sections_ids={sections_ids} />
        </Fragment>
    );
};

const GanttShow = props => {
    // console.log(props.record);
    return <GanttChart show={true} program_id={props.record.program_id} farm_id={props.record.farm_id}
        blocks_ids={props.record.blocks_ids} pivots_ids={props.record.pivots_ids} sections_ids={props.record.sections_ids} />
}
const ProgramName = ({ record }) => {
    const locale = useLocale();
    return <span>{record ? (locale === 'en' ? record.name : record.name_ar) : ''}</span>;
}
export const ActiveProgramShow = (props) => {
    const locale = useLocale();
    return (
        <Show {...props} title={<ProgramName />}>
            <TabbedShowLayout>
                <Tab label={locale === 'en' ? "summary" : "الملخص"} contentClassName={clsx('tab-rtl-layout')}>
                      
                    <TextField source={locale === 'en' ? "name" : "name_ar"} />
                    <ReferenceField source="program_id" reference="programs" allowEmpty={true} >
                        <TextField source={locale === 'en' ? "name" : "name_ar"} />
                    </ReferenceField>
                    <ReferenceField source="farm_id" reference="farms" allowEmpty={true} >
                        <TextField source="farm_code" />
                    </ReferenceField>
                    <ReferenceField source="user_id" reference="users" allowEmpty={true} >
                        <TextField source="username" />
                    </ReferenceField>
                    <TextField source="man_hour" />
                </Tab>
                <Tab label={locale === 'en' ? "Timeline" : "الخطة"}>
                    <GanttShow />
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
};

const ActiveProgramFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput perPage={-1} source="program_id" reference="programs">
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput perPage={-1} source="farm_id" reference="farms">
            <SelectInput optionText="farm_code" />
        </ReferenceInput>
        <ReferenceInput perPage={-1} source="user_id" reference="users">
            <SelectInput optionText="username" />
        </ReferenceInput>
    </Filter>
);
