import React from "react";
import { SvgIcon } from "@material-ui/core";

export const InventoryIcon = () => {
  return (
    <SvgIcon viewBox="0 0 512.002 512.002">
      <g id="filled_outline" data-name="filled outline">
        <path d="m488 488h-464v-336l232-104 232 104z" fill="#a55e00" />
        <path d="m72 184h368v304h-368z" fill="#844b02" />
        <path d="m272 368h80v64h-80z" fill="#ce9c77" />
        <path d="m280 368h64v8h-64z" fill="#ce9c77" />
        <path d="m304 368h16v32h-16z" fill="#c94536" />
        <path d="m272 304h72v64h-72z" fill="#efb58b" />
        <path d="m304 304h16v32h-16z" fill="#de4c3b" />
        <path d="m344 368h64v64h-64z" fill="#efb58b" />
        <path d="m368 368h16v32h-16z" fill="#4298d1" />
        <path
          d="m408 440h-136a8 8 0 0 1 0-16h136a8 8 0 0 1 0 16z"
          fill="#d38e14"
        />
        <path d="m152 304v40l56 64h40v-56a48 48 0 0 0 -48-48z" fill="#b6eaef" />
        <path
          d="m232 408v-40a48 48 0 0 0 -48-48h-32v24l56 64z"
          fill="#acdee3"
        />
        <path d="m104 368h48v16l24 24h80v40h-152z" fill="#f5b02e" />
        <path d="m488 152h-464l232-128z" fill="#ec9e15" />
        <path d="m72 192h368v56h-368z" fill="#c3c3c3" />
        <path d="m72 184h368v32h-368z" fill="#a8a8a8" />
        <path d="m72 184h368v8h-368z" fill="#878787" />
        <path d="m168 368h-16-48v32h64z" fill="#3e8cc7" />
        <path d="m224 112h64v16h-64z" fill="#c48412" />
        <path d="m224 80h64v16h-64z" fill="#c48412" />
        <circle cx="128" cy="456" fill="#e4e4e2" r="24" />
        <path
          d="m128 488a32 32 0 1 1 32-32 32.032 32.032 0 0 1 -32 32zm0-48a16 16 0 1 0 16 16 16.021 16.021 0 0 0 -16-16z"
          fill="#878787"
        />
        <path
          d="m152 344h16a16 16 0 0 1 16 16v16h16a16 16 0 0 1 16 16v16h-40l-24-24z"
          fill="#de4c3b"
        />
        <path d="m248 280h32v176h-32z" fill="#ec9e15" />
        <circle cx="224" cy="456" fill="#e4e4e2" r="24" />
        <path
          d="m224 440a16 16 0 1 0 16 16 16.021 16.021 0 0 0 -16-16zm0 48a32 32 0 1 1 32-32 32.032 32.032 0 0 1 -32 32z"
          fill="#878787"
        />
        <path d="m491.864 145-.028-.016-.041-.022-231.931-127.962a8 8 0 0 0 -7.728 0l-231.936 127.957-.041.022-.028.016a8 8 0 0 0 -4.131 7.005v336a8 8 0 0 0 8 8h464a8 8 0 0 0 8-8v-336a8 8 0 0 0 -4.136-7zm-235.864-111.863 200.939 110.863h-401.878zm-176 190.863h352v16h-352zm352-16h-352v-16h352zm-352 48h352v224h-186.868a32.037 32.037 0 0 0 9.855-16h25.013a8 8 0 0 0 8-8v-16h120a8 8 0 0 0 8-8v-64a8 8 0 0 0 -8-8h-56v-56a8 8 0 0 0 -8-8h-56v-16a8 8 0 0 0 -8-8h-32a8 8 0 0 0 -8 8v32.861a55.823 55.823 0 0 0 -40-16.861h-48a8 8 0 0 0 -8 8v56h-40a8 8 0 0 0 -8 8v80a7.97 7.97 0 0 0 .457 2.645 31.881 31.881 0 0 0 10.411 29.355h-26.868zm240 80v-24h16v48h-48v-48h16v24zm64 64v-24h16v48h-48v-48h16v24zm-96 24v-48h16v24h16v-24h16v48zm-16 24h-16v-160h16zm-63.31 3.367a7.912 7.912 0 0 0 .488-1.368 15.8 15.8 0 1 1 -.488 1.368zm31.31-23.062a31.943 31.943 0 0 0 -43.695 11.695h-40.605a31.943 31.943 0 0 0 -43.7-11.695v-20.305h52.686l5.657 5.657a8 8 0 0 0 5.657 2.343h64zm-96 27.695a16 16 0 1 1 -31.31-4.633 7.912 7.912 0 0 0 .488-1.368 15.985 15.985 0 0 1 29.643 0 8.01 8.01 0 0 0 .489 1.369 15.942 15.942 0 0 1 .69 4.632zm40-72h16a8.009 8.009 0 0 1 8 8v8h-28.687l-19.313-19.313v-28.687h8a8.009 8.009 0 0 1 8 8v16a8 8 0 0 0 8 8zm56 16h-16v-8a24.028 24.028 0 0 0 -24-24h-8v-8a24.028 24.028 0 0 0 -24-24h-8v-24h40a40.045 40.045 0 0 1 40 40zm-93.657-10.343 2.343 2.343h-36.686v-16h32v8a8 8 0 0 0 2.343 5.657zm13.657 66.343h32a31.923 31.923 0 0 0 10.868 24h-53.736a31.923 31.923 0 0 0 10.868-24zm320 24h-32v-296a8 8 0 0 0 -8-8h-368a8 8 0 0 0 -8 8v296h-32v-320h448z" />
        <path d="m224 112h64v16h-64z" />
        <path d="m224 80h64v16h-64z" />
      </g>
    </SvgIcon>
  );
};
