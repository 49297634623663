// authProvider.js

import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import Cookies from './helpers/Cookies';

export default (type, params) => {
    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        const identifier = username;
        // switching between local and deployed version
        // const request = new Request('http://localhost:1337/auth/local', {
        const request = new Request(process.env.REACT_APP_DATA_PROVIDER_URL + '/auth/local', {
            method: 'POST',
            body: JSON.stringify({ identifier, password }),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request)
            .then(response => {
                return response.json();
            })
            .then(response => {
                if (response.statusCode < 200 || response.statusCode >= 300) {
                    throw new Error(response.message[0].messages[0].message);
                }
                Cookies.setCookie('token', response.jwt, 1);
                Cookies.setCookie('role', response.user.role.name, 1);
                Cookies.setCookie('user', response.user.username, 1);
                const name = `${response.user.username} logged in`;
                const request = new Request(`${process.env.REACT_APP_DATA_PROVIDER_URL}/logevents`, {
                    method: 'POST',
                    body: JSON.stringify({ name }),
                    headers: new Headers({ Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${response.jwt}` })
                });
                fetch(request);
            });
    }

    if (type === AUTH_LOGOUT) {
        Cookies.deleteCookie('token');
        Cookies.deleteCookie('role');
        Cookies.deleteCookie('user');
        return Promise.resolve();
    }

    if (type === AUTH_ERROR) {
        const status = params.status;
        if (status === 401 || status === 403) {
            Cookies.deleteCookie('token');
            Cookies.deleteCookie('role');
            Cookies.deleteCookie('user');
            return Promise.reject();
        }
        return Promise.resolve();
    }

    if (type === AUTH_CHECK) {
        return Cookies.getCookie('token') ? Promise.resolve() : Promise.reject();
    }

    if (type === AUTH_GET_PERMISSIONS) {
        const role = Cookies.getCookie('role');
        return role ? Promise.resolve(role) : Promise.reject();
    }
    return Promise.resolve();
}

