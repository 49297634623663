import React from "react";
import { SvgIcon } from "@material-ui/core";

export const FarmIcon = () => {
  return (
    <SvgIcon
      enable-background="new 0 0 512 512"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path
            d="m330.56 74.78h-29.12c-26.1 6.5-45.44 30.1-45.44 58.22v243h120v-243c0-28.12-19.34-51.72-45.44-58.22z"
            fill="#ff9256"
          />
          <path
            d="m10 376v126h492c0-15.247 0-110.652 0-126-105.342 0-386.944 0-492 0z"
            fill="#d8ec84"
          />
          <g fill="#eefaff">
            <path d="m434.92 167.02-30 51.96c-9.298-8.991-76.401-73.875-88.92-85.98.117.033 118.789 33.982 118.92 34.02z" />
            <path d="m346 10c-.828 3.397-29.399 120.534-30 123-.6-2.46-29.172-119.607-30-123z" />
            <path d="m316 133c-5.423 5.243-67.732 65.493-88.92 85.98l-30-51.96c1.052-.301 118.186-33.81 118.92-34.02z" />
          </g>
          <circle cx="151" cy="55" fill="#ffda8f" r="45" />
          <path
            d="m112.73 213.94c10.66 10.83 17.27 25.66 17.27 42.06 0 33.14-26.86 60-60 60s-60-26.86-60-60c0-16.4 6.61-31.23 17.27-42.06-1.34-5.7-2.27-11.65-2.27-17.94 0-33.14 20.15-60 45-60s45 26.86 45 60c0 6.29-.93 12.24-2.27 17.94z"
            fill="#d8ec84"
          />
        </g>
        <g>
          <circle cx="316" cy="256" r="10" />
          <path d="m502 306h-116v-91.402l11.969 11.571c4.641 4.485 12.332 3.489 15.611-2.189l30-51.96c3.254-5.634.335-12.828-5.909-14.614l-51.671-14.782v-9.624c0-28.961-17.625-54.449-43.865-64.955l13.579-55.675c.728-2.982.045-6.133-1.851-8.546s-4.794-3.824-7.863-3.824h-60c-3.069 0-5.969 1.41-7.864 3.823s-2.578 5.564-1.851 8.546l13.579 55.675c-26.239 10.507-43.864 35.995-43.864 64.956v9.624l-51.671 14.782c-6.246 1.787-9.162 8.98-5.909 14.614l30 51.96c3.275 5.673 10.967 6.68 15.611 2.189l11.969-11.571v91.402h-50c-5.523 0-10 4.477-10 10v50h-106v-40.726c33.877-4.868 60-34.072 60-69.274 0-16.612-5.799-32.396-16.437-45.008.965-5.255 1.437-10.199 1.437-14.992 0-35.321-21.8-70-55-70-33.262 0-55 34.754-55 70 0 4.793.472 9.737 1.437 14.992-10.638 12.612-16.437 28.396-16.437 45.008 0 35.202 26.123 64.406 60 69.274v40.726h-50c-5.523 0-10 4.477-10 10v126c0 5.522 4.477 10 10 10h201c5.523 0 10-4.478 10-10s-4.477-10-10-10h-191v-43h472v43h-191c-5.522 0-10 4.478-10 10s4.478 10 10 10h201c5.522 0 10-4.478 10-10 0-10.779 0-175.178 0-186 0-5.523-4.478-10-10-10zm-482-50c0-13.167 5.113-25.613 14.397-35.045 2.409-2.448 3.394-5.96 2.608-9.304-1.368-5.818-2.005-10.791-2.005-15.651 0-27.103 16.028-50 35-50s35 22.897 35 50c0 4.86-.637 9.833-2.005 15.651-.786 3.343.199 6.856 2.608 9.304 9.284 9.432 14.397 21.878 14.397 35.045 0 27.57-22.43 50-50 50s-50-22.43-50-50zm472 110h-42v-40h42zm-62-40v40h-44v-40zm-104 40v-50c0-5.523-4.478-10-10-10s-10 4.477-10 10v50h-40v-170.749l50-48.341 50 48.341v170.749zm76.663-163.111c-13.054-12.624-42.021-40.635-50.956-49.275l68.149 19.498zm-36.663-69.889v3.903l-38.007-10.873 9.335-38.275c17.263 8.102 28.672 25.546 28.672 45.245zm-32.732-113-17.268 70.799-17.268-70.799zm-67.268 113c0-19.699 11.409-37.143 28.672-45.245l9.335 38.275-38.007 10.873zm-36.663 69.889-17.193-29.778 68.149-19.498c-8.935 8.641-37.902 36.652-50.956 49.276zm-23.337 123.111h40v40h-40zm-186 103v-43h472v43z" />
          <path d="m151 110c30.327 0 55-24.673 55-55s-24.673-55-55-55-55 24.673-55 55 24.673 55 55 55zm0-90c19.299 0 35 15.701 35 35s-15.701 35-35 35-35-15.701-35-35 15.701-35 35-35z" />
          <circle cx="256" cy="502" r="10" />
        </g>
      </g>
    </SvgIcon>
  );
};
