import React, { useState, useEffect } from 'react';
import {
    Create, Edit, SimpleForm, TextInput,
    DateField, Datagrid, TextField, ReferenceArrayField,
    Pagination, Show, ReferenceField, SingleFieldList,
    Tab, TabbedShowLayout, ReferenceManyField, useLocale,
    ShowButton, SelectInput, ReferenceInput
} from 'react-admin';
import List from './theme/components/List'
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import httpClient from './httpClient';
import WorkersActions from './helpers/workersActions'
export const WorkerList = (props) => {
    const locale = useLocale();
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} actions={<WorkersActions />} title={locale === 'en' ? "Labor" : "العمال"}
            perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} {...props}>
            <Datagrid>
                  
                <TextField source="name" />
                <TextField source="qr_value" />
                <TextField source="rating" />
                <TextField source="total_work_hours" />
                <ReferenceField source="team_id" reference="teams">
                    <TextField source={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceField>
                <ShowButton />
            </Datagrid>
        </List>
    )
};

export const WorkerEdit = props => {
    const locale = useLocale();
    return (
        <Edit undoable={false} {...props}>
            <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
                <TextInput disabled source="id" />
                <TextInput source="name" />
                <TextInput disabled source="qr_value" />
                <ReferenceInput perPage={-1} source="team_id" reference="teams" allowEmpty >
                    <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    )
};

export const WorkerCreate = props => {
    const locale = useLocale();
    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <TextInput source="name" />
                <TextInput source="qr_value" />
                <ReferenceInput perPage={-1} source="team_id" reference="teams" allowEmpty >
                    <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    )
};


const ActivityTypeField = props => {
    const locale = useLocale();
    const { data, source, ...rest } = props;
    if (data)
        return <ReferenceField source="activitytype_id" reference={`${data[rest.record.targetlevel_id]}activitytypes`} link={false} {...rest}>
            <TextField source={locale === 'en' ? "activity" : "activity_ar"} />
        </ReferenceField>;
    else
        return null;
};

const TargetLink = props => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            let query = "";
            Object.values(props.data).forEach((log, i) => {
                if (i === 0)
                    query += "?"
                else
                    query += "&";

                query += "id=" + log[props.target + '_id'];
            });
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/${props.target}s` + query);
            setData(response.json);
        };
        if (props.loaded)
            fetchData();
    }, []);

    if (data.length > 0)
        return <div style={{
            display: 'flex',
            flexWrap: 'wrap'
        }}>
            {data.map((record, key) =>
                <a href={`#/${props.target}s/${record.id}/show`} target="_blank" key={key}
                    style={{ display: 'block', color: 'teal', padding: '0.5em', margin: '1em 0.5em', backgroundColor: "#DDDDDD", borderRadius: '1em' }}>
                    <span>{record.qr_value}</span>
                </a>
            )}
        </div>;
    else
        return null;
}

const WorkerActivity = (props) => {
    const locale = useLocale();
    const { data, source, record, ...rest } = props;

    if (typeof record.activity_id === 'string')
        record.activity_id = JSON.parse(record.activity_id);

    if (data)
        return <ReferenceArrayField reference={`${data[record.targetlevel_id]}logs`} source="activity_id" {...rest} record={record}>
            <TargetLink target={data[record.targetlevel_id]} />
        </ReferenceArrayField>;
    else
        return null;
};
const WorkerName = ({ record }) => {
    const locale = useLocale();
    return <span>{record.name}</span>;
}
export const WorkerShow = (props) => {
    const locale = useLocale()
    const [data, setData] = useState(null);

    const [permissions, setPermissions] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/organizations/check`);
            setPermissions(response.json);
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchTargetlevels = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/targetlevels`);
            const targetlevelCodes = {};
            response.json.forEach(targetlevel => targetlevelCodes[targetlevel.id] = targetlevel.code);
            setData(targetlevelCodes);
        };

        fetchTargetlevels();
    }, []);

    return (
        <Show {...props} title={<WorkerName />} >
            <TabbedShowLayout>
                <Tab label={locale === 'en' ? "Activities" : "السجلات"}>
                    <ReferenceManyField perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} reference="workerlogs" target="worker_id" addLabel={false}>
                        <Datagrid expand={<WorkerActivity data={data} />}>
                              
                            <ActivityTypeField source={locale === 'en' ? "Activity" : "الإجراء"} data={data} />
                            <ReferenceField source="targetlevel_id" reference="targetlevels" link={false} allowEmpty={true} >
                                <TextField source={locale === 'en' ? "name" : "name_ar"} />
                            </ReferenceField>
                            <TextField source="hours" />
                            <TextField source="rating" />
                            {permissions.is_palm_tree && <TextField source="palms_number" />}
                            <TextField source="amount" label={locale === 'en' ? 'Amount KG' : 'الكمية كيلوجرام'} />
                            <TextField source="notes" />
                            <ReferenceField source="evaluator_id" reference="users" allowEmpty={true} >
                                <TextField source="username" />
                            </ReferenceField>
                            <DateField source="created_at" locales="en-GB" showTime />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
            </TabbedShowLayout>
        </Show >
    )
};

