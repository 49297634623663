import React from "react";
import {
  AppBar as MuiAppBar,
  Hidden,
  IconButton,
  Box,
  Tooltip,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { LoadingIndicator } from "react-admin";
import HeaderLogo from "./HeaderLogo";
import LocaleSwitcher from "./LocaleSwitcher";
import UserMenu from "./UserMenu";
import projectLogo from "../assets/images/logo.svg";
import { NotificationIcon } from "../../theme/components/icons/Notification";
import clsx from "clsx";
import { toggleSidebar, useTranslate } from "ra-core";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Popover from "./Popover";
import Cookies from "../../../src/helpers/Cookies";
import MenuOpenRoundedIcon from "@material-ui/icons/MenuOpenRounded";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
const currentLocale = Cookies.getCookie("locale");
const AppBar = (props) => {
  const {
    children,
    classes: classesOverride,
    className,
    color = "secondary",
    logo,
    logout,
    open,
    title,
    userMenu,
    record,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const translate = useTranslate();
  return (
    <MuiAppBar
      className={
        currentLocale === "en"
          ? clsx("app-header app-header-collapsed-sidebar")
          : clsx("app-header app-header-collapsed-sidebar-rtl")
      }
      color={color}
      {...rest}
    >
      <HeaderLogo sidebarToggle={!open} />
      <Box className="app-header-toolbar">
        <Hidden lgUp>
          <Box
            className="app-logo-wrapper"
            title="Carolina React Admin Dashboard with Material-UI PRO"
          >
            <Link to="/" className="app-logo-link">
              <IconButton
                color="primary"
                size="medium"
                className="app-logo-btn"
              >
                <img
                  className="app-logo-img"
                  alt="The Platform"
                  src={projectLogo}
                />
              </IconButton>
            </Link>
            <Hidden smDown>
              <Box className="app-logo-text">The Platform</Box>
            </Hidden>
          </Box>
        </Hidden>
        <Hidden mdDown>
          <Box className="d-flex align-items-center">
            <Box
              className={clsx("btn-toggle-collapse", {
                "btn-toggle-collapse-closed": !open,
              })}
            >
              <Tooltip title="Toggle Sidebar" placement="right">
                <IconButton
                  color="inherit"
                  onClick={() => dispatch(toggleSidebar())}
                  size="medium"
                  className="btn-inverse"
                >
                  {!open ? <MenuRoundedIcon /> : <MenuOpenRoundedIcon />}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Hidden>
        <Box className="d-flex align-items-center">
          {/* <Popover /> */}
          <LocaleSwitcher />
          <LoadingIndicator />
          <UserMenu />
          <Box className="toggle-sidebar-btn-mobile">
            <Tooltip title="Toggle Sidebar" placement="right">
              <IconButton
                color="inherit"
                onClick={() => dispatch(toggleSidebar())}
                size="medium"
              >
                {open ? <MenuOpenRoundedIcon /> : <MenuRoundedIcon />}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </MuiAppBar>
  );
};

AppBar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "default",
    "inherit",
    "primary",
    "secondary",
    "transparent",
  ]),
  logout: PropTypes.element,
  open: PropTypes.bool,
  userMenu: PropTypes.element,
  record:PropTypes.object
};

export default AppBar;
