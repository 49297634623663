import React from 'react';
import {
    Create, Edit, SimpleForm, TextInput, ShowButton, SelectInput,
    EditButton, Datagrid, TextField, ImageField, Show, ReferenceInput,
    TabbedShowLayout, Tab, ReferenceManyField, ReferenceField, useLocale, Pagination
} from 'react-admin';
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import List from './theme/components/List'

import { MyImageField, MyImageInput } from './helpers/iconComponent';
import { withStyles } from "@material-ui/core/styles";
import { EntityLocation, LaunchIconLink } from './entityLocation'
import clsx from 'clsx';
const styles = {
    image: { maxHeight: '1.4rem' }
}

export const DeviceTypeList = withStyles(styles)(({ classes, ...props }) => {
    const locale = useLocale()
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} title={locale === 'en' ? "Sensors" : "المستشعرات"} {...props}>
            <Datagrid>
                  
                <MyImageField source="icon_id" />
                <TextField source={locale === 'en' ? "type" : "type_ar"} />
                <EditButton />
                <ShowButton />
            </Datagrid>
        </List>
    )
});

export const DeviceTypeEdit = props => {
    const locale = useLocale()
    return (
        <Edit undoable={false} title={(locale === 'en' ? "Edit Sensor #" : "تعديل المستشعر #") + props.id} {...props}>
            <SimpleForm redirect="list" toolbar={<CustomToolbar />} className={clsx('tab-rtl-layout')}>
                <TextInput disabled source="id" />
                <TextInput source="type" />
                <TextInput source="type_ar" />
                <MyImageInput />
                <ReferenceInput perPage={-1} reference="decodingtemplates" source="template_id">
                    <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    )
};

export const DeviceTypeCreate = props => {
    const locale = useLocale()
    return (
        <Create title={(locale === 'en' ? "Create Sensor" : "إنشاء المستشعر")} {...props}>
            <SimpleForm redirect="list" className={clsx('tab-rtl-layout')}>
                <TextInput source="type" />
                <TextInput source="type_ar" />
                <MyImageInput />
                <ReferenceInput perPage={-1} reference="decodingtemplates" source="template_id">
                    <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    )
};
const DeviceTypeName = ({ record }) => {
    const locale = useLocale();
    return <span>{record ? (locale === 'en' ? record.type : record.type_ar) : ''}</span>;
}
export const DeviceTypeShow = (props) => {
    const locale = useLocale();
    return (
        <Show {...props} title={<DeviceTypeName />}>
            <TabbedShowLayout>
                <Tab label={locale === 'en' ? "Sensors" : "المستشعرات"}>
                    <ReferenceManyField perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} target="devicetype_id" reference="devices" addLabel={false}>
                        <Datagrid>
                              
                            <LaunchIconLink source={locale === 'en' ? "Location" : "الموقع"} tab={3} />
                            <TextField source="qr_value" />
                            <TextField source="deviceuid" />
                            <ReferenceField source="devicetype_id" reference="devicetypes" allowEmpty={true}>
                                <TextField source={locale === 'en' ? "type" : "type_ar"} />
                            </ReferenceField>
                            <ReferenceField source="farm_id" reference="farms" allowEmpty={true}>
                                <TextField source="farm_code" />
                            </ReferenceField>
                            <EditButton />
                            <ShowButton />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                <Tab label={locale === 'en' ? "summary" : "الملخص"} contentClassName={clsx('tab-rtl-layout')}>
                      
                    <TextField source={locale === 'en' ? "type" : "type_ar"} />
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
};
