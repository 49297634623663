import React, { Fragment, useEffect } from 'react';
import httpClient from './httpClient';
import { useLocale } from 'ra-core';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import * as jsPDF from 'jspdf'

import FormControlLabel from '@material-ui/core/FormControlLabel';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginTop: '2em',
        minWidth: 120,
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
            marginTop: '2em',
            minWidth: 120,
        },
    },
}));


const arabicLabel = {
    Farm: 'المزرعة',
    Pivot: 'قطاع الري المحوري',
    Block: 'الحوشة'
}

const MyDropDown = ({ classes, label_id, value, setValue, data, param }) => {
    const locale = useLocale();
    return <FormControl variant="filled" className={classes.formControl}>
        <InputLabel id={label_id}>{arabicLabel[label_id] && locale === 'ar' ? arabicLabel[label_id] : label_id}</InputLabel>
        <Select
            labelId={label_id}
            id={label_id}
            value={value}
            onChange={(event) => {
                setValue(event.target.value);
            }}
        >
            {data.map((option, key) =>
                <MenuItem key={key} value={option.id}>{option[param]}</MenuItem>
            )}
        </Select>
    </FormControl>;
}

const entities = {
    Traps: 'مصائد',
    Assets: 'أصول ثابتة',
    Inventories: 'مخازن'
}

const qrNaming = {
    Traps: 'Trap',
    Assets: 'Asset',
    Inventories: 'Inv'
}

const formatSingleDigit = (num) => {
    if (num < 10) return '0' + num;
    else return num;
}

const generateFile = async (codes) => {

    var QRCode = require('qrcode');

    let QrCodes = [];
    for (let code of codes) {
        QRCode.toDataURL(code, {
            errorCorrectionLevel: 'H',
            width: 200,
            margin: 2
        }, function (err, url) {
            QrCodes.push({
                url: url,
                code: code
            });
        });
    }

    const imagesPromise = QrCodes.map(code => new Promise((resolve, reject) => {
        const img = new Image();
        img.onerror = () => reject(new Error('Couldn\'t load image'));
        img.onload = () => resolve({ img: img, code: code.code });
        img.src = code.url;
    }));


    const canvas = window.document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = 200;
    canvas.height = 250;
    ctx.globalAlpha = 1;

    const images = await Promise.all(imagesPromise);

    QrCodes = images.map(({ img, code }) => {
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        ctx.drawImage(img, 0, 0);

        ctx.font = '20px Arial';
        ctx.fillStyle = 'black';
        const qrText = code;
        const textWidth = ctx.measureText(qrText).width;
        const textLeftOffset = Math.max(canvas.width * 0.05, (canvas.width / 2) - (textWidth / 2));
        ctx.fillText(qrText, textLeftOffset, 225, canvas.width * 0.9);
        const canvasURL = canvas.toDataURL('image/png', 0.92);
        return canvasURL;
    });

    const doc = new jsPDF({
        unit: 'in'
    });

    const pageWidth = 8.27;
    const pageHeight = 11.69;
    const rows = 6;
    const cols = 5;
    const imageWidth = (pageWidth / cols) * 0.8;
    const imageHeight = imageWidth * (canvas.height / canvas.width);

    function generatePDF() {
        let code = 0;

        while (code < QrCodes.length) {
            for (let i = 0; i < rows; i++) {
                for (let j = 0; j < cols; j++) {
                    const leftOffset = (pageWidth / cols) * j + (pageWidth / cols) * 0.1;
                    const upOffset = (pageHeight / rows) * i + (pageWidth / cols) * 0.1;
                    doc.addImage(QrCodes[code++], 'PNG', leftOffset, upOffset, imageWidth, imageHeight);
                    if (code === QrCodes.length)
                        return;
                }
            }
            doc.addPage();
        }
    }

    generatePDF();

    doc.save(`codes.pdf`);
}

const generateCodes = async (type, farm, subFarm, amount) => {
    const farmRecord = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/farms/${farm}`);
    const farmCode = farmRecord.json.farm_code.slice(farmRecord.json.farm_code.indexOf('-') + 1);

    let subFarmCode;
    if (subFarm) {
        const subFarmRecord = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/blocks/${subFarm}`);
        subFarmCode = subFarmRecord.json.qr_value.slice(subFarmRecord.json.qr_value.indexOf('-') + 1);
    }

    const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/qrcodes?farm=${farmCode}` +
        (!subFarm ? '' : `&subfarm=${subFarmCode}`) + `&type=${type}`);

    const codes = [];
    let start = 1;

    if (response.json.length > 0) {
        start = response.json[0].last_id
    }

    const code = `${qrNaming[type]}-${farmCode}-` + (!subFarm ? '' : `${subFarmCode}-`);

    for (let i = start; i < start + amount; i++) {
        codes.push(code + formatSingleDigit(i));
    }

    generateFile(codes);

    if (response.json.length > 0) {
        httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/qrcodes/${response.json[0].id}`, {
            method: 'PUT',
            body: JSON.stringify({ last_id: start + amount })
        })
    }
    else {
        httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/qrcodes`, {
            method: 'POST',
            body: JSON.stringify({
                last_id: start + amount,
                farm: farmCode,
                subfarm: subFarmCode,
                type: type
            })
        })
    }
}

const generateCurrentCodes = async (type, farm, subFarm) => {
    const resource = type.toLowerCase();
    const list = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/${resource}?farm_id=${farm}` +
        (!subFarm ? '' : `&block_id=${subFarm}`));
    const codes = list.json.map(entry => entry.qr_value);
    generateFile(codes);
}

const GenerateQR = props => {
    const locale = useLocale();
    const classes = useStyles();

    const [type, setType] = React.useState(null);
    const [farm, setFarm] = React.useState('');
    const [farmList, setFarmList] = React.useState([]);
    const [subFarm, setSubFarm] = React.useState('');
    const [subFarmList, setSubFarmList] = React.useState([]);
    const [amount, setAmount] = React.useState(0);
    const [option, setOption] = React.useState("Existing");
    useEffect(() => {
        const fetchFarms = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/farms?_limit=-1`);
            setFarmList(response.json);
            setSubFarm('');
            setAmount(0);
        }
        if (type)
            fetchFarms();
    }, [type]);

    useEffect(() => {
        const fetchSubFarms = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/blocks?farm_id=${farm}&_limit=-1`);
            setSubFarmList(response.json);
            setSubFarm('');
            setAmount(0);
        }
        if (farm && type !== 'Inventories')
            fetchSubFarms();
    }, [farm]);

    return <Fragment>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5em' }}>
            {Object.keys(entities).map(entity => {
                return <Button
                    key={entity}
                    variant="contained"
                    color={type && type !== entity ? "default" : "primary"}
                    style={{ margin: '1em', padding: '1em 4em' }}
                    onClick={() => setType(entity)}
                >
                    {locale === 'en' ? entity : entities[entity]}
                </Button>
            })}
        </div>
        {type &&
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <MyDropDown data={farmList} value={farm} setValue={setFarm} label_id='Farm' param="farm_code" classes={classes} />
                {type !== 'Inventories' && <MyDropDown data={subFarmList} value={subFarm} setValue={setSubFarm} label_id='Block' param="qr_value" classes={classes} />}
            </div>
        }
        {subFarm || (farm && type === 'Inventories') ?
            <Fragment>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <FormControl component="fieldset">
                        <RadioGroup row aria-label="index" name="index" value={option}
                            onChange={(event) => { setOption(event.target.value) }}>
                            <FormControlLabel style={{ margin: '1em' }} value={"Existing"} control={<Radio />} label={locale === 'en' ? "Existing" : "حالي"} />
                            <FormControlLabel style={{ margin: '1em' }} value={"New"} control={<Radio />} label={locale === 'en' ? "New" : "جديد"} />
                        </RadioGroup>
                    </FormControl>
                </div>
                {option === "Existing" &&
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ margin: '1em', padding: '1em 4em' }}
                            onClick={() => generateCurrentCodes(type, farm, subFarm)}
                        >
                            {locale === 'en' ? "Generate" : "إنشاء"}
                        </Button>
                    </div>
                }
                {option === "New" && <Fragment>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <form className={classes.root} noValidate autoComplete="off">
                            <TextField id="filled-basic" label={locale === 'en' ? "Amount" : "العدد"} variant="filled" defaultValue="0"
                                onChange={(e) => { setAmount(e.target.value) }} />
                        </form>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            disabled={!isNaN(amount) && amount > 0 ? false : true}
                            color="primary"
                            style={{ margin: '1em', padding: '1em 4em' }}
                            onClick={() => generateCodes(type, farm, subFarm, parseInt(amount, 10))}
                        >
                            {locale === 'en' ? "Generate" : "إنشاء"}
                        </Button>
                    </div>
                </Fragment>}
            </Fragment>
            : null
        }


    </Fragment>
}

export default GenerateQR;