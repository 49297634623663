import React, { Fragment } from 'react';
import {
    Create, Edit, SimpleForm, TextInput, useLocale, ReferenceField,
    EditButton, Datagrid, TextField, Pagination, ReferenceInput,
    SelectInput, ReferenceArrayField, SingleFieldList, ChipField, FormDataConsumer,
    ReferenceArrayInput, SelectArrayInput
} from 'react-admin';
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import { useForm } from 'react-final-form';
import List from './theme/components/List'
import clsx from 'clsx';
const AttributesField = (props) => {
    const locale = useLocale();
    if (props.record.attributes_ids) {
        const record = { ...props.record };
        try {
            record.attributes_ids = JSON.parse(record.attributes_ids);
            return <ReferenceArrayField reference="scoutingattributes" {...props} record={record}>
                <SingleFieldList>
                    <ChipField source={locale === 'en' ? 'name' : 'name_ar'} />
                </SingleFieldList>
            </ReferenceArrayField>
        }
        catch (e) {
            return null;
        }
    }
    else
        return null;
}

export const GrowthStageAttributeList = props => {
    const locale = useLocale();
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}{...props}>
            <Datagrid>
                  
                <ReferenceField source="plant_id" reference="pivotcroptypes" allowEmpty >
                    <TextField source={locale === 'en' ? 'name' : 'name_ar'} />
                </ReferenceField>
                <ReferenceField source="growthstage_id" reference="growthstages" allowEmpty >
                    <TextField source={locale === 'en' ? 'name' : 'name_ar'} />
                </ReferenceField>
                <AttributesField source="attributes_ids" />
                <EditButton />
            </Datagrid>
        </List>
    )
};

const FormHook = ({ formData, ...rest }) => {
    const form = useForm();
    const locale = useLocale();
    return <Fragment>
        <ReferenceInput perPage={-1} reference="pivotcroptypes" source="plant_id"
            onChange={() => form.change('growthstage_id', null)} {...rest} >
            <SelectInput optionText={locale === 'en' ? 'name' : 'name_ar'} />
        </ReferenceInput>
        {formData.plant_id &&
            <Fragment >
                <br />
                <ReferenceInput perPage={-1} reference="growthstages" source="growthstage_id"
                    filter={{ plant_id: formData.plant_id }} {...rest}>
                    <SelectInput optionText={locale === 'en' ? 'name' : 'name_ar'} />
                </ReferenceInput>
            </Fragment>
        }
        <br />
        <ReferenceArrayInput perPage={-1} source="attributes_ids" label={locale === 'en' ? "Attributes" : "المدخلات"} reference="scoutingattributes"
            parse={v => {
                return JSON.stringify(v);
            }}
            format={v => {
                let parsedArray = v;
                    try {
                        parsedArray = JSON.parse(v);
                    }
                    catch (e) {
                        if (typeof v === 'string')
                            parsedArray = [];
                    }
                    return parsedArray;
            }}
            {...rest}>
            <SelectArrayInput optionText={locale === 'en' ? 'name' : 'name_ar'} />
        </ReferenceArrayInput>
    </Fragment>
}

export const GrowthStageAttributeEdit = props => {
    return (
        <Edit undoable={false} {...props} className={clsx('tab-rtl-layout')}>
            <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
                <TextInput disabled source="id" />
                <FormDataConsumer>
                    {(props) =>
                        <FormHook {...props} />
                    }
                </FormDataConsumer>
            </SimpleForm>
        </Edit>
    )
};

export const GrowthStageAttributeCreate = props => {
    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <FormDataConsumer>
                    {(props) =>
                        <FormHook {...props} />
                    }
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    )
};
