import React, { Fragment } from 'react';
import Cookies from './../../helpers/Cookies';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useLocale } from 'react-admin';
import { IconButton, Box } from '@material-ui/core';

import projectLogo from '../assets/images/logo.svg';
const currentLocale = Cookies.getCookie("locale");

const HeaderLogo = props => {
    const { sidebarToggle } = props;
    const locale = useLocale();
    return (
        <Fragment >
            <div
                className={clsx(locale === 'ar' ? 'app-header-logo-rtl' : 'app-header-logo', {
                    'app-header-logo-close': sidebarToggle,
                })}>
                <Box
                    className="header-logo-wrapper"
                    flexDirection={currentLocale === "ar" ? "row-reverse" : null}
                    title="The Platform">
                    <Link to="/" className="header-logo-wrapper-link">
                        <IconButton
                            color="primary"
                            size="medium"
                            className="header-logo-wrapper-btn">
                            <img
                                className="app-header-logo-img"
                                alt="The Platform"
                                src={projectLogo}
                            />
                        </IconButton>
                    </Link>
                    <Box className="header-logo-text">The Platform</Box>

                </Box>
            </div>
        </Fragment>
    );
};

export default HeaderLogo;
