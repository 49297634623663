import React from "react";
import { SvgIcon } from "@material-ui/core";

export const SensorIcon = () => {
  return (
    <SvgIcon
      enable-background="new 0 0 300 300"
      height="512"
      viewBox="0 0 300 300"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <circle cx="184" cy="184" fill="#a9f8f6" r="86" />
        <g fill="#4c241d">
          <circle cx="258" cy="222" r="4" />
          <circle cx="254" cy="182" r="4" />
          <path d="m202.609 269.391c-1.023 0-2.047-.391-2.828-1.172-1.562-1.562-1.562-4.094 0-5.656l14.781-14.781c1.562-1.562 4.094-1.562 5.656 0s1.562 4.094 0 5.656l-14.781 14.781c-.781.781-1.804 1.172-2.828 1.172z" />
          <path d="m217.391 269.391c-1.023 0-2.047-.391-2.828-1.172l-14.781-14.781c-1.562-1.562-1.562-4.094 0-5.656s4.094-1.562 5.656 0l14.781 14.781c1.562 1.562 1.562 4.094 0 5.656-.781.781-1.805 1.172-2.828 1.172z" />
          <path d="m100.43 72.518c-.922 0-1.848-.316-2.602-.963-1.656-1.416-1.867-3.891-.496-5.568.215-.297 5.469-7.846-.141-27.193-.613-2.121.609-4.34 2.73-4.955 2.125-.609 4.344.609 4.957 2.729 6.922 23.9-.539 33.533-1.41 34.553-.792.922-1.913 1.397-3.038 1.397z" />
          <path d="m100.914 74.67c-1.469 0-2.879-.809-3.578-2.207-.992-1.977-.191-4.379 1.781-5.369 18.582-9.312 16.156-27.588 16.043-28.361-.316-2.18 1.188-4.215 3.367-4.541 2.176-.336 4.207 1.164 4.543 3.338 1.312 8.51-1.254 27.137-20.367 36.717-.574.286-1.187.423-1.789.423z" />
          <path d="m133.285 98.906c-2.078 0-3.828-1.604-3.984-3.705-.008-.111-.949-11.182-8.672-17.188-4.68-3.637-11.07-4.779-19.035-3.402-2.184.365-4.242-1.082-4.625-3.256-.379-2.178 1.082-4.248 3.258-4.627 10.297-1.787 18.836-.102 25.375 5.02 10.527 8.238 11.637 22.281 11.676 22.875.156 2.205-1.504 4.117-3.707 4.273-.094.006-.192.01-.286.01z" />
          <path d="m115.23 125.41c-.422 0-.852-.066-1.277-.211-2.094-.703-3.219-2.973-2.512-5.066 2.766-8.234 8.352-31.545 1.043-40.41-2.484-3.012-6.465-4.111-12.152-3.342-2.191.275-4.203-1.248-4.496-3.436-.293-2.189 1.246-4.201 3.434-4.494 8.551-1.143 15.07.939 19.391 6.188 11.426 13.863 1.527 44.592.359 48.049-.563 1.669-2.118 2.722-3.79 2.722z" />
          <path d="m74.121 78.381c-.535 0-1.086-.111-1.609-.35-1.227-.551-12.082-6.096-15.973-30.674-.348-2.182 1.141-4.23 3.324-4.576 2.191-.336 4.227 1.145 4.574 3.326 3.203 20.203 11.367 24.631 11.449 24.674 1.926.994 2.762 3.365 1.82 5.318-.69 1.432-2.101 2.282-3.585 2.282z" />
          <path d="m74.633 80.537c-.031 0-.066 0-.102-.002-21.375-.541-31.621-16.308-34.051-24.57-.625-2.119.586-4.342 2.707-4.967 2.117-.621 4.332.586 4.965 2.699.254.836 5.855 18.316 26.582 18.842 2.207.055 3.953 1.891 3.898 4.1-.054 2.173-1.835 3.898-3.999 3.898z" />
          <path d="m55.633 116.244c-1.605 0-3.117-.975-3.73-2.561-.215-.557-5.184-13.74.84-25.67 3.746-7.416 10.754-12.574 20.836-15.334 2.109-.578 4.328.67 4.914 2.801.582 2.131-.672 4.332-2.805 4.914-7.805 2.137-13.121 5.914-15.805 11.225-4.438 8.793-.559 19.08-.52 19.182.797 2.061-.23 4.377-2.289 5.174-.472.181-.961.269-1.441.269z" />
          <path d="m83.246 132.551c-1.051 0-2.102-.412-2.887-1.23-2.527-2.635-24.555-26.236-20.109-43.645 1.684-6.588 6.703-11.246 14.918-13.846 2.121-.664 4.355.504 5.02 2.607.668 2.107-.5 4.355-2.605 5.021-5.48 1.732-8.613 4.414-9.582 8.197-2.848 11.156 12.125 29.861 18.133 36.125 1.531 1.596 1.477 4.127-.117 5.656-.779.746-1.775 1.115-2.771 1.115z" />
          <path d="m147.414 250c-1.148 0-2.285-.49-3.078-1.439-1.414-1.697-1.184-4.219.512-5.633 20.727-17.271 64.758-18.127 80.469-17.812-7.805-32.051 10.129-68.27 15.703-78.449-22.566-23.939-19.871-61.568-17.754-76.26-14.484 2.795-51.695 6.914-78.516-16.947-1.648-1.469-1.797-3.996-.328-5.646 1.469-1.654 4.004-1.793 5.648-.33 29.941 26.643 76.613 13.973 77.082 13.842 1.355-.377 2.809-.02 3.824.945 1.023.963 1.465 2.393 1.164 3.766-.109.506-10.785 50.979 16.617 77.068 1.355 1.291 1.633 3.35.672 4.955-.27.451-26.879 45.447-14.941 79.928.434 1.26.215 2.654-.59 3.717-.805 1.064-2.078 1.654-3.414 1.586-.562-.029-58.004-2.973-80.512 15.783-.749.621-1.656.926-2.558.926z" />
          <path d="m146.004 211.734c-1.148 0-2.285-.49-3.078-1.439-1.414-1.697-1.184-4.219.512-5.633 12.207-10.17 36.965-11.359 48.031-11.225-3.566-18.727 5.852-39.461 9.621-46.766-12.363-13.875-11.926-33.963-10.766-43.893-10.199 1.633-31.359 2.844-46.984-11.055-1.648-1.469-1.797-3.996-.328-5.646 1.465-1.652 3.992-1.801 5.648-.33 17.562 15.631 45.172 8.162 45.445 8.086 1.355-.373 2.812-.018 3.832.955 1.023.969 1.461 2.402 1.152 3.775-.066.291-6.34 29.295 9.668 44.539 1.34 1.275 1.629 3.309.699 4.908-.16.277-16.016 27.936-8.984 48.246.434 1.26.215 2.654-.59 3.717-.805 1.066-2.145 1.637-3.41 1.586-.359-.02-34.695-1.77-47.91 9.246-.75.625-1.656.929-2.558.929z" />
          <path d="m146 262c-2.211 0-4-1.791-4-4v-224c0-2.209 1.789-4 4-4s4 1.791 4 4v224c0 2.209-1.789 4-4 4z" />
          <path d="m258 150h-224c-2.211 0-4-1.791-4-4s1.789-4 4-4h224c2.211 0 4 1.791 4 4s-1.789 4-4 4z" />
          <path d="m238 242c-1.023 0-2.047-.391-2.828-1.172l-92-92c-1.562-1.562-1.562-4.094 0-5.656s4.094-1.562 5.656 0l92 92c1.562 1.562 1.562 4.094 0 5.656-.781.781-1.805 1.172-2.828 1.172z" />
          <path d="m54 242c-1.023 0-2.047-.391-2.828-1.172-1.562-1.562-1.562-4.094 0-5.656l184-184c1.562-1.562 4.094-1.562 5.656 0s1.562 4.094 0 5.656l-184 184c-.781.781-1.805 1.172-2.828 1.172z" />
        </g>
        <g>
          <circle cx="87.038" cy="70.327" fill="#b5a19c" r="12" />
          <path
            d="m87.008 86.332c-3.004 0-5.949-.85-8.547-2.498-3.609-2.291-6.109-5.852-7.035-10.023-.934-4.168-.184-8.453 2.105-12.061 2.293-3.609 5.852-6.107 10.023-7.039 8.594-1.912 17.176 3.52 19.098 12.129.934 4.172.184 8.455-2.105 12.064-2.293 3.607-5.852 6.107-10.023 7.039-1.169.26-2.348.389-3.516.389zm.031-24.006c-.574 0-1.156.062-1.742.193-2.086.465-3.867 1.715-5.012 3.52-1.145 1.803-1.52 3.945-1.055 6.029.465 2.088 1.715 3.867 3.52 5.012 1.797 1.141 3.926 1.52 6.031 1.055 2.086-.465 3.867-1.717 5.012-3.52 1.145-1.805 1.52-3.945 1.055-6.029-.832-3.723-4.145-6.26-7.809-6.26z"
            fill="#4c241d"
          />
        </g>
        <path
          d="m145.996 250c-.902 0-1.809-.305-2.559-.928-22.527-18.773-79.934-15.814-80.508-15.783-1.336.072-2.609-.52-3.414-1.584s-1.023-2.459-.59-3.719c11.934-34.48-14.676-79.477-14.945-79.928-1.137-1.893-.523-4.35 1.371-5.486 1.895-1.145 4.352-.523 5.488 1.367 1.125 1.873 26.242 44.283 17.258 81.176 15.656-.324 59.738.541 80.465 17.812 1.695 1.414 1.926 3.936.512 5.633-.793.95-1.929 1.44-3.078 1.44z"
          fill="#4c241d"
        />
        <path
          d="m145.996 211.734c-.902 0-1.809-.305-2.559-.928-13.215-11.016-47.566-9.268-47.91-9.246-1.246.055-2.605-.52-3.41-1.586-.805-1.062-1.023-2.457-.59-3.717 7.051-20.365-8.82-47.967-8.984-48.244-1.109-1.908-.465-4.357 1.441-5.469 1.91-1.109 4.355-.467 5.473 1.441.676 1.162 15.391 26.777 11.074 49.451 11.219-.115 35.82 1.053 48.031 11.225 1.695 1.414 1.926 3.936.512 5.633-.793.95-1.929 1.44-3.078 1.44z"
          fill="#4c241d"
        />
        <path
          d="m86 150c-1.055 0-2.109-.416-2.898-1.242-1.523-1.6-1.461-4.131.141-5.654 9.246-8.801 11.059-22.158 10.949-31.814-.023-2.209 1.746-4.02 3.957-4.045h.043c2.188 0 3.977 1.762 4 3.955.125 11.244-2.105 26.912-13.434 37.697-.774.738-1.766 1.103-2.758 1.103z"
          fill="#4c241d"
        />
        <g>
          <path
            d="m106.14 82.456c-2.407-10.78-9.606-10.151-18.23-8.226s-15.408 4.417-13.001 15.198 13.091 25.767 21.716 23.842c8.624-1.926 11.921-20.033 9.515-30.814z"
            fill="#6b4f5b"
          />
          <path
            d="m95.082 117.438c-4.801 0-9.941-3.035-14.719-8.762-4.426-5.305-8.012-12.346-9.355-18.377-3.488-15.615 9.211-18.449 16.031-19.973 4.27-.953 9.172-1.859 13.465-.58 4.902 1.465 8.113 5.449 9.539 11.84 2.777 12.432-.812 32.967-12.547 35.588-.793.176-1.598.264-2.414.264zm.711-40.327c-2.066 0-4.555.475-7.012 1.023-9.629 2.15-11.402 4-9.969 10.422 1.07 4.789 4.09 10.676 7.695 14.994 3.41 4.09 6.945 6.338 9.246 5.814 5.145-1.148 8.781-15.748 6.484-26.037-1.129-5.051-3.16-5.658-4.027-5.916-.702-.21-1.519-.3-2.417-.3z"
            fill="#4c241d"
          />
        </g>
      </g>
    </SvgIcon>
  );
};
