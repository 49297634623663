import React from 'react';
import { Admin, Resource, Login,ShowGuesser } from 'react-admin';
import simpleRestProvider from './ra-strapi-rest';
import authProvider from './authProvider';
import httpClient from './httpClient';
import Cookies from './helpers/Cookies';
import Popover from './theme/components/Popover'
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// import MenuBookIcon from '@material-ui/icons/MenuBook';
// import ListAltIcon from '@material-ui/icons/ListAlt';
// import GroupIcon from '@material-ui/icons/Group';
// import HomeWorkIcon from '@material-ui/icons/HomeWork';
// import AppsIcon from '@material-ui/icons/Apps';
// import ClassIcon from '@material-ui/icons/Class';

import { ActionList } from './actions';
import { BlockList, BlockEdit, BlockCreate, BlockShow } from './blocks';
import { PalmList, PalmEdit, PalmShow } from './palms';
import { ProgramList, ProgramEdit, ProgramCreate, ProgramShow } from './programs';
import { FarmList, FarmEdit, FarmCreate, FarmShow } from './farms';
import { TrapList, TrapEdit, TrapCreate, TrapShow } from './traps';
import { UserList, UserEdit, UserCreate } from './users'
import { TeamList, TeamEdit, TeamCreate, TeamShow } from './teams'
import { WorkerList, WorkerEdit, WorkerCreate, WorkerShow } from './workers'
import { AssetList, AssetEdit, AssetCreate, AssetShow } from './assets'
import { AssetTypeList, AssetTypeEdit, AssetTypeCreate } from './assettypes'
import { TaskCreate } from './tasks'
import { ChemicalTypeList, ChemicalTypeEdit, ChemicalTypeCreate } from './chemicaltypes'
import { DeviceList, DeviceEdit, DeviceCreate, DeviceShow } from './devices'
import { PestList, PestEdit, PestCreate } from './pests'
import { ActiveProgramList, ActiveProgramShow, ActiveProgramCreate } from './activeprograms'
import { InventoryList, InventoryEdit, InventoryCreate, InventoryShow } from './inventories'
import { InventoryTypeList, InventoryTypeCreate, InventoryTypeEdit } from './inventorytypes'
import { InventoryEventList, InventoryEventCreate } from './inventoryevents'
import { InventoryCategoryList, InventoryCategoryEdit, InventoryCategoryCreate } from './inventorycategories'
import { InventorySubCategoryList, InventorySubCategoryEdit, InventorySubCategoryCreate } from './inventorysubcategories'
import { UnitList, UnitEdit, UnitCreate } from './units'
import { DeviceTypeList, DeviceTypeEdit, DeviceTypeCreate, DeviceTypeShow } from './devicetypes'
import { PalmTypeList, PalmTypeEdit, PalmTypeCreate } from './palmtypes'
import { TrapTypeList, TrapTypeEdit, TrapTypeCreate } from './traptypes'
import { TrapActivityTypeList, TrapActivityTypeEdit, TrapActivityTypeCreate } from './trapactivitytypes'
import { AssetActivityTypeList, AssetActivityTypeEdit, AssetActivityTypeCreate } from './assetactivitytypes'
import { AssetSubActivityTypeList, AssetSubActivityTypeEdit, AssetSubActivityTypeCreate } from './assetsubactivitytypes'
import { BlockActivityTypeList, BlockActivityTypeEdit, BlockActivityTypeCreate } from './blockactivitytypes'
import { PalmActivityTypeList, PalmActivityTypeEdit, PalmActivityTypeCreate } from './palmactivitytypes'
import { LogEventList } from './logevents'
// import { FarmTypeList } from './farmtypes'
import { PivotList, PivotEdit, PivotCreate, PivotShow } from './pivots'
import { PivotActivityTypeList, PivotActivityTypeEdit, PivotActivityTypeCreate } from './pivotactivitytypes'
import { PivotCropTypeList, PivotCropTypeEdit, PivotCropTypeCreate } from './pivotcroptypes'
import { PivotPlantationTypeList, PivotPlantationTypeEdit, PivotPlantationTypeCreate } from './pivotplantationtypes'
import { SectionList, SectionEdit, SectionCreate, SectionShow } from './sections'
import { SectionActivityTypeList, SectionActivityTypeEdit, SectionActivityTypeCreate } from './sectionactivitytypes'
import Menu from './theme/components/Menu';
import Layout from './theme/components/Layout';
import { DeviceVariableList, DeviceVariableEdit, DeviceVariableCreate } from './devicevariables'
import { IconList, IconEdit, IconCreate } from './icons'
import { ScoutingConfigCreate, ScoutingConfigEdit, ScoutingConfigList } from './scoutingconfigs'
import { ScoutingTaskList } from './scoutingtasks'
import { GrowthStageCreate, GrowthStageEdit, GrowthStageList } from './growthstages'
import { GrowthStageAttributeList, GrowthStageAttributeCreate, GrowthStageAttributeEdit } from './growthstageattributes'
import { WeatherAlertConfigList, WeatherAlertConfigCreate, WeatherAlertConfigEdit } from './weather_alert_configurations'
import GenerateQR from './qrcodes'

import englishMessages from 'ra-language-english';
import arabicMessages from 'ra-language-arabic';
import { translation as domainMessages } from './i18n';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import theme from './theme'
import './theme/assets/base.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  fas,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { PalmTreeIcon } from "./theme/components/icons/PalmTree"
import { InventoryIcon } from "./theme/components/icons/Inventory"
import { SettingsIcon } from "./theme/components/icons/Settings"
import { AssetsIcon } from "./theme/components/icons/Assets"
import { ProgrammsIcon } from "./theme/components/icons/Programms"
import { ActionsIcon } from "./theme/components/icons/Actions"
import { TrapIcon } from "./theme/components/icons/Trap"
import { SensorIcon } from "./theme/components/icons/Sensor"
import { InventoryActions } from "./theme/components/icons/InventoryActions"
import { TeamIcon } from "./theme/components/icons/Team"
import { SectionsIcon } from "./theme/components/icons/Sections"
import { PivotsIcon } from "./theme/components/icons/Pivots"
import { LogsIcon } from "./theme/components/icons/Logs"
import { Scouting } from "./theme/components/icons/Scouting"
import { BlocksIcon } from "./theme/components/icons/Blocks"
import { ActiveProgramms } from "./theme/components/icons/ActiveProgramms"
import { FarmIcon } from "./theme/components/icons/Farm"
import { WorkersIcons } from './theme/components/icons/Workers';
import { UserIcon, UsersIcon } from "./theme/components/icons/Users"
import { create } from 'jss';
import rtl from 'jss-rtl';
import JssProvider from 'react-jss/lib/JssProvider';
import { jssPreset } from '@material-ui/core/styles';
import { CentralUnitRedirectModal } from "./redirectCentralUnit";
import {FAOKCProfileCreate, FAOKCProfilesList, KCProfileShow , KCEdit, PalmKCProfileList, PalmKCProfileCreate} from './KCProfiles';
import { CanopyEdit, CanopyProfileCreate, CanopyProfilesList } from './canopy';
library.add(
  fas,
  faAngleDown
)

// switching between local and deployed version
// const dataProvider = simpleRestProvider('http://localhost:1337', httpClient);
const dataProvider = simpleRestProvider(process.env.REACT_APP_DATA_PROVIDER_URL, httpClient);

const MyLoginPage = () => <Login backgroundImage={process.env.PUBLIC_URL + '/Coverpage.jpg'} theme={theme} />;

const messages = {
  ar: { ...arabicMessages, ...domainMessages.ar },
  en: { ...englishMessages, ...domainMessages.en },
};

const i18nProvider = polyglotI18nProvider(locale => messages[locale], Cookies.getCookie('locale') || 'ar');

const customRedirectRoutes = [<Route
  path="/redirect-palm"
  noLayout
  render={(props) => {
    window.open("http://dashboard.platformx.me:8088/", "_blank")
    props.history.goBack()
  }}
//component={Redirect}
/>,
<Route
  path="/redirect_pivot"
  render={(props) => {
    window.open("http://dashboard.platformx.me:8088/", "_blank")
    props.history.goBack()
  }}
//component={Redirect}
/>,
<Route
  path="/modal"
  component={CentralUnitRedirectModal}
/>]
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const App = () => (
  <JssProvider jss={jss}>
    <Admin i18nProvider={i18nProvider} customRoutes={customRedirectRoutes} layout={Layout} menu={Menu} theme={theme} title="The Platform" loginPage={MyLoginPage} dataProvider={dataProvider} authProvider={authProvider} >
      {permissions => [
        <Resource name="farms" list={FarmList} edit={FarmEdit} create={FarmCreate} show={FarmShow} icon={FarmIcon} />,
        <Resource name="redirect" icon={FarmIcon} />,
        <Resource name="blocks" list={BlockList} edit={BlockEdit} create={BlockCreate} show={BlockShow} icon={BlocksIcon} />,
        <Resource name="pivots" list={PivotList} edit={PivotEdit} create={PivotCreate} show={PivotShow} icon={PivotsIcon} />,
        <Resource name="sections" list={SectionList} edit={SectionEdit} create={SectionCreate} show={SectionShow} icon={SectionsIcon} options={{ label: 'Sectors' }} />,
        <Resource name="palms" list={PalmList} edit={PalmEdit} show={PalmShow} icon={PalmTreeIcon} />,
        <Resource name="traps" list={TrapList} edit={TrapEdit} create={TrapCreate} show={TrapShow} icon={SensorIcon} />,
        <Resource name="assets" list={AssetList} edit={AssetEdit} create={AssetCreate} show={AssetShow} icon={AssetsIcon} />,
        <Resource name="devices" list={DeviceList} edit={DeviceEdit} create={DeviceCreate} show={DeviceShow} />,
        <Resource name="devicetypes" list={DeviceTypeList} edit={DeviceTypeEdit} create={DeviceTypeCreate} show={DeviceTypeShow} options={{ label: 'Sensors' }} icon={TrapIcon} />,
        <Resource name="inventories" list={InventoryList} edit={InventoryEdit} create={InventoryCreate} show={InventoryShow} icon={InventoryIcon} />,
        <Resource name="inventoryevents" list={InventoryEventList} create={InventoryEventCreate} options={{ label: 'Inventory Events' }} icon={InventoryActions} />,
        <Resource name="inventoryevents/balance" />,
        <Resource name="scoutingtasks" list={ScoutingTaskList} options={{ label: 'Scouting Tasks' }} icon={Scouting} />,
        <Resource name="users" list={UserList} edit={permissions === 'Admin' ? UserEdit : null} create={permissions === 'Admin' ? UserCreate : null} icon={UsersIcon} options={{ label: 'Supervisors' }} />,
        <Resource name="workers" list={WorkerList} edit={WorkerEdit} create={WorkerCreate} show={WorkerShow} icon={WorkersIcons} options={{ label: 'Labor' }} />,
        <Resource name="teams" list={TeamList} edit={TeamEdit} create={TeamCreate} show={TeamShow} icon={TeamIcon} />,
        <Resource name="programs" list={ProgramList} edit={ProgramEdit} create={ProgramCreate} show={ProgramShow} icon={ProgrammsIcon} />,
        <Resource name="activeprograms" list={ActiveProgramList} create={ActiveProgramCreate} show={ActiveProgramShow} options={{ label: 'Active Programs' }} icon={ActiveProgramms} />,
        <Resource name="actions" list={ActionList} icon={ActionsIcon} options={{ label: 'Running Tasks' }} />,
        <Resource name="logevents" list={LogEventList} options={{ label: 'Log Events' }} icon={LogsIcon} />,
        <Resource name="assetlogs" />,
        <Resource name="qrcodes" list={GenerateQR} options={{ label: 'QR Generation' }} />,
        <Resource name="icons" list={IconList} edit={IconEdit} create={IconCreate} />,
        // <Resource name="farmtypes" list={FarmTypeList} options={{ label: 'Farm Types' }} />,
        <Resource name="pivotcroptypes" list={PivotCropTypeList} edit={PivotCropTypeEdit} create={PivotCropTypeCreate} options={{ label: 'Crop Types' }} />,
        <Resource name="pivotplantationtypes" list={PivotPlantationTypeList} edit={PivotPlantationTypeEdit} create={PivotPlantationTypeCreate} options={{ label: 'Specie Types' }} />,
        <Resource name="assettypes" list={AssetTypeList} edit={AssetTypeEdit} create={AssetTypeCreate} options={{ label: 'Asset Types' }} />,
        <Resource name="palmtypes" list={PalmTypeList} edit={PalmTypeEdit} create={PalmTypeCreate} options={{ label: 'Palm Types' }} />,
        <Resource name="traptypes" list={TrapTypeList} edit={TrapTypeEdit} create={TrapTypeCreate} options={{ label: 'Trap Types' }} />,
        <Resource name="assetactivitytypes" list={AssetActivityTypeList} edit={AssetActivityTypeEdit} create={AssetActivityTypeCreate} options={{ label: 'Asset Activity Types' }} />,
        <Resource name="assetsubactivitytypes" list={AssetSubActivityTypeList} edit={AssetSubActivityTypeEdit} create={AssetSubActivityTypeCreate} options={{ label: 'Asset Sub Activity Types' }} />,
        <Resource name="blocklogs" />,
        <Resource name="blockactivitytypes" list={BlockActivityTypeList} edit={BlockActivityTypeEdit} create={BlockActivityTypeCreate} options={{ label: 'Block Activity Types' }} />,
        <Resource name="palmlogs" />,
        <Resource name="palmactivitytypes" list={PalmActivityTypeList} edit={PalmActivityTypeEdit} create={PalmActivityTypeCreate} options={{ label: 'Palm Activity Types' }} />,
        <Resource name="pivotactivitytypes" list={PivotActivityTypeList} edit={PivotActivityTypeEdit} create={PivotActivityTypeCreate} options={{ label: 'Pivot Activity Types' }} />,
        <Resource name="sectionactivitytypes" list={SectionActivityTypeList} edit={SectionActivityTypeEdit} create={SectionActivityTypeCreate} options={{ label: 'Section Activity Types' }} />,
        <Resource name="tasks" create={TaskCreate} />,
        <Resource name="targetlevels" />,
        <Resource name="traplogs" />,
        <Resource name="infestationtechniques" />,
        <Resource name="trapactivitytypes" list={TrapActivityTypeList} edit={TrapActivityTypeEdit} create={TrapActivityTypeCreate} options={{ label: 'Trap Activity Types' }} />,
        <Resource name="devicedata" />,
        <Resource name="chemicaltypes" list={ChemicalTypeList} edit={ChemicalTypeEdit} create={ChemicalTypeCreate} options={{ label: 'Chemical Types' }} />,
        <Resource name="devicevariables" list={DeviceVariableList} edit={DeviceVariableEdit} create={DeviceVariableCreate} options={{ label: 'Device Variables' }} />,
        <Resource name="units" list={UnitList} edit={UnitEdit} create={UnitCreate} />,
        <Resource name="kcprofiles" list={FAOKCProfilesList} create={FAOKCProfileCreate} edit={KCEdit}/>,
        <Resource name="palmkcprofiles" list={PalmKCProfileList} create={PalmKCProfileCreate}/>,
        <Resource name="palmcanopyareaprofiles" list={CanopyProfilesList} create={CanopyProfileCreate} edit={CanopyEdit}/>,
        <Resource name="pests" list={PestList} edit={PestEdit} create={PestCreate} />,
        <Resource name="pestinfectionlevels" />,
        <Resource name="inventorytypes" list={InventoryTypeList} edit={InventoryTypeEdit} create={InventoryTypeCreate} options={{ label: 'Inventory Types' }} />,
        <Resource name="inventorycategories" list={InventoryCategoryList} edit={InventoryCategoryEdit} create={InventoryCategoryCreate} options={{ label: 'Inventory Categories' }} />,
        <Resource name="inventorysubcategories" list={InventorySubCategoryList} edit={InventorySubCategoryEdit} create={InventorySubCategoryCreate} options={{ label: 'Inventory Subcategories' }} />,
        <Resource name="customroles" />,
        <Resource name="decodingtemplates" />,
        <Resource name="pivotlogs" />,
        <Resource name="sectionlogs" />,
        <Resource name="palmfertilizationtypes" />,
        <Resource name="pivotfertilizationtypes" />,
        <Resource name="pivotmachinerytypes" />,
        <Resource name="pivotspraytypes" />,
        <Resource name="pestcategories" />,
        <Resource name="scoutingconfigs" list={ScoutingConfigList} create={ScoutingConfigCreate} edit={ScoutingConfigEdit} options={{ label: 'Scouting Configurations' }} />,
        <Resource name="growthstages" list={GrowthStageList} create={GrowthStageCreate} edit={GrowthStageEdit} options={{ label: 'Growth Stages' }} />,
        <Resource name="growthstageattributes" list={GrowthStageAttributeList} create={GrowthStageAttributeCreate} edit={GrowthStageAttributeEdit} options={{ label: 'Growth Stage Attributes' }} />,
        <Resource name="weather-alert-configurations" list={WeatherAlertConfigList} create={WeatherAlertConfigCreate} edit={WeatherAlertConfigEdit} options={{ label: 'Weather Alerts Configuration' }} />,
        <Resource name="scoutingattributes" icon={SettingsIcon} />,
        <Resource name="workerlogs" />,
        <Resource name="taskinventories" />,
        <Resource name="sectionloginventories" />,
      ]}
    </Admin>

  </JssProvider>
);

export default App;
