import React, { Component } from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Avatar from '@material-ui/core/Avatar';

import { useLocale } from 'react-admin';
export const CentralUnitRedirectModal = props => {
  const { onClose, selectedValue, open } = props;
  // const [open, setOpen] = React.useState(true);
  // const [selectedValue, setSelectedValue] = React.useState(options[0]);
  const locale = useLocale()
  const options = locale === 'en' ? ['pivot', 'palm'] : ['القطاعات الفرعية', 'النخيل'];

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };


  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{locale === 'ar' ? 'اختر نوع التقرير' : 'Select Report type'}</DialogTitle>
      <List>
        {options.map((option) => (
          <ListItem button onClick={() => handleListItemClick(option)} key={option}>
            <ListItemAvatar>
              <Avatar >

              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={option} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

