import React, {Fragment} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useLogout, useTranslate} from 'ra-core';

import {
    Avatar,
    Box,
    Badge,
    Menu,
    Button,
    List,
    ListItem,
    Divider
} from '@material-ui/core';

import avatar4 from '../assets/images/avatars/index.png';
import {withStyles} from '@material-ui/core/styles';
import Cookies from '../../helpers/Cookies';

const StyledBadge = withStyles({
    badge: {
        backgroundColor: 'var(--success)',
        color: 'var(--success)',
        boxShadow: '0 0 0 2px #fff',
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""'
        }
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0
        }
    }
})(Badge);
const MyUserMenu = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const logout = useLogout();
    const translate = useTranslate()
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const username = Cookies.getCookie('user');

    return (
        <Fragment>
            <Button
                color="inherit"
                onClick={handleClick}
                className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center"
            >
                <Box>
                    <StyledBadge
                        overlap="circle"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        variant="dot"
                    >
                        <Avatar sizes="44" alt="Dustin Watson" src={avatar4} />
                    </StyledBadge>
                </Box>
                <div className="d-none d-xl-block pl-3">
                    <div className="font-weight-bold pt-2 line-height-1">
                        {username}
                    </div>
                </div>
                <span className="pl-1 pl-xl-3">
                    <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
                </span>
            </Button>

            <Menu
                anchorEl={anchorEl}
                keepMounted
                getContentAnchorEl={null}
                open={Boolean(anchorEl)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                }}
                onClose={handleClose}
                className="ml-2"
            >
                <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                    <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
                        <Box>
                            <StyledBadge
                                overlap="circle"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                                variant="dot"
                            >
                                <Avatar sizes="44" alt="Dustin Watson" src={avatar4} />
                            </StyledBadge>
                        </Box>
                        <div className="font-weight-bold text-center pt-2 line-height-1">
                            {username}
                        </div>
                        <Divider className="w-100 mt-2" />
                        <ListItem button onClick={() => logout()}>{translate('ra.auth.logout')}</ListItem>
                    </List>
                </div>
            </Menu>
        </Fragment>
    );
}
export default MyUserMenu;

