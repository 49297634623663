import React, { useState, Fragment } from 'react'
import httpClient from './httpClient';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { Button, useLocale } from 'react-admin';
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from 'recharts';
import { GoogleMap, LoadScript, Polygon, InfoWindow, GroundOverlay, DrawingManager } from '@react-google-maps/api'
import Popup from "reactjs-popup";
import TextField from '@material-ui/core/TextField';
import Chart from 'react-apexcharts';
const randomColor = () => {
    return '#' + '0123456789abcdef'.split('').map(function (v, i, a) {
        return i > 5 ? null : a[Math.floor(Math.random() * 16)]
    }).join('');
}

const drawingLibrary = ['drawing'];

const calculateBordersCenter = (boundaries) => {
    let avgLat = 0, avgLng = 0, center;
    for (let i = 0; i < boundaries.length; i++) {
        avgLat += boundaries[i][1];
        avgLng += boundaries[i][0];
    }
    if (boundaries.length > 0) {
        avgLat /= boundaries.length;
        avgLng /= boundaries.length;
    }
    center = { lat: avgLat, lng: avgLng };
    return center;
}

const handleToggleOpen = (qr, boundaries, setInfo) => {
    setInfo({
        isOpen: true,
        qr_value: qr,
        position: calculateBordersCenter(boundaries),
    });
};

const handleToggleClose = (setInfo) => {
    setInfo({
        isOpen: false,
    });
};

var overlayMap = {};

const getKey = (qr_value, index, date) => {
    const key = qr_value + '_' + index + date;
    if (!overlayMap[key]) {
        overlayMap[key] = key;
    }
    return overlayMap[key];
}

const CreateScouting = ({ location, date, qr_option, notes }) => {
    const locale = useLocale();
    return (
        <Button
            variant="contained"
            label={locale === 'en' ? `Create Scouting Task` : `إنشاء مهمة استكشافية`}
            style={{ margin: '2em' }}
            onClick={() => {
                httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/scoutingtasks/ondemand`, {
                    method: 'POST',
                    body: JSON.stringify({
                        ...location,
                        task_date: date,
                        notes: notes,
                        qr_value: qr_option
                    })
                });
                window.location.reload(true);
            }}
        >
        </Button>
    )
};

export const VegetationFarmMap = ({ farmBorders, images, indexOption, qr_option, scouting, mapImg }) => {
    const [info, setInfo] = useState({});
    const [mount, setMount] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [location, setLocation] = useState(null);
    const [notes, setNotes] = React.useState('');
    const locale = useLocale();
    return <Fragment>
        <LoadScript
            id="script-loader"
            googleMapsApiKey={process.env.REACT_APP_MAPS_KEY}
            libraries={drawingLibrary}
            >
            <GoogleMap
                id='drawing-example'
                defaultMapTypeId= "satellite"
                mapTypeId="satellite"
                zoom={14}
                options={{ gestureHandling: 'greedy',mapTypeId:'satellite' }}
                center={mount ? null : calculateBordersCenter(farmBorders)}
                onMouseOver={() => {
                    if (!mount)
                        setMount(true);
                }}
                style={{ flexBasis: 'auto' }}
                mapContainerStyle={{
                    position: 'relative', height: '600px', display: 'flex'
                }}>
                   {mapImg &&  <GroundOverlay
                    key={mapImg.url}
                    url={mapImg.url}
                    bounds={mapImg.bounds}
                />}

                {scouting && <DrawingManager drawingMode="marker" options={{ drawingControl: false }}
                    onMarkerComplete={(marker) => {
                        setLocation({ lat: marker.position.lat(), lng: marker.position.lng() });
                    }}
                />}
                {
                    images.filter(image => {
                        if (qr_option.includes('All')) return true;
                        else return qr_option.includes(image.qr_value);
                    }).map((image, key) => {
                        if (image.image[indexOption].pngURI){
                            return <GroundOverlay
                                key={getKey(image.qr_value, indexOption, image.image[indexOption].date)}
                                url={`${process.env.REACT_APP_FTP_IMAGES_SERVER}${image.image[indexOption].pngURI}`}
                                bounds={image.bounds}
                                
                            />
                        }
                    }
                    )
                }

                {
                    images.filter(image => {
                        if (qr_option.includes('All')) return true;
                        else return qr_option.includes(image.qr_value);
                    }).map((image, key) => {
                        const pathArray = image.borders.map(boundary => { return { lat: boundary[1], lng: boundary[0] } })
                        return (<Polygon options={{
                            fillColor: "#009688",
                            fillOpacity: '0.05',
                            strokeColor: "#009688",
                            strokeOpacity: '0.8',
                            strokeWeight: '2'
                        }}
                            path={pathArray}
                            key={key}
                            onMouseOver={() => handleToggleOpen(image.qr_value, image.borders, setInfo)}
                            onMouseOut={() => handleToggleClose(setInfo)}
                        />);
                    })
                }

                {info.isOpen && (
                    <InfoWindow onCloseClick={() => handleToggleClose(setInfo)} position={info.position}>
                        <span>{info.qr_value}</span>
                    </InfoWindow>
                )}
            </GoogleMap>
        </LoadScript>
        <Popup
            open={location ? true : false}
        >
            <div style={{ display: 'flex', justifyContent: 'center' }} >
                <Fragment>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label={locale === 'en' ? "Select Task Date" : "تاريخ المهمة"}
                            value={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <TextField
                        id="standard-multiline-flexible"
                        label={locale === 'en' ? "Notes" : "الملاحظات"}
                        style={{ margin: '8px', marginTop: '16px' }}
                        multiline
                        rowsMax="4"
                        value={notes}
                        onChange={(event) => setNotes(event.target.value)}
                    />
                    <CreateScouting location={location} date={selectedDate} qr_option={qr_option} notes={notes} />
                </Fragment>
            </div>
        </Popup>
    </Fragment>
}

export const VegetationChartComponent = ({ indexes, indexOption, qr_option, setDate }) => {
    const locale = useLocale();
    return Object.entries(indexes).filter(index => index[0] === indexOption).map((index, key) => {
        const name = index[0];
        const indexData = index[1];
        let obj = {};

        indexData.forEach(element => {
            element.index_date = (new Date(element.index_date)).toJSON();
            element.index_date = element.index_date.slice(0, element.index_date.indexOf('T'));
            Object.keys(element).forEach(objectKey => {
                if (objectKey !== 'index_date') {
                    obj[objectKey] = 1;
                }
            })
        });
        let series = [];
        Object.keys(obj).forEach(element => {
            if (qr_option.includes("All") || qr_option.includes(element)) {
                let currentSeries = {}
                currentSeries.name = element;
                let currentData = []
                indexData.forEach(index => {
                    if (Object.keys(index).find(val => val === element)) {
                        currentData.push({ x: index.index_date, y: parseFloat(index[element]).toFixed(2) })
                    }
                })
                currentSeries.data = currentData
                series.push(currentSeries)
            }
        })
        const options = {
            stroke: {
                curve: 'smooth'
            },
            markers: {
                size: 5,
            },
            xaxis: {
                type: 'string',
                position:'bottom',
                labels: {
                    show: true,
                    rotate: locale === "ar" ?45: -45 ,
                    rotateAlways: false}

            },
            tooltip: {
                shared: false,
                intersect: true
            },
            legend:{
                position: locale=="en"? "right" : "left",
                offsetX:-20
            },
            yaxis: {
                opposite:true,
                labels: {
                    show: true,
                    align:  "right",
                    minWidth: 0,
                    maxWidth: 160,
                    style: {
                        colors: [],
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-yaxis-label',
                    },
                    offsetX: 0,
                    offsetY: 0,
                    rotate: 0,
                }
            },
            chart: {
                animations: {
                    enabled: true
                }, events: {
                    markerClick: function (event, chartContext, { seriesIndex, dataPointIndex, config }) {

                        setDate(indexData[dataPointIndex].index_date)
                    }
                }
            }
        };
        return <div key={key} style={{ display: 'flex', justifyContent: 'center' }}>
            {
                <Chart
                    height={280}
                    width={1200}
                    margin={{ top: 50, right: 5, left: 5, bottom: 5 }}
                    options={options}
                    series={series}
                    type="line"
                    key={key} />
            /* {<LineChart width={1200} height={250} data={indexData}
                margin={{ top: 50, right: 5, left: 5, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="index_date" />
                <YAxis label={name} width={100} />
                <Tooltip />
                <Legend />
                {Object.keys(obj).filter(dataKey => {
                    if (qr_option.includes('All')) return true;
                    else return qr_option.includes(dataKey);
                }).map((dataKey, key) => {
                    let element = {}
                    element.x = new Date(Date.parse(indexData.index_date)).toLocaleString();
                    element.y = indexData[dataKey];
                    return <Line style={{ pointerEvents: 'none' }} key={key} name={dataKey} type="monotone" dataKey={dataKey} stroke={randomColor()}
                        activeDot={{
                            onClick: e => setDate(e.payload.index_date),
                            r: 10
                        }} />
                })}
            </LineChart>} */}

        </div>;
    })
}