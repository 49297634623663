import React from 'react';
import {
    List, Create, Edit, SimpleForm, TextInput, useLocale,
    EditButton, Datagrid, TextField, ImageField, Pagination
} from 'react-admin';
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';

import { MyImageField, MyImageInput } from './helpers/iconComponent';
import { withStyles } from "@material-ui/core/styles";
import clsx from 'clsx';

const styles = {
    image: { maxHeight: '1.4rem' }
}

export const PivotCropTypeList = withStyles(styles)(({ classes, ...props }) => {
    const locale = useLocale();
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}{...props}>
            <Datagrid>
                  
                <TextField source={locale === 'en' ? "name" : "name_ar"} />
                <EditButton />
            </Datagrid>
        </List>
    )
});

export const PivotCropTypeEdit = props => {
    return (
        <Edit undoable={false} {...props} >
            <SimpleForm redirect="list" toolbar={<CustomToolbar />} className={clsx('tab-rtl-layout')}>
                <TextInput disabled source="id" />
                <TextInput source="name" />
                <TextInput source="name_ar" />
            </SimpleForm>
        </Edit>
    )
};

export const PivotCropTypeCreate = props => {
    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <TextInput source="name" />
                <TextInput source="name_ar" />
            </SimpleForm>
        </Create>
    )
};
