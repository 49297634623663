import React from 'react';
import {
    Create, Edit, SimpleForm, TextInput, useLocale,
    EditButton, Datagrid, TextField, Filter, FormDataConsumer,
    ShowButton, Show, SimpleShowLayout, ReferenceField,
    Tab, TabbedShowLayout, ReferenceManyField, ImageField,
    DateField, required, ReferenceInput, SelectInput, Pagination
} from 'react-admin';
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import List from './theme/components/List'
import { EntityLocation, LaunchIconLink } from './entityLocation'
import clsx from 'clsx';
export const TrapList = (props) => {
    const locale = useLocale();
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} filters={<TrapFilter />} {...props}>
            <Datagrid>
                  
                <LaunchIconLink source={locale === 'en' ? "Location" : "الموقع"} tab={2} />
                <TextField source="qr_value" />
                <ReferenceField source="farm_id" reference="farms" allowEmpty={true} >
                    <TextField source="farm_code" />
                </ReferenceField>
                <ReferenceField reference="blocks" source="block_id" allowEmpty={true} >
                    <TextField source="qr_value" />
                </ReferenceField>
                <ReferenceField source="traptype_id" reference="traptypes" allowEmpty={true} >
                    <TextField source={locale === 'en' ? "type" : "type_ar"} />
                </ReferenceField>
                <ReferenceField source="created_by" reference="users" allowEmpty={true} >
                    <TextField source="username" />
                </ReferenceField>
                <TextField source="lat" />
                <TextField source="lng" />
                <EditButton />
                <ShowButton />
            </Datagrid>
        </List>
    )
};

const CustomSelectInput = props => {
    const choices = props.choices.filter(block => block.farm_id === props.farm_id);
    return (<SelectInput optionText="qr_value" {...props} choices={choices} />);
}

export const TrapEdit = props => {
    const locale = useLocale();
    return (
        <Edit undoable={false} {...props}>
            <SimpleForm redirect="list" toolbar={<CustomToolbar />} className={clsx('tab-rtl-layout')}>
                <TextInput disabled source="id" />
                <TextInput source="qr_value" validate={required()} />
                <ReferenceInput perPage={-1} source="traptype_id" reference="traptypes" validate={required()} >
                    <SelectInput optionText={locale === 'en' ? "type" : "type_ar"} />
                </ReferenceInput>
                <ReferenceInput perPage={-1} source="farm_id" reference="farms" validate={required()} >
                    <SelectInput optionText="farm_code" />
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) => (formData.farm_id &&
                        <ReferenceInput perPage={-1} source="block_id" reference="blocks" filter={{ farm_id: formData.farm_id }}
                            {...rest} validate={required()} >
                            <SelectInput optionText="qr_value" />
                        </ReferenceInput>
                    )}
                </FormDataConsumer>
                <ReferenceInput perPage={-1} source="created_by" reference="users" validate={required()} >
                    <SelectInput optionText="username" />
                </ReferenceInput>
                <TextInput source="lat" validate={required()} />
                <TextInput source="lng" validate={required()} />
            </SimpleForm>
        </Edit>
    )
};

export const TrapCreate = props => {
    const locale = useLocale();
    return (
        <Create {...props}>
            <SimpleForm redirect="list" className={clsx('tab-rtl-layout')}>
                <TextInput source="qr_value" validate={required()} />
                <ReferenceInput perPage={-1} source="farm_id" reference="farms" validate={required()} >
                    <SelectInput optionText="farm_code" />
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) => (formData.farm_id &&
                        <ReferenceInput perPage={-1} source="block_id" reference="blocks" filter={{ farm_id: formData.farm_id }}
                            {...rest} validate={required()} >
                            <SelectInput optionText="qr_value" />
                        </ReferenceInput>
                    )}
                </FormDataConsumer>
                <ReferenceInput perPage={-1} source="traptype_id" reference="traptypes" validate={required()} >
                    <SelectInput optionText={locale === 'en' ? "type" : "type_ar"} />
                </ReferenceInput>
                <ReferenceInput perPage={-1} source="created_by" reference="users" validate={required()} >
                    <SelectInput optionText="username" />
                </ReferenceInput>
                <TextInput source="lat" validate={required()} />
                <TextInput source="lng" validate={required()} />
            </SimpleForm>
        </Create>
    )
};

const TrapActivity = (props) => {
    const locale = useLocale();
    return (
        <Show {...props} title=" ">
            <SimpleShowLayout className={clsx('tab-rtl-layout')}>
                {props.record.weevil_cnt && <TextField source="weevil_cnt" />}
                {props.record.image_url && <ImageField source="image_url" title="Image" />}
                <ReferenceManyField perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
                    reference="workerlogs" target="activity_id" label={locale === 'en' ? "Workers" : "العمال"}>
                    <Datagrid>
                          
                        <ReferenceField source="worker_id" reference="workers" allowEmpty={true}>
                            <TextField source="name" />
                        </ReferenceField>
                        <TextField source="hours" />
                        <TextField source="rating" />
                        <TextField source="notes" />
                    </Datagrid>
                </ReferenceManyField>
            </SimpleShowLayout>
        </Show>
    )
};
const TrapName = ({ record }) => {
    const locale = useLocale();
    return <span>{record ? (locale === 'en' ? record.qr_value : record.qr_value) : ''}</span>;
}
export const TrapShow = (props) => {
    const locale = useLocale();
    return (
        <Show {...props} title={<TrapName />}>
            <TabbedShowLayout>
                <Tab label={locale === 'en' ? "summary" : "الملخص"} contentClassName={clsx('tab-rtl-layout')}>
                      
                    <TextField source="qr_value" />
                    <ReferenceField source="farm_id" reference="farms" allowEmpty={true} >
                        <TextField source="farm_code" />
                    </ReferenceField>
                    <ReferenceField reference="blocks" source="block_id" allowEmpty={true} >
                        <TextField source="qr_value" />
                    </ReferenceField>
                    <ReferenceField source="traptype_id" reference="traptypes" allowEmpty={true} >
                        <TextField source={locale === 'en' ? "type" : "type_ar"} />
                    </ReferenceField>
                    <ReferenceField source="created_by" reference="users" allowEmpty={true} >
                        <TextField source="username" />
                    </ReferenceField>
                    <TextField source="lat" />
                    <TextField source="lng" />
                </Tab>
                <Tab label={locale === 'en' ? "Activities" : "السجلات"}>
                    <ReferenceManyField perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} reference="traplogs" target="trap_id" addLabel={false}>
                        <Datagrid expand={<TrapActivity />}>
                              
                            <TextField source="qr_value" />
                            <ReferenceField source="activity_id" reference="trapactivitytypes" allowEmpty={true} >
                                <TextField source="activity" />
                            </ReferenceField>
                            <TextField source="type" />
                            <ReferenceField source="created_by" reference="users" allowEmpty={true} >
                                <TextField source="username" />
                            </ReferenceField>
                            <DateField source="created_at" locales="en-GB" showTime />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                <Tab label={locale === 'en' ? "Location" : "الموقع"}>
                    <EntityLocation lat="lat" lng="lng" />
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
};

const TrapFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput perPage={-1} source="farm_id" reference="farms" >
            <SelectInput optionText="farm_code" />
        </ReferenceInput>
        <ReferenceInput perPage={-1} source="traptype_id" reference="traptypes" >
            <SelectInput optionText="type" />
        </ReferenceInput>
        <ReferenceInput perPage={-1} source="created_by" reference="users" >
            <SelectInput optionText="username" />
        </ReferenceInput>
    </Filter>
);
