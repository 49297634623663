import React from "react";
import { SvgIcon } from "@material-ui/core";

export const ActiveProgramms = () => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 64 64"
      width="24px"
      class="hovered-paths"
    >
      <g>
        <g id="work_time" data-name="work time">
          <circle
            cx="43"
            cy="43"
            fill="#ffdaaa"
            r="18"
            data-original="#FFDAAA"
            class=""
          />
          <path
            d="m61 43a18 18 0 0 1 -18 18c-.33 0-.67-.01-1-.03a18 18 0 0 0 0-35.94c.33-.02.67-.03 1-.03a18 18 0 0 1 18 18z"
            fill="#ffc477"
            data-original="#FFC477"
          />
          <path
            d="m61 13-1.98.49 1.05 1.75-2.83 2.83-1.75-1.05-.49 1.98h-4l-.49-1.98-1.75 1.05-2.83-2.83 1.05-1.75-1.98-.49v-4l1.98-.49-1.05-1.75 2.83-2.83 1.75 1.05.49-1.98h4l.49 1.98 1.75-1.05 2.83 2.83-1.05 1.75 1.98.49z"
            fill="#c0ab91"
            data-original="#C0AB91"
            class=""
          />
          <circle
            cx="53"
            cy="11"
            fill="#ffde55"
            r="3"
            data-original="#FFDE55"
            class="hovered-path active-path"
            style={{ fill: "#E2FFE2" }}
            data-old_color="#ffde55"
          />
          <path
            d="m32.44 23.76a13.086 13.086 0 0 1 -.98 2.38l1.42 1.99 1.26 1.77-4.24 4.24-1.77-1.26-1.99-1.42a13.086 13.086 0 0 1 -2.38.98l-.76 4.56h-6l-.76-4.56a13.086 13.086 0 0 1 -2.38-.98l-3.76 2.68-4.24-4.24 2.68-3.76a14.517 14.517 0 0 1 -.98-2.38l-4.56-.76v-6l4.56-.76a16.936 16.936 0 0 1 .98-2.38l-2.68-3.76 4.24-4.24 3.76 2.68a13.086 13.086 0 0 1 2.38-.98l.76-4.56h6l.76 4.56a13.086 13.086 0 0 1 2.38.98l3.76-2.68 4.24 4.24-2.68 3.76a13.086 13.086 0 0 1 .98 2.38l4.56.76v6z"
            fill="#57a4ff"
            data-original="#57A4FF"
            class=""
            style={{ fill: "#69E781" }}
            data-old_color="#57a4ff"
          />
          <path
            d="m25 18-5 11v-7h-5l5-11v7z"
            fill="#ffde55"
            data-original="#FFDE55"
            class="hovered-path active-path"
            style={{ fill: "#E2FFE2" }}
            data-old_color="#ffde55"
          />
          <path
            d="m19 55-1.98.49 1.05 1.75-2.83 2.83-1.75-1.05-.49 1.98h-4l-.49-1.98-1.75 1.05-2.83-2.83 1.05-1.75-1.98-.49v-4l1.98-.49-1.05-1.75 2.83-2.83 1.75 1.05.49-1.98h4l.49 1.98 1.75-1.05 2.83 2.83-1.05 1.75 1.98.49z"
            fill="#c0ab91"
            data-original="#C0AB91"
            class=""
          />
          <circle
            cx="11"
            cy="53"
            fill="#ffde55"
            r="3"
            data-original="#FFDE55"
            class="hovered-path active-path"
            style={{ fill: "#E2FFE2" }}
            data-old_color="#ffde55"
          />
          <path
            d="m36.535 4h8.465v-2h-9a1 1 0 0 0 -.832.445l-2 3 1.664 1.11z"
            data-original="#000000"
            class=""
            style={{ fill: "#000000" }}
          />
          <path
            d="m27.465 60h-8.465v2h9a1 1 0 0 0 .832-.445l2-3-1.664-1.11z"
            data-original="#000000"
            class=""
            style={{ fill: "#000000" }}
          />
          <path
            d="m43 24a18.779 18.779 0 0 0 -9.857 2.78l-.519-.727a14.1 14.1 0 0 0 .581-1.407l3.96-.66a1 1 0 0 0 .835-.986v-6a1 1 0 0 0 -.835-.986l-3.96-.66a14.1 14.1 0 0 0 -.581-1.407l2.332-3.265a1 1 0 0 0 -.107-1.288l-4.243-4.243a1 1 0 0 0 -1.288-.107l-3.265 2.332a14.1 14.1 0 0 0 -1.407-.576l-.66-3.96a1 1 0 0 0 -.986-.84h-6a1 1 0 0 0 -.986.835l-.66 3.96a14.1 14.1 0 0 0 -1.407.581l-3.265-2.332a1 1 0 0 0 -1.288.107l-4.243 4.243a1 1 0 0 0 -.107 1.288l2.343 3.28a13.254 13.254 0 0 0 -.573 1.389l-3.979.663a1 1 0 0 0 -.835.986v6a1 1 0 0 0 .835.986l3.978.663a14.849 14.849 0 0 0 .563 1.4l-2.332 3.269a1 1 0 0 0 .107 1.288l4.243 4.243a1 1 0 0 0 1.288.107l3.265-2.332a14.1 14.1 0 0 0 1.407.581l.66 3.96a1 1 0 0 0 .986.835h6a1 1 0 0 0 .986-.835l.66-3.96a14.1 14.1 0 0 0 1.407-.581l.727.519a18.779 18.779 0 0 0 -2.78 9.857 19 19 0 1 0 19-19zm-17.332 6.577a11.966 11.966 0 0 1 -2.2.908 1 1 0 0 0 -.7.793l-.615 3.722h-4.306l-.62-3.722a1 1 0 0 0 -.7-.793 11.966 11.966 0 0 1 -2.2-.908 1 1 0 0 0 -1.054.067l-3.07 2.192-3.039-3.044 2.192-3.07a1 1 0 0 0 .067-1.054 13.512 13.512 0 0 1 -.923-2.245 1 1 0 0 0 -.777-.65l-3.723-.62v-4.306l3.722-.62a1 1 0 0 0 .777-.65 16.4 16.4 0 0 1 .947-2.289 1 1 0 0 0 -.09-1.01l-2.192-3.07 3.044-3.044 3.07 2.192a1 1 0 0 0 1.054.067 11.966 11.966 0 0 1 2.2-.908 1 1 0 0 0 .7-.793l.615-3.722h4.306l.62 3.722a1 1 0 0 0 .7.793 11.966 11.966 0 0 1 2.2.908 1 1 0 0 0 1.054-.067l3.07-2.192 3.044 3.044-2.192 3.07a1 1 0 0 0 -.067 1.054 11.966 11.966 0 0 1 .908 2.2 1 1 0 0 0 .793.7l3.722.62v4.306l-3.722.62a1 1 0 0 0 -.793.7 11.966 11.966 0 0 1 -.908 2.2 1 1 0 0 0 .067 1.054l2.192 3.07-3.044 3.044-3.07-2.192a1 1 0 0 0 -1.059-.077zm18.332 29.372v-2.949h-2v2.949a16.994 16.994 0 0 1 -15.949-15.949h2.949v-2h-2.963a16.748 16.748 0 0 1 2.372-7.693l.909.649a1 1 0 0 0 1.288-.107l4.243-4.243a1 1 0 0 0 .107-1.288l-.649-.909a16.748 16.748 0 0 1 7.693-2.372v2.963h2v-2.949a16.994 16.994 0 0 1 15.949 15.949h-2.949v2h2.949a16.994 16.994 0 0 1 -15.949 15.949z"
            data-original="#000000"
            class=""
            style={{ fill: "#000000" }}
          />
          <path
            d="m44 31h-2v12a1 1 0 0 0 1 1h8v-2h-7z"
            data-original="#000000"
            class=""
            style={{ fill: "#000000" }}
          />
          <path
            d="m25 17h-4v-6a1 1 0 0 0 -1.91-.414l-5 11a1 1 0 0 0 .91 1.414h4v6a1 1 0 0 0 .788.978 1.025 1.025 0 0 0 .212.022 1 1 0 0 0 .91-.586l5-11a1 1 0 0 0 -.91-1.414zm-4 7.383v-2.383a1 1 0 0 0 -1-1h-3.447l2.447-5.383v2.383a1 1 0 0 0 1 1h3.447z"
            data-original="#000000"
            class=""
            style={{ fill: "#000000" }}
          />
          <path
            d="m19 6h2v2h-2z"
            data-original="#000000"
            class=""
            style={{ fill: "#000000" }}
          />
          <path
            d="m9.808 9.808h2v2h-2z"
            transform="matrix(.707 -.707 .707 .707 -4.477 10.808)"
            data-original="#000000"
            class=""
            style={{ fill: "#000000" }}
          />
          <path
            d="m6 19h2v2h-2z"
            data-original="#000000"
            class=""
            style={{ fill: "#000000" }}
          />
          <path
            d="m9.808 28.192h2v2h-2z"
            transform="matrix(.707 -.707 .707 .707 -17.477 16.192)"
            data-original="#000000"
            class=""
            style={{ fill: "#000000" }}
          />
          <path
            d="m19 32h2v2h-2z"
            data-original="#000000"
            class=""
            style={{ fill: "#000000" }}
          />
          <path
            d="m28.192 28.192h2v2h-2z"
            transform="matrix(.707 -.707 .707 .707 -12.092 29.192)"
            data-original="#000000"
            class=""
            style={{ fill: "#000000" }}
          />
          <path
            d="m32 19h2v2h-2z"
            data-original="#000000"
            class=""
            style={{ fill: "#000000" }}
          />
          <path
            d="m28.192 9.808h2v2h-2z"
            transform="matrix(.707 -.707 .707 .707 .908 23.808)"
            data-original="#000000"
            class=""
            style={{ fill: "#000000" }}
          />
          <path
            d="m53 7a4 4 0 1 0 4 4 4 4 0 0 0 -4-4zm0 6a2 2 0 1 1 2-2 2 2 0 0 1 -2 2z"
            data-original="#000000"
            class=""
            style={{ fill: "#000000" }}
          />
          <path
            d="m61.243 8.03-.669-.167.355-.591a1 1 0 0 0 -.151-1.222l-2.828-2.828a1 1 0 0 0 -1.222-.151l-.591.355-.167-.669a1 1 0 0 0 -.97-.757h-4a1 1 0 0 0 -.97.757l-.167.669-.591-.355a1 1 0 0 0 -1.222.151l-2.828 2.828a1 1 0 0 0 -.151 1.222l.355.591-.669.167a1 1 0 0 0 -.757.97v4a1 1 0 0 0 .757.97l.669.167-.355.591a1 1 0 0 0 .151 1.222l2.828 2.828a1 1 0 0 0 1.222.151l.591-.355.167.669a1 1 0 0 0 .97.757h4a1 1 0 0 0 .97-.757l.167-.669.591.355a1 1 0 0 0 1.222-.151l2.828-2.828a1 1 0 0 0 .151-1.222l-.355-.591.669-.167a1 1 0 0 0 .757-.97v-4a1 1 0 0 0 -.757-.97zm-1.243 4.189-1.221.3a1 1 0 0 0 -.614 1.485l.647 1.079-1.724 1.724-1.079-.647a1 1 0 0 0 -1.485.614l-.305 1.226h-2.438l-.3-1.221a1 1 0 0 0 -1.485-.614l-1.079.647-1.724-1.724.647-1.079a1 1 0 0 0 -.614-1.485l-1.226-.305v-2.438l1.221-.3a1 1 0 0 0 .614-1.485l-.647-1.079 1.724-1.724 1.079.647a1 1 0 0 0 1.485-.615l.3-1.22h2.438l.3 1.22a1 1 0 0 0 1.485.615l1.079-.647 1.724 1.724-.647 1.079a1 1 0 0 0 .614 1.485l1.221.3z"
            data-original="#000000"
            class=""
            style={{ fill: "#000000" }}
          />
          <path
            d="m11 49a4 4 0 1 0 4 4 4 4 0 0 0 -4-4zm0 6a2 2 0 1 1 2-2 2 2 0 0 1 -2 2z"
            data-original="#000000"
            class=""
            style={{ fill: "#000000" }}
          />
          <path
            d="m18.929 56.728-.355-.591.669-.167a1 1 0 0 0 .757-.97v-4a1 1 0 0 0 -.757-.97l-.669-.167.355-.591a1 1 0 0 0 -.151-1.222l-2.828-2.828a1 1 0 0 0 -1.222-.151l-.591.355-.167-.669a1 1 0 0 0 -.97-.757h-4a1 1 0 0 0 -.97.757l-.167.669-.591-.355a1 1 0 0 0 -1.222.151l-2.828 2.828a1 1 0 0 0 -.151 1.222l.355.591-.669.167a1 1 0 0 0 -.757.97v4a1 1 0 0 0 .757.97l.669.167-.355.591a1 1 0 0 0 .151 1.222l2.828 2.828a1 1 0 0 0 1.222.151l.591-.355.167.669a1 1 0 0 0 .97.757h4a1 1 0 0 0 .97-.757l.167-.669.591.355a1 1 0 0 0 1.222-.151l2.828-2.828a1 1 0 0 0 .151-1.222zm-.929-2.509-1.221.3a1 1 0 0 0 -.614 1.485l.647 1.079-1.724 1.724-1.079-.647a1 1 0 0 0 -1.485.614l-.305 1.226h-2.438l-.3-1.221a1 1 0 0 0 -1.485-.614l-1.079.647-1.729-1.724.647-1.079a1 1 0 0 0 -.614-1.485l-1.221-.305v-2.438l1.221-.3a1 1 0 0 0 .614-1.485l-.647-1.079 1.724-1.724 1.079.647a1 1 0 0 0 1.485-.615l.3-1.22h2.438l.3 1.22a1 1 0 0 0 1.485.615l1.079-.647 1.724 1.724-.647 1.079a1 1 0 0 0 .614 1.485l1.221.3z"
            data-original="#000000"
            class=""
            style={{ fill: "#000000" }}
          />
        </g>
      </g>
    </SvgIcon>
  );
};
