import React from 'react';
import {
    Create, Edit, SimpleForm, TextInput, ImageInput, ImageField,
    EditButton, Datagrid, TextField, useLocale, Pagination
} from 'react-admin';
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import List from './theme/components/List'
import clsx from 'clsx';

const CustomImage = props => {
    if (props.record.file)
        return <img src={`${process.env.REACT_APP_DATA_PROVIDER_URL}${props.record.file.url}`} style={{ maxHeight: '1.4rem' }} />
    else
        return null;
}

export const IconList = (props) => {
    const locale = useLocale();
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}{...props}>
            <Datagrid>
                  
                <CustomImage source="icon" />
                <TextField source={locale === 'en' ? "name" : "name_ar"} />
                <EditButton />
            </Datagrid>
        </List>
    )
};

export const IconEdit = props => (
    <Edit undoable={false} {...props} className={clsx('tab-rtl-layout')}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
            <TextInput disabled source="id" />
            <TextInput source="name" />
            <TextInput source="name_ar" />
            <ImageInput source="file" label="icon" accept="image/*">
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Edit>
);

export const IconCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="name" />
            <TextInput source="name_ar" />
            <ImageInput source="file" label="icon" accept="image/*">
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Create>
);
