import React, { useState } from "react";
import {
  Create,
  Show,
  ShowButton,
  SimpleShowLayout,
  Edit,
  SimpleForm,
  TextInput,
  EditButton,
  Datagrid,
  useLocale,
  Pagination,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  FunctionField,
  NumberInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import MuiTextField from "@material-ui/core/TextField";
import CustomToolbar from "./helpers/customToolbar";
import PostBulkActionButtons from "./helpers/PostBulkActionButtons";
import List from "./theme/components/List";
import clsx from "clsx";
import { Fragment } from "react";
import httpClient from "./httpClient";
import {
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TablePagination,
  Paper,
  TableContainer,
} from "@material-ui/core";
const useStyles = makeStyles({
  table: {
    maxWidth: 350,
  },
});

export const CanopyEdit = (props) => (
  <Edit undoable={false} {...props}>
    <SimpleForm
      redirect="list"
      toolbar={<CustomToolbar />}
      className={clsx("tab-rtl-layout")}
    >
      <TextInput disabled source="id" />
      <TextInput source="name" />
      <TextInput source="name_ar" />
    </SimpleForm>
  </Edit>
);
export const CanopyProfileShow = ({ id, record, resource }) => {
  const locale = useLocale();
  const classes = useStyles();
  return (
    <SimpleShowLayout className={clsx("tab-rtl-layout")}>
      <TableContainer className={classes.table} component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>{locale==="en"?"Palm Age Start":"بداية عمر النخلة" }</TableCell>
              <TableCell>{locale==="en"?"Palm Age End":"نهاية عمر النخلة" }</TableCell>
              <TableCell>{locale==="en"?"Area":"المساحة بالمتر المربع" }</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record.data.map((row, index) => (
                 index %2 == 0?(
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.age}
                </TableCell>
                <TableCell>{record.data[index +1].age}</TableCell>
                <TableCell>{row.area}</TableCell>
              </TableRow>
                 ):(null)
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </SimpleShowLayout>
  );
};

export const CanopyProfilesList = (props) => {
  const locale = useLocale();
  return (
    <List
      sort={{ field: "id", order: "ASC" }}
      bulkActionButtons={<PostBulkActionButtons />}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
      {...props}
    >
      <Datagrid expand={<CanopyProfileShow {...props} />}>
        <TextField source="id" label={locale === "ar" ? "الرقم" : "id"} />
        <TextField
          source={locale === "en" ? "name" : "name_ar"}
          label={locale === "ar" ? "الاسم" : "Name"}
        />
         <ReferenceField
          label={locale === "ar" ? "نوع النخلة" : "Palm Type"}
          source="palmtype_id"
          reference="palmtypes"
        >
          <TextField source={locale === "en" ? "type" : "type_ar"} />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export const CanopyProfileCreate = (props) => {
  const locale = useLocale();
  const [values, setValues] = useState([{ from: 0, to: 0, value: 0 }]);
  const [errorMessage, setError] = useState("");
  function addNewItem(record) {
    setValues((prevItems) => [...prevItems, { from: 0, to: 0, value: 0 }]);
    console.log(values);
  }
  function handleChangeToValue(event, index) {
    var valCopy = values.slice(); // Make a copy of the emails first.

    if (
      values[index - 1] &&
      event.target.value < parseInt(values[index - 1].to)
    ) {
      setError((prevItems) => "Period must be less than previous period");
      console.log("error");
    } else setError("");
    values[index].from = values[index - 1]
      ? parseInt(values[index - 1].to) + 1
      : 1;
    values[index].to = event.target.value; // Update it with the modified email.
    setValues((prevItems) => values); // Update the state.
  }
  function handleChangeKCValue(event, index) {
    var valCopy = values.slice(); // Make a copy of the emails first.
    values[index].value = event.target.value; // Update it with the modified email.
    setValues((prevItems) => values); // Update the state.
    console.log(values);
  }
  function deleteItem(record) {
    values.splice(values.length - 1, 1);
    setValues((prevItesm) => [...values]);
  }
  const saveCustomKCProfile = async (formValues) => {
    let points = [];
    let payload = {
      name: formValues.name,
      name_ar: formValues.name_ar,
      palmtype_id: formValues.palmtype_id,
    };
    values.forEach((record, index) => {
      points.push({ x: record.from, y: record.value });
      points.push({ x: record.to, y: record.value });
    });
    payload.points = points;
    let response = await httpClient(
      `${process.env.REACT_APP_DATA_PROVIDER_URL}/palmcanopyareaprofiles/create_new`,
      {
        method: "POST",
        body: JSON.stringify(payload),
      }
    );
    if (response.status == 200) window.history.back();
    console.log("vals", payload);
  };
  return (
    <Fragment {...props}>
      {console.log(props)}
      { (
        <SimpleForm
          redirect="list"
          className={clsx("tab-rtl-layout")}
          save={saveCustomKCProfile}
        >
          <TextInput
            source="name"
            
            style={{
              marginRight: "1rem",
              marginLeft: "1rem",
              marginBottom: "1rem",
            }}
          />
          <TextInput
          label={locale === "ar" ? "الاسم" : "Name"}
            source="name_ar"
            style={{
              marginRight: "1rem",
              marginLeft: "1rem",
              marginBottom: "1rem",
            }}
          />
          <ReferenceInput
          perPage={-1}
          label={locale==="en"?"Palm Type":"نوع النخلة"}
          style={{
            marginRight: "1rem",
            marginLeft: "1rem",
            marginBottom: "1rem",
          }}
          reference="palmtypes"
          source="palmtype_id"
          allowEmpty
        >
          <SelectInput optionText={locale === "en" ? "type" : "type_ar"} />
        </ReferenceInput>
          {console.log(values)}
          {values.map((record, key) => {
            return (
              <Fragment key={key}>
                <MuiTextField
                  size="medium"
                  disabled
                  label={locale ==="en"?"Palm Age Start":"بداية عمر النخلة" }
                  variant="filled"
                  value={values[key - 1] ? parseInt(values[key - 1].to) + 1 : 1}
                  source="Period Start"
                  type="number"
                  // onChange={(e) => handleChangeFromValue(e, key)}
                  style={{
                    marginRight: "1rem",
                    marginLeft: "1rem",
                    marginBottom: "1rem",
                  }}
                />
                <MuiTextField
                  size="medium"
                  source="Period End"
                  label={locale ==="en"?"Palm Age End":"نهاية عمر النخلة" }
                  variant="filled"
                  type="number"
                  errorMessage={errorMessage}
                  error={errorMessage.length > 0 ? 1 : 0}
                  onChange={(e) => handleChangeToValue(e, key)}
                  style={{
                    marginRight: "1rem",
                    marginLeft: "1rem",
                    marginBottom: "1rem",
                  }}
                />
                <MuiTextField
                  size="medium"
                  source="KC Value"
                  label={locale ==="en"?"Area":"المساحة بالمتر المربع" }
                  variant="filled"
                  type="number"
                  onChange={(e) => handleChangeKCValue(e, key)}
                  style={{
                    marginRight: "1rem",
                    marginLeft: "1rem",
                    marginBottom: "1rem",
                  }}
                />
                {(key === values.length - 1 && values.length > 1) === true ? (
                  <img
                    src="https://nakheel-cdn.s3.eu-west-2.amazonaws.com/455-4552435_-remove-button-delete-denied-red-round-cancel.png"
                    style={{
                      maxHeight: "2rem",
                      margin: "1rem",
                      cursor: "pointer",
                    }}
                    onClick={() => deleteItem(record)}
                  />
                ) : null}

                {key === values.length - 1 ? (
                  <img
                    src="https://nakheel-cdn.s3.eu-west-2.amazonaws.com/add-icon-png-2459.png"
                    style={{
                      maxHeight: "2rem",
                      margin: "1rem",
                      cursor: "pointer",
                    }}
                    onClick={() => addNewItem(record)}
                  />
                ) : null}
              </Fragment>
            );
          })}
        </SimpleForm>
      )}
    </Fragment>
  );
};
