import React, { useState, useEffect } from 'react';
import {
    Create, Edit, SimpleForm, TextInput, EditButton, SimpleShowLayout,
    Datagrid, Filter, NumberInput,TextField,FunctionField, RichTextField, useLocale,
    Show, TabbedShowLayout, ReferenceManyField, ShowButton, Pagination,
    ReferenceField, Tab, DateField, ReferenceInput, SelectInput, TopToolbar,
    ReferenceArrayInput, SelectArrayInput, ReferenceArrayField, SingleFieldList,
    ChipField, Button, minValue, maxValue
} from 'react-admin';
import InputAdornment from '@material-ui/core/InputAdornment';
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import myHandleChange from './helpers/myHandleChange';
import List from './theme/components/List'
import { UnitField, MinutesField, IrrigationReasonField, IrrigationTypeField } from './helpers/irrigationComponents';
import * as Mui from '@material-ui/core';
import DrawBorders from './drawBorders';
import ShowBorders from './showBorders';
import { parse } from 'query-string';
import FilterSensors from './filterSensors';
import DrawLine from './drawLine';
import GetAppIcon from '@material-ui/icons/GetApp';
import LaunchIcon from '@material-ui/icons/Launch';
import * as jsPDF from 'jspdf'
import httpClient from './httpClient';
import Popup from "reactjs-popup";
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import clsx from 'clsx';
import { useInput, required } from 'react-admin';
import { faPercentage } from '@fortawesome/free-solid-svg-icons';
import { useField } from 'react-final-form';
const BoundedTextField = props => {
    let {
        input: { name, onChange, ...rest },
        meta: { touched, error },
        isRequired
    } = useInput(props);

    return (
        <Mui.TextField
        name={name}
        source="irrigation_scaler"
        className={props.className}
        label={props.label}
        variant="filled"
        InputProps={{
            endAdornment: <InputAdornment style={{margin:"8px"}} position="start">%</InputAdornment>,
          }}
         onChange={onChange}
        required={isRequired}
        {...rest}
    />
    );
};

const useStyles = makeStyles({
    chipBackground: {
        backgroundColor: '#00000022'
    }
});

const genderObject = {
    Male: 'ذكر',
    Female: 'أنثى'
}

const GenderField = props => {
    const locale = useLocale();
    return <span>{locale === 'en' ? props.record.gender : genderObject[props.record.gender]}</span>;
}

const LaunchIconLink = ({ record = {} }) =>
    <a href={`#/palms/${record.id}/show/2`} target="_blank">
        <LaunchIcon style={{ color: 'teal' }} />
    </a>;

const SupervisorsField = (props) => {
    if (props.record.supervisors) {
        const record = { ...props.record };
        record.supervisors = JSON.parse(record.supervisors);
        return <ReferenceArrayField reference="users" source="supervisors" {...props} record={record}>
            <SingleFieldList>
                <ChipField source="username" />
            </SingleFieldList>
        </ReferenceArrayField>
    }
    else
        return null;
}

export const BlockList = (props) => {
    const locale = useLocale();
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} filters={<BlockFilter />} {...props}>
            <Datagrid>
                  
                <TextField source="qr_value" />
                <TextField source={locale === 'en' ? "name" : "name_ar"} />
                <TextField source="rows" />
                <TextField source="columns" />
                <TextField source="total_palms" />
                <TextField source="actual_palm_count" />
                <TextField source="available_spots" />
                <TextField source="current_sprouts" />
                <TextField source="splitted_sprouts" />
                <TextField source="current_appendages" />
                <TextField source="splitted_appendages" />
                <ReferenceField reference="farms" source="farm_id" allowEmpty={true} >
                    <TextField source="farm_code" />
                </ReferenceField>
                <SupervisorsField source="supervisors" />
                <EditButton />
                <ShowButton />
            </Datagrid>
        </List>
    )
};

const CustomForm = (props) => {
    let record = { ...props.record };
    const [value, setValue] = React.useState(30);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
    const locale = useLocale();
    if (props.borders)
        record.borders = props.borders;
    const {source, ...rest} = props;
    return (
        <SimpleForm {...props} record={record} toolbar={<CustomToolbar />} className={clsx('tab-rtl-layout')}>
            <TextInput disabled source="id" />
            <TextInput disabled source="qr_value" />
            <TextInput source="name" />
            <TextInput source="name_ar" />
            <NumberInput source="rows" />
            <NumberInput source="columns" />
            <NumberInput source="total_palms" />
            <NumberInput source="actual_palm_count" />
            <NumberInput source="pump_flow_rate" label={locale === 'en' ? "Pump Flow Rate m³/hr" : "معدل تصريف المضخة م۳\\ساعة"} />
            <NumberInput source="palm_drip_flow_rate" label={locale === 'en' ? "Palm Drip Flow Rate L/hr" : "معدل تصريف النقاط لتر\\ساعة"} />
            <NumberInput source="drips_per_palm" />
            <NumberInput validate={[minValue(0), maxValue(100)]} source="irrigation_system_efficiency"
                label={locale === 'en' ? "Irrigation System Effieciency %" : "كفاءة شبكة الري %"} />
            <SelectInput source="irrigation_type" choices={[
                { id: 'Drip Irrigation', name: locale === 'en' ? 'Drip Irrigation' : 'ري بالتنقيط' },
                { id: 'Flood Irrigation', name: locale === 'en' ? 'Flood Irrigation' : 'ري بالغمر' }
            ]} />
            <ReferenceInput perPage={-1} reference="farms" source="farm_id">
                <SelectInput optionText="farm_code" />
            </ReferenceInput>
            <ReferenceInput perPage={-1} reference="palmkcprofiles" source="palmKCProfile_id" label={locale ==="en"?"KC Profile" : "معامل النبات للنخيل - KC"}>
                <SelectInput optionText={locale==="en"?"name":"name_ar"} />
            </ReferenceInput>
            <ReferenceInput perPage={-1} reference="palmcanopyareaprofiles" source="canopyAreaProfile_id" label={locale ==="en"?"Palm Canopy Profile" : "مساحة المجموع الخضرى"}>
                <SelectInput optionText={locale==="en"?"name":"name_ar"} />
            </ReferenceInput>
            <ReferenceArrayInput source="supervisors" reference="users"
                parse={v => {
                    return JSON.stringify(v);
                }}
                format={v => {
                    let parsedArray = v;
                    try {
                        parsedArray = JSON.parse(v);
                    }
                    catch (e) {
                        if (typeof v === 'string')
                            parsedArray = [];
                    }
                    return parsedArray;
                }} > 
                <SelectArrayInput optionText="username" />
            </ReferenceArrayInput>
            <TextInput disabled source="borders" />
            {/* <ScalarPercentage
          label="Number"
          name="number"
          source="irrigation_scaler" 
          
        /> */}
            {/* <NumberInput source="irrigation_scaler" validate = {[minValue(0), maxValue(200)]}  step={1} format={v => v=== null?100:v * 100} parse={v => (v/100)} label="Irrigation Scaler %" /> */}
            <BoundedTextField label="Irrigation Scaler [50 -150] %" source="irrigation_scaler" 
            format={(v) =>{ console.log("format" , v);
                return  v===null?100:Math.floor(v * 100);
            }} 
            parse={ v =>{ console.log("parse",v);return v===null || v === ""?0: (parseFloat(v) / 100)}} {...rest} />
        </SimpleForm>
    )
};

export const BlockEdit = props => {
    const { borders: bordersArray } = parse(props.location.search);
    return (
        <Edit undoable={false} {...props}>
            <CustomForm borders={bordersArray} />
        </Edit>
    )
};

export const BlockCreate = props => {
    const locale = useLocale();
    const { farm_id_param: farm_id_string } = parse(props.location.search);
    const farm_id = farm_id_string ? parseInt(farm_id_string, 10) : null;
    return (
        <Create {...props}>
            <SimpleForm redirect="list" className={clsx('tab-rtl-layout')}>
                <TextInput source="qr_value" />
                <TextInput source="name" />
                <TextInput source="name_ar" />
                <NumberInput source="rows" />
                <NumberInput source="columns" />
                <NumberInput source="total_palms" />
                <NumberInput source="actual_palm_count" />
                <NumberInput source="pump_flow_rate" label={locale === 'en' ? "Pump Flow Rate m³/hr" : "معدل تصريف المضخة م۳\\ساعة"} />
                <NumberInput source="palm_drip_flow_rate" label={locale === 'en' ? "Palm Drip Flow Rate L/hr" : "معدل تصريف النقاط لتر\\ساعة"} />
                <NumberInput source="drips_per_palm" />
                <NumberInput validate={[minValue(0), maxValue(100)]} source="irrigation_system_efficiency"
                    label={locale === 'en' ? "Irrigation System Effieciency %" : "كفاءة شبكة الري %"} />
                <SelectInput source="irrigation_type" choices={[
                    { id: 'Drip Irrigation', name: locale === 'en' ? 'Drip Irrigation' : 'ري بالتنقيط' },
                    { id: 'Flood Irrigation', name: locale === 'en' ? 'Flood Irrigation' : 'ري بالغمر' }
                ]} />
                <ReferenceInput perPage={-1} reference="farms" source="farm_id" defaultValue={farm_id}>
                    <SelectInput optionText="farm_code" />
                </ReferenceInput>
                <ReferenceArrayInput source="supervisors" reference="users"
                    parse={v => {
                        return JSON.stringify(v);
                    }}
                    format={v => {
                        return typeof v === 'string' ? JSON.parse(v) : v;
                    }} >
                    <SelectArrayInput optionText="username" />
                </ReferenceArrayInput>
                <TextInput disabled source="borders" />
            </SimpleForm>
        </Create>
    )
};


const BlockActivity = (props) => {
    const locale = useLocale();

    const [data, setData] = useState(null);
    const [subcategory, setSubcategory] = useState(null);
    const [activity, setActivity] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/inventoryevents/${props.record.inventoryevent_id}`);
            setData(response.json);
            if (response.json.subcategory_id) {
                const subcategoryResponse = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/inventorysubcategories/${response.json.subcategory_id}`);
                setSubcategory(subcategoryResponse.json);
            }
        };
        if (props.record.inventoryevent_id)
            fetchData();
    }, [props.record.inventoryevent_id]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/blockactivitytypes?id=${props.record.activity_id}`);
            if (response.json.length > 0)
                setActivity(response.json[0].activity);
        };
        if (props.record.activity_id)
            fetchData();
    }, [props.record.activity_id]);

    const MyTextField = props => {
        const locale = useLocale();
        return <span>{locale === 'en' ? props.record[props.source] : props.arabic[props.record[props.source]]}</span>
    }
    const MyChipList = props => {
        const locale = useLocale();
        const classes = useStyles();
        try {
            const activity = JSON.parse(props.record.activity_actions);
            if (activity.length > 0)
                return activity.map(chip => <ChipField className={classes.chipBackground} source="id" record={{ id: chip }} />)
        }
        catch (e) {
            console.log(e);
            return null;
        }
    }

    const BunchCoverField = props => {
        const locale = useLocale();
        return <span>{locale === 'en' ? (props.record[props.source] ? "Yes" : "No") : (props.record[props.source] ? "نعم" : "لا")}</span>
    }

    const AmountField = props => {
        return data[props.source];
    }

    const MyReferenceField = ({ reference, source, source2, myResource = "inventoryevents", myRecord = data }) => {
        return <ReferenceField reference={reference} source={source}
            basePath={`/${myResource}`} resource={myResource} record={myRecord}>
            <TextField source={source2} />
        </ReferenceField>
    }
    return (
        <Show {...props} title=" ">
            <SimpleShowLayout className={clsx('tab-rtl-layout')}>
                {props.record.chemical_amount &&
                    <Tab label="Chemicals">
                        <ReferenceField reference="chemicaltypes" source="chemicaltype_id" allowEmpty={true} >
                            <TextField source="type" />
                        </ReferenceField>
                        <TextField source="chemical_amount" />
                        <TextField source="chemical_unit" />
                    </Tab>
                }
                {props.record.pesttype_id &&
                    <Tab label="Pest" path="#">
                        <ReferenceField reference="pests" source="pesttype_id"  label={locale =="ar"?"نوع الافة":"pest type"}>
                            <TextField source={locale ==="ar"? "name_ar":"name"} />
                        </ReferenceField>
                        <ReferenceField reference="infestationtechniques" source="technique_id"  label={locale =="ar"?"طريقة الكشف":"Infestation technique"}>
                            <TextField source={locale ==="ar"? "technique_ar" : "technique"} />
                        </ReferenceField>
                        <RichTextField source="notes" label={locale ==="ar"? "ملاحظات":"Notes"} />
                    </Tab>
                }
                {props.record.inventoryevent_id &&
                    <Tab label="Inventory">
                        {data && <MyReferenceField source="inventory_id" reference="inventories" source2={locale === 'en' ? "name" : "name_ar"}
                            addLabel={true} label={locale === 'en' ? "Inventory" : "رمز المخزن"} />}

                        {data && <MyReferenceField source="subcategory_id" reference="inventorysubcategories" source2={locale === 'en' ? "name" : "name_ar"}
                            addLabel={true} label={locale === 'en' ? "Inventory Subcategory" : "الفئة الفرعية"} />}

                        {data && subcategory && <MyReferenceField source="unit_id" reference="units" source2={locale === 'en' ? "name" : "name_ar"}
                            addLabel={true} label={locale === 'en' ? "Unit" : "الوحدة"}
                            myRecord={subcategory} myResource="inventorysubcategories" />}

                        {data && <AmountField source="amount" addLabel label={locale === 'en' ? "Amount" : "الكمية"} />}
                    </Tab>
                }
                {props.record.fertilizer_amount &&
                    <Tab label="Fertilization">
                        <ReferenceField source="fertilizertype_id" reference="palmfertilizationtypes"
                            label={locale === 'en' ? "Fertilizer Type" : "نوع التسميد"} allowEmpty={true}>
                            <TextField source={locale === 'en' ? "name" : "name_ar"} />
                        </ReferenceField>
                        <ReferenceField source="fertilizer_subcategory_id" reference="inventorysubcategories"
                            label={locale === 'en' ? "Fertilizer Subcategory" : "نوع التسميد الفرعي"} allowEmpty={true}>
                            <TextField source={locale === 'en' ? "name" : "name_ar"} />
                        </ReferenceField>
                        <TextField source="fertilizer_amount" label={locale === 'en' ? "Fertilizer Amount" : "كمية التسميد"} />
                    </Tab>
                }
                {props.record.yield_sorting &&
                    <Tab label="yield_sorting">
                        <UnitField source="yield_sorting" unit="KG" unit_ar="كيلو جرام" label={locale === 'en' ? "Quantity" : "الكمية"} addLabel />
                    </Tab>
                }
                {activity === 'Land Preparation' &&
                    <Tab label="Land Preparation">
                        <MyTextField source="activity_mechanism" arabic={{ 'Land Grading': 'Land Grading', 'Land Plowing': 'Land Plowing' }}
                            label={locale === 'en' ? 'Land Preparation Type' : "طريقة تجهيز الارض"} addLabel />
                    </Tab>
                }
                {activity === 'Yeild' &&
                    <Tab label="Yeild">
                        <UnitField source="actual_production" unit="KG" unit_ar="كيلو جرام" addLabel />
                        <MyChipList source="activity_actions" label={locale === 'en' ? 'Yield Mechanism' : "طريقة الحصاد"} addLabel />
                    </Tab>
                }
                {activity === 'Pruning' &&
                    <Tab label="Pruning">
                        <MyTextField source="activity_mechanism" arabic={{ 'Automatic': 'تلقائي', 'Manual': 'عادي' }}
                            label={locale === 'en' ? 'Pruning Mechanism' : "طريقة التقليم"} addLabel />
                        <MyChipList source="activity_actions" label={locale === 'en' ? 'Pruning Type' : "نوع التقليم"} addLabel />
                    </Tab>
                }
                {activity === 'Dethroning' &&
                    <Tab label="Dethroning">
                        <MyTextField source="dethroning" arabic={{ 'Automatic': 'تلقائي', 'Manual': 'عادي' }}
                            label={locale === 'en' ? 'Dethroning Type' : "نوع الإزالة"} addLabel />
                    </Tab>
                }
                {activity === 'Thinning' &&
                    <Tab label="Thinning">
                        <MyTextField source="activity_mechanism" arabic={{ 'Automatic': 'تلقائي', 'Manual': 'عادي' }}
                            label={locale === 'en' ? 'Thinning Mechanism' : "طريقة الخف"} addLabel />
                        <MyChipList source="activity_actions" label={locale === 'en' ? 'Thinning Type' : "نوع الخف"} addLabel />
                    </Tab>
                }
                {activity === 'Pollination' &&
                    <Tab label="Pollination">
                        <TextField source="pollinated_branches" />
                        <MyTextField source="activity_mechanism" arabic={{ 'Automatic': 'تلقائي', 'Manual': 'عادي' }}
                            label={locale === 'en' ? 'Pollination Mechanism' : "طريقة التلقيح"} addLabel />
                        <BunchCoverField source="bunch_cover" label={"Bunch Cover"} addLabel />
                    </Tab>
                }
                {activity === 'Weed Control' &&
                    <Tab label="Weed Control">
                        <MyTextField source="activity_mechanism" arabic={{ 'Automatic': 'تلقائي', 'Manual': 'عادي' }}
                            label={locale === 'en' ? 'Weed Control Type' : "نوع الإزالة"} addLabel />
                    </Tab>
                }
                {activity === 'Irrigation' &&
                    <Tab label="irrigation">
                        <MinutesField source="actual_irrigation_time" addLabel />
                        <MinutesField source="projected_irrigation_time" addLabel />
                        <UnitField source="actual_irrigated_water" unit="m³" unit_ar="م۳" addLabel />
                        <UnitField source="projected_irrigation_water" unit="m³" unit_ar="م۳" addLabel />
                        <IrrigationReasonField source="irrigation_reason" addLabel />
                    </Tab>
                }
                {props.record.palm_id &&
                    <Tab label="palm_id">
                        <ReferenceField source="palm_id" reference="palms" label={locale === 'en' ? "Palm QR" : "رمز النخلة"}
                            allowEmpty={true}>
                            <TextField source="qr_value" />
                        </ReferenceField>
                    </Tab>
                }
                {props.record.row_number &&
                    <Tab label="row_number">
                        <TextField source="row_number" label={locale === 'en' ? "Row Number" : "رقم الصف"} />
                    </Tab>
                }
                <ReferenceManyField perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
                    reference="workerlogs" target="activity_id" label={locale === 'en' ? "Workers" : "العمال"}>
                    <Datagrid>
                          
                        <ReferenceField source="worker_id" reference="workers" allowEmpty={true}>
                            <TextField source="name" />
                        </ReferenceField>
                        <TextField source="amount" />
                        <TextField source="palms_number" label={"Palms Number"} />
                        <TextField source="hours" />
                        <TextField source="rating" />
                        <TextField source="notes" />
                    </Datagrid>
                </ReferenceManyField>
            </SimpleShowLayout>
        </Show>
    )
};

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 30,
        borderRadius: 20,
    },
    bar: {
        borderRadius: 20,
    },
}))(LinearProgress);

const PostShowActions = ({ basePath, data, resource }) => {
    const locale = useLocale();
    const [csvProgress, setProgress] = useState(0);
    return (
        <TopToolbar>
            {/* Add your custom actions */}
            <Button
                color="primary"
                component="span"
                onClick={async () => {
                    // const t0 = performance.now();
                    var QRCode = require('qrcode');
                    const palms = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/palms?block_id=${data.id}&_limit=-1`);
                    let codes = palms.json.map(palm => {
                        return {
                            qr_value: palm.qr_value,
                            palm_number: palm.palm_number
                        }
                    });
                    let QrCodes = [];
                    for (let code of codes) {
                        QRCode.toDataURL(code.qr_value, {
                            errorCorrectionLevel: 'H',
                            width: 200,
                            margin: 2
                        }, function (err, url) {
                            QrCodes.push({
                                url: url,
                                ...code
                            });
                        });
                    }
                    // const t1 = performance.now();
                    // console.log("time to generate qrcodes without text " + (t1 - t0) + " milliseconds.");
                    console.log('Finished creating URLs');

                    const imagesPromise = QrCodes.map(code => new Promise((resolve, reject) => {
                        const img = new Image();
                        img.onerror = () => reject(new Error('Couldn\'t load image'));
                        img.onload = () => resolve({ img: img, ...code });
                        img.src = code.url;
                    }));


                    const canvas = window.document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.width = 200;
                    canvas.height = 250;
                    ctx.globalAlpha = 1;

                    const images = await Promise.all(imagesPromise);
                    console.log('Finished loading images');

                    QrCodes = images.map(({ img, qr_value, palm_number }) => {
                        ctx.fillStyle = 'white';
                        ctx.fillRect(0, 0, canvas.width, canvas.height);

                        ctx.drawImage(img, 0, 0);

                        ctx.font = '20px Arial';
                        ctx.fillStyle = 'black';
                        const qrText = qr_value;
                        const textWidth = ctx.measureText(qrText).width;
                        const textLeftOffset = Math.max(canvas.width * 0.05, (canvas.width / 2) - (textWidth / 2));
                        ctx.fillText(qrText, textLeftOffset, 225, canvas.width * 0.9);
                        // if (palm_number) {
                        //     const numberText = palm_number.toString(10);
                        //     const numberTextWidth = ctx.measureText(numberText).width;
                        //     const numberLeftOffset = Math.max(canvas.width * 0.05, (canvas.width / 2) - (numberTextWidth / 2));
                        //     ctx.fillText(numberText, numberLeftOffset, 250, canvas.width * 0.9);
                        // }
                        const canvasURL = canvas.toDataURL('image/png', 0.92);
                        return canvasURL;
                    });
                    console.log('Finished creating canvas');
                    // const t3 = performance.now();
                    // console.log("time to generate qrcodes with text " + (t3 - t1) + " milliseconds.")

                    let QrCodesDivided = {};
                    const maxCodes = 2100;
                    for (let i = 0; i < QrCodes.length; i++) {
                        if (!QrCodesDivided[Math.floor(i / maxCodes)])
                            QrCodesDivided[Math.floor(i / maxCodes)] = [];

                        QrCodesDivided[Math.floor(i / maxCodes)].push(QrCodes[i]);
                    }

                    async function createDocument(documentCodes, index) {
                        const doc = new jsPDF({
                            unit: 'in'
                        });

                        const pageWidth = 8.27;
                        const pageHeight = 11.69;
                        const rows = 6;
                        const cols = 5;
                        const imageWidth = (pageWidth / cols) * 0.8;
                        const imageHeight = imageWidth * (canvas.height / canvas.width);

                        function generatePDF() {
                            let code = 0;

                            while (code < documentCodes.length) {
                                for (let i = 0; i < rows; i++) {
                                    for (let j = 0; j < cols; j++) {
                                        const leftOffset = (pageWidth / cols) * j + (pageWidth / cols) * 0.1;
                                        const upOffset = (pageHeight / rows) * i + (pageWidth / cols) * 0.1;
                                        doc.addImage(documentCodes[code++], 'PNG', leftOffset, upOffset, imageWidth, imageHeight);
                                        if (code === documentCodes.length)
                                            return;
                                    }
                                }
                                doc.addPage();
                            }
                        }

                        generatePDF();
                        // const t4 = performance.now();
                        // console.log("time to generate PDF file " + (t4 - t3) + " milliseconds.")
                        doc.save(`${data.qr_value}_${parseInt(index, 10) + 1}.pdf`);
                    }
                    for (let index of Object.keys(QrCodesDivided)) {
                        createDocument(QrCodesDivided[index], index);
                    }
                }}
            >
                <>
                    <GetAppIcon style={{ fontSize: 20 }} />
                    <span style={{ paddingLeft: '0.8125rem' }}>{locale === 'en' ? "Export QR" : "تنزيل الرموز"}</span>
                </>
            </Button>
            <>
                <input
                    type="file"
                    id="csvInput"
                    style={{ display: 'none' }}
                    accept='.csv'
                    onChange={(event) => myHandleChange(event, "csv", setProgress)}
                />
                <label htmlFor="csvInput" style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <Button color="primary" component="span">
                        <>
                            <GetAppIcon style={{ transform: 'rotate(180deg)', fontSize: 20 }} />
                            <span style={{ paddingLeft: '0.8125rem' }}>{locale === 'en' ? "Import Palm File" : "رفع ملف النخيل"}</span>
                        </>
                    </Button>
                </label>
                <Popup
                    open={csvProgress ? true : false}
                    contentStyle={{
                        position: 'relative',
                        background: '#00000000',
                        width: '50%',
                        margin: 'auto',
                        border: '0px solid rgb(187, 187, 187)',
                        padding: '0px'
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'center' }} >
                        <Box width="100%" className="modal">
                            <BorderLinearProgress variant="determinate" value={Math.round(csvProgress)} />
                        </Box>
                    </div>
                </Popup>
            </>
            <EditButton basePath={basePath} record={data} />
        </TopToolbar >
    )
};

const InventoryField = ({ source, ...props }) => {
    const locale = useLocale();
    return props.record && props.record.type === "Inventory" ?
        <TextField source="type" {...props} /> :
        <ReferenceField source="activity_id" reference="blockactivitytypes" allowEmpty={true} {...props} >
            <TextField source={locale === 'en' ? "activity" : "activity_ar"} />
        </ReferenceField>
}
const BlockName = ({ record }) => {
    const locale = useLocale();
    return <span>{record ? (locale === 'en' ? record.name : record.name_ar) : ''}</span>;
}
export const BlockShow = (props) => {
    const locale = useLocale();
    return <Show actions={<PostShowActions />} title={<BlockName />} {...props}>
        <TabbedShowLayout>
            <Tab label={locale === 'en' ? "summary" : "الملخص"} contentClassName={clsx('tab-rtl-layout')} >
                  
                <TextField source="qr_value" />
                <TextField source={locale === 'en' ? "name" : "name_ar"} />
                <TextField source="rows" />
                <TextField source="columns" />
                <TextField source="total_palms" />
                <TextField source="actual_palm_count" />
                <TextField source="current_sprouts" />
                <TextField source="splitted_sprouts" />
                <TextField source="current_appendages" />
                <TextField source="splitted_appendages" />
                <TextField source="projected_production" />
                <TextField source="actual_production" />
                <UnitField source="pump_flow_rate" unit="m³/hr" unit_ar="م۳\ساعة" addLabel />
                <UnitField source="palm_drip_flow_rate" unit="L/hr" unit_ar="لتر\ساعة" addLabel />
                <TextField source="drips_per_palm" />
                <UnitField source="irrigation_system_efficiency" unit="%" unit_ar="%" addLabel />
                <IrrigationTypeField source="irrigation_type" addLabel />
                <ReferenceField reference="farms" source="farm_id" allowEmpty={true} >
                    <TextField source="farm_code" />
                </ReferenceField>
            </Tab>
            <Tab label={locale === 'en' ? "Palms" : "النخيل"}>
                <ReferenceManyField perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} reference="palms" target="block_id" addLabel={false}>
                    <Datagrid>
                          
                        <LaunchIconLink source={locale === 'en' ? "Location" : "الموقع"} />
                        <TextField source="qr_value" />
                        <ReferenceField source="palm_type" reference="palmtypes" allowEmpty={true} >
                            <TextField source={locale === 'en' ? "type" : "type_ar"} />
                        </ReferenceField>
                        <TextField source="palm_number" />
                        <GenderField source="gender" />
                        <TextField source="age" />
                        <TextField source={locale === 'en' ? "health_status" : "health_status_ar"} />
                        <TextField source="current_sprouts" />
                        <TextField source="splitted_sprouts" />
                        <TextField source="current_appendages" />
                        <TextField source="splitted_appendages" />
                        <ShowButton />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab label={locale === 'en' ? "Activities" : "السجلات"}>
                <ReferenceManyField perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} reference="blocklogs" target="block_id" addLabel={false}>
                    <Datagrid expand={<BlockActivity />} >
                          
                        <InventoryField source="activity_id" />
                        <ReferenceField source="created_by" reference="users" allowEmpty={true} >
                            <TextField source="username" />
                        </ReferenceField>
                        <DateField source="created_at" locales="en-GB" showTime />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab label={locale === 'en' ? "Map" : "الخريطة"}>
                <ShowBorders source="borders" type="Blocks" />
            </Tab>
            <Tab label={locale === 'en' ? "Map Tool" : "أداة تعيين الخريطة"}>
                <DrawBorders table="blocks" source="borders" />
            </Tab>
            <Tab label={locale === 'en' ? "Sensors" : "المستشعرات"}>
                <FilterSensors filterParam="block_id" />
            </Tab>
            <Tab label={locale === 'en' ? "Auto Label" : "ترقيم تلقائي"}>
                <DrawLine />
            </Tab>
        </TabbedShowLayout>
    </Show>
};

const BlockFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput perPage={-1} reference="farms" source="farm_id" >
            <SelectInput optionText="farm_code" />
        </ReferenceInput>
    </Filter>
);
