import React from "react";
import { SvgIcon } from "@material-ui/core";

export const TrapIcon = () => {
  return (
    <SvgIcon
      enable-background="new 0 0 64 64"
      height="512"
      viewBox="0 0 64 64"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="32"
        x2="32"
        y1="63"
        y2="1"
      >
        <stop offset="0" stop-color="#9f2fff" />
        <stop offset="1" stop-color="#0bb1d3" />
      </linearGradient>
      <path
        d="m11 14v2 2 4c0 5.004 2.846 9.349 7 11.521v26.479c0 1.654 1.346 3 3 3s3-1.346 3-3v-25h16v25c0 1.654 1.346 3 3 3s3-1.346 3-3v-26.479c4.154-2.172 7-6.517 7-11.521v-4-2-2c0-7.168-5.832-13-13-13h-16c-7.168 0-13 5.832-13 13zm11 46c0 .552-.448 1-1 1s-1-.448-1-1v-25.636c.645.209 1.315.359 2 .466zm22 0c0 .552-.448 1-1 1s-1-.448-1-1v-25.17c.685-.107 1.355-.257 2-.466zm-4-27h-16c-4.664 0-8.648-2.922-10.246-7.027 2.381 3.053 6.083 5.027 10.246 5.027h16c4.163 0 7.865-1.974 10.246-5.027-1.598 4.105-5.582 7.027-10.246 7.027zm11-19v2 2c0 6.065-4.935 11-11 11h-13v-2h9c1.654 0 3-1.346 3-3s-1.346-3-3-3h-9v-2h9c1.654 0 3-1.346 3-3s-1.346-3-3-3h-9v-2h9c1.654 0 3-1.346 3-3s-1.346-3-3-3h-9v-2h13c6.065 0 11 4.935 11 11zm-27-11h1v4h11c.552 0 1 .449 1 1s-.448 1-1 1h-11v6h11c.552 0 1 .449 1 1s-.448 1-1 1h-11v6h11c.552 0 1 .449 1 1s-.448 1-1 1h-11v4h-1c-6.065 0-11-4.935-11-11v-2-2c0-6.065 4.935-11 11-11zm-4 16c1.654 0 3-1.346 3-3s-1.346-3-3-3-3 1.346-3 3 1.346 3 3 3zm0-4c.552 0 1 .449 1 1s-.448 1-1 1-1-.449-1-1 .448-1 1-1zm24 4c1.654 0 3-1.346 3-3s-1.346-3-3-3-3 1.346-3 3 1.346 3 3 3zm0-4c.552 0 1 .449 1 1s-.448 1-1 1-1-.449-1-1 .448-1 1-1z"
        fill="url(#SVGID_1_)"
      />
    </SvgIcon>
  );
};
