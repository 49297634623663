import React from "react";
import { SvgIcon } from "@material-ui/core";

export const PivotsIcon = () => {
  return (
    <SvgIcon
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
    >
      <path
        d="m120.537 421.463a234.019 234.019 0 0 1 132.031-397.078l.013-2.343a233.988 233.988 0 0 0 -162.044 399.421 232.376 232.376 0 0 0 159.5 68.462l.014-2.669a232.244 232.244 0 0 1 -129.514-65.793z"
        fill="#d4e1f4"
      />
      <path
        d="m259.286 54.54-1.828 11.86c55.179 8.5 100.7 29.629 131.638 61.087 31.949 32.486 48.149 74.554 48.149 125.038 0 50.44-18.106 97.2-50.982 131.671-33.456 35.078-79.718 54.4-130.263 54.4v12c53.858 0 103.2-20.639 138.946-58.114 35.015-36.712 54.3-86.416 54.3-139.954 0-52.986-17.841-99.133-51.593-133.452-32.767-33.32-80.613-55.635-138.367-64.536z"
        fill="#0635c9"
      />
      <path
        d="m257.755 378.463 1.845 11.857c75.363-11.737 126-64.778 126-131.986 0-35.368-11.215-66.429-32.433-89.822-21.722-23.95-53.569-39.3-92.1-44.389l-1.571 11.9c71.448 9.438 114.1 55.163 114.1 122.314a111.808 111.808 0 0 1 -31.348 78.463c-20.848 21.892-50.062 36.3-84.493 41.663z"
        fill="#0635c9"
      />
      <path
        d="m256 10a246 246 0 0 0 -173.948 419.948 246 246 0 1 0 347.9-347.9 244.39 244.39 0 0 0 -173.952-72.048zm-234 246a233.97 233.97 0 0 1 230.581-233.958l-2.548 467.883a234 234 0 0 1 -228.033-233.925zm399.463 165.463a232.369 232.369 0 0 1 -159.43 68.459l2.547-467.751a233.99 233.99 0 0 1 156.883 399.292z"
        fill="#1ae5be"
      />
    </SvgIcon>
  );
};
