import React, { useState, useEffect } from 'react';
import {
    Datagrid, TextField, useLocale, ShowButton,
    Show, Tab, TabbedShowLayout, TextInput, ReferenceInput,
    SelectInput, SimpleForm, Edit, Create, ReferenceManyField,
    Pagination, ReferenceField, DateField, SimpleShowLayout,
    NumberInput, ReferenceArrayField, SingleFieldList, ChipField,
    ReferenceArrayInput, SelectArrayInput, Filter
} from 'react-admin';
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import MyCreateButton from './helpers/MyCreateButton';
import List from './theme/components/List'
import DrawBorders from './drawBorders';
import ShowBorders from './showBorders';
import { parse } from 'query-string';
import httpClient from './httpClient';
import LaunchIcon from '@material-ui/icons/Launch';
import clsx from 'clsx';
import { UnitField } from './helpers/irrigationComponents';

const FixedFloatField = props => props.record[props.source] !== null ?
    <span>{Number.parseFloat(props.record[props.source]).toFixed(2)}</span> :
    null;

const LaunchIconLink = ({ record = {} }) =>
    <a href={`#/pivots/${record.id}/show/2`} target="_blank">
        <LaunchIcon style={{ color: 'teal' }} />
    </a>;

const SupervisorsField = (props) => {
    if (props.record.supervisors) {
        const record = { ...props.record };
        record.supervisors = JSON.parse(record.supervisors);
        return <ReferenceArrayField reference="users" source="supervisors" {...props} record={record}>
            <SingleFieldList>
                <ChipField source="username" />
            </SingleFieldList>
        </ReferenceArrayField>
    }
    else
        return null;
}

export const PivotList = props => {
    const locale = useLocale();
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} filters={<PivotFilter />} {...props}>
            <Datagrid>
                  
                <TextField source="qr_value" />
                <LaunchIconLink source={locale === 'en' ? "Production" : "الإنتاج"} />
                <TextField source="total_sections" />
                <FixedFloatField source="area" />
                <TextField source="water_pump_flow_rate" />
                <FixedFloatField source="accumulated_water_consumption" />
                <FixedFloatField source="accumulated_water_consumption_feddan" />
                <SupervisorsField source="supervisors" />
                <ReferenceField reference="farms" source="farm_id" allowEmpty={true} >
                    <TextField source="farm_code" />
                </ReferenceField>
                <ShowButton />
            </Datagrid>
        </List>
    )
}

const CustomForm = (props) => {
    const locale = useLocale();
    let record = { ...props.record };
    if (props.borders)
        record.borders = props.borders;
    return (
        <SimpleForm {...props} record={record} toolbar={<CustomToolbar />} className={clsx('tab-rtl-layout')}>
            <TextInput disabled source="id" />
            <TextInput disabled source="qr_value" />
            <NumberInput source="water_pump_flow_rate" step={0.01} />
            <NumberInput source="irrigation_pump_efficiency" label={locale === 'en' ? 'Irrigation Pump Efficiency %' : "كفاءة مضخة الري %"} />
            <NumberInput source="first_tower_length" step={0.01} label={locale === 'en' ? 'First Tower Length m' : "طول أول برج متر"} />
            <NumberInput source="rest_towers_length" step={0.01} label={locale === 'en' ? 'Rest Towers Length m' : "طول باقي الأبراج متر"} />
            <NumberInput source="number_of_towers" label={locale === 'en' ? 'Number Of Towers' : 'عدد الأبراج'} />
            <ReferenceInput perPage={-1} reference="farms" source="farm_id">
                <SelectInput optionText="farm_code" />
            </ReferenceInput>
            <ReferenceArrayInput source="supervisors" reference="users"
                parse={v => {
                    return JSON.stringify(v);
                }}
                format={v => {
                    let parsedArray = v;
                    try {
                        parsedArray = JSON.parse(v);
                    }
                    catch (e) {
                        if (typeof v === 'string')
                            parsedArray = [];
                    }
                    return parsedArray;
                }} >
                <SelectArrayInput optionText="username" />
            </ReferenceArrayInput>
            <TextInput disabled source="borders" />
        </SimpleForm>
    )
};

export const PivotEdit = props => {
    const { borders: bordersArray } = parse(props.location.search);
    return (
        <Edit undoable={false} {...props}>
            <CustomForm borders={bordersArray} />
        </Edit>
    )
};

export const PivotCreate = props => {
    const locale = useLocale();
    const { farm_id_param: farm_id_string } = parse(props.location.search);
    const farm_id = farm_id_string ? parseInt(farm_id_string, 10) : null;
    return (
        <Create {...props}>
            <SimpleForm redirect="list" className={clsx('tab-rtl-layout')}>
                <TextInput source="qr_value" />
                <NumberInput source="water_pump_flow_rate" step={0.01} />
                <NumberInput source="irrigation_pump_efficiency" label={locale === 'en' ? 'Irrigation Pump Efficiency %' : "كفاءة مضخة الري %"} />
                <NumberInput source="last_tower_speed" step={0.01} label={locale === 'en' ? 'Last Tower Speed m/min' : "سرعة اخر برج متر/دقيقة"} />
                <NumberInput source="first_tower_length" step={0.01} label={locale === 'en' ? 'First Tower Length m' : "طول أول برج متر"} />
                <NumberInput source="rest_towers_length" step={0.01} label={locale === 'en' ? 'Rest Towers Length m' : "طول باقي الأبراج متر"} />
                <NumberInput source="number_of_towers" label={locale === 'en' ? 'Number Of Towers' : 'عدد الأبراج'} />
                <ReferenceInput perPage={-1} reference="farms" source="farm_id" defaultValue={farm_id}>
                    <SelectInput optionText="farm_code" />
                </ReferenceInput>
                <ReferenceArrayInput source="supervisors" reference="users"
                    parse={v => {
                        return JSON.stringify(v);
                    }}
                    format={v => {
                        return typeof v === 'string' ? JSON.parse(v) : v;
                    }} >
                    <SelectArrayInput optionText="username" />
                </ReferenceArrayInput>
                <TextInput disabled source="borders" />
            </SimpleForm>
        </Create>
    )
};

const PivotActivity = (props) => {
    const locale = useLocale();
    const [activity, setActivity] = useState(null);
    const [data, setData] = useState(null);
    const [subcategory, setSubcategory] = useState(null);

    useEffect(() => {
        const fetchActivity = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/pivotactivitytypes/${props.record.activity_id}`);
            setActivity(response.json);
        };
        const fetchInventory = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/inventoryevents/${props.record.inventoryevent_id}`);
            setData(response.json);
            if (response.json.subcategory_id) {
                const subcategoryResponse = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/inventorysubcategories/${response.json.subcategory_id}`);
                setSubcategory(subcategoryResponse.json);
            }
        };

        if (props.record.inventoryevent_id)
            fetchInventory();

        if (props.record.activity_id)
            fetchActivity();
    }, []);


    const MyTextField = props => {
        return data[props.source];
    }
    const MyReferenceField = ({ reference, source, source2, myResource = "inventoryevents", myRecord = data }) => {
        return <ReferenceField reference={reference} source={source}
            basePath={`/${myResource}`} resource={myResource} record={myRecord}>
            <TextField source={source2} />
        </ReferenceField>
    }
    return (
        <Show {...props} title=" ">
            <SimpleShowLayout className={clsx('tab-rtl-layout')}>
                {props.record.speed &&
                    <Tab label="speed">
                        <TextField source="speed" />
                        <TextField source="water_amount" />
                        <TextField source="irrigation_period" />
                    </Tab>
                }
                {activity && props.record.type_id &&
                    <Tab>
                        <ReferenceField source="type_id"
                            reference={activity.activity === 'Fertilization' ? "pivotfertilizationtypes" : "pivotspraytypes"}
                            allowEmpty={true}>

                            <TextField source={locale === 'en' ? "name" : "name_ar"} />
                        </ReferenceField>
                        <ReferenceField source="subcategory_id" reference="inventorysubcategories" allowEmpty={true}>
                            <TextField source={locale === 'en' ? "name" : "name_ar"} />
                        </ReferenceField>
                        <TextField source="amount" />
                    </Tab>
                }
                {props.record.inventoryevent_id &&
                    <Tab label="Inventory">
                        {data && <MyReferenceField source="inventory_id" reference="inventories" source2={locale === 'en' ? "name" : "name_ar"}
                            addLabel={true} label={locale === 'en' ? "Inventory" : "رمز المخزن"} />}

                        {data && <MyReferenceField source="subcategory_id" reference="inventorysubcategories" source2={locale === 'en' ? "name" : "name_ar"}
                            addLabel={true} label={locale === 'en' ? "Inventory Subcategory" : "الفئة الفرعية"} />}

                        {data && subcategory && <MyReferenceField source="unit_id" reference="units" source2={locale === 'en' ? "name" : "name_ar"}
                            addLabel={true} label={locale === 'en' ? "Unit" : "الوحدة"}
                            myRecord={subcategory} myResource="inventorysubcategories" />}

                        {data && <MyTextField source="amount" addLabel={true} label={locale === 'en' ? "Amount" : "الكمية"} />}
                    </Tab>
                }
                <ReferenceManyField perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
                    reference="workerlogs" target="activity_id" label={locale === 'en' ? "Workers" : "العمال"}>
                    <Datagrid>
                          
                        <ReferenceField source="worker_id" reference="workers" allowEmpty={true}>
                            <TextField source="name" />
                        </ReferenceField>
                        <TextField source="hours" />
                        <TextField source="rating" />
                        <TextField source="notes" />
                    </Datagrid>
                </ReferenceManyField>
            </SimpleShowLayout>
        </Show>
    )
};

const InventoryField = ({ source, ...props }) => {
    const locale = useLocale();
    return props.record && props.record.type === "Inventory" ?
        <TextField source="type" {...props} /> :
        <ReferenceField source="activity_id" reference="pivotactivitytypes" allowEmpty={true} {...props} >
            <TextField source={locale === 'en' ? "activity" : "activity_ar"} />
        </ReferenceField>
}

const AgeField = props => {
    const today = new Date();
    const start_day = new Date(props.record.season_start_date);
    props.record.age = (today - start_day) > 0 && props.record.season_start_date ? Math.floor((today - start_day) / (1000 * 60 * 60 * 24)) : 0;

    return <TextField {...props} />
}
const PivotName = ({ record }) => {
    const locale = useLocale();
    return <span>{record ? (locale === 'en' ? record.qr_value : record.qr_value) : ''}</span>;
}
export const PivotShow = (props) => {
    const locale = useLocale();
    return <Show {...props} title={<PivotName />}>
        <TabbedShowLayout>
            <Tab label={locale === 'en' ? "summary" : "الملخص"} contentClassName={clsx('tab-rtl-layout')}>
                  
                <TextField source="qr_value" />
                <TextField source="total_sections" />
                <FixedFloatField source="area" addLabel />
                <TextField source="water_pump_flow_rate" />
                <UnitField source="irrigation_pump_efficiency" unit="%" unit_ar="%"
                    label={locale === 'en' ? 'Irrigation Pump Efficiency' : "كفاءة مضخة الري"} addLabel />
                <UnitField source="last_tower_speed" unit="m/min" unit_ar="متر/دقيقة"
                    label={locale === 'en' ? 'Last Tower Speed' : "سرعة اخر برج"} addLabel />
                <UnitField source="first_tower_length" unit="m" unit_ar="متر"
                    label={locale === 'en' ? 'First Tower Length' : "طول أول برج"} addLabel />
                <UnitField source="rest_towers_length" unit="m" unit_ar="متر"
                    label={locale === 'en' ? 'Rest Towers Length' : "طول باقي الأبراج"} addLabel />
                <TextField source="number_of_towers" label={locale === 'en' ? 'Number Of Towers' : 'عدد الأبراج'} />
                <FixedFloatField source="accumulated_water_consumption" addLabel />
                <FixedFloatField source="accumulated_water_consumption_feddan" addLabel />
                <ReferenceField reference="farms" source="farm_id" allowEmpty={true} >
                    <TextField source="farm_code" />
                </ReferenceField>
            </Tab>
            <Tab label={locale === 'en' ? "Activities" : "السجلات"}>
                <ReferenceManyField perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} reference="pivotlogs" target="pivot_id" addLabel={false}>
                    <Datagrid expand={<PivotActivity />}>
                          
                        <InventoryField source="activity_id" />
                        <ReferenceField source="created_by" reference="users" allowEmpty={true} >
                            <TextField source="username" />
                        </ReferenceField>
                        <DateField source="created_at" locales="en-GB" showTime />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab label={locale === 'en' ? "Sections" : "القطاعات الفرعية"}>
                <ReferenceManyField perPage={100} sort={{ field: 'id', order: 'ASC' }} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} reference="sections" target="pivot_id" addLabel={false}>
                    <Datagrid>
                          
                        <TextField source="qr_value" />
                        <ReferenceField source="plantationtype_id" reference="pivotplantationtypes" allowEmpty={true}>
                            <TextField source={locale === 'en' ? "name" : "name_ar"} />
                        </ReferenceField>
                        <AgeField source="age" />
                        <ReferenceField source="growthstage_id" reference="growthstages" allowEmpty={true} link={false}>
                            <TextField source={locale === 'en' ? "name" : "name_ar"} />
                        </ReferenceField>
                        <DateField source="season_start_date" />
                        <DateField source="season_end_date" />
                        <FixedFloatField source="area" />
                        <FixedFloatField source="accumulated_water_consumption" />
                        <FixedFloatField source="accumulated_water_consumption_feddan" />
                        <FixedFloatField source="projected_production" />
                        <FixedFloatField source="actual_production" />
                        <ShowButton />
                    </Datagrid>
                </ReferenceManyField>
                <MyCreateButton entity="Section" table="sections" param="pivot_id_param" />
            </Tab>
            <Tab label={locale === 'en' ? "Map" : "الخريطة"}>
                <ShowBorders source="borders" type='Pivots' />
            </Tab>
            <Tab label={locale === 'en' ? "Map Tool" : "أداة تعيين الخريطة"}>
                <DrawBorders table="pivots" source="borders" />
            </Tab>
            {/* <Tab label={locale === 'en' ? "Sensors" : "المستشعرات"}>
                <FilterSensors filterParam="pivot_id" />
            </Tab> */}
        </TabbedShowLayout>
    </Show>
}
const PivotFilter = (props) => {
    const locale = useLocale();
    return <Filter {...props}>
        <ReferenceInput perPage={-1} reference="farms" source="farm_id" >
            <SelectInput optionText="farm_code" />
        </ReferenceInput>
        <TextInput source="qr_value_contains" label={locale === 'en' ? "qr_value" : "رمز قطاع الري المحوري"} />
    </Filter>
};
