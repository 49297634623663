import React from 'react';
import {
    Create, Edit, SimpleForm, TextInput,
    EditButton, Datagrid, TextField, Filter,
    BooleanInput, Show, ReferenceField,
    Tab, TabbedShowLayout, useLocale,
    ShowButton, EmailField, SelectInput, required,
    minLength, email, ReferenceInput, Pagination
} from 'react-admin';
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import List from './theme/components/List'
import clsx from 'clsx';
const validateUserName = [required(), minLength(4)];
const validateEmail = [required(), email()];
const validatePassword = [required(), minLength(6)];
const validateRole = required();

export const UserList = ({ permissions, ...props }) => {
    const locale = useLocale();
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} title={locale === 'en' ? "Supervisors" : "المشرفين"}
            perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} filters={<UserFilter />} {...props}>
            <Datagrid>
                  
                <TextField source="first_name" />
                <TextField source="last_name" />
                <TextField source="username" />
                <TextField source="mobile" />
                <EmailField source="email" />
                <ReferenceField source="role" reference="customroles" allowEmpty={true} link={false} >
                    <TextField source={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceField>
                <ReferenceField source="team_id" reference="teams" allowEmpty={true} >
                    <TextField source="name" />
                </ReferenceField>
                {permissions === 'Admin' &&
                    <EditButton />
                }
            </Datagrid>
        </List>
    )
};

export const UserEdit = props => {
    const locale = useLocale();
    return (
        <Edit undoable={false} {...props}>
            <SimpleForm redirect="list" toolbar={<CustomToolbar />} className={clsx('tab-rtl-layout')}>
                <TextInput disabled source="id" />
                <TextInput source="first_name" validate={required()} />
                <TextInput source="last_name" validate={required()} />
                <TextInput source="username" validate={validateUserName} />
                <TextInput source="mobile" />
                <TextField label="Example: +9711234567" />
                <TextInput source="email" type="email" validate={validateEmail} />
                <TextInput source="password" type="password" validate={validatePassword} />
                <ReferenceInput perPage={-1} source="role" reference="customroles" validate={validateRole} >
                    <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceInput>
                <ReferenceInput perPage={-1} source="team_id" reference="teams" allowEmpty >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <BooleanInput source="confirmed" />
                <BooleanInput source="blocked" />
            </SimpleForm>
        </Edit>
    )
};

export const UserCreate = props => {
    const locale = useLocale();
    return (
        <Create {...props}>
            <SimpleForm redirect="list" className={clsx('tab-rtl-layout')}>
                <TextInput source="first_name" validate={required()} />
                <TextInput source="last_name" validate={required()} />
                <TextInput source="username" validate={validateUserName} />
                <TextInput source="mobile" />
                <TextField label="Example: +9711234567" />
                <TextInput source="email" type="email" validate={validateEmail} />
                <TextInput source="password" type="password" validate={validatePassword} />
                <ReferenceInput perPage={-1} source="role" reference="customroles" validate={validateRole} >
                    <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
                </ReferenceInput>
                <ReferenceInput perPage={-1} source="team_id" reference="teams" allowEmpty >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <BooleanInput source="confirmed" initialValue={false} />
                <BooleanInput source="blocked" initialValue={false} />
            </SimpleForm>
        </Create>
    )
};


export const UserShow = (props) => {
    const locale = useLocale();
    return (
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label="summary">
                    <TextField source="first_name" />
                    <TextField source="last_name" />
                    <TextField source="username" />
                    <EmailField source="email" />
                    <ReferenceField source="role" reference="customroles" allowEmpty={true} link={false} >
                        <TextField source={locale === 'en' ? "name" : "name_ar"} />
                    </ReferenceField>
                    <ReferenceField source="team_id" reference="teams" allowEmpty={true} >
                        <TextField source="name" />
                    </ReferenceField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
};

const UserFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput perPage={-1} source="team_id" reference="teams" >
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);
