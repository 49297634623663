import React from 'react';
import { ReferenceManyField, Datagrid, TextField, ReferenceField, useLocale, ShowButton, Pagination } from 'react-admin'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import httpClient from './httpClient';

const FormHook = props => {
    const locale = useLocale();
    return <FormControlLabel control={
        <Checkbox
            checked={props.checked}
            onChange={props.onChange}
        />}
        label={locale === 'en' ? props.label.type : props.label.type_ar}
        key={props.key}
    />
}
const MyDataGrid = props => {
    const locale = useLocale();
    return <Datagrid {...props}>
          
        <ReferenceField source="devicetype_id" reference="devicetypes" allowEmpty={true} >
            <TextField source={locale === 'en' ? "type" : "type_ar"} />
        </ReferenceField>
        <TextField source="qr_value" />
        <ShowButton />
    </Datagrid>
}
class FilterSensors extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.sensorTypes = [];
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/devicetypes`);
        const stateObj = {};
        for (let item of response.json) {
            this.sensorTypes.push({ id: item.id, type: item.type, type_ar: item.type_ar });
            stateObj[item.id] = false;
        }
        this.setState({ ...stateObj });
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    render() {
        let filterIds = [];
        if (this.state) {
            const deviceTypes = Object.entries(this.state).filter(item => item[1] === true);
            filterIds = deviceTypes.map(item => parseInt(item[0], 10));
        }
        return (
            <div>
                <div>
                    <FormLabel style={{ margin: '1em', marginBottom: '0' }}>Sensor Type</FormLabel>
                    {this.sensorTypes.map(item => (
                        <FormHook
                            checked={this.state[item.id]}
                            onChange={this.handleChange(item.id)}
                            label={item}
                            key={item.id}
                        />
                    ))}
                </div>
                <ReferenceManyField perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} filter={this.state ? { devicetype_id: filterIds } : {}} reference="devices" target={this.props.filterParam} addLabel={false} {...this.props}>
                    <MyDataGrid />
                </ReferenceManyField>
            </div>
        );
    }
}

export default FilterSensors;