import React, { Fragment, useState, useEffect } from 'react'
import { GoogleMap, DrawingManager, LoadScript, Polyline, Polygon, GroundOverlay } from '@react-google-maps/api'
import { Button } from '@material-ui/core';
import { useLocale } from 'react-admin';
import httpClient from './httpClient';
import { CSVLink } from "react-csv";

var rows = [], cols = [];
var polygonArr = [];

const drawingLibrary = ['drawing'];

const formatSingleDigit = (num) => {
    if (num < 10) return '0' + num;
    else return num;
}
function line_intersect(x1, y1, x2, y2, x3, y3, x4, y4) {
    let ua, ub, denom = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
    if (denom == 0) {
        return null;
    }
    ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denom;
    // ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denom;
    return {
        lat: x1 + ua * (x2 - x1),
        lng: y1 + ua * (y2 - y1)
    };
}

function normalLabel(rows, cols, farm_code, block_code, record) {
    let list_intersection = [];
    const inside = require('point-in-polygon');
    const parsedBorders = JSON.parse(record.borders);

    for (let i = 0; i < rows.length; i++) {
        for (let j = 0; j < cols.length; j++) {
            const intersection = line_intersect(rows[i][0]['lat'], rows[i][0]['lng'], rows[i][1]['lat'], rows[i][1]['lng'], cols[j][0]['lat'], cols[j][0]['lng'], cols[j][1]['lat'], cols[j][1]['lng'])
            if (intersection != null && inside([intersection['lng'], intersection['lat']], parsedBorders)) {
                list_intersection.push({
                    gender: '',
                    health_status: '',
                    lat: intersection['lat'],
                    long: intersection['lng'],
                    palm_type: '',
                    deviceuid: '',
                    qr_value: "PLM-" + farm_code + "-" + block_code + "-" + formatSingleDigit(j + 1) + "/" + formatSingleDigit(i + 1),
                    age: '',
                    region_id: '',
                    consecutive_clean_reports: '',
                    row: i + 1,
                    column: j + 1,
                    farm_id: record.farm_id,
                    block_id: record.id,
                    organization_id: record.organization_id,
                    health_status_ar: '',
                    current_sprouts: '',
                    splitted_sprouts: '',
                    current_appendages: '',
                    splitted_appendages: '',
                    average_production_per_branch: '',
                    date_branches: '',
                    projected_production: '',
                    actual_production: '',
                    pollinated_branches: '',
                    country: '',
                    supplier: '',
                    total_drips: '',
                    broken_drips: 0,
                    blocked_drips: 0
                });
            }
        }
    }
    return list_intersection;
}

function calculateStep(final, initial, steps) {
    return (final - initial) / (steps - 1);
}

function smartLabelFunc(rows, cols, farm_code, block_code, record) {
    let list_intersection = [];
    const initialPoint = line_intersect(rows[0][0]['lat'], rows[0][0]['lng'], rows[0][1]['lat'], rows[0][1]['lng'],
        cols[0][0]['lat'], cols[0][0]['lng'], cols[0][1]['lat'], cols[0][1]['lng']);

    const lastCol = line_intersect(rows[0][0]['lat'], rows[0][0]['lng'], rows[0][1]['lat'], rows[0][1]['lng'],
        cols[1][0]['lat'], cols[1][0]['lng'], cols[1][1]['lat'], cols[1][1]['lng']);

    const lastRow = line_intersect(rows[1][0]['lat'], rows[1][0]['lng'], rows[1][1]['lat'], rows[1][1]['lng'],
        cols[0][0]['lat'], cols[0][0]['lng'], cols[0][1]['lat'], cols[0][1]['lng']);

    if (initialPoint && lastCol && lastRow) {
        const colStep = {
            lat: calculateStep(lastCol.lat, initialPoint.lat, record.columns),
            lng: calculateStep(lastCol.lng, initialPoint.lng, record.columns)
        };
        const rowStep = {
            lat: calculateStep(lastRow.lat, initialPoint.lat, record.rows),
            lng: calculateStep(lastRow.lng, initialPoint.lng, record.rows)
        };
        let step = { ...initialPoint };
        const inside = require('point-in-polygon');
        const parsedBorders = JSON.parse(record.borders);

        for (let i = 0; i < record.rows; i++) {
            for (let j = 0; j < record.columns; j++) {
                if (inside([step.lng + (colStep.lng * j), step.lat + (colStep.lat * j)], parsedBorders)) {
                    list_intersection.push({
                        gender: '',
                        health_status: '',
                        lat: step.lat + (colStep.lat * j),
                        long: step.lng + (colStep.lng * j),
                        palm_type: '',
                        deviceuid: '',
                        qr_value: "PLM-" + farm_code + "-" + block_code + "-" + formatSingleDigit(j + 1) + "/" + formatSingleDigit(i + 1),
                        age: '',
                        region_id: '',
                        consecutive_clean_reports: '',
                        row: i + 1,
                        column: j + 1,
                        farm_id: record.farm_id,
                        block_id: record.id,
                        organization_id: record.organization_id,
                        health_status_ar: '',
                        current_sprouts: '',
                        splitted_sprouts: '',
                        current_appendages: '',
                        splitted_appendages: '',
                        average_production_per_branch: '',
                        date_branches: '',
                        projected_production: '',
                        actual_production: '',
                        pollinated_branches: '',
                        country: '',
                        supplier: '',
                        total_drips: '',
                        broken_drips: 0,
                        blocked_drips: 0
                    });
                }
            }
            step.lat += rowStep.lat;
            step.lng += rowStep.lng;
        }
    }
    return list_intersection;
}

function get_intersection(rows, cols, farm_code, block_code, smartLabel, setExportCSV, downloadText, record) {
    let list_intersection = [];
    if (smartLabel) {
        list_intersection = smartLabelFunc(rows, cols, farm_code, block_code, record);
    }
    else {
        list_intersection = normalLabel(rows, cols, farm_code, block_code, record);
    }
    const ExportCSV =
        <CSVLink filename={`${farm_code}-${block_code}.csv`} data={list_intersection} style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary" >
                {downloadText}
            </Button>
        </CSVLink>;

    setExportCSV(ExportCSV);
}


const saveFile = (record, smartLabel, setExportCSV, downloadText) => {
    httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/farms/${record.farm_id}`).then(
        response => {
            const farm_code = response.json.farm_code.slice(response.json.farm_code.indexOf('-') + 1);
            const block_code = record.qr_value.slice(record.qr_value.indexOf('-') + 1);
            get_intersection(rows, cols, farm_code, block_code, smartLabel, setExportCSV, downloadText, record);
        }
    );
}

const FinishButton = props => {
    const locale = useLocale();
    return <Button
        variant="contained"
        disabled={(props.isRowsFinished && cols.length === 0) || (!props.isRowsFinished && rows.length === 0)}
        color={((props.isRowsFinished && cols.length === 0) || (!props.isRowsFinished && rows.length === 0)) ? "default" : "primary"}
        style={{ margin: '1em' }}
        onClick={() => {
            props.isRowsFinished ? saveFile(props.record, props.smartLabel, props.setExportCSV, locale === 'en' ? "Download" : "تنزيل")
                : props.setIsRowsFinished(true);
        }}
    >
        {locale === 'en' ? ("Finish " + (props.isRowsFinished ? "columns" : "rows")) : ("انتهاء " + (props.isRowsFinished ? "الأعمدة" : "الصفوف"))}
    </Button>
}

function calculateAvg(bordersArr) {
    let avgLat = 0, avgLng = 0;
    let boundaries = JSON.parse(bordersArr);
    polygonArr = [];
    for (var i = 0; i < boundaries.length; i++) {
        avgLat += boundaries[i][1];
        avgLng += boundaries[i][0];
        polygonArr.push({ lat: boundaries[i][1], lng: boundaries[i][0] });
    }
    if (boundaries.length > 0) {
        avgLat /= boundaries.length;
        avgLng /= boundaries.length;
    }
    return { lat: avgLat, lng: avgLng };
}

const DrawLine = props => {

    const [isRowsFinished, setIsRowsFinished] = useState(false);
    const [isEndPoint, setIsEndPoint] = useState(false);
    const [smartLabel, setSmartLabel] = useState(false);
    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const [exportCSV, setExportCSV] = useState(false);
    const [startPoint, setStartPoint] = useState(null);
    const [mapImg, setMapImage] = React.useState(false)
    let { record } = props;
    const bounds = {
        north: -90,
        south: 90,
        east: -180,
        west: 180
    };
    let url = "";
    let boundaries = "";
    const locale = useLocale();
    const fetchMapImg = async () => {
        const farm = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/farms/${record.farm_id}`);
        if (farm) {
            if (farm.json.borders) {
                if (farm.json.file) {
                    url = process.env.REACT_APP_DATA_PROVIDER_URL + farm.json.file.url;
                }
                boundaries = farm.json.borders;
                boundaries = JSON.parse(boundaries);
                for (var i = 0; i < boundaries.length; i++) {
                    bounds.north = Math.max(bounds.north, boundaries[i][1]);
                    bounds.south = Math.min(bounds.south, boundaries[i][1]);
                    bounds.east = Math.max(bounds.east, boundaries[i][0]);
                    bounds.west = Math.min(bounds.west, boundaries[i][0]);
                }
                console.log(bounds)
            }
        }
        setMapImage({
            url: url,
            bounds: bounds
        })
    }
    
    useEffect(() => {
        fetchMapImg();
        rows = [];
        cols = [];
        setCenter(calculateAvg(props.record.borders))
    }, [props.record.borders]);


    return (
        <Fragment>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    variant="contained"
                    color={!smartLabel ? "default" : "primary"}
                    style={{ margin: '1em', padding: '1em 4em' }}
                    onClick={() => setSmartLabel(!smartLabel)}
                >
                    {locale === 'en' ? "Smart Labeling" : "ترقيم ذكي"}
                </Button>
            </div>
            <LoadScript
                id="script-loader"
                googleMapsApiKey={process.env.REACT_APP_MAPS_KEY}
                libraries={drawingLibrary}
            >
                <GoogleMap
                    id='drawing-example'
                    mapTypeId="satellite"
                    zoom={14}
                    center={center}
                    style={{ flexBasis: 'auto' }}
                    mapContainerStyle={{
                        position: 'relative', height: '600px', display: 'flex'
                    }}
                >
                    {mapImg && <GroundOverlay
                        key={mapImg.url}
                        url={mapImg.url}
                        bounds={mapImg.bounds}
                    />}
                    <DrawingManager drawingMode="marker"
                        options={{
                            drawingControl: false
                        }}
                        onMarkerComplete={(marker) => {
                            if (isEndPoint) {
                                marker.setMap(null);
                                startPoint.setMap(null);
                                if (isRowsFinished)
                                    cols.push(
                                        [{ lat: startPoint.position.lat(), lng: startPoint.position.lng() },
                                        { lat: marker.position.lat(), lng: marker.position.lng() }]);
                                else
                                    rows.push(
                                        [{ lat: startPoint.position.lat(), lng: startPoint.position.lng() },
                                        { lat: marker.position.lat(), lng: marker.position.lng() }]);

                            }
                            else {
                                setStartPoint(marker);
                            }
                            setIsEndPoint(!isEndPoint);
                        }}
                    />
                    <Polygon options={{
                        fillColor: "#0000FF",
                        fillOpacity: '0.35',
                        strokeColor: "#0000FF",
                        strokeOpacity: '0.8',
                        strokeWeight: '2'
                    }}
                        path={polygonArr}
                    />
                    {rows.map((row, i) => {
                        return <Polyline
                            path={row}
                            key={"R" + i}
                        />
                    })}
                    {cols.map((row, i) => {
                        return <Polyline
                            path={row}
                            key={"C" + i}
                        />
                    })}
                </GoogleMap>

                <FinishButton isRowsFinished={isRowsFinished} setIsRowsFinished={setIsRowsFinished}
                    record={props.record} smartLabel={smartLabel} setExportCSV={setExportCSV} />

                {exportCSV ? exportCSV : null}

            </LoadScript>
        </Fragment>
    )

}

export default DrawLine