import React from 'react';
import {
    Card,
    CardContent
} from '@material-ui/core';

const MyCard = props => {
    const { children } = props;
    return (
        <Card className="card-box mb-4" style={{ overflowX: "auto" }} >
            <CardContent className="p-0">
                {children}
            </CardContent>
        </Card >
    );
};
export default MyCard
