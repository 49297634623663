import React from "react";
import { SvgIcon } from "@material-ui/core";

export const SectionsIcon = () => {
  return (
    <SvgIcon
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 57.924 57.924"
      //style="enable-background:new 0 0 57.924 57.924;"
    >
      <g>
        <path
          style={{ fill: "#F0C419" }}
          d="M31,26.924h26.924C56.94,12.503,45.421,0.983,31,0V26.924z"
        />
        <path
          style={{ fill: "#71C285" }}
          d="M50.309,48.577c4.343-4.71,7.151-10.858,7.614-17.653H32.656L50.309,48.577z"
        />
        <path
          style={{ fill: "#556080" }}
          d="M27,30.924V0C11.918,1.028,0,13.58,0,28.924c0,16.016,12.984,29,29,29
		c6.99,0,13.396-2.479,18.401-6.599L27,30.924z"
        />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </SvgIcon>
  );
};
