import React from "react";
import { SvgIcon } from "@material-ui/core";

export const ActionsIcon = () => {
  return (
    <SvgIcon viewBox="0 0 512.002 512.002" xmlns="http://www.w3.org/2000/svg">
      <linearGradient
        id="New_Gradient_Swatch_21"
        gradientUnits="userSpaceOnUse"
        x1="216.402"
        x2="295.598"
        y1="447.598"
        y2="368.402"
      >
        <stop offset=".011" stop-color="#fdecbf" />
        <stop offset="1" stop-color="#fef9ea" />
      </linearGradient>
      <linearGradient
        id="New_Gradient_Swatch_21-2"
        x1="32.402"
        x2="111.598"
        y1="447.598"
        y2="368.402"
      />
      <linearGradient
        id="New_Gradient_Swatch_3"
        gradientUnits="userSpaceOnUse"
        x1="188.118"
        x2="323.882"
        y1="179.882"
        y2="44.118"
      >
        <stop offset=".011" stop-color="#606082" />
        <stop offset="1" stop-color="#83839e" />
      </linearGradient>
      <g id="_19-check_lisst" data-name="19-check lisst">
        <g id="gradient">
          <circle
            cx="256"
            cy="408"
            fill="url(#New_Gradient_Swatch_21)"
            r="56"
          />
          <circle
            cx="72"
            cy="408"
            fill="url(#New_Gradient_Swatch_21-2)"
            r="56"
          />
          <path
            d="m452 341.082v-37.082a12 12 0 0 0 -12-12h-172v-44a12 12 0 0 0 -24 0v44h-172a12 12 0 0 0 -12 12v37.082a68 68 0 1 0 24 0v-25.082h160v25.082a68 68 0 1 0 24 0v-25.082h160v25.082a68 68 0 1 0 24 0zm-336 66.918a44 44 0 1 1 -44-44 44.049 44.049 0 0 1 44 44zm184 0a44 44 0 1 1 -44-44 44.049 44.049 0 0 1 44 44zm140 44a44 44 0 1 1 44-44 44.049 44.049 0 0 1 -44 44z"
            fill="#f9cd55"
          />
          <path
            d="m72 436a11.971 11.971 0 0 1 -8.487-3.515l-16-16a12 12 0 0 1 16.97-16.97l8 8 63.543-56.483a12 12 0 0 1 15.946 17.938l-72 64a11.969 11.969 0 0 1 -7.972 3.03z"
            fill="#80d1cb"
          />
          <path
            d="m256 436a11.971 11.971 0 0 1 -8.487-3.515l-16-16a12 12 0 0 1 16.97-16.97l8 8 63.543-56.483a12 12 0 0 1 15.946 17.938l-72 64a11.969 11.969 0 0 1 -7.972 3.03z"
            fill="#80d1cb"
          />
          <circle cx="256" cy="112" fill="url(#New_Gradient_Swatch_3)" r="96" />
          <path
            d="m256 4a108 108 0 1 0 108 108 108.122 108.122 0 0 0 -108-108zm12 191.129v-11.129a12 12 0 0 0 -24 0v11.129a84.17 84.17 0 0 1 -71.129-71.129h11.129a12 12 0 0 0 0-24h-11.129a84.17 84.17 0 0 1 71.129-71.129v11.129a12 12 0 0 0 24 0v-11.129a84.17 84.17 0 0 1 71.129 71.129h-11.129a12 12 0 0 0 0 24h11.129a84.17 84.17 0 0 1 -71.129 71.129z"
            fill="#70ccc4"
          />
          <path
            d="m256 124a11.969 11.969 0 0 1 -5.362-1.267l-32-16a12 12 0 1 1 10.732-21.466l24.261 12.133 25.884-25.883a12 12 0 0 1 16.97 16.97l-32 32a12 12 0 0 1 -8.485 3.513z"
            fill="#e2999b"
          />
        </g>
      </g>
    </SvgIcon>
  );
};
