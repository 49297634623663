import React, { useState, useEffect, Fragment } from 'react'
import httpClient from './httpClient';
import { useLocale, Button } from 'react-admin';
import { VegetationChartComponent, VegetationFarmMap } from './vegetationChartComponent'

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import { Grid, Card, CardContent } from "@material-ui/core";
const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginTop: '1em',
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const arabicTypes = {
    Pivots: 'قطاعات الري المحوري',
    Sections: 'القطاعات الفرعية'
}
const arabicLabel = {
    Type: 'النوع',
    'QR Code': 'الرمز'
}

const parseBorders = (borders) => {
    let parserdBorders = null;
    try {
        if (typeof borders === 'string' && borders.length > 0) {
            parserdBorders = JSON.parse(borders);
            if (Array.isArray(parserdBorders)) {
                return parserdBorders;
            }
        }
    }
    catch (error) {
        return null;
    }
    return null;
}

const calculateBB = (imagesArray) => {
    
    imagesArray.forEach((obj, key) => {
        const bounds = {
            north: -90,
            south: 90,
            east: -180,
            west: 180
        };
        obj.borders = JSON.parse(obj.borders);
        const borders = obj.borders;
        for (let i = 0; i < borders.length; i++) {
            bounds.north = Math.max(bounds.north, borders[i][1]);
            bounds.south = Math.min(bounds.south, borders[i][1]);
            bounds.east = Math.max(bounds.east, borders[i][0]);
            bounds.west = Math.min(bounds.west, borders[i][0]);
        }
        imagesArray[key].bounds = bounds;
    })
    return imagesArray;
}

const MyDropDown = ({ classes, label_id, input_id, label, value, setValue, options, setQr, arabic, isMultiple }) => {
    const locale = useLocale();
    return <FormControl  className={classes.formControl}>
        <InputLabel id={label_id}>{arabicLabel[label] && locale === 'ar' ? arabicLabel[label] : label}</InputLabel>
        <Select
            labelId={label_id}
            id={input_id}
            value={value}
            style={{
                marginTop: '0px'
            }}
            input={<Input />}
            multiple={isMultiple}
            onChange={(event) => {
                // if(event.target.value ===(arabicLabel[label] && locale === 'ar' ? arabicLabel[label] : label))
                // return
                // if (event.target.value.includes('All') && event.target.value.length > 1)
                //     event.target.value = event.target.value.filter(value => value !== 'All')
                // else if ()
                if (isMultiple) {
                    if (event.target.value[event.target.value.length - 1] === 'All') {
                        event.target.value = event.target.value.filter(value => value === 'All')
                    } else {
                        event.target.value = event.target.value.filter(value => value !== 'All')
                    }
                }
                setValue(event.target.value);
                if (setQr && event.target.value.includes('All'))
                    setQr(['All']);
                if (label === "Type")
                    setQr(['All']);
                else if (setQr && !event.target.value.includes('All')) setQr(event.target.value)

            }}
        >
            {label === 'QR Code' && <MenuItem  value={'All'}>All</MenuItem>}
           
            {options.map((option, key) =>
                <MenuItem key={key} value={option} >{arabic && locale === 'ar' ? arabic[option] : option}</MenuItem>
            )}
            
        </Select>
        {/* <FormHelperText>{arabicLabel[label] && locale === 'ar' ? arabicLabel[label] : label}</FormHelperText> */}
    </FormControl >
}

const OnDemandScouting = ({ qr_value, classes, scouting, setScouting }) => {
    const locale = useLocale();
    return <Button
        variant="contained"
        className={classes.formControl}
        style={{
            color: qr_value === 'All' ? 'rgba(0, 0, 0, 0.87)' : null,
            marginTop: '1rem',
            backgroundColor: qr_value === 'All' ? null : !scouting ? '#B22222' : null
        }}
        label={locale === 'en' ? `On Demand Scouting` : `طلب استكشاف`}
        disabled={qr_value === 'All'}
        onClick={() => {
            setScouting(1 - scouting);
        }}
    >
    </Button>
}

const VegetationChart = props => {
    console.log(props)

    const classes = useStyles();
    const [indexOption, setIndexOption] = useState('NDVI');
    const [piv_sect, setPiv_sect] = useState('Pivots');
    const [qr_option, setQr_option] = useState(['All']);
    const [qr_values, setQr_values] = useState(null);
    const [data, setData] = useState(null);
    const [images, setImages] = useState({});
    const [farmBorders, setFarmBorders] = useState(null);
    const [date, setDate] = useState(null);
    const [scouting, setScouting] = useState(false);
    const [mapImg, setMapImage] = React.useState(false)
    useEffect(() => {
        const fetchData = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/farms/vegetation?id=${props.record.id}`);
            setData(response.json);
            const images = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/farms/${props.record.id}`);
            let boundaries = [];
            const bounds = {
                north: -90,
                south: 90,
                east: -180,
                west: 180
            };
            
            let url ="";
            let avgLat = 0, avgLng = 0
                let initialCenter = {};
            if (images.json.borders) {
                if(images.json.file){
                    url = process.env.REACT_APP_DATA_PROVIDER_URL + images.json.file.url;
                }
                boundaries = images.json.borders;
                boundaries = JSON.parse(boundaries);
                for (var i = 0; i < boundaries.length; i++) {
                    avgLat += boundaries[i][1];
                    avgLng += boundaries[i][0];
                    bounds.north = Math.max(bounds.north, boundaries[i][1]);
                    bounds.south = Math.min(bounds.south, boundaries[i][1]);
                    bounds.east = Math.max(bounds.east, boundaries[i][0]);
                    bounds.west = Math.min(bounds.west, boundaries[i][0]);
                }
                console.log(bounds)
    
                
                if (boundaries.length > 0) {
                    avgLat /= boundaries.length;
                    avgLng /= boundaries.length;
                }
                initialCenter = { lat: avgLat, lng: avgLng };
    
                // setViewport({
                //     latitude: avgLat,
                //     longitude: avgLng,
                //     zoom: 12,
                //     bearing: 0,
                //     pitch: 0
                //   })
            }
            setMapImage({
                url: url,
                bounds: bounds
            })

        };
        const fetchIndex = async () => {
            const qr_values = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/farms/qrvalues?id=${props.record.id}`);
            setQr_values(qr_values.json);
        }
        setFarmBorders(parseBorders(props.record.borders));
        fetchData();
        fetchIndex();
    }, []);
    useEffect(() => {
        const fetchImages = async () => {
            const type = piv_sect[0].toLowerCase() + piv_sect.slice(1, piv_sect.length - 1);
            const images = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/farms/images?id=${props.record.id}&type=${type}${date ? '&date=' + date : ''}`);
            setImages({
                data: calculateBB(images.json),
                type: piv_sect
            });
        }
        fetchImages();
    }, [piv_sect, date]);
    
    return data ? Object.entries(data).filter(entity => entity[0] === piv_sect).map((entity, key) => {
        console.log("entries");
    console.log(Object.entries(data));
        const type = entity[0];
        const indexes = entity[1];
        console.log(entity);
        return <Fragment key={key}>
            <Grid
        container
        direction="row"
        alignContent="center"
        alignItems="center"
        spacing={1}
      > <Grid item xs={3} sm={2}>
            <div style={{ display: 'flex', flexDirection: 'row',flexFlow: 'column',alignItems:"center"}}>
                <MyDropDown classes={classes} label_id="piv_sectLabel" input_id="piv_sectInput" setQr={setQr_option}
                    label="Type" value={piv_sect} setValue={setPiv_sect} options={Object.keys(data)} arabic={arabicTypes} />
                <MyDropDown classes={classes} label_id="indexesLabel" input_id="indexesInput"
                    label="Index" value={indexOption} setValue={setIndexOption} options={Object.keys(indexes)} />
                <MyDropDown classes={classes} label_id="qrLabel" input_id="qrInput"
                    label="QR Code" value={qr_option} setValue={setQr_option} isMultiple={true}
                    options={qr_values ? (piv_sect === 'Pivots' ? qr_values.pivots : qr_values.sections) : []} />
                <OnDemandScouting qr_value={qr_option} classes={classes} setScouting={setScouting} scouting={scouting} />
            </div>
            </Grid>
            <Grid item xs={9} sm={10}>
            <VegetationChartComponent indexes={indexes} indexOption={indexOption} qr_option={qr_option} setDate={setDate} />
            </Grid>
            </Grid>

            {(farmBorders && images.data) ? <VegetationFarmMap farmBorders={farmBorders} images={images.data} indexOption={indexOption} qr_option={qr_option} scouting={scouting} mapImg={mapImg}/> : null}
        </Fragment >

    }) : null;
}


export default VegetationChart;