import React from "react";
import { SvgIcon } from "@material-ui/core";

export const UsersIcon = () => {
  return (
    <SvgIcon
      enable-background="new 0 0 512 512"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="m440 512h-368c-9.739 0-16.853-9.12-14.58-18.53.284-1.173 7.344-30.354 7.51-31.02 6.69-26.76 30.62-45.45 58.2-45.45h265.74c27.317 0 51.438 18.394 58.23 45.56l7.48 30.91c2.277 9.426-4.86 18.53-14.58 18.53z"
          fill="#a4d772"
        />
        <path
          d="m440 512h-184v-95h132.87c27.317 0 51.438 18.394 58.23 45.56l7.48 30.91c2.277 9.426-4.86 18.53-14.58 18.53z"
          fill="#7baf6b"
        />
        <path d="m137 417h30v95h-30z" fill="#e27a4e" />
        <path d="m345 417h30v95h-30z" fill="#dc455b" />
        <path
          d="m330.61 442.61c-1.16 1.16-28.92 28.39-74.61 28.39s-73.45-27.23-74.61-28.39c-4.12-4.12-5.48-10.26-3.49-15.74l32-88c2.16-5.92 7.79-9.87 14.1-9.87h64c6.31 0 11.94 3.95 14.1 9.87l32 88c1.99 5.48.63 11.62-3.49 15.74z"
          fill="#eebda9"
        />
        <path
          d="m330.61 442.61c-1.16 1.16-28.92 28.39-74.61 28.39v-142h32c6.31 0 11.94 3.95 14.1 9.87l32 88c1.99 5.48.63 11.62-3.49 15.74z"
          fill="#d2ae91"
        />
        <path
          d="m320 111h-128c-8.856 0-15.79-7.636-14.93-16.46l5.3-53.99c2.31-23.13 21.56-40.55 44.78-40.55h57.7c23.22 0 42.47 17.42 44.78 40.52l5.3 54.02c.859 8.822-6.073 16.46-14.93 16.46z"
          fill="#674d55"
        />
        <path
          d="m320 111h-64v-111h28.85c23.22 0 42.47 17.42 44.78 40.52l5.3 54.02c.859 8.822-6.073 16.46-14.93 16.46z"
          fill="#52374c"
        />
        <path
          d="m471 176c0 31.73-23.83 60.64-67.09 81.41-82.25 39.47-213.542 39.484-295.82 0-43.26-20.77-67.09-49.68-67.09-81.41s23.83-60.64 67.09-81.41c82.25-39.47 213.542-39.484 295.82 0 43.26 20.77 67.09 49.68 67.09 81.41z"
          fill="#90776f"
        />
        <path
          d="m471 176c0 31.73-23.83 60.64-67.09 81.41-39.76 19.08-92.29 29.59-147.91 29.59v-222c55.62 0 108.15 10.51 147.91 29.59 43.26 20.77 67.09 49.68 67.09 81.41z"
          fill="#674d55"
        />
        <path
          d="m366.99 160.58-4.62 120.06c-1.06 27.65-12.63 53.43-32.56 72.61-40.709 39.197-105.847 40.11-147.56.07-19.97-19.18-31.56-44.99-32.62-72.68-7.729-200.665-2.719-76.829-4.53-118.92-.867-7.411 3.913-14.526 11.44-16.32 63.542-15.856 132.463-16.61 199.1.05 6.88 1.72 11.62 8.03 11.35 15.13z"
          fill="#f6dfb9"
        />
        <path
          d="m366.99 160.58-4.62 120.06c-2.19 57.137-49.11 102.36-106.37 102.36v-249.77c33.52 0 67.04 4.07 99.64 12.22 6.88 1.72 11.62 8.03 11.35 15.13z"
          fill="#eebda9"
        />
      </g>
    </SvgIcon>
  );
};
