import React, { useRef, useCallback, useEffect } from 'react'
import { GoogleMap, DrawingManager, LoadScript, GroundOverlay } from '@react-google-maps/api'
import { Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useLocale } from 'react-admin';
import httpClient from './httpClient';
import TextField from '@material-ui/core/TextField';
import KMLComponent from './helpers/KMLComponent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
//import MapGL, {Marker, Source, Layer} from 'react-map-gl';
//import { Editor, DrawPolygonMode,} from 'react-map-gl-draw';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
const useStyles = makeStyles({
    rootUl: {
        maxHeight: '100%', overflow: 'auto',
        minWidth: '20%',
        borderBottomLeftRadius: '8px',
        alignSelf: 'center',
        border: '1px solid #bbb',
        borderBottomRightRadius: '8px'
    }
});

const MAPBOX_TOKEN = process.env.REACT_APP_MAP_GL_KEY;

const google = window.google;

var self, borders;
const drawingLibrary = ['drawing', 'places'];
const PlacesAutocomplete = props => {
    const classes = useStyles();
    console.log(props)
    const locale = useLocale();
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
        },
        debounce: 300,
    });
    const handleInput = (e) => {
        // Update the keyword of the input element
        setValue(e.target.value);
    };

    const handleSelect = ({ description }) => () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter as "false"
        setValue(description, false);
        clearSuggestions();

        // Get latitude and longitude via utility functions
        getGeocode({ address: description })
            .then((results) => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                props.updateCenter({ lat, lng })
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
    };

    const renderSuggestions = () => {
        return (
            <List
                classes={{ root: classes.rootUl }}
            >
                {
                    data.map((suggestion) => {
                        const {
                            id,
                            structured_formatting: { main_text, secondary_text },
                        } = suggestion;

                        return (
                            <ListItem button>
                                <ListItemText
                                    primary={main_text}
                                    secondary={secondary_text}
                                    onClick={handleSelect(suggestion)}
                                />
                            </ListItem>)
                    }
                    )
                }
            </List >
        )

    }
    return (
        <div style={{
            display: 'flex',
            justifyItems: 'center',
            marginBottom: '1em',
            flexDirection: 'column'
        }} >
            <TextField
                style={{ alignSelf: 'center', minWidth: '20%' }}
                variant="outlined"
                value={value}
                onChange={handleInput}
                disabled={!ready}
                placeholder={locale === 'ar' ? "ابحث عن مكان " : "Search for a place"}
            />
            {/* We can use the "status" to decide whether we should display the dropdown or not */}
            {status === "OK" && renderSuggestions()}
        </div >
    );
};
const SaveButton = props => {
    const locale = useLocale();
    let { table, source, record } = props.myProps;
    console.log(borders)
    return props.showButton ?
        <Button
            variant="contained"
            component={Link}
            to={`/${table}/${record.id}?${source}=${borders}`}
            style={{ color: 'white', backgroundColor: '#009688', margin: '1em' }}
        >
            <SaveIcon style={{ marginRight: '0.5em' }} />
            {locale === 'en' ? "Save" : "حفظ"}
        </Button>
        :
        <Button
            variant="contained"
            disabled
            style={{ margin: '1em' }}
        >
            <SaveIcon style={{ marginRight: '0.5em' }} />
            {locale === 'en' ? "Save" : "حفظ"}
        </Button>
}
class DrawBorders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showButton: false,
            center: this.props.record.borders ? this.calculateAvg(this.props.record.borders) : { lat: 30.0194302, lng: 31.1921378, },
            viewport: {
                latitude: 30.0194302,
                longitude: 31.1921378,
                zoom: 12,
                bearing: 0,
                pitch: 0
            },
            rgbImage: null,
            bounds: null,
        };
        self = this;
        
    }
    

    settingState() {
        this.setState({
            showButton: true,
            center: this.calculateAvg(borders),
        });
    }
    updateCenter(center) {
        this.setState({ center: center });
    }
    async componentDidMount() {
        console.log(this.props)
        if (this.props.resource === 'pivots' || this.props.resource === 'blocks') {
            const farmBorders = (await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/farms/${this.props.record.farm_id}`)).json.borders;
            const center = this.calculateAvg(farmBorders);
            this.setState({ center: center });
        }
        else if (this.props.resource === 'sections') {
            const pivotBorders = (await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/pivots/${this.props.record.pivot_id}`)).json.borders;
            const center = this.calculateAvg(pivotBorders);
            this.setState({ center: center });
        }
        
        try{

            let id = this.props.record.farm_id ? this.props.record.farm_id :this.props.record.id;

            const images = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/farms/${id}`);

            if (images.json.file) {
                this.setState({ rgbImage: images.json.file.url })
                this.setState({ bounds: images.json.borders })
            }

        }catch(e){
            console.log(e)
        }
        this.setState({active: true})

    }

    calculateBounds(boundaries){
        const bounds = {
            north: -90,
            south: 90,
            east: -180,
            west: 180
        };
        
        if (boundaries) {
            boundaries = JSON.parse(boundaries);
            for (var i = 0; i < boundaries.length; i++) {

                bounds.north = Math.max(bounds.north, boundaries[i][1]);
                bounds.south = Math.min(bounds.south, boundaries[i][1]);
                bounds.east = Math.max(bounds.east, boundaries[i][0]);
                bounds.west = Math.min(bounds.west, boundaries[i][0]);
            }

            return bounds
            
    }else return null
}

    calculateAvg(bordersArr) {
        if(bordersArr){
            let avgLat = 0, avgLng = 0;
            let boundaries = JSON.parse(bordersArr);
            for (var i = 0; i < boundaries.length; i++) {
                avgLat += boundaries[i][1];
                avgLng += boundaries[i][0];
            }
            if (boundaries.length > 0) {
                avgLat /= boundaries.length;
                avgLng /= boundaries.length;
            }
            return { lat: avgLat, lng: avgLng };
        }else{
            return {lat: this.state.viewport.longitude, lng: this.state.viewport.longitude}
        }

    }
    render() {
        let imgurl = process.env.REACT_APP_DATA_PROVIDER_URL + this.state.rgbImage; 

        let bounds = this.calculateBounds(this.state.bounds);

        return (
            <>
                <KMLComponent basePath={this.props.table} data={this.props.record} />

                {/* <MapGL
                    {...this.state.viewport}
                    latitude={this.state.active ? this.state.viewport.latitude : this.calculateAvg(this.props.record.borders).lat}
                    longitude={this.state.active ? this.state.viewport.longitude : this.calculateAvg(this.props.record.borders).lng}
                    width="100vw"
                    height="100vh"
                    mapStyle="mapbox://styles/mapbox/satellite-v9"
                    dragPan={false} 
                    onViewportChange={viewport => this.setState({ viewport })}
                    mapboxApiAccessToken={MAPBOX_TOKEN}
                    getCursor={(e) => "crosshair"}
                >

                    {this.state.active && bounds && <Source
                        type="image"
                        url={imgurl}
                        coordinates={
                            [[bounds.west, bounds.north],
            [bounds.east, bounds.north],
            [bounds.east, bounds.south],
            [bounds.west, bounds.south]]
                        }>
                        <Layer
                            type="raster"
                        />


                    </Source>}

                    <Editor
                        // to make the lines/vertices easier to interact with
                        clickRadius={12}
                        mode={new DrawPolygonMode()}
                        onUpdate={(data)=>{
                            console.log(data.data.length)
                            if(data.data.length > 0){
                            console.log(data.data[0].geometry.coordinates)
                            borders = JSON.stringify(data.data[0].geometry.coordinates[0    ])
                            self.settingState();
                            console.log(this.state.showButton)
                            return borders;
                            }

                        }}
                    />

                </MapGL>
                <SaveButton showButton={this.state.showButton} myProps={this.props} />       */}

                <LoadScript
                    id="script-loader"
                    googleMapsApiKey={process.env.REACT_APP_MAPS_KEY}
                    libraries={drawingLibrary}
                >
                    <PlacesAutocomplete updateCenter={this.updateCenter.bind(this)} />
                    <GoogleMap
                        id='drawing-example'
                        mapTypeId="satellite"
                        zoom={14}
                        center={self.state.center}
                        style={{ flexBasis: 'auto' }}
                        mapContainerStyle={{
                            position: 'relative', height: '600px', display: 'flex'
                        }}
                    >
                    { this.state.active &&<GroundOverlay
                                    url={imgurl}
                                    bounds={bounds}
                                />}
                        <DrawingManager drawingMode="polygon" options={{ drawingControl: false }}
                            onPolygonComplete={function (polygon) {
                                borders = JSON.stringify(polygon.getPath().getArray().map((element) => [element.lng(), element.lat()]));
                                self.settingState();
                                return borders;
                            }}
                        />
                    </GoogleMap>
                    <SaveButton showButton={this.state.showButton} myProps={this.props} />
                </LoadScript>
            </>
        )
    }
}

DrawBorders.propTypes = {
    table: PropTypes.string,
}

export default DrawBorders