import React from 'react';
import { Button, useLocale } from 'react-admin';
import { Link } from 'react-router-dom';

const arabic = {
    Block: 'حوشة',
    Pivot: 'قطاع ري محوري',
    Section: 'قطاع فرعي'
};

const MyCreateButton = ({ record, entity, table, param }) => {
    const locale = useLocale();
    return (
        <Button
            variant="contained"
            component={Link}
            to={`/${table}/create?${param}=${record.id}`}
            label={locale === 'en' ? `Create ${entity}` : `إنشاء ${arabic[entity]}`}
            style={{ margin: '1em' }}
        >
        </Button>
    )
};

export default MyCreateButton;