import React from 'react';
import {
    Create, Edit, SimpleForm, TextInput, useLocale,
    EditButton, Datagrid, TextField, ImageField, Pagination
} from 'react-admin';
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import List from './theme/components/List'

import { MyImageField, MyImageInput } from './helpers/iconComponent';
import { withStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
const styles = {
    image: { maxHeight: '1.4rem' }
}

export const PalmActivityTypeList = withStyles(styles)(({ classes, ...props }) => {
    const locale = useLocale();
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}{...props}>
            <Datagrid>
                  
                <MyImageField source="icon_id" />
                <TextField source={locale === 'en' ? "activity" : "activity_ar"} />
                <EditButton />
            </Datagrid>
        </List>
    )
});

export const PalmActivityTypeEdit = props => (
    <Edit undoable={false} {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar />} className={clsx('tab-rtl-layout')}>
            <TextInput disabled source="id" />
            <TextInput source="activity" />
            <TextInput source="activity_ar" />
            <MyImageInput />
        </SimpleForm>
    </Edit>
);

export const PalmActivityTypeCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list" className={clsx('tab-rtl-layout')}>
            <TextInput source="activity" />
            <TextInput source="activity_ar" />
            <MyImageInput />
        </SimpleForm>
    </Create>
);
