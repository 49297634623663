import React from "react";
import { SvgIcon } from "@material-ui/core";

export const InventoryActions = () => {
  return (
    <SvgIcon
      enable-background="new 0 0 64 64"
      height="512"
      viewBox="0 0 64 64"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m34 40h28v22h-28z" fill="#d3a06c" />
      <path
        d="m14 22h2c-2.8 0-5.2 1.63-6.32 4h-6.68c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1h3z"
        fill="#838f9b"
      />
      <path
        d="m22 22h8 4 8 8 8 3c.55 0 1 .45 1 1v2c0 .55-.45 1-1 1h-38.68c-1.12-2.37-3.52-4-6.32-4z"
        fill="#838f9b"
      />
      <path d="m5 54 .03-.12c.31.08.64.12.97.12h5v8h-6z" fill="#fcd770" />
      <path
        d="m16 36c7.73 0 14 6.27 14 14v12h-5v-15l-5-5h-9v7h-8.96c.24-3.46 1.75-6.58 4.06-8.9 2.54-2.53 6.04-4.1 9.9-4.1z"
        fill="#fcd770"
      />
      <path
        d="m25 47v15h-14v-8h4.5c.69 0 1.32-.28 1.77-.73s.73-1.08.73-1.77c0-1.38-1.12-2.5-2.5-2.5h-4.5v-7h9v5z"
        fill="#e6e9ed"
      />
      <path d="m25 47h-5v-5z" fill="#fc6e51" />
      <path
        d="m15.5 49c1.38 0 2.5 1.12 2.5 2.5 0 .69-.28 1.32-.73 1.77s-1.08.73-1.77.73h-4.5-5c-.33 0-.66-.04-.97-.12-1.74-.43-3.03-2.01-3.03-3.88 0-.34.01-.67.04-1h8.96z"
        fill="#f0d0b4"
      />
      <path
        d="m22.32 26c.44.91.68 1.92.68 3 0 3.87-3.13 7-7 7s-7-3.13-7-7c0-1.08.24-2.09.68-3 1.12-2.37 3.52-4 6.32-4s5.2 1.63 6.32 4z"
        fill="#f0d0b4"
      />
      <path d="m34 2h24v20h-24z" fill="#d3a06c" />
      <path d="m6 2h24v20h-24z" fill="#d3a06c" />
      <g fill="#ecc19c">
        <path d="m22 18v4h-6-2v-4z" />
        <path d="m14 2h8v4h-8z" />
        <path d="m42 2h8v4h-8z" />
        <path d="m42 18h8v4h-8z" />
        <path d="m44 40h8v4h-8z" />
      </g>
      <path d="m33 63h30v-24h-30zm12-22h6v2h-6zm-10 0h8v4h10v-4h8v20h-26z" />
      <path d="m57 57h2v2h-2z" />
      <path d="m53 57h2v2h-2z" />
      <path d="m49 57h2v2h-2z" />
      <path d="m61 21h-2v-20h-26v20h-2v-20h-26v20h-2c-1.103 0-2 .897-2 2v2c0 1.103.897 2 2 2h5.263c-.166.641-.263 1.308-.263 2 0 2.78 1.427 5.231 3.585 6.665-6.123 1.89-10.585 7.6-10.585 14.335 0 2.045 1.237 3.802 3 4.576v8.424h27v-13c0-6.735-4.462-12.445-10.585-14.335 2.158-1.434 3.585-3.885 3.585-6.665 0-.692-.097-1.359-.263-2h37.263c1.103 0 2-.897 2-2v-2c0-1.103-.897-2-2-2zm-18-18h6v2h-6zm-8 0h6v4h10v-4h6v18h-6v-4h-10v4h-6zm14 18h-6v-2h6zm-34-18h6v2h-6zm-8 0h6v4h10v-4h6v18h-6v-4h-10v4h-6zm8 18v-2h6v2zm-12 4v-2h7.726c-.65.572-1.206 1.247-1.644 2zm3 30h4v6h-4zm9.5-7h-3.5v-5h7v5h5v13h-12v-6h3.5c1.93 0 3.5-1.57 3.5-3.5s-1.57-3.5-3.5-3.5zm5.5-3.586 1.586 1.586h-1.586zm8 5.586v11h-3v-14.414l-5.586-5.586h-10.414v7h-3v2h8.5c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5h-9.5c-1.654 0-3-1.346-3-3 0-7.168 5.832-13 13-13s13 5.832 13 13zm-13-15c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6zm45-10h-38.082c-.437-.753-.994-1.428-1.644-2h39.726z" />
      <path d="m49 53h10v2h-10z" />
    </SvgIcon>
  );
};
