import React, { useState, useEffect } from 'react'
import { GoogleMap, LoadScript, Polygon, Marker } from '@react-google-maps/api'
import httpClient from './httpClient';

export const PestLocation = ({ lat, lng, type, id }) => {

    const [borders, setBorders] = useState([]);
    const [mount, setMount] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/${type}/${id}`);
            try {
                let borders = JSON.parse(response.json.borders);
                borders = borders.map(point => ({ lat: point[1], lng: point[0] }));
                setBorders(borders);

            }
            catch (e) {
                console.log('PestLocation: error parsing borders');
            }
        };
        fetchData();
    }, []);

    return <LoadScript
        id="script-loader"
        googleMapsApiKey={process.env.REACT_APP_MAPS_KEY}>
        <GoogleMap
            id='drawing-example'
            mapTypeId="satellite"
            zoom={14}
            center={mount ? null : { lat: lat, lng: lng }}
            onMouseOver={() => {
                if (!mount)
                    setMount(true);
            }}
            style={{ flexBasis: 'auto' }}
            mapContainerStyle={{
                position: 'relative', height: '600px', width: '600px', display: 'flex'
            }}>

            <Polygon options={{
                fillColor: "#009688",
                fillOpacity: '0.05',
                strokeColor: "#009688",
                strokeOpacity: '0.8',
                strokeWeight: '2'
            }}
                path={borders}
            />

            <Marker
                position={{ lat: lat, lng: lng }}
                title='pest'
            />

        </GoogleMap>
    </LoadScript>
}