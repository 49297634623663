import React from 'react';
import {
    Create, Edit, SimpleForm, TextInput, useLocale, ReferenceField,
    EditButton, Datagrid, TextField, Pagination, NumberInput, ReferenceInput,
    SelectInput
} from 'react-admin';
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import List from './theme/components/List'
import clsx from 'clsx';

export const GrowthStageList = props => {
    const locale = useLocale();
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}{...props}>
            <Datagrid>
                  
                <TextField source={locale === 'en' ? 'name' : 'name_ar'} />
                <ReferenceField source="plant_id" reference="pivotcroptypes" allowEmpty >
                    <TextField source={locale === 'en' ? 'name' : 'name_ar'} />
                </ReferenceField>
                <TextField source="start_age" />
                <TextField source="end_age" />
                <EditButton />
            </Datagrid>
        </List>
    )
};

export const GrowthStageEdit = props => {
    const locale = useLocale();
    return (
        <Edit undoable={false} {...props} className={clsx('tab-rtl-layout')}>
            <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
                <TextInput disabled source="id" />
                <TextInput source="name" />
                <TextInput source="name_ar" />
                <ReferenceInput perPage={-1} reference="pivotcroptypes" source="plant_id">
                    <SelectInput optionText={locale === 'en' ? 'name' : 'name_ar'} />
                </ReferenceInput>
                <NumberInput source="start_age" />
                <NumberInput source="end_age" />
            </SimpleForm>
        </Edit>
    )
};

export const GrowthStageCreate = props => {
    const locale = useLocale();
    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <TextInput source="name" />
                <TextInput source="name_ar" />
                <ReferenceInput perPage={-1} reference="pivotcroptypes" source="plant_id">
                    <SelectInput optionText={locale === 'en' ? 'name' : 'name_ar'} />
                </ReferenceInput>
                <NumberInput source="start_age" />
                <NumberInput source="end_age" />
            </SimpleForm>
        </Create>
    )
};
