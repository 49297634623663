import React, { useState, useEffect } from 'react';
import {
    Create, Edit, SimpleForm, TextInput, SelectInput,
    EditButton, Datagrid, Button, useLocale, Pagination,
    TextField, Filter, DateField, ReferenceField, ShowButton,
    Tab, TabbedShowLayout, Show, ReferenceManyField, DeleteButton,
    ReferenceInput, FormDataConsumer, SimpleShowLayout, ChipField
} from 'react-admin';
import CustomToolbar from './helpers/customToolbar';
import PostBulkActionButtons from './helpers/PostBulkActionButtons';
import List from './theme/components/List'
import { Link } from 'react-router-dom';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import httpClient from './httpClient';
import { UnitField, IrrigationPeriodicity, IrrigationReasonField } from './helpers/irrigationComponents';
import clsx from 'clsx';

const styles = {
    button: {
        margin: '1em', marginLeft: '0'
    }
};

const useStyles = makeStyles({
    chipBackground: {
        backgroundColor: '#00000022'
    }
});

const AddTaskButton = withStyles(styles)(({ classes, record }) => {
    const locale = useLocale();
    return (
        <Button
            className={classes.button}
            variant="contained"
            component={Link}
            to={`/tasks/create?program_id=${record.id}`}
            label={locale === 'en' ? "Add Task" : "إضافة مهمة"}
            title="Add Task"
        >
        </Button>
    );
});

const AddActiveProgramButton = withStyles(styles)(({ classes, record }) => {
    const locale = useLocale();
    return (
        <Button
            className={classes.button}
            variant="contained"
            component={Link}
            to={`/activeprograms/create?program_id=${record.id}`}
            label={locale === 'en' ? "Assign Program" : "تعيين برنامج نشط"}
            title="Assign Program"
        >
        </Button>
    )
});


export const ProgramList = (props) => {
    const locale = useLocale();
    return (
        <List sort={{ field: 'id', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}  {...props}>
            <Datagrid>
                  
                <TextField source={locale === 'en' ? "name" : "name_ar"} />
                <EditButton />
                <ShowButton />
            </Datagrid>
        </List>
    )
};

export const ProgramEdit = props => (
    <Edit undoable={false} {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar />} className={clsx('tab-rtl-layout')}>
            <TextInput disabled source="id" />
            <TextInput source="name" />
            <TextInput source="name_ar" />
        </SimpleForm>
    </Edit>
);

const farmTypes = {
    is_palm_tree: {
        en: 'Palm Tree Farm',
        ar: 'زراعة النخيل'
    },
    is_pivot: {
        en: 'Pivot Farm',
        ar: 'زراعة قطاعات الري المحوري',
    },
    is_orchard: 'Orchard Farm',
    is_greenhouse: 'GreenHouse Farm'
}

export const ProgramCreate = props => {
    const locale = useLocale();
    const [data, setData] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/organizations/check`);
            setData(response.json);
        };
        fetchData();
    }, []);
    const choices = Object.entries(farmTypes).filter(item => data && data[item[0]]).map(type => ({ id: type[0], name: type[1][locale] }));
    return (
        <Create {...props}>
            <SimpleForm redirect="list" className={clsx('tab-rtl-layout')}>
                <TextInput source="name" />
                <TextInput source="name_ar" />
                <SelectInput source="agriculture_type" choices={choices} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        return formData.agriculture_type === 'is_pivot' &&
                            <ReferenceInput perPage={-1} reference="pivotcroptypes" source="plantationtype_id" {...rest} >
                                <SelectInput optionText={locale === 'en' ? "name" : "name_ar"} />
                            </ReferenceInput>
                    }}
                </FormDataConsumer>

            </SimpleForm>
        </Create>
    )
};

const ActivityField = props => {
    const locale = useLocale();
    const { data, source, ...rest } = props
    if (data)
        return <ReferenceField source="activitytype_id" reference={`${data[rest.record.targetlevel_id]}activitytypes`} {...rest}>
            <TextField source={locale === 'en' ? "activity" : "activity_ar"} />
        </ReferenceField>;
    else
        return null;

}

const CustomTextField = ({ record, data }) => {
    const locale = useLocale();
    let textDisplayed;
    if (data && data[record.unit_id])
        textDisplayed = locale === 'en' ?
            record.name + ' - ' + data[record.unit_id].name :
            record.name_ar + ' - ' + data[record.unit_id].name_ar;
    else
        textDisplayed = locale === 'en' ?
            record.name :
            record.name_ar;
    return <span>{textDisplayed}</span>
}

const PivotPlantation = props => {
    const locale = useLocale();
    return props.record.agriculture_type === 'is_pivot' ?
        <ReferenceField reference="pivotcroptypes" allowEmpty {...props} >
            <TextField source={locale === 'en' ? "name" : "name_ar"} />
        </ReferenceField>
        : null;
}

const AgricultureType = ({ record, setPivotPlantation, ...rest }) => {
    const locale = useLocale();

    if (record.agriculture_type === 'is_pivot') {
        setPivotPlantation(true);
    }
    const myRecord = { ...record };

    myRecord.agriculture_type = farmTypes[record.agriculture_type][locale];

    return <TextField record={myRecord} {...rest} />
}

const MyTextField = props => {
    const locale = useLocale();
    return <span>{locale === 'en' ? props.record[props.source] : props.arabic[props.record[props.source]]}</span>
}
const MyChipList = props => {
    const locale = useLocale();
    const classes = useStyles();
    try {
        const activity = JSON.parse(props.record.activity_actions);
        if (activity.length > 0)
            return activity.map(chip => <ChipField className={classes.chipBackground} source="id" record={{ id: chip }} />)
    }
    catch (e) {
        console.log(e);
        return null;
    }
}
const BunchCoverField = props => {
    const locale = useLocale();
    return <span>{locale === 'en' ? (props.record[props.source] ? "Yes" : "No") : (props.record[props.source] ? "نعم" : "لا")}</span>
}

const TaskInfo = ({ data, ...props }) => {

    const subActivities = {
        block: ["Irrigation", 'Land Preparation'],
        palm: ["Fertilization", 'Pruning', 'Thinning', 'Pollination', 'Weed Control'],
        section: ["Fertilization", "Spray", "Machinery"]
    }

    const [sub_activity, setSub_activity] = useState(null);
    const locale = useLocale();

    const entity = data[props.record.targetlevel_id];

    useEffect(() => {
        const fetchData = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/${entity}activitytypes?id=${props.record.activitytype_id}`);
            if (response.json.length > 0)
                if (entity === 'block' && response.json[0].activity === 'Irrigation') {
                    setSub_activity(response.json[0].activity.toLowerCase());
                    return;
                }
                else
                    for (let activity of subActivities[entity]) {
                        if (response.json[0].activity.includes(activity)) {
                            setSub_activity(activity.toLowerCase());
                            return;
                        }
                    }
            setSub_activity(null);
        };
        if (props.record.activitytype_id)
            fetchData();
    }, [props.record.activitytype_id]);

    return <Show {...props} title=" ">
        <SimpleShowLayout className={clsx('tab-rtl-layout')}>
            {props.record.start_time &&
                <Tab label="date">
                    <DateField source="start_time" locales="en-GB" />
                    <DateField source="end_time" locales="en-GB" />
                </Tab>
            }
            {props.record.section_age &&
                <Tab label="date">
                    <TextField source="section_age" />
                </Tab>
            }
            {sub_activity &&
                <Tab label="Sub Activity">
                    <ReferenceField source="sub_activity" reference={`${entity === 'section' ? "pivot" : entity === 'block' ? "palm" : entity}${sub_activity}types`} allowEmpty={true} >
                        <TextField source={locale === 'en' ? "name" : "name_ar"} />
                    </ReferenceField>
                </Tab>
            }
            {props.record.activity_actions &&
                <Tab label="activity_actions">
                    <MyTextField source="activity_mechanism" arabic={{ 'Automatic': 'تلقائي', 'Manual': 'عادي' }}
                        label={locale === 'en' ? 'Mechanism' : "الطريقة"} addLabel />
                </Tab>
            }
            {props.record.activity_actions &&
                <Tab label="activity_actions">
                    <MyChipList source="activity_actions" label={locale === 'en' ? 'Actions' : "النوع"} addLabel />
                </Tab>
            }
            {props.record.bunch_cover &&
                <Tab label="bunch_cover">
                    <BunchCoverField source="bunch_cover" label={"Bunch Cover"} addLabel />
                </Tab>
            }
            {props.record.irrigation_periodicity &&
                <Tab label="irrigation_periodicity">
                    <IrrigationPeriodicity source="irrigation_periodicity" addLabel />
                </Tab>}
            {/* block irrigation */}
            {props.record.irrigation_reason &&
                <Tab label="Irrigation">
                    {console.log("props" , props.record)}
                    {props.record.required_water_per_palm_in_liters === null?null: <UnitField source="required_water_per_palm_in_liters" unit={"L"} unit_ar={"لتر"} addLabel />}
                    <IrrigationReasonField source="irrigation_reason" addLabel />
                </Tab>
            }
            {props.record.notes &&
                <Tab label="notes">
                    <TextField source="notes" />
                </Tab>
            }
            <ReferenceManyField perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
                reference="taskinventories" target="task_id" label={locale === 'en' ? "Inventory Items" : "أصناف المخزن المستخدمة"}>
                <Datagrid>
                      
                    <ReferenceField source="category_id" reference="inventorycategories" allowEmpty={true}>
                        <TextField source={locale === 'en' ? "name" : "name_ar"} />
                    </ReferenceField>
                    <ReferenceField source="subcategory_id" reference="inventorysubcategories" allowEmpty={true}>
                        <TextField source={locale === 'en' ? "name" : "name_ar"} />
                    </ReferenceField>
                    <TextField source="amount" />
                </Datagrid>
            </ReferenceManyField>
        </SimpleShowLayout>
    </Show>
}

const ProgramName = ({ record }) => {
    const locale = useLocale();
    return <span>{record ? (locale === 'en' ? record.name : record.name_ar) : ''}</span>;
}

export const ProgramShow = (props) => {
    const locale = useLocale();
    const [data, setData] = useState(null);
    const [pivotPlantation, setPivotPlantation] = useState(false);

    useEffect(() => {
        const fetchUnits = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/units`);
            const units = {};
            response.json.forEach(element => {
                units[element.id] = element;
            });
            return units;
        };

        const fetchTargetlevels = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/targetlevels`);
            const targetlevelCodes = {};
            response.json.forEach(targetlevel => targetlevelCodes[targetlevel.id] = targetlevel.code);
            return targetlevelCodes;
        };
        const units = fetchUnits();
        const targetlevels = fetchTargetlevels();
        Promise.all([units, targetlevels])
            .then((values) => setData({ units: values[0], targetlevels: values[1] }));
    }, []);
    return (
        <Show {...props} title={<ProgramName />}>
            <TabbedShowLayout>
                <Tab label={locale === 'en' ? "summary" : "الملخص"} contentClassName={clsx('tab-rtl-layout')}>
                      
                    <TextField source={locale === 'en' ? "name" : "name_ar"} />
                    <AgricultureType source="agriculture_type" setPivotPlantation={setPivotPlantation} addLabel />
                    {pivotPlantation && <PivotPlantation source="plantationtype_id" addLabel />}
                    <AddActiveProgramButton />
                </Tab>
                <Tab label={locale === 'en' ? "Tasks" : "المهام"}>
                    <ReferenceManyField perPage={100} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />} reference="tasks" target="program_id" addLabel={false}>
                        <Datagrid expand={<TaskInfo data={data && data.targetlevels} />}>
                              
                            <TextField source={locale === 'en' ? "name" : "name_ar"} />
                            <ReferenceField source="targetlevel_id" reference="targetlevels" allowEmpty={true} >
                                <TextField source={locale === 'en' ? "name" : "name_ar"} />
                            </ReferenceField>
                            <ActivityField source={locale === 'en' ? "Activity" : "الإجراء"} data={data && data.targetlevels} />
                            <TextField source="man_hour" />
                            <DeleteButton basePath={`/programs/${props.id}/show/1`} undoable={false} />
                        </Datagrid>
                    </ReferenceManyField>
                    <AddTaskButton />
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
};


const ProgramFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);
