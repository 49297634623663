import React, { useState, useEffect } from "react";
import Chart from "react-google-charts";
import httpClient from './httpClient';
import { Button } from '@material-ui/core';
import { useLocale } from "ra-core";
const columns = [
    { type: "string", label: "Task ID" },
    { type: "string", label: "Task Name" },
    { type: "string", label: "Resource" },
    { type: "date", label: "Start Date" },
    { type: "date", label: "End Date" },
    { type: "number", label: "Duration" },
    { type: "number", label: "Percent Complete" },
    { type: "string", label: "Dependencies" }
];

const ManHour = props => {
    const locale = useLocale();
    return locale === 'en' ? <span>Man Hour</span> : <span>عدد الساعات</span>
}

const Inventory = props => {
    const locale = useLocale();
    return locale === 'en' ? <span>Inventory</span> : <span>المخزن</span>
}

const WaterAmount = props => {
    const locale = useLocale();
    return locale === 'en' ? <span>Water Amount</span> : <span>كمية المياه</span>
}

const CustomChart = props => {
    const locale = useLocale();
    const { data, data_ar } = props;
    const rows = locale === 'en' ? data : data_ar;
    return <Chart
        chartType="Gantt"
        chartLanguage={'en'}
        data={[columns, ...rows]}
        height={Math.max(((data.length + 1) * 42), 200) + 'px'}
        legendToggle
    />
}

const GanttChart = props => {
    const [data, setData] = useState({});

    function convertStringToDate(data) {
        if (data)
            return data.map(row => {
                row[3] = new Date(row[3]);
                row[4] = new Date(row[4]);
                return row;
            });
        else
            return [];
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/activeprograms/fetchdata?` +
                `program_id=${props.program_id}` +
                `&farm_id=${props.farm_id}` +
                (props.blocks_ids && props.blocks_ids.length ? `&blocks_ids=[${props.blocks_ids.toString()}]` : ``) +
                (props.pivots_ids && props.pivots_ids.length ? `&pivots_ids=[${props.pivots_ids.toString()}]` : ``) +
                (props.sections_ids && props.sections_ids.length ? `&sections_ids=[${props.sections_ids.toString()}]` : ``)
            );
            response.json.man_hour_data = convertStringToDate(response.json.man_hour_data);
            response.json.man_hour_data_ar = convertStringToDate(response.json.man_hour_data_ar);
            response.json.inventory_data = convertStringToDate(response.json.inventory_data);
            response.json.inventory_data_ar = convertStringToDate(response.json.inventory_data_ar);
            response.json.irrigation = convertStringToDate(response.json.irrigation);

            setData({
                man_hour_data: response.json.man_hour_data,
                man_hour_data_ar: response.json.man_hour_data_ar,
                inventory_data: response.json.inventory_data,
                inventory_data_ar: response.json.inventory_data_ar,
                irrigation: response.json.irrigation
            });
        };
        if (props.farm_id)
            fetchData();
    }, [props.farm_id, props.blocks_ids, props.pivots_ids, props.sections_ids]);


    if (Object.keys(data).length)
        return (
            <div style={{
                position: 'relative',
                display: 'flex'
            }}>
                {data.man_hour_data.length ?
                    <div style={{ direction: 'ltr', flex: '0 0 50%', margin: '1em 0.1em' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button style={{ margin: '1em' }} color='primary' variant='contained' >
                                <ManHour />
                            </Button>
                        </div>
                        <CustomChart data={data.man_hour_data} data_ar={data.man_hour_data} chartLanguage="ar" />
                    </div> : null
                }
                {data.inventory_data.length ?
                    <div style={{ direction: 'ltr', flex: '0 0 50%', margin: '1em 0.1em' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button style={{ margin: '1em' }} color='primary' variant='contained' >
                                <Inventory />
                            </Button>
                        </div>
                        <CustomChart data={data.inventory_data} data_ar={data.inventory_data} />
                    </div> : null
                }
                {data.irrigation.length ?
                    <div style={{ flex: '0 0 50%', margin: '1em 0.1em' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button style={{ margin: '1em' }} color='primary' variant='contained' >
                                <WaterAmount />
                            </Button>
                        </div>
                        <CustomChart data={data.irrigation} data_ar={data.irrigation} />
                    </div> : null
                }
            </div>
        );
    else
        return null;

}

export default GanttChart;
