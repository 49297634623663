import React, { useState, useEffect, Fragment } from 'react';
import httpClient from './httpClient';
import { useLocale } from 'react-admin';
import { GoogleMap, LoadScript, Marker, GroundOverlay } from '@react-google-maps/api'
//import MapGL, { Marker, Source, Layer, Popup } from 'react-map-gl';   

import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { ErrorOutline } from '@material-ui/icons';
import Slider from '@material-ui/core/Slider';
import ValueLabel from "@material-ui/core/Slider/ValueLabel";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

const MAPBOX_TOKEN = process.env.REACT_APP_MAP_GL_KEY;

let datesObject = {};
let disease = false;

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginTop: '2em',
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        display: 'flex'
    },
    icon: {
        margin: theme.spacing(1),
        fontSize: 32,
    },
    attributeLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        padding: 0,
        fontSize: '1rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '0.00938em',
        textAlign: 'center'
    },
    attribute: {
        fontSize: '0.875rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight: 1.43,
        letterSpacing: '0.01071em',
        textAlign: 'center'
    }
}));

const StyledValueLabel = withStyles({
    offset: {
        top: -70,
        left: 'calc(-50% + -23px)',
    },
    circle: {
        width: 70,
        height: 70
    }
})(ValueLabel);


const arabicLabel = {
    Pivot: 'قطاع الري المحوري',
    Section: 'القطاع الفرعي'
}

var overlayMap = {};

const getKey = (key) => {
    if (!overlayMap[key]) {
        overlayMap[key] = key;
    }
    return overlayMap[key];
}

const MyDropDown = ({ classes, label_id, value, setValue, data }) => {
    const locale = useLocale();
    return <FormControl variant="filled" className={classes.formControl}>
        <InputLabel id={label_id}>{arabicLabel[label_id] && locale === 'ar' ? arabicLabel[label_id] : label_id}</InputLabel>
        <Select
            labelId={label_id}
            id={label_id}
            value={value}
            onChange={(event) => {
                setValue(event.target.value);
            }}
        >
            {data.map((option, key) =>
                <MenuItem key={key} value={option.id}>{option.qr_value}</MenuItem>
            )}
        </Select>
    </FormControl>;
}

const useSlider = makeStyles(theme => ({
    root: {
        color: '#B22222'
    }
}));

const DateSlider = ({ marks, date, setDate, setTaskID, setTaskList, setTaskCounter }) => {
    const [myDate, setMyDate] = useState(date);
    useEffect(() => {
        setMyDate(date);
    }, [date]);

    const classes = useSlider();

    return <Slider
        classes={disease ? classes : {}}
        value={myDate.value}
        valueLabelFormat={(value) => {
            const index = marks.findIndex(mark => mark.value === value);
            return marks[index].date;
        }}
        ValueLabelComponent={StyledValueLabel}
        step={null}
        max={marks.length - 1}
        valueLabelDisplay="on"
        marks={marks}
        onChange={(event, value) => {
            const index = marks.findIndex(mark => mark.value === value);
            setMyDate(marks[index]);
        }}
        onChangeCommitted={(event, value) => {
            const index = marks.findIndex(mark => mark.value === value);

            if (datesObject[marks[index].date][0].disease) {
                disease = true;
            }
            else {
                disease = false;
            }

            setDate(marks[index]);
            setTaskList(datesObject[marks[index].date]);
            setTaskID(datesObject[marks[index].date][0].id);
            setTaskCounter(0);
        }}
    />;
}

const RightArrow = props => {
    const [mouseStyle, setMouseStyle] = useState({});
    const classes = useStyles();

    return <ArrowForwardIosIcon
        style={mouseStyle}
        color={props.counter === props.length - 1 ? "disabled" : "primary"}
        className={classes.icon}
        onMouseEnter={() => {
            if (props.counter !== props.length - 1) {
                setMouseStyle({ cursor: 'pointer' })
            }
        }}
        onMouseLeave={() => setMouseStyle({})}
        onClick={() => {
            if (props.counter !== props.length - 1) {
                props.setCounter(props.counter + 1);
            }
        }} />
}

const LeftArrow = props => {
    const [mouseStyle, setMouseStyle] = useState({});
    const classes = useStyles();

    return <ArrowBackIosIcon
        style={mouseStyle}
        color={props.counter === 0 ? "disabled" : "primary"}
        className={classes.icon}
        onMouseEnter={() => {
            if (props.counter !== 0) {
                setMouseStyle({ cursor: 'pointer' })
            }
        }}
        onMouseLeave={() => setMouseStyle({})}
        onClick={() => {
            if (props.counter !== 0) {
                props.setCounter(props.counter - 1);
            }
        }} />
}

const TaskMap = props => {
    const [mount, setMount] = useState(false);
    console.log(props)
    return <Fragment>
        <LeftArrow counter={props.taskCounter} setCounter={props.setTaskCounter} type='map' />

        <LoadScript
            id="script-loader"
            googleMapsApiKey={process.env.REACT_APP_MAPS_KEY}>
            <GoogleMap
                id='drawing-example'
                mapTypeId="satellite"
                zoom={14}
                center={mount ? null : { lat: props.lat, lng: props.lng }}
                onMouseOver={() => {
                    if (!mount)
                        setMount(true);
                }}
                mapContainerStyle={{
                    width: 500, height: 500
                }}>

                {props.mapImg && <GroundOverlay
                    key={props.mapImg.url}
                    url={props.mapImg.url}
                    bounds={props.mapImg.bounds}
                />}

                <Marker
                    position={{ lat: props.lat, lng: props.lng }}
                    title='location'
                />
                {Object.keys(props.indexes).length > 0 ?
                    <GroundOverlay
                        key={getKey(props.indexes[props.indexOption])}
                        url={`${process.env.REACT_APP_DATA_PROVIDER_URL}${props.indexes[props.indexOption]}`}
                        bounds={props.indexes.BBox} /> : null
                }
            </GoogleMap>
        </LoadScript >
        <RightArrow counter={props.taskCounter} setCounter={props.setTaskCounter} length={props.taskLength} type='map' />
    </Fragment>
}

const PhotoDisplay = props => {
    return <Fragment>
        <LeftArrow counter={props.photoCounter} setCounter={props.setPhotoCounter} />
        <div style={{ display: 'flex', justifyContent: 'center', width: 500, backgroundColor: '#0000000F' }}>
            {props.photo.file &&
                <img src={`${process.env.REACT_APP_DATA_PROVIDER_URL}${props.photo.file.url}`} style={{ maxWidth: 500 }} />
            }
        </div>
        <RightArrow counter={props.photoCounter} setCounter={props.setPhotoCounter} length={props.photoLength} />
    </Fragment>
}

const TaskAttributes = props => {
    const locale = useLocale();
    const classes = useStyles();
    return props.attributes.map((attribute, key) => {
        return <div key={key} style={{ margin: '1em', padding: '0.5em', backgroundColor: '#0000000F' }}>
            <div className={classes.attributeLabel}>
                {locale === 'en' ? attribute.name : attribute.name_ar}
            </div>
            <div className={classes.attribute}>
                {locale === 'ar' && attribute.value_ar ? attribute.value_ar : attribute.value}
            </div>
        </div>
    });
}

const IndexOption = props => {
    const { BBox, ...options } = props.indexes;
    return <FormControl component="fieldset">
        <RadioGroup aria-label="index" name="index" value={props.indexOption}
            onChange={(event) => props.setIndexOption(event.target.value)}
        >
            {Object.keys(options).map((option, key) =>
                <FormControlLabel style={{ margin: '1em 3em' }} key={key} value={option} control={<Radio />} label={option} />
            )}
        </RadioGroup>
    </FormControl>
}

export const ScoutingVis = props => {

    const classes = useStyles();
    let boundaries=[];


    const [mapImg, setMapImage] = useState(false)
    const [pivot, setPivot] = useState(0);
    const [pivotList, setPivotList] = useState([]);
    const [section, setSection] = useState(0);
    const [sectionList, setSectionList] = useState([]);
    const [date, setDate] = useState(null);
    const [dateList, setDateList] = useState([]);
    const [task_id, setTaskID] = useState(0);
    const [taskCounter, setTaskCounter] = useState(0);
    const [taskList, setTaskList] = useState([]);
    const [photoCounter, setPhotoCounter] = useState(0);
    const [photoList, setPhotoList] = useState([]);

    const [attributes, setAttributes] = useState([]);
    const [indexes, setIndexes] = useState({});
    const [indexOption, setIndexOption] = useState('NDVI');

    // fetching rgb farm images

    const fetchMapImg = async () => {
        const bounds = {
            north: -90,
            south: 90,
            east: -180,
            west: 180
        };
        const images = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/farms/${props.record.id}`);
        if (images) {
            let url = "";
            let avgLat = 0, avgLng = 0
            let initialCenter = {};
            if (images.json.borders) {
                if (images.json.file) {
                    url = process.env.REACT_APP_DATA_PROVIDER_URL + images.json.file.url;
                }
                boundaries = images.json.borders;
                boundaries = JSON.parse(boundaries);
                for (var i = 0; i < boundaries.length; i++) {
                    avgLat += boundaries[i][1];
                    avgLng += boundaries[i][0];
                    bounds.north = Math.max(bounds.north, boundaries[i][1]);
                    bounds.south = Math.min(bounds.south, boundaries[i][1]);
                    bounds.east = Math.max(bounds.east, boundaries[i][0]);
                    bounds.west = Math.min(bounds.west, boundaries[i][0]);
                }
                console.log(bounds)


                if (boundaries.length > 0) {
                    avgLat /= boundaries.length;
                    avgLng /= boundaries.length;
                }
                initialCenter = { lat: avgLat, lng: avgLng };

                // setViewport({
                //     latitude: avgLat,
                //     longitude: avgLng,
                //     zoom: 12,
                //     bearing: 0,
                //     pitch: 0
                //   })
            }

            setMapImage({
                url: url,
                bounds: bounds
            })
        }

    }
    

    // fetching pivots
    useEffect(() => {
        const fetchPivots = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/pivots?farm_id=${props.record.id}`);
            setPivotList(response.json);
        }
        fetchPivots();
        fetchMapImg();
    }, []);

    // fetching sections based on pivot id
    useEffect(() => {
        const fetchSections = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/sections?pivot_id=${pivot}`);
            setSectionList(response.json);
            setSection(0);
        }
        if (pivot)
            fetchSections();
    }, [pivot]);

    // fetching scouting tasks based on section id
    useEffect(() => {
        const fetchTasks = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/scoutingtasks?section_id=${section}` +
                `&_sort=action_date:ASC&action_date_null=false&disease=true`);
            datesObject = {};
            const tasks = response.json;
            tasks.forEach(task => {
                task.action_date = (new Date(task.action_date)).toJSON();
                task.action_date = task.action_date.slice(0, task.action_date.indexOf('T'));
                if (datesObject[task.action_date]) {
                    datesObject[task.action_date].push(task);
                }
                else {
                    datesObject[task.action_date] = [task];
                }
            });

            const marks = Object.keys(datesObject).map((date, key) => {
                return { value: key, date: date };
            });

            if (marks.length > 0) {
                if (datesObject[marks[marks.length - 1].date][0].disease) {
                    disease = true;
                }
                else {
                    disease = false;
                }

                setDateList(marks);
                setDate(marks[marks.length - 1]);
                setTaskList(datesObject[marks[marks.length - 1].date]);
                setTaskID(datesObject[marks[marks.length - 1].date][0].id)
                setTaskCounter(0);
            }
            else {
                setTaskList([]);
                setDateList([]);
                setPhotoList([]);
                setTaskID(0);
                setTaskCounter(0);
                setAttributes([]);
                setIndexes({});
            }
        }
        if (section)
            fetchTasks();
        else {
            setTaskList([]);
            setDateList([]);
            setPhotoList([]);
            setTaskID(0);
            setTaskCounter(0);
            setAttributes([]);
            setIndexes({});
        }
    }, [section]);

    // setting task_id based on task counter
    useEffect(() => {
        if (taskCounter < taskList.length) {
            if (taskList[taskCounter].disease) {
                disease = true;
            }
            else {
                disease = false;
            }
            setTaskID(taskList[taskCounter].id);
        }
    }, [taskCounter]);

    // fetching scouting photos based on task id
    useEffect(() => {
        const fetchPhotos = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/scoutingphotos?task_id=${task_id}` +
                (disease ? `&disease=true` : ``));
            setPhotoList(response.json);
            setPhotoCounter(0);
        }
        const fetchAttributes = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/taskattributes/getattributes?task_id=${task_id}` +
                (disease ? `&disease=true` : ``));
            setAttributes(response.json);
        }

        if (task_id) {
            fetchPhotos();
            fetchAttributes();
        }
        else {
            setPhotoList([]);
            setPhotoCounter(0);

            setAttributes([]);
        }
    }, [task_id]);

    // setting indexes based on date
    useEffect(() => {
        const fetchIndexes = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/scoutingtasks/indexes?section_id=${section}` +
                `&date=${date.date}`);
            setIndexes(response.json);
        }

        if (section)
            fetchIndexes();
        else
            setIndexes({});

    }, [date]);

    return <Fragment>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <MyDropDown data={pivotList} value={pivot} setValue={setPivot} label_id='Pivot' classes={classes} />
            <MyDropDown data={sectionList} value={section} setValue={setSection} label_id='Section' classes={classes} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            {section && dateList.length === 0 ?
                <Fragment>
                    <ErrorOutline className={classes.icon} />
                    <span style={{ margin: '0.5em', fontSize: '1.2em' }}>There are no scouting tasks</span>
                </Fragment> :
                dateList.length > 0 && date ?
                    <div style={{ width: 500, marginTop: '5em' }} >
                        <DateSlider marks={dateList} date={date} setDate={setDate}
                            setTaskList={setTaskList} setTaskID={setTaskID} setTaskCounter={setTaskCounter} />
                    </div>
                    : null
            }
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '3em' }}>
            {photoCounter < photoList.length ? <PhotoDisplay photo={photoList[photoCounter]}
                photoCounter={photoCounter} setPhotoCounter={setPhotoCounter} photoLength={photoList.length} /> : null}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: 300 }}>
                {attributes.length > 0 && <TaskAttributes attributes={attributes} />}
            </div>
            {taskCounter < taskList.length ?
                <TaskMap lat={taskList[taskCounter].lat} lng={taskList[taskCounter].lng}
                    indexOption={indexOption} indexes={indexes} task_id={task_id}
                    taskCounter={taskCounter} setTaskCounter={setTaskCounter} taskLength={taskList.length}
                    mapImg={mapImg} />
                : null
            }
            <div style={{ display: 'flex', justifyContent: 'center', width: 300 }}>
                {Object.keys(indexes).length > 0 &&
                    <IndexOption indexOption={indexOption} setIndexOption={setIndexOption} indexes={indexes} />}
            </div>
        </div>
    </Fragment>
}

const pivots = {};

export const ScoutingEnable = props => {
    const [sections, setSections] = useState([]);
    const [ids, setIds] = useState({});
    const classes = useStyles();
    const locale = useLocale();

    useEffect(() => {
        const fetchSections = async () => {
            const response = await httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/sections?farm_id=${props.record.id}&_sort=qr_value:ASC`);
            const idsObj = {};
            response.json.forEach(section => {
                idsObj[section.id] = section.scouting;
            })

            for (let section of response.json) {
                if (!pivots[section.pivot_id]) {
                    pivots[section.pivot_id] = section.qr_value.slice(0, section.qr_value.lastIndexOf('_'));
                }
            }

            setIds(idsObj);
            setSections(response.json);
        }
        fetchSections();
    }, []);

    return sections.length > 0 && Object.keys(ids).length > 0 ?
        <Fragment>
            <div className={classes.root}>
                <FormGroup style={{ flexDirection: 'row' }}>
                    {Object.keys(pivots).map(key => {
                        return <FormControl key={key} component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">{pivots[key]}</FormLabel>
                            {sections.filter(section => section.pivot_id == key).map((section, key) => {
                                return <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={ids[section.id]}
                                            onChange={(event) => setIds({ ...ids, [section.id]: event.target.checked })}
                                            value={section.id}
                                            color="primary"
                                        />
                                    }
                                    label={section.qr_value}
                                    key={key}
                                />
                            })}
                        </FormControl>;
                    })
                    }
                </FormGroup>
            </div>
            <Button
                variant="contained"
                color="primary"
                fullWidth={true}
                className={classes.formControl}
                onClick={() => {
                    httpClient(`${process.env.REACT_APP_DATA_PROVIDER_URL}/sections/scouting_enable`, {
                        method: 'POST',
                        body: JSON.stringify({
                            ids: ids,
                            farm_id: props.record.id
                        })
                    }).then(res => {
                        window.location.reload(true)
                    });
                }}
            >
                <span>{locale === 'en' ? "Save" : "حفظ"}</span>
            </Button>
        </Fragment>
        : null
}