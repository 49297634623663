import React, { Fragment, useEffect, useState } from "react";
import { ActionList } from "../../../src/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { shallowEqual, useSelector } from "react-redux";
import { useLocale } from "react-admin";
import {
  Grid,
  IconButton,
  Popover,
  LinearProgress,
  Card,
  CardContent,
  Button,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Divider,
} from "@material-ui/core";
import httpClient from "../../httpClient";
import { NotificationIcon } from "../components/icons/Notification";
import Cookies from "../../helpers/Cookies";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function getProps(index) {
  return {
    id: `tab-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
export default (props) => {
  const [actions, setActions] = useState([]);
  let users = useSelector((state) => state.admin.resources.users);
  let farms = useSelector((state) => state.admin.resources.farms);

  const userName = Cookies.getCookie("user");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const locale = useLocale();
  const handlePanelChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    async function getData() {
      let ids = [];
      if (users && farms && Object.keys(users.data).length !== 0) {
        for (const object in users.data) {
          // console.log("user", users.data[object].username);
          if (users.data[object].username === userName)
            ids.push(users.data[object].id);
        }

        let responseArray = [];
        Object.values(farms.data).map(async (farm) => {
          const response = await httpClient(
            `${process.env.REACT_APP_DATA_PROVIDER_URL}/weatheralerts?farm_id=${farm.id}`
          );
          console.log("response", responseArray);
          responseArray.push(...Object.values(response.json));
          setActions((prevState) =>([...prevState, ...responseArray]));
        });
        
      }
    }
    getData();
  }, [users]);
  const open2 = Boolean(anchorEl);
  return (
    <Fragment>
      <div className="text-center">
        <IconButton
          color="black"
          variant="contained"
          onClick={handleClickPopover}
        >
          <div class="badge badge-pill badge-warning badge-header">
            {actions.length}
          </div>
          <NotificationIcon />
        </IconButton>
        <Popover
          open={open2}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div className={classes.root}>
            <AppBar position="static">
              <Tabs
                position="static"
                value={value}
                onChange={handlePanelChange}
                aria-label="simple tabs example"
              >
                <Tab
                  label={locale === "en" ? "Weather" : "الطقس"}
                  {...getProps(0)}
                />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <List className="text-left bg-transparent popover-custom-wrapper">
                {actions.map((action, index) => (
                  <ListItem
                    key={index}
                    value={action}
                    style={{ padding: "0px" }}
                  >
                    <div className="align-box-row align-self-start">
                      <div className="timeline-list timeline-list-offset timeline-list-offset-dot">
                        <div
                          class="timeline-item-offset"
                          style={{ padding: "0 0 0 0px" }}
                        >
                          {new Date(action.time).toLocaleDateString(locale, {
                            hour: "numeric",
                            minute: "numeric",
                            day: "numeric",
                            month: "short",
                          })}
                        </div>
                        <div className="timeline-item">
                          <div className="timeline-item--content">
                            <div className="timeline-item--icon" />
                            <h4 className="timeline-item--label mb-2 font-weight-bold">
                              {locale === "en"
                                ? `${action.name}`
                                : `${action.name_ar}`}
                            </h4>
                            <p>{action.value}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ListItem>
                ))}
              </List>
            </TabPanel>
          </div>
        </Popover>
      </div>
    </Fragment>
  );
};
